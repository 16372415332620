import _ from 'lodash';
import 'moment-timezone';
import moment from 'moment';
import { v4 as uuidv4 } from 'uuid';
import KeyValueGrid from '../KeyValueGrid';
import { Stack, Typography } from '@mui/material';
import { getComplementaryInfoName, isDate, isNotAllowed } from 'src/utils/utilities';

type Props = {
  data: any;
  checklistItemModified: any;
};

const ComplementaryInfoReadMode = ({ 
  data,
  checklistItemModified
}: Props) => {

	const { scope = {} } = data;
  const { checklistItem = {} } = scope;

	const formattedData = _.merge(checklistItem, checklistItemModified);

  const ObjectComponent = ({ elem }: any) => {
    if(isNotAllowed.includes(elem?.[0])) {
      return <></>;
    }

    return (
      <Stack direction="row" alignItems="center" justifyContent="space-between">
        <KeyValueGrid
          label={getComplementaryInfoName(elem?.[0])} 
          value={isDate(elem?.[0])
            ? moment.utc(elem?.[1]).format('MM/DD/YYYY HH:mm:ss')
            : elem?.[1]}
        />
      </Stack>
    );
  };

  return formattedData && Object.keys(formattedData).length > 0 ? (
   <>
	 	{Object.entries(formattedData).map((item: any) => (
				<div key={uuidv4()} className="complementary-item-container">
					<Typography variant='body2'>
            <b>{getComplementaryInfoName(item?.[0])}</b>
					</Typography>

					<Stack>
						<Stack sx={{ m: 2 }}>
							{item?.[1] &&
								Object.keys(item?.[1]).length > 0 &&
								Object.entries(item?.[1]).sort().map((elem: any) => (
									elem?.[0] !== 'modifiedFields' ? (
                    <KeyValueGrid label={getComplementaryInfoName(elem?.[0])} value={isDate(elem?.[0])
                      ? moment.utc(elem?.[1]).format('MM/DD/YYYY HH:mm:ss')
                      : elem?.[1]} 
                    />
									) : (
										elem?.[1] &&
										Object.keys(elem?.[1]).length > 0 &&
										Object.entries(elem?.[1]).map((i: any) => {
											if (_.isObject(i?.[1])) {
												return Object.entries(i?.[1]).map((el: any) => {
													if (_.isObject(el?.[1])) {
														return Object.entries(el?.[1]).map((objItem: any) => (
															<ObjectComponent key={uuidv4()} elem={objItem} />
														));
													} else if (Array.isArray(el?.[1])) {
														return el?.[1].map((arItem: any) => (
															arItem && <ObjectComponent elem={[el?.[0], arItem]} />
														))
													} else {
														return <ObjectComponent elem={el} />;
													}
												});
											} else {
												if(!isNotAllowed.includes(i?.[0])) {
													return (
														<Stack
															direction="row"
															alignItems="center"
															justifyContent="space-between"
														>
															<KeyValueGrid 
																label={getComplementaryInfoName(i?.[0])}
																value= {i?.[0] === 'isInCounty' ? JSON.stringify(i?.[1]) === 'true'
																	? 'Yes'
																	: 'No'
																: isDate(i?.[0])
																? moment.utc(i?.[1]).format('MM/DD/YYYY HH:mm:ss')
																: i?.[1]}
															/>
														</Stack>
													);
												}
											}

											return <></>;
										})
									)
								))}
						</Stack>
					</Stack>
				</div>
			)
		)}
	 </>
  ) : (
    <Typography sx={{ fontSize: '0.875rem', marginTop: '-16px', marginBottom: '-20px !important' }}>
      No data
    </Typography>
  );
};

export default ComplementaryInfoReadMode;