export const ALL_WILDCARD = '*';
export const EDIT = 'edit';
export const ADD = 'add';
export const DELETE = 'delete';
export const VIEW = 'view';
export const MENU = 'menu';

class ACL {
  private _currentPath: string;

  public static separator: string = '.';

  constructor(path: string) {
    this._currentPath = path;
  }

  public concat(...acls: string[]): string {
    return concatACL(...[this.currentPath, ...acls]);
  }

  public newChild(path: string): ACL {
    return new ACL(`${this._currentPath}${ACL.separator}${path}`);
  }

  public get currentPath() {
    return this._currentPath;
  }
}

export const concatACL = (...acls: string[]) => acls.join(ACL.separator);

export default ACL;
