import { IconButton, Stack, Typography, Box } from '@mui/material';
import Iconify from 'src/components/Iconify';
import { Section } from 'src/utils/layout';

export interface EditableCardProps {
  children: React.ReactNode;
  title: string;
  onEditButtonPress: VoidFunction;
}

const EditableCard = ({ title, onEditButtonPress, children }: EditableCardProps) => (
  <Section fullWidth>
    <Box sx={{ paddingX: 1, paddingY: 2 }}>
      <Stack direction="row" sx={{ display: 'flex' }}>
        <Typography variant="button" sx={{ textTransform: 'capitalize' }}>
          {title}
        </Typography>

        <IconButton
          sx={{ position: 'relative', top: -10, marginLeft: 0.6 }}
          onClick={() => onEditButtonPress?.()}
        >
          <Iconify icon={'eva:edit-outline'} />
        </IconButton>
      </Stack>
      <Box sx={{ marginTop: 0 }}>{children}</Box>
    </Box>
  </Section>
);

export default EditableCard;
