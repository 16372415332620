export const TABLE_HEAD_PATIENT_ENGAGEMENT_LIST = [
  { id: 'workflowStatus', label: 'Status', align: 'left' },
  { id: 'workflowStageName', label: 'Sub Status', align: 'left' },
  { id: 'workflowScope', label: 'Scope', align: 'left' },
  { id: 'patientLastSIL', label: 'SIL', align: 'left' },
  { id: 'patientName', label: 'Patient', align: 'left' },
  { id: 'patientLanguage', label: 'Lang', align: 'left' },
  { id: 'contactAttemptContactOn', label: 'Last Contact', align: 'left' },
  { id: 'workflowOwnedBy', label: 'Assigned To', align: 'left' },
  { id: 'workflowDispositionReason', label: 'Disp Reason', align: 'left' },
  { id: 'workflowDispositionOn', label: 'Disp On', align: 'left' }
];

export const TABLE_HEAD_PATIENT_ENGAGEMENT_LIST_TABLE = [
  { id: 'workflowStatus', label: 'Status', align: 'left' },
  { id: 'workflowStageName', label: 'Sub Status', align: 'left' },
  { id: 'workflowScope', label: 'Scope', align: 'left' },
  { id: 'contactAttemptContactOn', label: 'Last Contact', align: 'left' },
  { id: 'workflowOwnedBy', label: 'Assigned To', align: 'left' },
  { id: 'workflowDispositionReason', label: 'Disp Reason', align: 'left' },
  { id: 'workflowDispositionOn', label: 'Disp On', align: 'left' }
];

export const TABLE_HEAD_CONTACT = [
  { id: 'when', label: 'When', align: 'center' },
  { id: 'outcome', label: 'Outcome', align: 'left' },
  { id: 'type', label: 'Type', align: 'left' },
  { id: 'nextContact', label: 'Next Contact', align: 'left' },
  { id: 'notes', label: 'Notes', align: 'center' },
];

export const TABLE_HEAD_NOTES = [
  { id: 'text', label: 'Text', align: 'center' },
  { id: 'type', label: 'Type', align: 'left' },
  { id: 'author', label: 'Author', align: 'left' },
  { id: 'date', label: 'Date', align: 'left' }
];
