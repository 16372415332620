import {
  Autocomplete,
  Box,
  Button,
  Grid,
  List,
  ListItem,
  Stack,
  TextField,
  TextFieldProps,
  Typography,
} from '@mui/material';
import Map from './Map';
import { isEmpty } from 'lodash';
import { useEffect, useState } from 'react';
import { Marker } from '@react-google-maps/api';
import parse from 'autosuggest-highlight/parse';
import LocationOnIcon from '@mui/icons-material/LocationOn';
import useAddEntityRequestStates from 'src/hooks/useAddEntityRequestStates';
import usePlacesService from 'react-google-autocomplete/lib/usePlacesAutocompleteService';
import useLocales from 'src/hooks/useLocales';

interface MainTextMatchedSubstrings {
  offset: number;
  length: number;
}

interface StructuredFormatting {
  main_text: string;
  secondary_text: string;
  main_text_matched_substrings: readonly MainTextMatchedSubstrings[];
}

interface PlaceType {
  place_id: string;
  description: string;
  structured_formatting: StructuredFormatting;
}

interface AddressInfoExtensionType {
  url?: string;
  valueBoolean?: boolean;
  valueString?: string;
  valueDecimal?: number;
}

interface AddressInfoType {
  id?: string;
  line?: string[];
  city?: string;
  district?: string;
  state?: string;
  postalCode?: string;
  country?: string;
  extension?: [{ extension: AddressInfoExtensionType[] }][];
  latitude?: number;
  longitude?: number;
}

const FHIR_API = process.env.REACT_APP_FHIR_API_BASE_URL;


const AddAddress = ({
  handleClose,
  handleSave,
  isEditable,
  externalAddress,
  children,
  height,
  disabled,
}: any) => {
  const [addressData, setAddressData] = useState<AddressInfoType>();
  const [selectedAddress, setSelectedAddress] = useState<PlaceType | null>(null);
  const [markerPosition, setMarkerPosition] = useState({
    lat: '',
    lng: '',
  });
  const [{ error }, { setError }] = useAddEntityRequestStates();
  const [autocompleteOptions, setAutocompleteOptions] = useState<any[]>([]);
  const { i18n } = useLocales();

  const { placesService, placePredictions, getPlacePredictions, isPlacePredictionsLoading } =
    usePlacesService({});

  const updateAddressData = (
    callback: (prevState: AddressInfoType | undefined) => AddressInfoType
  ) => {
    if (!isEditable) return;
    if (callback)
      setAddressData((data) => ({
        ...data,
        ...callback(data),
      }));
  };

  useEffect(() => {
    if (!externalAddress) return;
    const { id, city, district, postalCode, state, country, line } = externalAddress ?? {};
    setAddressData({
      id: id || '',
      line: line || [],
      city: city || '',
      district: district || '',
      state: state || '',
      postalCode: postalCode || '',
      country: country || '',
    });
  }, [externalAddress]);

  const handleAddressLineChange =
    (lineNumber: number) => (event: React.ChangeEvent<HTMLInputElement>) => {
      updateAddressData((address) => {
        const lines = [...(address?.line ?? [])];
        lines[lineNumber] = event.target.value;
        return { line: lines };
      });
      setError(null);
    };

  const handleFieldChange =
    (fieldName: keyof AddressInfoType) => (event: React.ChangeEvent<HTMLInputElement>) => {
      updateAddressData((_) => ({
        [fieldName]: event.target.value,
      }));
      setError(null);
    };

  const getLine = (addressArray: any) => {
    let street = '';
    for (let i = 0; i < addressArray.length; i++) {
      if (
        (addressArray[i].types[0] && 'street_number' === addressArray[i].types[0]) ||
        'route' === addressArray[i].types[0]
      ) {
        street = street + addressArray[i].long_name + ' ';
      }
    }
    return street;
  };

  const getCity = (addressArray: any) => {
    let city = '';
    for (let i = 0; i < addressArray.length; i++) {
      if (addressArray[i].types[0] && 'locality' === addressArray[i].types[0]) {
        city = addressArray[i].long_name;
        return city;
      }
    }
  };

  const getState = (addressArray: any) => {
    let state = '';
    for (let i = 0; i < addressArray.length; i++) {
      if (addressArray[i].types[0] && 'administrative_area_level_1' === addressArray[i].types[0]) {
        state = addressArray[i].long_name;
        return state;
      }
    }
  };

  const getDistrict = (addressArray: any) => {
    let district = '';
    for (let i = 0; i < addressArray.length; i++) {
      if (addressArray[i].types[0] && 'administrative_area_level_2' === addressArray[i].types[0]) {
        district = addressArray[i].long_name;
        return district;
      }
    }
  };

  const getPostalCode = (addressArray: any) => {
    let postal = '';
    for (let i = 0; i < addressArray.length; i++) {
      if (addressArray[i].types[0] && 'postal_code' === addressArray[i].types[0]) {
        postal = addressArray[i].long_name;
        return postal;
      }
    }
  };

  const getCountry = (addressArray: any) => {
    let country = '';
    for (let i = 0; i < addressArray.length; i++) {
      if (addressArray[i].types[0] && 'country' === addressArray[i].types[0]) {
        country = addressArray[i].long_name;
        return country;
      }
    }
  };

  const onPlaceSelected = (place: any) => {
    const addressArray = place.address_components;
    const line = getLine(addressArray);
    const city = getCity(addressArray);
    const state = getState(addressArray);
    const district = getDistrict(addressArray);
    const postalCode = getPostalCode(addressArray);
    const country = getCountry(addressArray);
    setAddressData({
      ...addressData,
      id: place.place_id,
      line: [line],
      city: city || '',
      district: district || '',
      state: state || '',
      postalCode: postalCode || '',
      country: country || '',
      latitude: place.geometry.location.lat(),
      longitude: place.geometry.location.lng(),
    });
    setMarkerPosition({
      lat: place.geometry.location.lat(),
      lng: place.geometry.location.lng(),
    });
  };

  const validateRequiredFields = (payload: any) => {
    let isValid = true;
    if (
      isEmpty(payload?.line) ||
      isEmpty(payload?.city) ||
      isEmpty(payload?.district) ||
      isEmpty(payload?.state) ||
      isEmpty(payload?.postalCode)
    ) {
      setError('Please, fill all required fields')
      isValid = false;
    }
    return isValid;
  };

  const saveAddress = () => {
    const payload = {
      id: addressData?.id,
      city: addressData?.city,
      district: addressData?.district,
      line: addressData?.line,
      postalCode: addressData?.postalCode,
      state: addressData?.state,
      country: addressData?.country,
      use: 'home',
      extension: [
        {
          extension: [
            {
              url: 'identifier',
              valueString: addressData?.id,
            },
            {
              url: 'valid',
              valueBoolean: true,
            },
            {
              url: 'address',
              valueString: addressData?.line?.[0],
            },
            {
              url: 'latitude',
              valueDecimal: addressData?.latitude,
            },
            {
              url: 'longitude',
              valueDecimal: addressData?.longitude,
            },
          ],
          url: `${FHIR_API}/StructureDefinition/PH-address-valid`,
        },
      ],
    };

    if (!validateRequiredFields(payload)) return;

    handleSave(payload);
  };

  useEffect(() => {
    setAutocompleteOptions(placePredictions);
  }, [JSON.stringify(placePredictions)]);

  useEffect(() => {
    if (selectedAddress !== null) {
      placesService?.getDetails(
        {
          placeId: selectedAddress.place_id,
        },
        onPlaceSelected
      );
    }
  }, [selectedAddress]);

  return (
    <Grid
      container
      spacing={1}
      sx={{
        height: 600,
      }}
    >
      <Grid item sm={5}>
        <List>
          <ListItem>
            <Autocomplete
              autoComplete
              includeInputInList
              getOptionLabel={(option) =>
                typeof option === 'string' ? option : option.description
              }
              filterOptions={(x) => x}
              filterSelectedOptions
              isOptionEqualToValue={(option, value) => option.place_id === value.place_id}
              renderInput={(params: TextFieldProps) => (
                <TextField {...params} label={i18n('addAddress.enterAddress', 'crs')} fullWidth />
              )}
              onChange={(event: any, newValue: PlaceType) => {
                setSelectedAddress(newValue);
                setError(null);
              }}
              onInputChange={(event: any, newInputValue: string) => {
                getPlacePredictions({
                  input: newInputValue,
                });
              }}
              value={selectedAddress}
              options={autocompleteOptions}
              style={{ width: '100%' }}
              loading={isPlacePredictionsLoading}
              renderOption={(props, option) => {
                const matches = option.structured_formatting.main_text_matched_substrings;
                const parts = parse(
                  option.structured_formatting.main_text,
                  matches?.map((match: any) => [match.offset, match.offset + match.length])
                );

                return (
                  <li {...props}>
                    <Grid container alignItems="center">
                      <Grid item>
                        <Box component={LocationOnIcon} sx={{ color: 'text.secondary', mr: 2 }} />
                      </Grid>
                      <Grid item xs>
                        {parts.map((part, index) => (
                          <span
                            key={index}
                            style={{
                              fontWeight: part.highlight ? 700 : 400,
                            }}
                          >
                            {part.text}
                          </span>
                        ))}
                        <Typography variant="body2" color="text.secondary">
                          {option.structured_formatting.secondary_text}
                        </Typography>
                      </Grid>
                    </Grid>
                  </li>
                );
              }}
            />
          </ListItem>

          <ListItem>
            <TextField
              label={i18n('addAddress.streetAddress', 'crs')}
              value={addressData?.line?.[0] || ''}
              onChange={handleAddressLineChange(0)}
              fullWidth
              error={!!error && isEmpty(addressData?.line?.[0]) ? true : false}
            />
          </ListItem>

          <ListItem>
            <TextField
              label={i18n('addAddress.unitSuitFloor', 'crs')}
              value={addressData?.line?.[1] || ''}
              onChange={handleAddressLineChange(1)}
              fullWidth
            />
          </ListItem>

          <ListItem>
            <TextField
              label={i18n('addAddress.city', 'crs')}
              value={addressData?.city || ''}
              onChange={handleFieldChange('city')}
              fullWidth
              error={!!error && !addressData?.city ? true : false}
            />
          </ListItem>

          <ListItem>
            <TextField
              label={i18n('addAddress.county', 'crs')}
              value={addressData?.district || ''}
              onChange={handleFieldChange('district')}
              fullWidth
              error={!!error && !addressData?.district ? true : false}
            />
          </ListItem>

          <ListItem>
            <TextField
              label={i18n('addAddress.state', 'crs')}
              value={addressData?.state || ''}
              onChange={handleFieldChange('state')}
              fullWidth
              error={!!error && !addressData?.state ? true : false}
            />
          </ListItem>

          <ListItem>
            <TextField
              label={i18n('addAddress.zipCode', 'crs')}
              value={addressData?.postalCode || ''}
              onChange={handleFieldChange('postalCode')}
              fullWidth
              error={!!error && !addressData?.postalCode ? true : false}
            />
          </ListItem>
          {children}
        </List>
      </Grid>

      <Grid item sm={7} sx={{ mt: 2 }}>
        <Map
          center={{ lat: parseFloat(markerPosition.lat), lng: parseFloat(markerPosition.lng) }}
          height={height}
        >
          {markerPosition.lat !== '' && markerPosition.lng !== '' ? (
            <Marker
              position={{
                lat: parseFloat(markerPosition.lat),
                lng: parseFloat(markerPosition.lng),
              }}
            />
          ) : null}
        </Map>
      </Grid>

      {error &&
        <Grid
          item
          xs={12}
          display={'flex'}
          justifyContent={'center'}
        >
          <Typography sx={{ color: '#FF4842' }}>
            {error}
          </Typography>
        </Grid>
      }

      <Grid item sm={12}>
        <Stack direction="row" spacing={2} justifyContent="flex-end" sx={{ mb: 2, marginTop: 1 }}>
          <Button variant="contained" color="inherit" onClick={handleClose}>
            {i18n('cancel')}
          </Button>
          <Button variant="contained" onClick={saveAddress} disabled={disabled}>
            {i18n('save')}
          </Button>
        </Stack>
      </Grid>
    </Grid>
  );
};

export default AddAddress;
