import moment from 'moment';
import { useState } from 'react';
import { capitalCase } from 'change-case';
import { TableMoreMenu } from 'src/components/table';
import { MenuItem, TableCell, TableRow, Typography } from '@mui/material';
import { NoteAdd as NoteAddIcon, Edit as EditIcon } from '@mui/icons-material';
import { PatientGenericServiceRequestDto } from './patientGenericServiceRequest.dto';
import { checkAclValidation } from 'src/utils/permissions/permission.utils';
import crsAcls from 'src/utils/permissions/crs/crsAcls';
import CustomLink from 'src/sections/crs/common/CustomLink';

type Props = {
  row: PatientGenericServiceRequestDto;
  onNotesOpen: VoidFunction;
  onOpenReferral: VoidFunction;
  onEdit?: VoidFunction;
};

export function PatientGenericReferralRow({ row, onNotesOpen, onEdit, onOpenReferral }: Props) {
  const [data, setData] = useState(null);
  const [openMenu, setOpenMenuActions] = useState<HTMLElement | null>(null);
  const [openModalEdit, setOpenModalEdit] = useState(false);

  const handleOpenMenu = (event: React.MouseEvent<HTMLElement>) => {
    setOpenMenuActions(event.currentTarget);
  };

  const handleCloseMenu = () => {
    setOpenMenuActions(null);
  };

  const handleEdit = (row: any) => {
    handleCloseMenu();
    setData(row);
    setOpenModalEdit(true);
  };

  return (
    <>
      <TableRow>
        <TableCell>
          {
            <CustomLink
              to="#"
              onClick={() => {
                onOpenReferral();
              }}
            >
              <Typography noWrap variant="subtitle2">
                {row?.internalNumber}
              </Typography>
            </CustomLink>
          }
        </TableCell>
        <TableCell>{row?.created && moment(row?.created).format('MM/DD/YYYY')}</TableCell>
        <TableCell>{row?.referredFromName}</TableCell>
        <TableCell>{row?.serviceName}</TableCell>
        <TableCell>{row?.serviceOrganizationName}</TableCell>
        <TableCell>{row?.status && capitalCase(row?.status)}</TableCell>
        {checkAclValidation({ acls: [crsAcls.CRS.PATIENT.SERVICE_REQUESTS.NOTES.VIEW] }) && (
          <TableCell align="right">
            <TableMoreMenu
              open={openMenu}
              onOpen={handleOpenMenu}
              onClose={handleCloseMenu}
              actions={[
                <MenuItem key={row?.id} onClick={() => onNotesOpen()}>
                  <NoteAddIcon />
                  Notes
                </MenuItem>,
                <MenuItem key={row?.id} onClick={() => onEdit?.()}>
                  <EditIcon />
                  Edit
                </MenuItem>,
              ]}
            />
          </TableCell>
        )}
      </TableRow>
    </>
  );
}
