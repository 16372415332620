import {
  Box,
  Button,
  ButtonProps,
  CircularProgress,
  Grid,
  SxProps,
  Typography,
} from '@mui/material';
import CustomDrawer, { CustomDrawerProps } from 'src/components/CustomDrawer';
import { Option } from 'src/@types/crs/case';
import { spreadSxProp } from 'src/utils/cssStyles';
import useLocales from 'src/hooks/useLocales';
import { GridItem } from 'src/components/CustomModal';
import { Star as StarIcon } from '@mui/icons-material';

export interface PreviewDetailDrawerProps extends Omit<CustomDrawerProps, 'title'> {
  isLoading?: boolean;
  shouldShowActionButton?: boolean;
  actionButtonTitle?: string;
  actionButtonProps?: ButtonProps;
  title?: string;
}

const { valueTextFontSize, blackTextColor }: { [k: string]: SxProps } = {
  valueTextFontSize: {
    fontSize: '0.8rem',
  },
  blackTextColor: {
    color: '#212b36',
  },
};

const PreviewDetailDrawer = ({
  isLoading,
  containerSx,
  shouldShowActionButton,
  actionButtonTitle = 'Edit',
  actionButtonProps,
  children,
  title,
  contentContainerSx,
  ...drawerProps
}: PreviewDetailDrawerProps) => {
  const { sx: actionButtonSx, ...otherActionButtonProps } = actionButtonProps ?? {};
  const { i18n } = useLocales();

  return (
    <CustomDrawer
      title={title || i18n('preview', 'crs')}
      {...drawerProps}
      containerSx={[{ width: '25vw' }, ...spreadSxProp(containerSx)]}
      contentContainerSx={{ padding: 0 }}
    >
      {!isLoading ? (
        <>
          <Container sx={[{ backgroundColor: '#f4f6f8' }, ...spreadSxProp(contentContainerSx)]}>
            {children}
          </Container>
          {shouldShowActionButton && (
            <Container sx={{ mt: 3 }}>
              <Button
                fullWidth
                sx={[{ height: 48 }, ...spreadSxProp(actionButtonSx)]}
                variant="contained"
                {...otherActionButtonProps}
              >
                {actionButtonTitle}
              </Button>
            </Container>
          )}
        </>
      ) : (
        <Box flex={1} display={'flex'} justifyContent={'center'} alignItems={'center'}>
          <CircularProgress />
        </Box>
      )}
    </CustomDrawer>
  );
};

export const Container = ({ children, sx }: { children: React.ReactNode; sx?: SxProps }) => (
  <Box
    sx={[
      {
        px: 2.8,
      },
      ...spreadSxProp(sx),
    ]}
  >
    {children}
  </Box>
);

interface InfoRowProps extends Option<string | React.ReactNode> {
  labelSx?: SxProps;
}

export const InfoRow = ({ label, value, labelSx }: InfoRowProps) => (
  <Grid
    container
    sx={{
      my: 2,
      width: '100%',
    }}
  >
    <GridItem xs={4}>
      <Typography
        sx={[
          valueTextFontSize,
          { color: '#637381', width: '100%', wordWrap: 'break-word' },
          ...spreadSxProp(labelSx),
        ]}
      >
        {label}
      </Typography>
    </GridItem>
    <GridItem xs={8}>
      <InfoRowValue value={value} />
    </GridItem>
  </Grid>
);

export const InfoRowValue = ({ value, sx }: { value: string | React.ReactNode; sx?: SxProps }) => (
  <Typography
    sx={[
      valueTextFontSize,
      blackTextColor,
      { wordWrap: 'break-word', width: '100%' },
      ...spreadSxProp(sx),
    ]}
  >
    {value}
  </Typography>
);

interface SectionInfoProps {
  title: string;
  children: React.ReactNode;
  sx?: SxProps;
}

export const SectionInfo = ({ title, children, sx }: SectionInfoProps) => (
  <Box sx={[{ my: 3 }, ...spreadSxProp(sx)]}>
    <Typography sx={{ fontWeight: 'bold', mb: 2 }}>{title}</Typography>
    {children}
  </Box>
);

export interface InfoRowValueListProps {
  label: string;
  values: (string | undefined | null)[];
  showPreferredIcon?: boolean;
  valueSx?: SxProps;
}

export const InfoRowValueList = ({
  label,
  values,
  showPreferredIcon = true,
  valueSx,
}: InfoRowValueListProps) => (
  <>
    {!!values.length && (
      <InfoRow
        label={label}
        value={values
          .filter((value) => !!value)
          .map((value, index) => (
            <Box
              key={`${label}-${value}`}
              sx={{
                display: 'flex',
                flexDirection: 'row',
                justifyContent: 'flex-start',
              }}
            >
              <InfoRowValue
                value={value}
                sx={[{ marginTop: index !== 0 ? 1 : 0, flex: 0 }, ...spreadSxProp(valueSx)]}
              />
              {index === 0 && showPreferredIcon && (
                <StarIcon
                  htmlColor="#637381"
                  sx={[
                    {
                      marginLeft: 0.5,
                      height: 15,
                      width: 15,
                      top: 1,
                      position: 'relative',
                    },
                  ]}
                />
              )}
            </Box>
          ))}
      />
    )}
  </>
);

export default PreviewDetailDrawer;
