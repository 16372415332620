// @mui
import { enUS, frFR, zhCN, viVN, arSD } from '@mui/material/locale';
// components
import { SettingsValueProps } from './components/settings/type';
// routes
import { PATH_DASHBOARD } from './routes/paths';

export enum Modules {
  CCM = 'ccm',
  CRS = 'crs',
  ENGAGEMENT_HUB = 'engagementhub',
}

// API
// ----------------------------------------------------------------------
export const NODE_ENV = process.env.NODE_ENV;
export const REACT_APP_SECTION_CRS = process.env.REACT_APP_SECTION_CRS ?? '';
export const IS_CRS = REACT_APP_SECTION_CRS.toLowerCase() === Modules.CRS;
export const HOST_API = process.env.REACT_APP_HOST_API || '';

export const REACT_APP_SENTRY_DSN_KEY = process.env.REACT_APP_SENTRY_DSN_KEY;
export const APP_URL = process.env.REACT_APP_APP_URL;
export const SELECT_TENANT_URL = APP_URL + '/select-tenant';
export const SESSION_EXPIRED_URL = APP_URL + '/session-expired';
export const REDIRECT_AUTHORIZE_URL = HOST_API + '/auth/redirect-authorize-url';
export const RENDER_TEMPLATE_BACK_URL = HOST_API + '/crs/case/render-template';

export const DEVELOPMENT_CONFIG = {
  disableTokenCheck: process.env.REACT_APP_DEVELOPMENT_DISABLE_TOKEN_CHECK === 'true',
};

export const DEBUG_CONFIG = {
  debugTokenLife: process.env.REACT_APP_DEBUG_TOKEN_LIFE === 'true',
};

export const HAPI_ICD_URL = process.env.REACT_APP_ICD_HAPI_CODES_URL || '';

export const ASSESSMENTS_BASE_URL =
  process.env.REACT_APP_ASSESSMENTS_BASE_URL || 'https://assessments.nicheaim.com/index.php';
export const ASSESSMENT_SOCIAL_HISTORY = process.env.REACT_APP_ASSESSMENT_SOCIAL_HISTORY;
export const ASSESSMENT_PREVENTION_LINK = process.env.REACT_APP_ASSESSMENT_PREVENTION_LINK;

export const FORESIGHT_IFRAME_URL = process.env.REACT_APP_FORESIGHT_IFRAME_URL;
export const PROVIDER_WINDOW_LAUNCH_URL = process.env.REACT_APP_PROVIDER_WINDOW_LAUNCH_URL;
export const FORESIGHT_REPORTS_LAUNCH_URL = process.env.REACT_APP_FORESIGHT_REPORTS_LAUNCH_URL;
export const FORESIGHT_ANALYTICS_LAUNCH_URL = process.env.REACT_APP_FORESIGHT_ANALYTICS_LAUNCH_URL;

export const REACT_APP_CARE_PLAN_PDF_URL = process.env.REACT_APP_CARE_PLAN_PDF_URL;

export const PRINTABLE_CARE_PLAN_TEMPLATE_URL =
  process.env.REACT_APP_PRINTABLE_CARE_PLAN_TEMPLATE_URL;

export const REACT_APP_OUTBOUND_REFERRAL_FEATURE_FLAG = !!eval(
  process.env.REACT_APP_OUTBOUND_REFERRAL_FEATURE_FLAG ?? ''
);

//Yocare

export const YOCARE_FIREBASE_DEFAULT_COLLECTION_PATH =
  process.env.REACT_APP_YOCARE_FIREBASE_DEFAULT_COLLECTION_PATH ?? '';

export const YOCARE_FIREBASE_STORAGE_PROFILE_IMAGE_PATH =
  process.env.REACT_APP_YOCARE_FIREBASE_STORAGE_PROFILE_IMAGE_PATH ?? '';

export const YOCARE_FIREBASE_STORAGE_DATA_SYSTEM_ICON_PATH =
  process.env.REACT_APP_YOCARE_FIREBASE_STORAGE_DATA_SYSTEM_ICON_PATH ?? '';

export const YOCARE_FIREBASE_CONFIG = {
  apiKey: process.env.REACT_APP_YOCARE_FIREBASE_API_KEY,
  authDomain: process.env.REACT_APP_YOCARE_FIREBASE_AUTH_DOMAIN,
  projectId: process.env.REACT_APP_YOCARE_FIREBASE_PROJECT_ID,
  storageBucket: process.env.REACT_APP_YOCARE_FIREBASE_STORAGE_BUCKET,
  messagingSenderId: process.env.REACT_APP_YOCARE_FIREBASE_MESSAGING_SENDER_ID,
  appId: process.env.REACT_APP_YOCARE_FIREBASE_APP_ID,
  measurementId: process.env.REACT_APP_YOCARE_FIREBASE_MEASUREMENT_ID,
};

export const TENANT_INFO = {
  logo: process.env.REACT_APP_TENANT_INFO_LOGO,
  tenantName: process.env.REACT_APP_TENANT_INFO_NAME,
  pagePrefix: process.env.REACT_APP_TENANT_INFO_PAGE_PREFIX,
  tenantFavicon: process.env.REACT_APP_TENANT_INFO_FAVICON,
  tenantSpinnerLogo: process.env.REACT_APP_TENANT_INFO_SPINNER_LOGO,
  url: process.env.REACT_APP_TENANT_INFO_URL,
};

export const FIREBASE_API = {
  apiKey: process.env.REACT_APP_FIREBASE_API_KEY,
  authDomain: process.env.REACT_APP_FIREBASE_AUTH_DOMAIN,
  databaseURL: process.env.REACT_APP_FIREBASE_DATABASE_URL,
  projectId: process.env.REACT_APP_FIREBASE_PROJECT_ID,
  storageBucket: process.env.REACT_APP_FIREBASE_STORAGE_BUCKET,
  messagingSenderId: process.env.REACT_APP_FIREBASE_MESSAGING_SENDER_ID,
  appId: process.env.REACT_APP_FIREBASE_APPID,
  measurementId: process.env.REACT_APP_FIREBASE_MEASUREMENT_ID,
};

export const COGNITO_API = {
  userPoolId: process.env.REACT_APP_AWS_COGNITO_USER_POOL_ID,
  clientId: process.env.REACT_APP_AWS_COGNITO_CLIENT_ID,
};

export const AUTH0_API = {
  clientId: process.env.REACT_APP_AUTH0_CLIENT_ID,
  domain: process.env.REACT_APP_AUTH0_DOMAIN,
};

export const MAPBOX_API = process.env.REACT_APP_MAPBOX_API;

// ROOT PATH AFTER LOGIN SUCCESSFUL
export const PATH_AFTER_LOGIN =
  REACT_APP_SECTION_CRS.toLowerCase() === 'crs'
    ? PATH_DASHBOARD.general.app
    : PATH_DASHBOARD.general.activities; // as '/dashboard/app'

// LAYOUT
// ----------------------------------------------------------------------

export const HEADER = {
  MOBILE_HEIGHT: 64,
  MAIN_DESKTOP_HEIGHT: 88,
  DASHBOARD_DESKTOP_HEIGHT: 92,
  DASHBOARD_DESKTOP_OFFSET_HEIGHT: 92 - 32,
};

export const NAVBAR = {
  BASE_WIDTH: 260,
  DASHBOARD_WIDTH: 280,
  DASHBOARD_COLLAPSE_WIDTH: 88,
  //
  DASHBOARD_ITEM_ROOT_HEIGHT: 48,
  DASHBOARD_ITEM_SUB_HEIGHT: 40,
  DASHBOARD_ITEM_HORIZONTAL_HEIGHT: 32,
};

export const ICON = {
  NAVBAR_ITEM: 22,
  NAVBAR_ITEM_HORIZONTAL: 20,
};

// SETTINGS
// Please remove `localStorage` when you change settings.
// ----------------------------------------------------------------------

export const defaultSettings: SettingsValueProps = {
  themeMode: 'light',
  themeDirection: 'ltr',
  themeContrast: 'default',
  themeLayout: 'horizontal',
  themeColorPresets: 'default',
  themeStretch: false,
};

// MULTI LANGUAGES
// Please remove `localStorage` when you change settings.
// ----------------------------------------------------------------------

export const allLangs = [
  {
    label: 'CT',
    value: 'ct',
    systemValue: enUS,
    icon: '/assets/icons/flags/ic_flag_us.svg',
  },
  {
    label: 'NJ',
    value: 'nj',
    systemValue: enUS,
    icon: '/assets/icons/flags/ic_flag_us.svg',
  },
  {
    label: 'SBHA',
    value: 'mcc_sbha',
    systemValue: enUS,
    icon: '/assets/icons/flags/ic_flag_us.svg',
  },
  {
    label: 'NJ Demo',
    value: 'mcc_demo',
    systemValue: enUS,
    icon: '/assets/icons/flags/ic_flag_us.svg',
  },
  // {
  //   label: 'French',
  //   value: 'fr',
  //   systemValue: frFR,
  //   icon: '/assets/icons/flags/ic_flag_fr.svg',
  // },
  // {
  //   label: 'Vietnamese',
  //   value: 'vn',
  //   systemValue: viVN,
  //   icon: '/assets/icons/flags/ic_flag_vn.svg',
  // },
  // {
  //   label: 'Chinese',
  //   value: 'cn',
  //   systemValue: zhCN,
  //   icon: '/assets/icons/flags/ic_flag_cn.svg',
  // },
  // {
  //   label: 'Arabic (Sudan)',
  //   value: 'ar',
  //   systemValue: arSD,
  //   icon: '/assets/icons/flags/ic_flag_sa.svg',
  // },
  // {
  //   label: 'CT',
  //   value: 'ct',
  //   systemValue: arSD,
  //   icon: '/assets/icons/flags/ic_flag_sa.svg',
  // },
  // {
  //   label: 'NJ',
  //   value: 'nj',
  //   systemValue: arSD,
  //   icon: '/assets/icons/flags/ic_flag_sa.svg',
  // },
];
// ----------------------------------------------------------------------

export const defaultLang = allLangs[0]; // English

export const FHIR_CATEGORIES = {
  ethnicity: process.env.REACT_APP_ETHNICITY_URL,
  race:
    process.env.REACT_APP_RACE_URL ||
    'http://hl7.org/fhir/us/core/StructureDefinition/us-core-race',
  ssn: process.env.REACT_APP_SYSTEM_SSN || 'http://hl7.org/fhir/sid/us-ssn',
  sexualOrientation:
    process.env.REACT_APP_SEXUAL_ORIENTATION_URL ||
    'http://hl7.org/fhir/us/cdmh/StructureDefinition/cdmh-patient-sexualOrientation',
};

export const config = {
  fhirServerUrl: process.env.REACT_APP_FHIR_API_BASE_URL,
  fhirTenantId: '', // do not set header until FHIR Proxy accepts it again // process.env.REACT_APP_FHIR_TENANT_ID,
  smartLaunchUrl: process.env.REACT_APP_SMART_LAUNCH_URL,
};

export const OBSERVATION_CODE = process.env.REACT_APP_OBSERVATION_CODE;
export const OBSERVATION_TYPE = process.env.REACT_APP_OBSERVATION_TYPE;
export const OBSERVATION_BARRIERS_CODE_SYSTEM =
  process.env.REACT_APP_OBSERVATION_BARRIERS_CODE_SYSTEM || '';
export const OBSERVATION_INCK_SCORES_CODE_SYSTEM =
  process.env.REACT_APP_OBSERVATION_INCK_SCORES_CODE_SYSTEM || '';

export const USE_MINT_REFERRAL_WINDOW = process.env.REACT_APP_USE_MINT_REFERRAL_WINDOW || false;

export const GENDER_IDENTITY_EXTENSION_DEFINITION_URL =
  process.env.REACT_APP_GENDER_IDENTITY_EXTENSION_DEFINITION_URL ?? '';

export const GENDER_IDENTITY_CODE_SYSTEM_URL =
  process.env.REACT_APP_GENDER_IDENTITY_CODE_SYSTEM_URL ?? '';

export const MEDICAID_IDENTIFIER_SYSTEM_URL =
  process.env.REACT_APP_MEDICAID_IDENTIFIER_SYSTEM_URL ?? '';

//Incident Module
// ADD REACT_APP env
export const PRACTITIONER_PROVIDER_DIR_LINK_IDENTIFIER_SYSTEM =
  process.env.REACT_APP_PRACTITIONER_PROVIDER_DIR_LINK_IDENTIFIER_SYSTEM ?? '';

export const PRACTITIONER_PROVIDER_DIR_LINK_IDENTIFIER_TYPE_CODE =
  process.env.REACT_APP_PRACTITIONER_PROVIDER_DIR_LINK_IDENTIFIER_TYPE_CODE ?? '';

export const PRACTITIONER_PROVIDER_DIR_LINK_IDENTIFIER_TYPE_SYSTEM =
  process.env.REACT_APP_PRACTITIONER_PROVIDER_DIR_LINK_IDENTIFIER_TYPE_SYSTEM ?? '';

export const PROVIDER_LICENSE_VERIFICATION_IDENTIFIER_SYSTEM =
  process.env.REACT_APP_PROVIDER_LICENSE_VERIFICATION_IDENTIFIER_SYSTEM ?? '';

export const PROVIDER_LICENSE_VERIFICATION_IDENTIFIER_CODING_SYSTEM =
  process.env.REACT_APP_PROVIDER_LICENSE_VERIFICATION_IDENTIFIER_CODING_SYSTEM ?? '';

export const PROVIDER_LICENSE_IDENTIFIER_CODING_CODE =
  process.env.REACT_APP_PROVIDER_LICENSE_IDENTIFIER_CODING_CODE ?? '';

export const PROVIDER_CLASSIFICATION_CODING_SYSTEM =
  process.env.REACT_APP_PROVIDER_CLASSIFICATION_CODING_SYSTEM ?? '';

export const PROVIDER_CLASSIFICATION_IDENTIFIER_CODING_CODE =
  process.env.REACT_APP_PROVIDER_CLASSIFICATION_IDENTIFIER_CODING_CODE ?? '';

export const PH_META_TAG_CREATED_ON_CODE = process.env.REACT_APP_PH_META_TAG_CREATED_ON_CODE ?? '';

export const PH_META_TAG_CREATED_BY_CODE = process.env.REACT_APP_PH_META_TAG_CREATED_BY_CODE ?? '';

export const PH_META_TAG_CODE_SYSTEM_URL = process.env.REACT_APP_PH_META_TAG_CODE_SYSTEM_URL ?? '';

export const PROVIDER_DIRECTORY_BASE_URL = process.env.REACT_APP_PROVIDER_DIRECTORY_BASE_URL ?? '';

export const PH_META_TAG_PROVIDER_SOURCE_CODE =
  process.env.REACT_APP_PH_META_TAG_PROVIDER_SOURCE_CODE ?? '';

export const PCP_ROLE_CODE = process.env.REACT_APP_PCP_ROLE_CODE ?? '';

export const TASK_INTERNAL_NUMBER_IDENTIFIER_SYSTEM =
  process.env.REACT_APP_TASK_INTERNAL_NUMBER_IDENTIFIER_SYSTEM ?? '';
export const GOAL_INTERNAL_NUMBER_IDENTIFIER_SYSTEM =
  process.env.REACT_APP_GOAL_INTERNAL_NUMBER_IDENTIFIER_SYSTEM ?? '';
export const CAREPLAN_INTERNAL_NUMBER_IDENTIFIER_SYSTEM =
  process.env.REACT_APP_CAREPLAN_INTERNAL_NUMBER_IDENTIFIER_SYSTEM ?? '';
export const SERVICEREQUEST_INTERNAL_NUMBER_IDENTIFIER_SYSTEM =
  process.env.REACT_APP_SERVICEREQUEST_INTERNAL_NUMBER_IDENTIFIER_SYSTEM ?? '';
