//@ts-nocheck
import { Typography, Autocomplete, TextField } from '@mui/material';
import useGridFilterConsolidation from 'src/hooks/useGridFilterConsolidation';
import { GridFilterDrawerProps } from '../DataGridWithFilters/DataGridWithFilters';
import ActionButton from '../../../../../components/ActionButton';
import { Edit as EditIcon } from '@mui/icons-material';
import { GridColDef } from '@mui/x-data-grid';
import SeverityStatus from 'src/components/SeverityStatus';
import CollapsibleDataGrid from '../CollapsibleDataGrid';
import { WrappedCareTeam } from 'src/@nicheaim/fhir-base/wrappers/CareTeam';
import { CRSGridProps, CareTeamPermissions } from 'src/@types/crs/case';
import CaseCareTeamMemberGrid from '../CaseCareTeamMemberGrid';
import useObjectState from 'src/hooks/useObjectState';
import CareTeamModal, { CareTeamStatus } from './CareTeamModal';
import { WrappedCarePlan } from 'src/@nicheaim/fhir-base/wrappers/CarePlan';
import CheckboxList from 'src/components/CheckboxList';
import {
  CareTeamParticipant,
  Reference,
  ValueSetComposeIncludeConcept,
} from 'src/nicheaim-infrastructure/application/adapters/out/repositories/fhir/resources';
import useValueSetsByIdentifiers from 'src/hooks/useValueSetsByIdentifier';
import useLocales from 'src/hooks/useLocales';
import usePermissionsContext from 'src/hooks/usePermissionsContext';

export interface CareTeamGridProps extends GridFilterDrawerProps<CareTeamFilters>, CRSGridProps {
  careTeams: WrappedCareTeam[];
  onMemberUpdateSuccess: Function;
  carePlan: WrappedCarePlan;
  managingOrganizations: Reference[];
}

export interface CareTeamFilters {
  category: ValueSetComposeIncludeConcept[];
  status: CareTeamStatus[];
  managingOrganization: Reference[];
}

interface CareTeamGridState {
  isModalOpen: boolean;
  careTeamToEdit: WrappedCareTeam | null;
}

export const initialCareTeamFilters: CareTeamFilters = {
  category: [],
  status: [],
  managingOrganization: [],
};

const CareTeamGrid = ({
  careTeams,
  filterValues,
  onSearchTextFieldChange,
  searchTextFieldValue,
  onFilterDrawerOpen,
  onFilterDrawerClose,
  isFilterDrawerOpen,
  onApplyFilters,
  patient,
  onSuccessfulCreation,
  onSuccessfulEdit,
  onMemberUpdateSuccess,
  carePlan,
  managingOrganizations,
}: CareTeamGridProps) => {
  const { filters, updateFilters, onClearAllFilters } = useGridFilterConsolidation<CareTeamFilters>(
    filterValues,
    initialCareTeamFilters
  );
  const { i18n } = useLocales();

  const [{ isModalOpen, careTeamToEdit }, updateState] = useObjectState<CareTeamGridState>({
    isModalOpen: false,
    careTeamToEdit: null,
  });

  const {
    valueSets: [categories, careTeamStatuses],
  } = useValueSetsByIdentifiers(['crs-careteam-categories', 'crs-careteam-status']);

  const permissions = usePermissionsContext<CareTeamPermissions>();

  const columns: GridColDef[] = [
    { field: 'name', headerName: `${i18n('patients.details.careteams.name', 'crs')}`, flex: 1.3 },
    {
      field: 'noOfMembers',
      headerName: `${i18n('patients.details.careteams.members', 'crs')}`,
      headerAlign: 'center',
      flex: 0.3,
      align: 'right',
    },
    {
      field: 'plainCategory',
      headerName: `${i18n('patients.details.careteams.category', 'crs')}`,
      flex: 1,
    },
    {
      field: 'managingOrganizationName',
      headerName: `${i18n('patients.details.careteams.managingOrganization', 'crs')}`,
      flex: 1.3,
    },
    {
      field: 'severityStatus',
      headerName: `${i18n('patients.details.careteams.status', 'crs')}`,
      headerAlign: 'center',
      align: 'center',
      flex: 0.5,
      renderCell: (params) => {
        const { severityStatus } = params.row as WrappedCareTeam;
        return <SeverityStatus status={severityStatus} />;
      },
    },
    {
      field: 'edit',
      headerName: '',
      flex: 0.3,
      sortable: false,
      renderCell: (params) => {
        const careTeam = params.row as WrappedCareTeam;
        return !!permissions.isAllowedToEdit ? 
          <ActionButton
            menuOptions={[
              {
                IconComponent: EditIcon,
                title: 'Edit',
                callback: () => {
                  updateState({
                    isModalOpen: true,
                    careTeamToEdit: careTeam,
                  });
                },
                iconColor: '#00ab55',
              },
            ]}
          /> : null
      },
    },
  ];

  return (
    <>
      <CollapsibleDataGrid
        addButtonTitle={i18n('patients.details.careteams.button', 'crs')}
        title={''}
        onAddButtonClick={() => {
          updateState({
            isModalOpen: true,
            careTeamToEdit: null,
          });
        }}
        onFilterDrawerOpen={onFilterDrawerOpen}
        rows={careTeams}
        columns={columns}
        getRowId={(row) => row.id}
        onSearchTextFieldChange={onSearchTextFieldChange}
        searchTextFieldValue={searchTextFieldValue}
        renderCollapsibleContent={(careTeam: WrappedCareTeam) => (
          <CaseCareTeamMemberGrid
            careTeam={careTeam}
            patient={patient}
            participants={careTeam?.participant as CareTeamParticipant[]}
            onMemberUpdateSuccess={onMemberUpdateSuccess}
            permissions={permissions?.members ?? null}
          />
        )}
        subGridRowsExtractor={(row) => row?.members ?? []}
        Filters={
          <>
            <Typography mt={3} mb={1} fontWeight={'bold'}>
              {i18n('patients.details.careteams.filterPopUp.managingOrganization', 'crs')}
            </Typography>
            <Autocomplete
              value={filters.managingOrganization}
              popupIcon={null}
              fullWidth
              multiple
              onChange={(_, value) => {
                updateFilters({
                  managingOrganization: value,
                });
              }}
              options={managingOrganizations}
              getOptionLabel={(option) => option?.display || ''}
              defaultValue={[]}
              renderInput={(params) => <TextField {...params} variant="outlined" />}
            />

            <CheckboxList
              containerSx={{ marginTop: 3 }}
              title={i18n('patients.details.careteams.filterPopUp.category', 'crs')}
              keyExtractor={(item) => item.code as string}
              labelExtractor={(item) => item.display}
              onChange={(checkedItems) => {
                updateFilters({
                  category: checkedItems,
                });
              }}
              options={categories?.asList?.() ?? []}
              externalCheckedItems={filters.category}
            />
            <CheckboxList
              containerSx={{ marginTop: 3, marginBottom: 2 }}
              title={i18n('patients.details.careteams.filterPopUp.status', 'crs')}
              keyExtractor={(item) => item.code as string}
              labelExtractor={(item) => item.display}
              onChange={(checkedItems) => {
                updateFilters({
                  status: checkedItems,
                });
              }}
              options={careTeamStatuses?.asList?.() ?? []}
              externalCheckedItems={filters.status}
            />
          </>
        }
        FilterDrawerProps={{
          title: `${i18n('patients.details.careteams.filterPopUp.title', 'crs')}`,
          open: isFilterDrawerOpen,
          onApplyButtonClick: () => {
            onApplyFilters(filters);
          },
          onCloseIconButtonClick: onFilterDrawerClose,
          onClearAllButtonClick: onClearAllFilters,
          i18n: i18n as any,
        }}
      />
      <CareTeamModal
        patient={patient}
        onSuccessfulCreation={onSuccessfulCreation}
        onSuccessfulEdit={onSuccessfulEdit}
        careTeam={careTeamToEdit}
        carePlan={carePlan}
        open={isModalOpen}
        onClose={() => {
          updateState({
            isModalOpen: false,
            careTeamToEdit: null,
          });
        }}
      />
    </>
  );
};

export default CareTeamGrid;
