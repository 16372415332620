import { useEffect, useState } from "react";
// @mui
import { Alert, AlertTitle, Box, Button, Card, Container, Link, Stack, Typography } from '@mui/material';
import { styled } from '@mui/material/styles';
// routes
// hooks
import useResponsive from '../../hooks/useResponsive';
// components
import { SELECT_TENANT_URL } from 'src/config';
import useTenantTheme from 'src/hooks/useTenantTheme';
import Image from '../../components/Image';
import Logo from '../../components/Logo';
import Page from '../../components/Page';

// ----------------------------------------------------------------------

const RootStyle = styled('div')(({ theme }) => ({
  [theme.breakpoints.up('md')]: {
    display: 'flex',
  },
}));

const HeaderStyle = styled('header')(({ theme }) => ({
  top: 0,
  zIndex: 9,
  lineHeight: 0,
  width: '100%',
  display: 'flex',
  alignItems: 'center',
  position: 'absolute',
  padding: theme.spacing(3),
  justifyContent: 'space-between',
  [theme.breakpoints.up('md')]: {
    alignItems: 'flex-start',
    padding: theme.spacing(7, 5, 0, 7),
  },
}));

const SectionStyle = styled(Card)(({ theme }) => ({
  width: '100%',
  maxWidth: 464,
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  margin: theme.spacing(2, 0, 2, 2),
}));

const ContentStyle = styled('div')(({ theme }) => ({
  maxWidth: 480,
  margin: 'auto',
  minHeight: '100vh',
  display: 'flex',
  justifyContent: 'center',
  flexDirection: 'column',
  padding: theme.spacing(12, 0),
}));

// ----------------------------------------------------------------------

export default function Login() {
  const { tenantName } = useTenantTheme();
  const [showExpired, setShowExpired] = useState(false);

  useEffect(
    () => {
      if (sessionStorage.getItem("sessionExpired")) {
        sessionStorage.removeItem("sessionExpired");
        setShowExpired(true);
      }
    },
    [showExpired]
  )

  const mdUp = useResponsive('up', 'md');

  return (
    <Page title="Login">
      <RootStyle>
        <HeaderStyle>
          <Logo />
        </HeaderStyle>

        {mdUp && (
          <SectionStyle>
            <Typography variant="h3" sx={{ px: 5, mt: 10, mb: 5 }}>
              Hi, Welcome Back
            </Typography>
            <Image
              visibleByDefault
              disabledEffect
              src="/assets/illustrations/illustration_login.png"
              alt="login"
            />
          </SectionStyle>
        )}

        <Container maxWidth="sm">
          <ContentStyle>
            <Stack direction="row" alignItems="center" sx={{ mb: 5 }}>
              <Box sx={{ flexGrow: 1 }}>
                {showExpired &&
                  <Alert severity="info" sx={{ mb: 5 }}>
                    <AlertTitle>Your session has expired.</AlertTitle>
                    Please log in again.
                  </Alert>
                }
                <Typography variant="h4" gutterBottom>
                  Sign in {tenantName ? `to ${tenantName}` : ''}
                </Typography>
                <Typography sx={{ color: 'text.secondary' }}>Enter your details below.</Typography>
              </Box>
            </Stack>

            <Link
              color="inherit"
              underline="none"
              variant="subtitle2"
              href={SELECT_TENANT_URL}
            >
              <Button fullWidth size="large" type="submit" variant="contained">
                Log In
              </Button>
            </Link>
          </ContentStyle>
        </Container>
      </RootStyle>
    </Page>
  );
}
