import { useEffect, useMemo, useState } from 'react';
import {
  useHealthcareServices,
  useOrganizations,
  useServiceRequests,
} from '../../../../../@nicheaim/fhir-react';
import {
  ServiceRequestWrapper,
  WrappedServiceRequest,
} from '../../../../../@nicheaim/fhir-base/wrappers/ServiceRequest';
import useTable from '../../../../../hooks/useTable';
import {
  Box,
  Card,
  TableBody,
  TableCell,
  TableContainer,
  TablePagination,
  Grid,
  Stack,
  Typography,
  Table,
  Button,
} from '@mui/material';
import { TableHeadCustom } from '../../../../../components/table';
import { isEmpty } from 'lodash';
import { TABLE_HEAD_SERVICE_REQUEST_PATIENT } from '../../../common/table-head';
import { PatientGenericReferralRow } from './PatientGenericServiceRequestRow';
import { getIdFromReference } from '../../../../../utils/fhir';
import { OrganizationWrapper } from '../../../../../@nicheaim/fhir-base/wrappers/Organization';
import {
  PatientGenericServiceRequestDto,
  wrappedServiceRequestToPatientGenericServiceRequestDto,
} from './patientGenericServiceRequest.dto';
import { HealthcareServiceWrapper } from '../../../../../@nicheaim/fhir-base/wrappers/HealthcareService';
import { translateTableHead } from 'src/sections/crs/helpers/common';
import { Add } from '@mui/icons-material';
import AddReferral from 'src/sections/crs/referral/components/child-referral/AddReferral';
import { WrappedPatient } from 'src/@nicheaim/fhir-base/wrappers/Patient';
import { checkAclValidation } from 'src/utils/permissions/permission.utils';
import crsAcls from 'src/utils/permissions/crs/crsAcls';
import ModalCommunication from 'src/sections/crs/common/ModalCommunication';
import OutboundReferralDetailDrawer from 'src/sections/crs/referral/components/OutboundReferralDetailDrawer';

type Props = {
  patient?: WrappedPatient | null;
};

export default function PatientGenericServiceRequest({ patient }: Props) {
  const [openAdd, setOpenAdd] = useState(false);
  const { page, rowsPerPage, onChangePage, onChangeRowsPerPage } = useTable({});
  const [isNotesModalOpen, setIsNotesModalOpen] = useState(false);
  const [serviceRequestToEdit, setServiceRequestToEdit] = useState<WrappedServiceRequest | null>(
    null
  );
  const [isReferralDrawerOpen, setIsReferralDrawerOpen] = useState(false);

  const [serviceRequests, { create: createServiceRequest, refresh: refreshServiceRequests }] =
    useServiceRequests({
      filter: { patient: patient?.id },
      autofetch: !!patient,
      map: ServiceRequestWrapper,
    });

  const [organizationIds, setOrganizationIds] = useState<string[]>([]);

  const references = useMemo(() => {
    const perf = serviceRequests
      .filter((s) => s?.performer)
      ?.map((s) => s?.performer)
      ?.flat();

    const req = serviceRequests
      .filter((s) => s?.requester)
      ?.map((s) => s?.requester)
      ?.flat();

    const uniqueCombinedRefs = [
      ...new Map([...perf, ...req].map((x) => [x?.reference, x])).values(),
    ];

    return uniqueCombinedRefs;
  }, [serviceRequests]);

  const healthcareServiceIds = useMemo(() => {
    if (references) {
      const organizationRefs = references.filter((x) =>
        x?.reference?.startsWith('HealthcareService')
      );
      if (organizationRefs) {
        return organizationRefs.map((x) =>
          x?.reference ? getIdFromReference(x?.reference) : null
        );
      }
    }

    return [];
  }, [references]);

  const [organizations, { refresh: refreshOrganizations }] = useOrganizations({
    filter: {
      _id: organizationIds?.join(',') ?? null,
    },
    autofetch: !!organizationIds?.join(','),
    map: OrganizationWrapper,
  });

  const [healthcareServices, { refresh: refreshHealthcareServices }] = useHealthcareServices({
    filter: {
      _id: healthcareServiceIds?.join(',') ?? null,
    },
    autofetch: !!healthcareServiceIds?.join(','),
    map: HealthcareServiceWrapper,
  });

  useEffect(() => {
    if (!healthcareServices?.length) return;
    const organizationIds = [
      ...healthcareServices.reduce<Set<string>>((organizationIds, healthCareService) => {
        const organizationId = healthCareService?.providedBy?.reference?.split?.('/')?.[1];
        if (organizationId) organizationIds.add(organizationId);
        return organizationIds;
      }, new Set()),
    ];
    setOrganizationIds(organizationIds);
  }, [healthcareServices]);

  const data = useMemo(() => {
    if (serviceRequests) {
      return serviceRequests?.map((x) =>
        wrappedServiceRequestToPatientGenericServiceRequestDto(
          x,
          organizations ?? [],
          healthcareServices ?? []
        )
      );
    }
    return [];
  }, [serviceRequests, organizations]);

  const handleClose = () => {
    setOpenAdd(false);
    refreshServiceRequests();
  };

  return (
    <>
      <Card style={{ boxShadow: 'none', position: 'static' }} sx={{ m: 1 }}>
        <Grid container>
          <Grid item xs={12}>
            <TableContainer>
              {checkAclValidation({ acls: [crsAcls.CRS.PATIENT.SERVICE_REQUESTS.ADD] }) && (
                <Stack direction="row" justifyContent="left" sx={{ m: 0 }}>
                  <Grid container display={'flex'} alignItems={'center'} paddingRight={2}>
                    <Grid
                      sx={{ position: 'absolute', top: '25px', right: '25px' }}
                      item
                      xs={2}
                      display={'flex'}
                      justifyContent={'flex-end'}
                    >
                      <Button
                        size="small"
                        sx={{ height: '36px' }}
                        startIcon={<Add />}
                        onClick={() => {
                          setServiceRequestToEdit(null);
                          setOpenAdd(true);
                        }}
                      >
                        Add
                      </Button>
                    </Grid>
                  </Grid>
                </Stack>
              )}
              <Table size="small" sx={{ mb: 2 }}>
                <TableHeadCustom
                  headLabel={translateTableHead(TABLE_HEAD_SERVICE_REQUEST_PATIENT, 'crs')}
                />
                <TableBody>
                  {!isEmpty(data) ? (
                    data
                      ?.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                      .map((row: PatientGenericServiceRequestDto) => {
                        const serviceRequest = serviceRequests?.find?.(({ id }) => id === row.id);
                        return (
                          <PatientGenericReferralRow
                            key={row?.id}
                            row={row}
                            onOpenReferral={() => {
                              if (!serviceRequest) return;
                              setIsReferralDrawerOpen(true);
                              setServiceRequestToEdit(serviceRequest);
                            }}
                            onNotesOpen={() => {
                              if (!serviceRequest) return;
                              setIsNotesModalOpen(true);
                              setServiceRequestToEdit(serviceRequest);
                            }}
                            onEdit={() => {
                              if (!serviceRequest) return;
                              setOpenAdd(true);
                              setServiceRequestToEdit(serviceRequest);
                            }}
                          />
                        );
                      })
                  ) : (
                    <TableCell colSpan={TABLE_HEAD_SERVICE_REQUEST_PATIENT?.length}>
                      <Typography variant="body2" align="center">
                        No rows
                      </Typography>
                    </TableCell>
                  )}
                </TableBody>
              </Table>
            </TableContainer>
          </Grid>
        </Grid>
        <Box sx={{ position: 'relative', boxShadow: 'none !important' }}>
          <TablePagination
            rowsPerPageOptions={[5, 10, 25]}
            component="div"
            count={serviceRequests?.length}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={onChangePage}
            onRowsPerPageChange={onChangeRowsPerPage}
          />
        </Box>
        <AddReferral
          openAdd={openAdd}
          referral={serviceRequestToEdit}
          patient={patient}
          onCancel={handleClose}
        />
        <ModalCommunication
          isAllowedToAdd={checkAclValidation({
            acls: [crsAcls.CRS.PATIENT.SERVICE_REQUESTS.NOTES.ADD],
          })}
          open={isNotesModalOpen}
          patient={patient ?? null}
          onCancel={() => {
            setIsNotesModalOpen(false);
          }}
          resource={serviceRequestToEdit}
          typeNote="notes_referral"
        />
      </Card>
      <OutboundReferralDetailDrawer
        open={isReferralDrawerOpen}
        serviceRequest={serviceRequestToEdit}
        onCloseIconButtonClick={() => {
          setIsReferralDrawerOpen(false);
        }}
      />
    </>
  );
}
