import { Coding } from 'src/nicheaim-infrastructure/application/adapters/out/repositories/fhir/resources';
import { createWrapper } from '../../fhir-react/base';

export const ConditionWrapper = createWrapper('Condition', (condition) => ({
  getDisplay: () => condition?.code?.text ?? '',
  getConditionCoding: (): Coding => {
    const coding = condition?.code?.coding?.[0];
    return {
      ...(coding ?? {}),
      ...(coding ? { display: coding.display ?? condition?.code?.text } : {}),
    };
  },
}));
export type WrappedCondition = ReturnType<typeof ConditionWrapper>;
