import axios from "axios";
import { 
  AssignableGeneralPractitioner, 
  DirectoryProvider, 
  DirectoryProviderLookUpResponse, 
  PractitionerRoleReference, 
  ProviderDirectorySearchFields, 
  Resource, 
  ResourceQueryParams 
} from "src/@types/crs/providerDirectory";
import { PCP_ROLE_CODE } from "src/config";
import { convertToQueryParams } from "src/utils/string";
import { IApiClient } from "src/api/clients/api_client";
import { getRegistries } from "src/services/api/registry";
import { NPI_PROVIDER_IDENTIFIER_CODE, SSN_PROVIDER_IDENTIFIER_CODE } from "src/sections/crs/constants";
import { Coding } from "src/nicheaim-infrastructure/application/adapters/out/repositories/fhir/resources";

export class PractitionerApiClient {
  apiBase: string;

  practitionerApiClient: IApiClient;

  constructor(practitionerApiClient: IApiClient){
    this.apiBase = 'crs/practitioner';
    this.practitionerApiClient = practitionerApiClient;
  }

  async getProviderDetails(
    providerId: string,
    providerDirUrl?: string | null
  ): Promise<DirectoryProvider> {
    const response = await this.practitionerApiClient.get<DirectoryProvider>(
      `${this.apiBase}/provider/${providerId}${
        providerDirUrl
          ? `?providerDirUrl=${encodeURIComponent(providerDirUrl)}`
          : ""
      }`
    );
    return response;
  }

  async getPractitionerRole(
    practitionerRole: string
  ): Promise<AssignableGeneralPractitioner | null> {
    const response = await this.practitionerApiClient.get<AssignableGeneralPractitioner>(
      `${this.apiBase}/practitionerRole/${practitionerRole}`
    );
    return response;
  }  

  async getPractitionerRoles(
    practitionerId: string
  ): Promise<AssignableGeneralPractitioner[] | null> {
    const response = await this.practitionerApiClient.get<AssignableGeneralPractitioner[]>(
      `${this.apiBase}/roles/${practitionerId}?roleCode=${PCP_ROLE_CODE}`
    );
    return response;
  }

  async getClassifications(): Promise<string[]> {
    const response = await this.practitionerApiClient.get<string[]>(
      `${this.apiBase}/classification/list`
    );
    return response;
  }

  async lookupPractitionersInDirectory(
    searchFields: ProviderDirectorySearchFields
  ): Promise<DirectoryProviderLookUpResponse>{
    const response = await this.practitionerApiClient.post<ProviderDirectorySearchFields, DirectoryProviderLookUpResponse>(
      `${this.apiBase}/lookup`,
      searchFields
    );
    return response;
  }

  async getValueSetFromTeminology(
    valueSetName: string
  ): Promise<any> {
    const url = process.env.REACT_APP_TERMINOLOGY_URL;
    const response = await axios.get(
      `${url}/fhir/ValueSet/${valueSetName}`
    );
    return response;
  }

  async getResource(
    queryParams: ResourceQueryParams
  ): Promise<Resource[]> {
    const { search, resourceType } = queryParams;
    const url = convertToQueryParams({ search: search });
    const response = await this.practitionerApiClient.get<Resource[]>(
      `${this.apiBase}/resources?resourceType=${resourceType}${url}`
    )
    return response;
  }

  async getPractitionerRolesByReference(
    role: string,
    practitionerId?: string,
    organizationId?: string,
  ): Promise<PractitionerRoleReference[]> {
    const practitioner = convertToQueryParams({practitionerId: practitionerId});
    const organization = convertToQueryParams({organizationId: organizationId});
    const response= await this.practitionerApiClient.get<PractitionerRoleReference[]>(
      `${this.apiBase}/practitionerRoles?role=${role}${practitioner}${organization}`);

    return response;
  }

  async getPatientGeneralPractitioner(
    patientId: string
  ): Promise<AssignableGeneralPractitioner[]> {
    const response = await this.practitionerApiClient.get<AssignableGeneralPractitioner[]>(
      `${this.apiBase}/patient/${patientId}/generalPractitioner`
    );
    return response;
  }
}

export default class PractitionerService {
  practitionerApiClient: PractitionerApiClient;

  constructor(practitionerApiClient: PractitionerApiClient){
    this.practitionerApiClient = practitionerApiClient;
  }

  async getProviderDetails(
    providerId: string,
    providerDirUrl?: string | null
  ): Promise<DirectoryProvider | null> {
    const data = await this.practitionerApiClient.getProviderDetails(providerId, providerDirUrl);

    if(!data) return null;

    return {
      ...data,
      npi:
        data?.identifier?.find?.(
          ({ code }) => code === NPI_PROVIDER_IDENTIFIER_CODE
        )?.value ?? "",
      ssn:
        data?.identifier?.find?.(
          ({ code }) => code === SSN_PROVIDER_IDENTIFIER_CODE
        )?.value ?? "",
    };
  }

  async getPractitionerRole(
    practitionerRole: string
  ): Promise<AssignableGeneralPractitioner | null> {
    const response = await this.practitionerApiClient.getPractitionerRole(practitionerRole);
    if(!response) return null;
    return response;
  }

  async getPractitionerRoles(
    practitionerId: string
  ): Promise<AssignableGeneralPractitioner[] | null> {
    const response = await this.practitionerApiClient.getPractitionerRoles(practitionerId);
    if(!response) return null;
    return response;
  }

  async getClassifications(): Promise<string[]> {
    const response = await this.practitionerApiClient.getClassifications();
    if(!response) return [];
    return response;
  }

  async lookupPractitionersInDirectory(
    searchFields: ProviderDirectorySearchFields
  ): Promise<DirectoryProviderLookUpResponse | null> {
    const response = await this.practitionerApiClient.lookupPractitionersInDirectory(searchFields);
    if(!response) return null;
    return response;
  }

  async getValueSetFromTerminology(
    valueSetName: string
  ): Promise<Coding[] | null> {
    const response = await this.practitionerApiClient.getValueSetFromTeminology(valueSetName);
    if(!response) return null;
    return response?.data?.compose?.include?.[0]?.concept;
  }

  async getProviderDirectoryMappingData() {
    const registry = await getRegistries(undefined, 'PROVIDER-DIR-LICENSE-IDENTIFIER-TYPE-MAPPING');
    const fhirIdentifierTypes = await this.getValueSetFromTerminology('fhir-identifier-types');
    const licenseCodeMapping: any = JSON.parse(registry?.data?.[0]?.keyValue); 
    return {
      licenseCodeMapping, 
      fhirIdentifierTypes
    }
  }

  async getResource(
    queryParams: ResourceQueryParams
  ): Promise<Resource[]> {
    const response = await this.practitionerApiClient.getResource(queryParams);
    if(!response) return [];
    return response;
  }

  async getPractitionerRolesByReference(
    role: string,
    practitionerId?: string,
    organizationId?: string,
  ): Promise<PractitionerRoleReference[]> {
    const response = await this.practitionerApiClient.getPractitionerRolesByReference(
      role,
      practitionerId,
      organizationId
    );
    if(!response) return [];
    return response;
  }

  async getPatientGeneralPractitioner(
    patientId: string
  ): Promise<AssignableGeneralPractitioner[]> {
    const response = await this.practitionerApiClient.getPatientGeneralPractitioner(patientId);
    if(!response) return [];
    return response;
  }
}