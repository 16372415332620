export enum BannerInfoName {
  MRN = 'patientMRN',
  AGE = 'patientAge',
  GENDER = 'patientGender',
  PRIMARY_PHONE = 'patientPrimaryPhone',
  PRIMARY_EMAIL = 'patientPrimaryEmail',
  MEDICAID = 'patientMedicaId',
  PRIMARY_ZIPCODE = 'patientPrimaryZipCode',
  SIL_SCORE = 'patientSILScore',
}

export interface BannerData {
  order: number;
  name: BannerInfoName;
}

export interface BannerConfigData {
  tenantCode: string;
  data: BannerData[];
}

export const defaultBannerData: BannerConfigData = {
  tenantCode: '*',
  data: [
    {
      order: 1,
      name: BannerInfoName.MRN,
    },
    {
      order: 2,
      name: BannerInfoName.AGE,
    },
    {
      order: 3,
      name: BannerInfoName.GENDER,
    },
    {
      order: 4,
      name: BannerInfoName.PRIMARY_PHONE,
    },
    {
      order: 5,
      name: BannerInfoName.PRIMARY_EMAIL,
    },
    {
      order: 6,
      name: BannerInfoName.MEDICAID,
    },
    {
      order: 7,
      name: BannerInfoName.PRIMARY_ZIPCODE,
    },
  ],
};
