import { Tooltip } from "@mui/material";
import { Check, PriorityHigh } from "@mui/icons-material";

export interface VerifiedDecoratorProps {
  isVerified: boolean;
}

const VerifiedDecorator = ({ isVerified }: VerifiedDecoratorProps) => (
  <>
    {isVerified ? (
      <Tooltip title={"Verified"}>
        <Check
          style={{
            color: "rgb(255, 255, 255)",
            borderRadius: "50%",
            backgroundColor: "rgb(0, 171, 85)",
            height: "20px",
            width: "20px",
            fontSize: "20px",
          }}
        />
      </Tooltip>
    ) : (
      <Tooltip title={"Non-Verified"}>
        <PriorityHigh
          style={{
            color: "rgb(255, 255, 255)",
            borderRadius: "50%",
            backgroundColor: "rgb(255, 193, 7)",
            height: "20px",
            width: "20px",
            fontSize: "20px",
          }}
        />
      </Tooltip>
    )}
  </>
);

export default VerifiedDecorator;