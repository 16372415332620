import moment from 'moment';
import {
  Cake as CakeIcon,
  Person as PersonIcon,
} from '@mui/icons-material';
import { capitalCase } from 'change-case';
import { setSILDisplay } from 'src/utils/fhir';
import { useNavigate, useLocation } from 'react-router-dom';
import { TableRow, TableCell, Typography, Chip, Checkbox } from '@mui/material';
import { PatientEngagementResponse } from 'src/engagement/patient/PatientService';

type Props = {
  row: PatientEngagementResponse;
  selected: boolean;
  onSelectRow: VoidFunction;
};

export default function PatientEngagementRow({ 
  row,
  selected,
  onSelectRow, 
}: Props) {
  const {
    workflowStatus,
    workflowStageName,
    workflowScope,
    patientLastSIL,
    patientName,
    patientDOBFormatted,
    patientAge,
    patientLanguage,
    contactAttemptContactOn,
    workflowOwnedBy,
    workflowDispositionReason,
    workflowDispositionOn,
    patientEngagementId,
  } = row;

  const navigate = useNavigate();
  const location = useLocation();

  const handleOnRowDoubleClick = (event: React.MouseEvent<Element, MouseEvent>) => {
    navigate(`${location.pathname}/${patientEngagementId}`, {
      replace: false,
    });
  };

  return (
    <TableRow hover selected={selected} onDoubleClick={handleOnRowDoubleClick}>
      <TableCell padding="checkbox">
        <Checkbox checked={selected} onClick={onSelectRow} />
      </TableCell>
      <TableCell>
        <Typography noWrap variant="subtitle2">
          {workflowStatus === "Open" ? (
            <Chip color="success" label={workflowStatus} />
          ) : workflowStatus === "Close" ? (
            <Chip color="info" label={workflowStatus} />
          ) : (
            <Chip color="primary" label={workflowStatus} />
          )}
        </Typography>
      </TableCell>
      <TableCell>
        <Typography noWrap variant="body2">
          {workflowStageName}
        </Typography>
      </TableCell>
      <TableCell>
        <Typography noWrap variant="body2">
          {workflowScope}
        </Typography>
      </TableCell>
      <TableCell>
        <Typography noWrap variant="body2">
          {patientLastSIL ? setSILDisplay(patientLastSIL) : ""}
        </Typography>
      </TableCell>
      <TableCell>
        <Typography variant="subtitle2">
          <PersonIcon 
            sx={{ marginRight: 1, marginBottom: '-2px' }} 
            fontSize={'small'} 
          /> 
          {patientName}
        </Typography>
        <Typography variant="body2" sx={{ color: 'text.secondary' }}>
          <CakeIcon 
            sx={{ marginRight: 1, color: '#919eab', marginBottom: '-2px' }} 
            fontSize={'small'} 
          />
          {patientDOBFormatted ? 
            `${patientDOBFormatted} (${patientAge})` : null}
        </Typography>
      </TableCell>
      <TableCell>
        <Typography variant="body2">{patientLanguage}</Typography>
      </TableCell>
      <TableCell>
        <Typography variant="body2">
          {contactAttemptContactOn ? 
            moment(contactAttemptContactOn).format("MM/DD/YYYY") : ""}
        </Typography>
      </TableCell>
      <TableCell>
        <Typography variant="body2">
          {workflowOwnedBy
            ? capitalCase(workflowOwnedBy?.split('@')[0].replace('.', ' ')) : ""}
        </Typography>
      </TableCell>
      <TableCell>
        <Typography variant="body2">{workflowDispositionReason}</Typography>
      </TableCell>
      <TableCell>
        <Typography variant="body2">
          {workflowDispositionOn
            ? moment(workflowDispositionOn).format("MM/DD/YYYY"): ""}
        </Typography>
      </TableCell>
    </TableRow>
  );
}
