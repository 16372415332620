/* eslint-disable */
import axios from 'axios';
import { HOST_API } from 'src/config';
import { getCareflowToken, getTenantCodeFromCareflowToken } from 'src/utils/auth-utils';

// TODO: refactor to have less similar axios instances
const client = axios.create({
  baseURL: HOST_API,
});

// use interceptor to get the most recent careflow token
client.interceptors.request.use(
  async function (config) {
    if (config.headers === undefined) {
      config.headers = {};
    }

    const actualCareflowToken = getCareflowToken();

    if (actualCareflowToken) {
      config.headers['Authorization'] = 'Bearer ' + actualCareflowToken;
    }

    const tenantCode = getTenantCodeFromCareflowToken();

    if (tenantCode) {
      config.headers['X-TENANT-ID'] = tenantCode;
    }

    return config;
  },
  function (error) {
    // Do something with request error
    return Promise.reject(error);
  }
);

export default client;
