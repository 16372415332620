import i18n from 'i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import { initReactI18next } from 'react-i18next';
import { defaultLang, allLangs } from '../config';

const resources = {};

const namespacesToLoad = ['admin', 'crs', 'ccm', 'translations', 'engagement'];

const importTranslations = async (lang: any, namespace: any) => {
  try {
    const translationModule = await import(
      `../../public/modulesTranslations/${lang.toLowerCase()}/${namespace}`
    );
    return {
      namespace,
      translations: translationModule.default ?? {},
    };
  } catch (error) {
    console.error(`Error importing translations for ${lang} and ${namespace}:`, error);
    return {};
  }
};

// ---------------------------------------------------------------------

const getResourcesByLang = async (lang: string, namespacesToLoad: string[]) => {
  const namespaceTranslations = await Promise.all(
    namespacesToLoad.map((namespace: any) => importTranslations(lang, namespace))
  );

  const languageResources = namespaceTranslations.reduce((acc, { namespace, translations }) => {
    acc[namespace] = translations;
    return acc;
  }, {});

  return languageResources;
};

const initLangResources = async () => {
  for (const { value: lang } of allLangs) {
    const resourcesByLang = await getResourcesByLang(lang, namespacesToLoad);
    resources[lang] = { ...resourcesByLang };
  }
};

//This might change to a solution where we get the resources async from the back-end
initLangResources();

i18n
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    resources,
    lng: localStorage.getItem('i18nextLng') || defaultLang.value,
    fallbackLng: defaultLang.value,
    debug: false,
    ns: namespacesToLoad,
    defaultNS: 'translations',
    interpolation: {
      escapeValue: false,
    },
  });
