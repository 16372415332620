import { createWrapper } from '../../fhir-react/base';

export const ConsentWrapper = createWrapper('Consent', (consent) => ({
  
  getOptInStatus(): string {
    const policyRuleCode = consent?.policyRule?.coding?.[0]?.code;
    let optInStatus: string = "";

    switch (policyRuleCode) {
      case "OIC":
        optInStatus = "Opt In";
        break;
      case "OIS":
        optInStatus = "Opt In";
        break;
      case "OOC":
        optInStatus = "Opt Out";
        break;
      case "OOS":
        optInStatus = "Opt Out";
        break;
      default:
        optInStatus = 'Not available';
    }

    return optInStatus;
  },
}));

export type WrappedConsent = ReturnType<typeof ConsentWrapper>;