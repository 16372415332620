import Cookies from 'js-cookie';
import { getAclsFromStorage } from 'src/services/api/permissions';
import { DEVELOPMENT_CONFIG, HOST_API, SESSION_EXPIRED_URL } from '../../config';
import { isValidToken } from '../jwt';
import {
  newPermissionsContainer,
  NO_ALLOWING_CONTAINER,
  PermissionsContainer,
} from './permissions.container';

export function getUserPermissions(): PermissionsContainer {
  const careflowToken = Cookies.get('careflowToken');
  if (careflowToken && isValidToken(careflowToken)) {
    const acls = getAclsFromStorage();

    return newPermissionsContainer(acls, (dat) => dat);
  } else {
    return NO_ALLOWING_CONTAINER;
  }
}

export async function expireSession() {
  try {
    await fetch(`${HOST_API}/auth/logout-handler`, {
      method: 'post',
      body: new URLSearchParams({ no_redirect: 'true', refresh_token: Cookies.get('refreshToken') || '' }),
    });
  } catch (err) {
    console.log('handled error: ', err);
  }

  sessionStorage.setItem('sessionExpired', 'true');
  window.location.href = SESSION_EXPIRED_URL;
}

export async function checkPermissions() {
  if (!DEVELOPMENT_CONFIG.disableTokenCheck && getUserPermissions() === NO_ALLOWING_CONTAINER) {
    console.log('checkPersmissions logout');
    await expireSession();
  }
}
