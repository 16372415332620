import { Button } from '@mui/material';
import { useEffect, useState } from 'react';
import usePrintableCarePlan from 'src/crs/printable/usePrintableCarePlan';
import { useStandardDialog } from 'src/sections/careflow/common/StandardDialogProvider';
import { useStandardLoadingBackdrop } from 'src/sections/careflow/common/StandardLoadingBackdropProvider';

interface PrintableCarePlanPropsI {
  carePlanId: string | undefined;
  renderButton?: (onClick: () => void) => JSX.Element;
}

export function PrintableCarePlan({ carePlanId, renderButton }: PrintableCarePlanPropsI) {
  const printableCarePlan = usePrintableCarePlan();
  const loadingBackdrop = useStandardLoadingBackdrop();
  const [openPrintableCarePlan, setOpenPrintableCarePlan] = useState<boolean>(false);
  const standardDialog = useStandardDialog();

  function getShowElement(iframeSrc) {
    return (
      <>
        <iframe
          title="Printable CarePlan"
          style={{ minHeight: '70vh', width: '100%', height: '100%' }}
          srcDoc={iframeSrc}
          sandbox="allow-scripts allow-modals"
        ></iframe>
        <Button
          type="button"
          variant="contained"
          onClick={() => {
            setOpenPrintableCarePlan(false);
            standardDialog.close();
          }}
        >
          Close
        </Button>
      </>
    );
  }

  async function loadPrintable() {
    if (!carePlanId) {
      return;
    }

    const result = await printableCarePlan.getPrintableCareplan(carePlanId);

    loadingBackdrop.close();
    const process = result.replace('<!DOCTYPE html>', '');

    standardDialog.open(getShowElement(process));
  }

  useEffect(() => {
    if (!openPrintableCarePlan) {
      return;
    }

    loadingBackdrop.open();

    loadPrintable();
  }, [openPrintableCarePlan]);

  return renderButton ? (
    renderButton(() => {
      setOpenPrintableCarePlan(true);
    })
  ) : (
    <Button
      type="button"
      variant="contained"
      onClick={() => {
        setOpenPrintableCarePlan(true);
      }}
    >
      Generate Printable Case
    </Button>
  );
}
