import has from 'lodash/has';

export interface PaginateQuery {
  page?: number;
  limit?: number;
  sortBy?: [string, string][];
  searchBy?: string[];
  search?: string;
  filter?: {
    [column: string]: string | string[];
  };
}

export const paginateQueryToURLParams = (paginateQuery: PaginateQuery): URLSearchParams => {
  const paginationObj: any = {};
  const filters = {};
  if (has(paginateQuery, 'page')) {
    paginationObj.page = paginateQuery.page;
  }
  if (has(paginateQuery, 'limit')) {
    paginationObj.limit = paginateQuery.limit;
  }
  if (has(paginateQuery, 'search')) {
    paginationObj.search = paginateQuery.search;
  }
  if (has(paginateQuery, 'sortBy')) {
    const sortingFields =
      paginateQuery?.sortBy?.map?.(([field, order]) => `${field}:${order.toUpperCase()}`) ?? [];
    if (sortingFields.length) paginationObj.sortBy = sortingFields;
  }
  if (paginateQuery?.filter) {
    Object.entries(paginateQuery.filter).map(([key, value]) => {
      filters[`filter.${key}`] = value;
    });
    delete paginateQuery.filter;
  }

  return new URLSearchParams({ ...paginationObj, ...filters });
};
