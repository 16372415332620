import {
  AccordionSummary,
  Card,
  Chip,
  Grid,
  IconButton,
  Menu,
  Paper,
  Stack,
  TableBody,
  TableRow,
} from '@mui/material';
import { PatientDetails } from '../PatientDetails';
import { Contacts } from './PatientContacts';
import { RelatedPersons } from './PatientRelatedPersons';
import AddNewMemberGroup from './details/GroupAddNewMember';
import ExpandableCard from '../../../../components/common/ExpandableCard';
import { CardContent } from '@mui/material';
import CaseCareTeamGrid from '../../case/components/CaseCareTeamGrid';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import { useDialogStore } from 'src/stores/dialog';
import AddGroup from './details/GroupAdd';
import EditGroup from './details/GroupEdit';
import { CarePlanWrapper } from 'src/@nicheaim/fhir-base/wrappers/CarePlan';
import { useCarePlans, useCareTeams, useGroups, useTasks } from 'src/@nicheaim/fhir-react';
import { CareTeamWrapper } from 'src/@nicheaim/fhir-base/wrappers/CareTeam';
import { useCallback, useMemo, useState } from 'react';
import { PatientWrapper, WrappedPatient } from 'src/@nicheaim/fhir-base/wrappers/Patient';
import { TaskWrapper } from 'src/@nicheaim/fhir-base/wrappers/Task';
import { Typography } from '@mui/material';
import { Button } from '@mui/material';
import { Table } from '@mui/material';
import { TableHead } from '@mui/material';
import { TableCell } from '@mui/material';
import { Accordion } from '@mui/material';
import { MenuItem } from '@mui/material';
import { AccordionDetails } from '@mui/material';
import { ExpandMore, ExpandLess, Add } from '@mui/icons-material';
import { makeStyles } from '@mui/styles';
import CheckIcon from '@mui/icons-material/Check';
import { getAgeYearsAndMonths } from 'src/utils/formatTime';
import { isEmpty } from 'lodash';
import { GridRowId } from '@mui/x-data-grid';
import useLocales from 'src/hooks/useLocales';
import { ACLValidationMode, checkAclValidation } from 'src/utils/permissions/permission.utils';
import crsAcls from 'src/utils/permissions/crs/crsAcls';

export default function PatientGroups({ patient }: PatientDetails) {
  const { confirmDialog } = useDialogStore();
  const [
    carePlan,
    { isSuccess: isSuccessCarePlan, update: updateCarePlan, refresh: refreshCarePlan },
  ] = useCarePlans({ filter: { subject: patient.id }, map: CarePlanWrapper });
  const [groups, { remove, refresh, update }] = useGroups({
    filter: { member: `${patient?.id}`, _include: 'Group:member' },
  });
  const groupsRows = useMemo(() => separarRespuestas(groups), [groups]);
  const [expandedIndex, setExpandedIndex] = useState(-1);
  const [anchorEl, setAnchorEl] = useState(null);
  const [openCollapse, setOpenCollapse] = useState(false);
  const [openAddGroup, setAddGroup] = useState(false);
  const [isLastAccordionExpanded, setIsLastAccordionExpanded] = useState(false);
  const [idGroup, setIdGroup] = useState('');
  const [openEditGroup, setEditGroup] = useState(false);
  const [openAddNewMemberGroup, setAddNewMemberGroup] = useState(false);
  const [anchorElSub, setAnchorElSub] = useState(null);
  const [idGroupMember, setIdGroupMember] = useState('');
  const [anchorElMember, setAnchorElMember] = useState<null | HTMLElement>(null);
  const openElMember = Boolean(anchorElMember);
  const [openRows, setOpenRows] = useState<GridRowId[]>([]);
  const { i18n } = useLocales();

  const [careTeams, { refresh: refreshCareTeams }] = useCareTeams({
    map: CareTeamWrapper,
    filter: {
      _id: carePlan[0]?.getCareTeamIds().join(','),
    },
    autofetch: !!carePlan[0]?.getCareTeamIds().length,
  });

  const [tasks, { refresh: refreshTasksEntities }] = useTasks({
    filter: {
      _id: carePlan[0]?.getTasksIdsByOutcomeReference()?.join(',') ?? null,
    },
    autofetch: !!carePlan[0]?.getTasksIdsByOutcomeReference()?.join(','),
    map: TaskWrapper,
  });
  const refreshEntities = useCallback(() => {
    refreshCarePlan();
  }, []);
  const handleCloseEditGroup = () => {
    setEditGroup(false);
  };
  const handleCloseAddNewMemberGroup = () => {
    setAddNewMemberGroup(false);
  };
  const memoizedRefreshTasks = useCallback(() => {
    refreshTasksEntities();
  }, []);
  const useStyles = makeStyles({
    content: {
      margin: 0,
    },
  });
  const classes: any = useStyles();
  const handleOptionClick = (option: any) => {
    handleMenuClose();
  };
  const handleCloseAddGroup = () => {
    setAddGroup(false);
  };
  const memoizedRefreshCareTeam = useCallback((careTeam) => {
    refreshCareTeams();
  }, []);
  const handleMenuClose = () => {
    setAnchorEl(null);
    setIsLastAccordionExpanded(false);
    setExpandedIndex(-1);
  };
  const handleClickElMember = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorElMember(event.currentTarget);
  };
  const handleCloseElMember = () => {
    setAnchorElMember(null);
  };
  const removeGroup = async () => {
    const group = groups.filter((group) => group.id === idGroup);
    try {
      remove(group);
    } catch {}
  };
  const rows = useMemo(
    () =>
      groupsRows?.map((item: any) => {
        const { id, name, member, type, code, managingEntity, identifier, patients } = item;
        const codeDisplayMap = code?.coding?.map((val: any) => val?.display);
        return {
          id: id || '',
          name: name || '',
          members: member?.length || 0,
          type: type || '',
          code: codeDisplayMap || '',
          identifier: identifier?.[0]?.value || '',
          patients: patients,
        };
      }),
    [groupsRows]
  );

  const handleButtonClick = (event: any) => {
    setAnchorEl(event.currentTarget);
    setIsLastAccordionExpanded(false);
    setExpandedIndex(-1);
  };

  const handleSubMenuOpen = (event: any) => {
    setAnchorElSub(event.currentTarget);
  };
  const dateTimeTransForm = (date: any) => {
    {
      let targetDate = new Date('2023-07-31T07:00:00.000Z');

      const months = [
        'Jan',
        'Feb',
        'Mar',
        'Apr',
        'May',
        'Jun',
        'Jul',
        'Aug',
        'Sep',
        'Oct',
        'Nov',
        'Dec',
      ];
      let monthShort = months[targetDate.getMonth()];

      let year = targetDate.getFullYear();

      let result = `${monthShort} ${year}`;

      return result;
    }
  };
  function separarRespuestas(respuestas: any) {
    const grupos: any = [];
    const patients: any = [];
    respuestas.forEach((respuesta: any) => {
      if (respuesta.resourceType === 'Patient') {
        patients.push(respuesta);
      }
    });

    respuestas.forEach((respuesta: any) => {
      if (respuesta.resourceType === 'Group') {
        const grupo: any = {
          ...respuesta,
          patients: [],
        };
        function quitarPrefijoPatient(reference: string) {
          if (reference.startsWith('Patient/')) {
            return reference.slice(8);
          }
          return reference;
        }
        patients?.forEach((patient: any) => {
          respuesta?.member?.forEach((member: any) => {
            if (patient.id == quitarPrefijoPatient(member.entity.reference)) {
              patient.period = [member?.period];
              const wrappedPatient: WrappedPatient | any = PatientWrapper(patient);
              grupo.patients.push(wrappedPatient);
            }
          });
        });
        grupos.push(grupo);
      }
    });
    return grupos;
  }

  const removeMember = async () => {
    const group = groups?.find((item) => item?.id === idGroup);
    const members: any = group?.member?.filter(
      (item) => item?.entity?.reference !== `Patient/${idGroupMember}`
    );

    const confirmed = await confirmDialog({
      title: 'Confirm Delete',
      disabledConfirm: members?.length === 0 ? true : false,
      description: (
        <>
          {members?.length === 0 ? (
            <Typography gutterBottom>
              There must be at least one associate member of the group
            </Typography>
          ) : (
            <Typography gutterBottom>Want to permanently delete the member??</Typography>
          )}
        </>
      ),
    });

    if (confirmed && group) {
      if (!isEmpty(members)) {
        group.member = members;
      } else {
        delete group?.member;
      }
      update(group);
      refresh();
    }
    handleCloseElMember();
  };

  const toggleRow = (id: string) => {
    setOpenRows((openRows) => {
      if (openRows.includes(id)) {
        return openRows.filter((openRow) => openRow !== id);
      }
      return [...openRows, id];
    });
  };

  const careTeamPermissions = useMemo(
    () => ({
      isAllowedToAdd: checkAclValidation({
        acls: [crsAcls.CRS.PATIENT.CARE_TEAMS.ADD],
      }),
      isAllowedToEdit: checkAclValidation({
        acls: [crsAcls.CRS.PATIENT.CARE_TEAMS.EDIT],
      }),
      members: {
        isAllowedToAdd: checkAclValidation({
          acls: [crsAcls.CRS.PATIENT.CARE_TEAMS.MEMBERS.ADD],
        }),
        isAllowedToDelete: checkAclValidation({
          acls: [crsAcls.CRS.PATIENT.CARE_TEAMS.MEMBERS.DELETE],
        }),
        isAllowedToEdit: checkAclValidation({
          acls: [crsAcls.CRS.PATIENT.CARE_TEAMS.MEMBERS.EDIT],
        }),
      },
    }),
    []
  );

  return (
    <Card sx={{ p: 2, boxShadow: 'none' }}>
      {checkAclValidation({ acls: [crsAcls.CRS.PATIENT.GROUPS.VIEW] }) && (
        <ExpandableCard title={i18n('patients.details.groups.title', 'crs')} sxProp={{ m: 1 }}>
          <Card style={{ boxShadow: 'none', position: 'static' }} sx={{ p: 0, boxShadow: 'none' }}>
            <Stack sx={{ m: 1 }}>
              <Grid container display={'flex'} alignItems={'center'} paddingRight={2}>
                {checkAclValidation({ acls: [crsAcls.CRS.PATIENT.GROUPS.ADD] }) && (
                  <Grid item xs={12} display={'flex'} justifyContent={'flex-end'}>
                    <Button
                      size="small"
                      sx={{ height: '36px' }}
                      startIcon={<Add />}
                      onClick={() => setAddGroup(true)}
                      style={{ position: 'absolute', top: '25px', right: '25px' }}
                    >
                      {i18n('patients.details.groups.button', 'crs')}
                    </Button>
                  </Grid>
                )}
              </Grid>
            </Stack>
            <Paper>
              <Table>
                <TableHead>
                  <TableRow style={{ display: 'flex', justifyContent: 'space-around' }}>
                    <TableCell align="left" style={{ width: '5%' }}></TableCell>
                    <TableCell align="left" style={{ width: '27%' }}>
                      {i18n('patients.details.groups.name', 'crs')}
                    </TableCell>
                    <TableCell align="left" style={{ width: '13%' }}>
                      {i18n('patients.details.groups.members', 'crs')}
                    </TableCell>
                    <TableCell align="left" style={{ width: '13%' }}>
                      {i18n('patients.details.groups.type', 'crs')}
                    </TableCell>
                    <TableCell align="left" style={{ width: '13%' }}>
                      {i18n('patients.details.groups.code', 'crs')}
                    </TableCell>
                    <TableCell align="left" style={{ width: '21.5%' }}>
                      {i18n('patients.details.groups.identifier', 'crs')}
                    </TableCell>
                    <TableCell align="left" style={{ width: '5.5%' }}></TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {rows?.map((row: any, index: any) => (
                    <TableRow style={{ width: '100%' }} key={index}>
                      <TableCell colSpan={8} style={{ padding: 0 }}>
                        <Accordion
                          expanded={index === expandedIndex && !Boolean(anchorEl)}
                          onChange={() => {
                            if (index === (!Boolean(anchorEl) ? rows.length : rows.length - 1)) {
                              // If it's the last accordion, toggle the expanded state separately
                              setIsLastAccordionExpanded(!isLastAccordionExpanded);
                              toggleRow(row?.id);
                            } else {
                              setExpandedIndex(index === expandedIndex ? -1 : index); // Toggle the accordion expansion state
                              toggleRow(row?.id);
                            }
                          }}
                          onClick={(event) => {
                            if (
                              Boolean(anchorEl) ||
                              (index === expandedIndex && !isLastAccordionExpanded)
                            ) {
                              event.stopPropagation();
                            }
                          }}
                        >
                          <AccordionSummary
                            expandIcon={null}
                            aria-controls="panel1a-content"
                            id="panel1a-header"
                            style={{ padding: 0 }}
                            classes={{
                              content: classes.content,
                            }}
                          >
                            <div
                              style={{
                                width: '100%',
                                display: 'flex',
                                justifyContent: 'space-around',
                              }}
                            >
                              <TableCell align="left" scope="row" style={{ width: '5%' }}>
                                {openRows.includes(row?.id) ? <ExpandLess /> : <ExpandMore />}
                              </TableCell>

                              <TableCell scope="row" align="left" style={{ width: '27%' }}>
                                {row.name}
                              </TableCell>
                              <TableCell scope="row" align="left" style={{ width: '13%' }}>
                                {row.members}
                              </TableCell>
                              <TableCell scope="row" align="left" style={{ width: '13%' }}>
                                {row.type}
                              </TableCell>
                              <TableCell scope="row" align="left" style={{ width: '13%' }}>
                                {row.code.map((code: any) => (
                                  <Chip
                                    clickable={false}
                                    size="small"
                                    key={index}
                                    label={code}
                                    sx={{ m: 0.2 }}
                                  />
                                ))}
                              </TableCell>
                              <TableCell scope="row" align="left" style={{ width: '21.5%' }}>
                                {row.identifier}
                              </TableCell>
                              <TableCell scope="row" align="left" style={{ width: '5.5%' }}>
                                <div>
                                  <MoreVertIcon
                                    onClick={(event) => {
                                      event.stopPropagation();
                                      if (Boolean(anchorEl)) {
                                        handleMenuClose();
                                      } else {
                                        handleButtonClick(event);
                                      }
                                      setIdGroup(row.id);
                                    }}
                                  />
                                  <Menu
                                    anchorEl={anchorEl}
                                    open={Boolean(anchorEl)}
                                    onClose={handleMenuClose}
                                  >
                                    {checkAclValidation({
                                      acls: [crsAcls.CRS.PATIENT.GROUPS.EDIT],
                                    }) && (
                                      <MenuItem
                                        onClick={() => {
                                          setEditGroup(true);
                                        }}
                                      >
                                        Edit
                                      </MenuItem>
                                    )}
                                    {checkAclValidation({
                                      acls: [crsAcls.CRS.PATIENT.GROUPS.MEMBERS.ADD],
                                    }) && (
                                      <MenuItem
                                        onClick={() => {
                                          setIdGroup(row?.id);
                                          setAddNewMemberGroup(true);
                                        }}
                                      >
                                        {i18n('patients.details.groups.buttonAddMember', 'crs')}
                                      </MenuItem>
                                    )}
                                    {checkAclValidation({
                                      acls: [crsAcls.CRS.PATIENT.GROUPS.DELETE],
                                    }) && (
                                      <MenuItem
                                        onClick={async () => {
                                          handleOptionClick('Detalles');
                                          const confirmed = await confirmDialog({
                                            title: 'Confirm Delete',
                                            description: `Want to Permanently delete the Group`,
                                            confirmText: 'Delete',
                                            cancelText: 'Cancel',
                                            cancellable: true,
                                          });
                                          if (confirmed) return removeGroup();
                                        }}
                                      >
                                        Delete
                                      </MenuItem>
                                    )}
                                    {checkAclValidation({
                                      acls: [crsAcls.CRS.PATIENT.GROUPS.EDIT],
                                    }) && (
                                      <MenuItem onClick={(event) => handleSubMenuOpen(event)}>
                                        Status
                                        <Menu
                                          id="submenu"
                                          anchorEl={anchorElSub}
                                          open={Boolean(anchorElSub)}
                                          onClose={handleMenuClose}
                                        >
                                          <MenuItem>
                                            Active <CheckIcon style={{ margin: '10px' }} />{' '}
                                          </MenuItem>
                                          <MenuItem>
                                            Inactive <CheckIcon style={{ margin: '10px' }} />
                                          </MenuItem>
                                        </Menu>
                                      </MenuItem>
                                    )}
                                  </Menu>
                                </div>
                              </TableCell>
                            </div>
                          </AccordionSummary>
                          <AccordionDetails style={{ width: '100%' }}>
                            <div style={{ position: 'relative', zIndex: 1 }}>
                              <Table>
                                <TableHead>
                                  <TableRow>
                                    <TableCell
                                      component="th"
                                      scope="row"
                                      align="left"
                                      style={{ width: '18%' }}
                                    >
                                      {i18n('patients.details.groups.mrn', 'crs')}
                                    </TableCell>
                                    <TableCell align="left" style={{ width: '25%' }}>
                                      {i18n('patients.details.groups.fullname', 'crs')}
                                    </TableCell>
                                    <TableCell align="left" style={{ width: '18%' }}>
                                      {i18n('patients.dateOfBirth', 'crs')}
                                    </TableCell>
                                    <TableCell align="left" style={{ width: '18%' }}>
                                      {i18n('patients.details.groups.period', 'crs')}
                                    </TableCell>
                                    <TableCell align="left" style={{ width: '18%' }}>
                                      {i18n('patients.details.groups.age', 'crs')}
                                    </TableCell>
                                    <TableCell align="left" style={{ width: '5%' }}></TableCell>
                                  </TableRow>
                                </TableHead>
                                <TableBody>
                                  {row?.patients?.map((rowp: any, indexp: any) => (
                                    <TableRow key={`detail-${indexp}`}>
                                      <TableCell
                                        component="th"
                                        scope="row"
                                        style={{ width: '18%' }}
                                      >
                                        {rowp?.getMRN()?.value}
                                      </TableCell>
                                      <TableCell
                                        align="left"
                                        style={{ width: '25%', paddingLeft: '16px' }}
                                      >
                                        {rowp?.getFullName()}
                                      </TableCell>
                                      <TableCell align="left" style={{ width: '18%' }}>
                                        {rowp.getBirthDateForDisplay()} ({rowp.getAgeInYears()}{' '}
                                        years)
                                      </TableCell>
                                      <TableCell align="left" style={{ width: '18%' }}>
                                        {dateTimeTransForm(rowp?.period[0]?.end) || ''} to Current
                                      </TableCell>
                                      <TableCell align="left" style={{ width: '18%' }}>
                                        {getAgeYearsAndMonths(rowp?.birthDate)}
                                      </TableCell>
                                      <TableCell scope="row" align="left" style={{ width: '5%' }}>
                                        <div>
                                          <IconButton
                                            onClick={(event) => {
                                              handleClickElMember(event);
                                              setIdGroup(row?.id);
                                              setIdGroupMember(rowp?.id);
                                            }}
                                          >
                                            <MoreVertIcon />
                                          </IconButton>
                                          <Menu
                                            anchorEl={anchorElMember}
                                            open={openElMember}
                                            onClose={handleCloseElMember}
                                          >
                                            {checkAclValidation({
                                              acls: [crsAcls.CRS.PATIENT.GROUPS.MEMBERS.EDIT],
                                            }) && (
                                              <MenuItem
                                                onClick={() => {
                                                  setEditGroup(true);
                                                  handleCloseElMember();
                                                }}
                                              >
                                                Edit
                                              </MenuItem>
                                            )}
                                            {checkAclValidation({
                                              acls: [crsAcls.CRS.PATIENT.GROUPS.MEMBERS.ADD],
                                            }) && (
                                              <MenuItem
                                                onClick={() => {
                                                  setAddNewMemberGroup(true);
                                                  handleCloseElMember();
                                                }}
                                              >
                                                {i18n(
                                                  'patients.details.groups.buttonAddMember',
                                                  'crs'
                                                )}
                                              </MenuItem>
                                            )}
                                            {checkAclValidation({
                                              acls: [crsAcls.CRS.PATIENT.GROUPS.MEMBERS.DELETE],
                                            }) && (
                                              <MenuItem
                                                onClick={() => {
                                                  removeMember();
                                                  handleCloseElMember();
                                                }}
                                              >
                                                Delete
                                              </MenuItem>
                                            )}
                                          </Menu>
                                        </div>
                                      </TableCell>
                                    </TableRow>
                                  ))}
                                </TableBody>
                              </Table>
                            </div>
                          </AccordionDetails>
                        </Accordion>
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </Paper>
            <AddGroup patient={patient} open={openAddGroup} onClose={handleCloseAddGroup} />
            <EditGroup
              patient={patient}
              open={openEditGroup}
              onClose={handleCloseEditGroup}
              id={idGroup}
              refresh={refresh}
            />
            <AddNewMemberGroup
              id={idGroup}
              patient={patient}
              open={openAddNewMemberGroup}
              onClose={handleCloseAddNewMemberGroup}
            />
          </Card>
        </ExpandableCard>
      )}

      {/* <FamilyDetails />

      <Divider sx={{ my: 2 }} />

      <ContactDetails patient={patient} />

      <Divider sx={{ my: 2 }} />

      <AddressDetails />

      <Divider sx={{ my: 2 }} />

      <OtherDetails /> */}

      {checkAclValidation({ acls: [crsAcls.CRS.PATIENT.CONTACTS.VIEW] }) && (
        <ExpandableCard title={i18n('patients.details.contacts.title', 'crs')} sxProp={{ m: 1 }}>
          <Contacts patient={patient} />
        </ExpandableCard>
      )}

      {checkAclValidation({ acls: [crsAcls.CRS.PATIENT.RELATED_PERSONS.VIEW] }) && (
        <ExpandableCard
          title={i18n('patients.details.relatedPersons.title', 'crs')}
          sxProp={{ m: 1 }}
        >
          <RelatedPersons patient={patient} />
        </ExpandableCard>
      )}
      {checkAclValidation({ acls: [crsAcls.CRS.PATIENT.CARE_TEAMS.VIEW] }) && (
        <Grid sx={{ p: 1, boxShadow: 'none' }} item xs={12}>
          <Card>
            <CardContent sx={{ padding: 0, py: 3 }}>
              <CaseCareTeamGrid
                carePlan={carePlan[0]}
                careTeams={careTeams}
                patient={patient as WrappedPatient}
                onSuccessfulCreation={refreshEntities}
                onSuccessfulEdit={memoizedRefreshCareTeam}
                onMemberUpdateSuccess={memoizedRefreshCareTeam}
                openCollapseExternal={openCollapse}
                permissions={careTeamPermissions}
              />
            </CardContent>
          </Card>
        </Grid>
      )}
    </Card>
  );
}
