import React, { useState, useEffect, memo, useMemo } from 'react';
import {
  Box,
  Stepper,
  Step,
  StepLabel,
  StepContent,
  Grid,
  Container,
  Typography,
  Alert,
  AlertTitle,
  StepConnector,
  StepIconProps,
  Stack,
  Snackbar,
  CircularProgress,
  ButtonBase,
} from '@mui/material';
import { styled, makeStyles } from '@mui/styles';
import agent from '../../api/agent';
import Transitions from './Transitions';
import RejectDialog from './common/Dialog/RejectDialog';
import DispositionDialog from './common/Dialog/DispositionDialog';
import renderDynamicComponent from './common/renderDynamicComponent';
import { Theme } from '@mui/material/styles';
import useAuth from 'src/hooks/useAuth';
import { isArray } from 'lodash';
import useLocales from 'src/hooks/useLocales';

declare module '@mui/styles/defaultTheme' {
  interface DefaultTheme extends Theme { }
}

interface EntendedStepIconProps extends StepIconProps {
  onClick?: () => void;
  showDefaultMark?: number;
}

const Workflow = ({
  data,
  refreshRecord,
  initialWorkflow,
  componentMapping,
  workflowHandler,
  workflowData,
  refreshWorkFlowExternal,
  onWorkflowDisposition = (dataDisposition: any) => { },
  i18Path,
  namespace,
}: any) => {

  const useStyles = makeStyles({
    tableContainer: {
      backgroundColor: '#FFF',
      width: '100%',
      paddingTop: '70px',
      paddingBottom: '38px',
      paddingLeft: '40px',
      paddingRight: '40px',
      borderRadius: '16px',
      marginTop: '40px',
      minHeight: '25vh',
      boxShadow: (props: any) =>
        `0px 16px 32px -4px ${props?.boxShadowColor ? props?.boxShadowColor : 'rgba(145,158,171,0.24)'
        }, 0px 0px 2px rgba(145,158,171,0.24)`,
      '& .MuiDataGrid-columnHeaderWrapper': {
        backgroundColor: '#f4f6f8',
        borderRadius: '8px',
      },
      '& .MuiTablePagination-select': {
        height: '30px',
      },
      '& .MuiDataGrid-toolbarContainer': {
        margin: '20px 10px 20px 20px',
      },
      '& .MuiDataGrid-footerContainer': {
        minHeight: '160px',
      },
      '& .MuiDataGrid-root': {
        border: 'none',
      },
    },
    searchContainer: {
      flex: '1 auto',
    },
    textField: {
      '& .MuiOutlinedInput-root': {
        height: '56px',
      },
    },
    dataGrid: {
      width: '100%',
      padding: 8,
    },
    headerOpts: {
      marginLeft: '25px',
      marginTop: '25px',
    },
    customLabelStyle: {
      fontSize: '13px !important',
      fontWeight: '700 !important',
      color: '#212B36 !important',
    },
  });
  const { i18n } = useLocales();

  const classes = useStyles({});
  const user = useAuth();

  const ErrorContainer = ({
    showErrorContainer: show,
    errorContainerTitle: title,
    errorContainerList: errors = [],
  }: any) => {
    if (show) {
      const filterErrors = errors.filter((i: any) => i.reasonNotValid !== null);

      return (
        <>
          <Alert severity="error" sx={{ marginTop: 5, minWidth: '270%' }}>
            <AlertTitle>{title}</AlertTitle>
            <ul>
              {filterErrors && isArray(filterErrors) && filterErrors.map((err: any) => (
                <li key={err.id}>{err.reasonNotValid}</li>
              ))}
            </ul>
          </Alert>
        </>
      );
    }

    return null;
  };

  const QontoStepIcon = (props: EntendedStepIconProps) => {
    const { active, completed, className, onClick, showDefaultMark } = props;

    return (
      <QontoStepIconRoot ownerstate={{ active }} className={className}>
        <ButtonBase
          style={{
            flex: 1,
            width: 25,
            height: 25,
            borderRadius: 32,
            padding: 0,
            margin: 0,
            ...(showDefaultMark && { backgroundColor: 'lightGray' }),
          }}
          onClick={() => onClick?.()}
        >
          <div className="QontoStepIcon-circle" />
        </ButtonBase>
      </QontoStepIconRoot>
    );
  };

  const QontoStepIconRoot = styled('div')(({ theme, ownerstate }: any) => ({
    color: '#006cff',
    display: 'flex',
    height: 22,
    zIndex: 100,
    alignItems: 'center',
    ...(ownerstate.active && {
      color: '#ADD8E6',
      width: 22,
      height: 22,
      border: '5px solid #006cff',
      borderRadius: '100%',
    }),
    '& .QontoStepIcon-completedIcon': {
      color: '#006cff',
      zIndex: 1,
      fontSize: 18,
    },
    '& .QontoStepIcon-circle': {
      width: 12,
      height: 12,
      borderRadius: '50%',
      backgroundColor: 'currentColor',
    },
  }));

  const [workflow, setWorkFlow] = useState<any>();
  const [stages, setStages] = useState<any>([]);
  const [transitionList, setTransitionList] = useState<any>([]);
  const [showErrorContainer, setShowErrorContainer] = useState<any>(false);
  const [errorContainerTitle, setErrorContainerTitle] = useState<any>();
  const [errorContainerList, setErrorContainerList] = useState<any>([]);
  const [openSnackbar, setOpenSnackbar] = useState<any>(false);
  const [record, setRecord] = useState<any>();
  const [errorMessageSnackbar, setErrorMessageSnackbar] = useState<any>();
  const [checklist, setChecklist] = useState<any>([]);
  const [transitionMessage, setTransitionMessage] = useState<any>();
  const [transitionTitle, setTransitionTitle] = useState<any>();

  const refreshWorkFlow = () => {
    refreshRecord();
    setShowErrorContainer(false);
    setChecklist([]);
    fetchRecord();
  };

  useEffect(() => {
    fetchWorkFlow();
  }, [initialWorkflow]);

  useEffect(() => {
    fetchRecord();
  }, [data]);

  useEffect(() => {
    refreshWorkFlow();
  }, [refreshWorkFlowExternal]);

  const memoWorkflowInstance = useMemo(() => data?.workflowInstance, [data]);

  const [defaultStage, setDefaultStage] = useState<any>(memoWorkflowInstance?.stage?.order);
  const [activeStep, setActiveStep] = useState<any>(memoWorkflowInstance?.stage?.order);
  const [isOnCurrentStep, setIsOnCurrentStep] = useState<any>(
    memoWorkflowInstance?.stage?.order === defaultStage
  );

  useEffect(() => {
    fetchTransitions();
    setDefaultStage(memoWorkflowInstance?.stage?.order);
  }, [memoWorkflowInstance, workflow]);

  useEffect(() => {
    fetchChecklist(memoWorkflowInstance?.stage?.order);
    setActiveStep(memoWorkflowInstance?.stage?.order - 1);
  }, [memoWorkflowInstance, stages]);

  const fetchChecklist = async (activeStep: any) => {
    if (activeStep && stages.length) {
      setChecklist([]);
      const getChecklist = await agent.Checklist.getChecklistById({
        checklistId: stages[activeStep - 1]?.id,
        id: data?.id,
        referenceName: data?.recordReference,
        referenceId: data?.recordId,
      });
      if (!getChecklist?.length) return;

      const sortChecklistItems = getChecklist.sort((a: any, b: any) => {
        const parseConditionA = JSON.parse(a.conditions);
        const parseConditionB = JSON.parse(b.conditions);

        return parseConditionA.order - parseConditionB.order;
      });
      setChecklist(sortChecklistItems);
    }
  };

  const handleCloseSnackbar = (event: React.SyntheticEvent | Event, reason?: string) => {
    if (reason === 'clickaway') {
      return;
    }

    setOpenSnackbar(false);
  };

  const steps = isArray(stages) ? stages.map((i: any) => i?.name) : [];

  const fetchWorkFlow = async () => {
    const getWorkflow = await agent.Workflow.getWorkflowByCode(initialWorkflow);
    setWorkFlow(getWorkflow);
    setStages(getWorkflow?.stages);
  };


  const fetchRecord = async () => {
    if (data?.id) {
      const getRecord = await agent.User.getRecord(data?.id);
      setRecord(data); // Todo: Remove this temporal workarround
    }
  };

  const fetchTransitions = async () => {
    const getTransitions = await agent.Workflow.getTransitions(workflow?.id);
    setTransitionList(getTransitions);
  };

  const getActionProperties = (action: any) => {
    const { type } = action;

    return {
      type,
      handler: (data: any) => workflowHandler[`set${type}`].handler(data),
      component: type,
    };
  };

  const attemptTransition = async (id: any) => {
    const getTransitionId = transitionList.filter((i: any) => i.id === id);

    const { isValid, reasonNotValid } = await agent.Transition.attempt({
      fhirId: data?.fhirId || data?.referralRecord?.fhirId,
      transition: { id: getTransitionId?.[0]?.id },
      record: { id: data?.id },
      apply: true,
      originalData: data,
    });

    const parseResultValidation = typeof isValid === 'string' ? JSON.parse(isValid) : isValid;

    if (parseResultValidation) {
      await agent.Transition.updateStage({
        reference_name: data?.recordReference,
        reference_id: data?.recordId,
        record: { id: data?.id },
        stage: { id: getTransitionId?.[0]?.toStagesId },
        workflow: { id: workflow?.id },
        transition: { id: getTransitionId?.[0]?.id },
      });

      return true;
    } else {
      setShowErrorContainer(true);
      setErrorContainerTitle('Could not move to the next stage');
      setErrorContainerList([{ reasonNotValid }]);
      return false;
    }
  };

  const handleTransition = async (transition: any) => {
    const { ui } = JSON.parse(transition?.metadata);

    if (ui?.action && Object.keys(ui?.action).length > 0) {
      const actionProperties: any = getActionProperties(ui?.action);
      const { handler } = actionProperties;
      const message = ui?.action?.message;
      const title = ui?.action?.title;

      handler(true);
      setTransitionMessage(message);
      setTransitionTitle(title);
      return;
    }

    const isValid = await attemptTransition(transition?.id);

    if (isValid) {
      refreshWorkFlow();
    }
  };

  const handlerReject = async (rejectData: any) => {
    const getClosedStage = transitionList.filter((item: any) => item.code === 'REJECT');
    const isValid = await attemptTransition(
      getClosedStage && getClosedStage.length > 0 && getClosedStage[0].id
    );

    try {
      const payload = {
        status: 'Close',
        subStatus: 'Canceled',
        referenceName: data?.recordReference,
        referenceId: data?.recordId,
        dispositionReason: rejectData?.reason,
        dispositionNote: rejectData?.reasonNote,
        dispositionOn: new Date().toISOString(),
        dispositionBy: user?.getCurrentUser()?.email,
      };

      if (isValid) {
        const result = await agent.Workflow.updateWorkflowInstance(payload);
      }
    } catch (err) {
      console.log('error on reject', err);
    } finally {
      workflowHandler.setReject.handler(false);
      workflowHandler.setReject.data = {};
      refreshWorkFlow();
    }
  };

  const handlerDisposition = async (dataDisposition: any) => {
    try {
      const transitionDispositionId = transitionList?.find((i: any) =>
        i.metadata.includes('Disposition')
      );
      const isValid = await attemptTransition(transitionDispositionId?.id);

      const payload = {
        status: 'Close',
        subStatus: 'Completed',
        referenceName: data?.recordReference,
        referenceId: data?.recordId,
      };

      if (isValid) {
        const result = await agent.Workflow.updateWorkflowInstance(payload);

        if (data?.recordReference === 'REFERRAL') {
          onWorkflowDisposition(dataDisposition);
        }
        refreshWorkFlow();
      }
    } catch (error) {
      console.log('error', error);
    }
  };

  const isLoading = !(checklist && checklist.length > 0 && data?.workflowInstance?.stage?.id);

  const GoBack = async (index: number = 1) => {
    if (index > defaultStage) return;
    setIsOnCurrentStep(index === defaultStage);
    setActiveStep(index - 1);
    fetchChecklist(index);
  };

  return (
    <Container disableGutters>
      <Grid container spacing={2}>
        <Grid item md={12}>
          <div className={classes.tableContainer}>
            <Box sx={{ width: '100%' }}>
              <h2 style={{ marginBottom: 50 }}>Workflow</h2>
              <Stepper
                alternativeLabel
                activeStep={activeStep}
                connector={<StepConnector />}
              >
                {steps && isArray(steps) && steps.map((label: any, index: number) => (
                  <Step key={label}>
                    <StepLabel
                      StepIconComponent={(props) => (
                        <QontoStepIcon
                          showDefaultMark={defaultStage - 1 === index && activeStep !== index}
                          {...props}
                          onClick={() => {
                            GoBack(index + 1);
                          }}
                        />
                      )}
                      classes={{
                        labelContainer: classes.customLabelStyle,
                        label: classes.customLabelStyle,
                        active: classes.customLabelStyle,
                        completed: classes.customLabelStyle,
                        alternativeLabel: classes.customLabelStyle,
                      }}
                    >
                      {label}
                    </StepLabel>

                    <StepContent
                      sx={{
                        marginTop: 5,
                        maxWidth: 100,
                        flex: 1,
                        alignItems: 'center',
                        justifyContent: 'center',
                        overflow: 'visible',
                      }}
                    >
                      <Box sx={{ width: 190 }}>
                        {!isLoading ? (
                          checklist && isArray(checklist) && checklist?.map((item: any) =>
                            renderDynamicComponent(item, componentMapping, {
                              ...workflowHandler,
                              ...(item?.code && {
                                [item.code]: {
                                  ...workflowHandler[item.code],
                                  others: {
                                    data,
                                    record,
                                    checklistItem: item,
                                    refreshWorkFlow,
                                  },
                                },
                              }),
                            }, i18n, i18Path, namespace)
                          )
                        ) : (
                          <CircularProgress />
                        )}
                        {!isLoading && (
                          <Box
                            display="flex"
                            flexWrap="wrap"
                            alignContent="center"
                            justifyContent="space-evenly"
                            sx={{ pt: 2 }}
                          >
                            <Transitions
                              isOnCurrentStep={isOnCurrentStep}
                              transitions={transitionList?.filter(
                                (item: any) => item.fromStagesId === memoWorkflowInstance?.stage?.id
                              )}
                              handleTransition={handleTransition}
                            />
                          </Box>
                        )}
                      </Box>

                      <ErrorContainer
                        showErrorContainer={showErrorContainer}
                        errorContainerTitle={errorContainerTitle}
                        errorContainerList={errorContainerList}
                      />
                    </StepContent>
                  </Step>
                ))}
              </Stepper>

              {memoWorkflowInstance?.stage?.order === steps.length + 1 && (
                <>
                  <Typography sx={{ mt: 2, mb: 1 }}>
                    All steps completed - you&apos;re finished
                  </Typography>
                </>
              )}
            </Box>

            <RejectDialog
              isOpen={workflowData?.reject}
              handlerReject={handlerReject}
              handlerIsOpenConfirmDialog={workflowHandler?.setReject?.handler}
              rejectCounty={workflowHandler?.setReject?.data}
              message={transitionMessage}
              title={transitionTitle}
            />

            <DispositionDialog
              isOpen={workflowData?.disposition}
              handlerDisposition={handlerDisposition}
              handlerIsOpenDispositionDialog={workflowHandler.setDisposition.handler}
              message={transitionMessage}
              data={workflowHandler.setDisposition.data}
              type={data?.recordReference}
            />

            <Stack spacing={2} sx={{ width: '100%' }}>
              <Snackbar open={openSnackbar} autoHideDuration={6000} onClose={handleCloseSnackbar}>
                <Alert onClose={handleCloseSnackbar} severity="error" sx={{ width: '100%' }}>
                  {errorMessageSnackbar}
                </Alert>
              </Snackbar>
            </Stack>
          </div>
        </Grid>
      </Grid>
    </Container>
  );
};

export default memo(Workflow);
