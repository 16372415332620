import { createWrapper } from 'src/@nicheaim/fhir-react/base';
import { TASK_INTERNAL_NUMBER_IDENTIFIER_SYSTEM } from 'src/config';
import {
  Reference,
  TaskInput,
} from 'src/nicheaim-infrastructure/application/adapters/out/repositories/fhir/resources';
import { getTableRowDueTo } from 'src/sections/crs/common/common-utils';
import { getReferenceResourceId } from 'src/sections/crs/helpers/common';

export const TaskWrapper = createWrapper('Task', (task) => ({
  getPastDue(): string {
    const result = getTableRowDueTo(
      new Date(task?.executionPeriod?.end || ''),
      task?.status
    ).subStatus;
    return result;
  },
  getInternalNumber: (): string | null =>
    task?.identifier?.find?.(({ system }) => system === TASK_INTERNAL_NUMBER_IDENTIFIER_SYSTEM)
      ?.value ?? null,
  getDueDate: (): string | undefined => task?.executionPeriod?.end?.split?.('T')?.[0],
  getTaskType: (): string | undefined => task?.code?.coding?.[0]?.code,
  getTaskTypeDisplay: (): string | undefined => task?.code?.coding?.[0]?.display,
  getForDisplay: (): string | undefined => task?.for?.display,
  getComments: (): string | undefined => task?.note?.[0]?.text,
  getEndDate: (): string | undefined => task?.executionPeriod?.end,
  getStartDate: (): string | undefined => task?.executionPeriod?.start,
  getBasedOn: (): Reference[] | undefined => task?.basedOn,
  getOwnerId: (): string | undefined => task?.owner?.reference,
  getOwnerDisplay: () => task?.owner?.display,
  getPatientId: (): string | undefined => {
    if (!task?.for?.reference?.includes('Patient')) return;
    return getReferenceResourceId(task?.for?.reference);
  },
  getTaskNumberNAT: (): string | undefined =>
    task?.identifier?.find((identifier) => identifier?.type?.text === 'NJ inCK Task Number')?.value,
  getURLResources: (): TaskInput[] =>
    task?.input?.filter?.(({ type }) =>
      type?.coding?.some?.(({ code }) => code?.toLowerCase?.()?.includes?.('resource.url'))
    ) ?? [],
}));
export type WrappedTask = ReturnType<typeof TaskWrapper>;
