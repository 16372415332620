import RoleGrid from "./RoleGrid";
import useAuth from "src/hooks/useAuth";
import { 
  AssignableGeneralPractitioner, 
  DirectoryProvider, 
  OnPractitionerRoleCreation, 
  OnPractitionerRoleSelect, 
  OnProviderAsyncAction, 
  OnProviderUnselect, 
  ProviderDirectorySearchFields 
} from "src/@types/crs/providerDirectory";
import ProviderGrid from "./ProviderGrid";
import { 
  getNewLinkedPractitionerPayload, 
  getNewLinkedPractitionerRolePayload, 
  getNewLinkedProviderPayload, 
  getPCPHealthStoryAnswers, 
  getPCPHealthStoryResponses, 
  getPractitionersLinkedToProvider,
  updateLinkedPractitionerRole, 
  updateLinkedProvider 
} from "src/sections/crs/provider/helpers";
import Iconify from "src/components/Iconify";
import { HowToReg } from "@mui/icons-material";
import { PATH_DASHBOARD } from "src/routes/paths";
import { incidentService } from "src/crs/incident";
import MatchedProvidersGrid from "./MatchedProvidersGrid";
import { practitionerService } from "src/crs/practitioner";
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import HealthStoryInformation from "./HealthStoryInformation";
import { useCallback, useEffect, useMemo, useState } from "react";
import NewProvider from "src/sections/crs/provider/new/NewProvider";
import CreatePractitionerRole from "src/sections/crs/provider/CreatePractitionerRoles";
import {  PCPAssignmentScopeData, PCPIncidentSourceData } from "src/@types/crs/incident";
import axiosFhirInstance from "src/application/adapters/out/repositories/axiosFhirInstance";
import { Accordion, AccordionSummary, Card, Grid, IconButton, Stack, Typography } from "@mui/material";
import { PractitionerWrapper, WrappedPractitioner } from "src/@nicheaim/fhir-base/wrappers/Practitioner";
import { IncidentResponse, IncidentStatus, pcpAssignmentIncidentScopeCodes } from "src/crs/incident/IncidentService";
import { Practitioner } from "src/nicheaim-infrastructure/application/adapters/out/repositories/fhir/resources/resources";

interface PCPIncident {
  incident: IncidentResponse | undefined;
  patientPCPPractitionerRoles: AssignableGeneralPractitioner[] | null;
  onIncidentUpdate: (updatedIncident: IncidentResponse) => void;
  onLoading: (isLoading: boolean) => void;
}

export function PCPIncident({
  incident,
  patientPCPPractitionerRoles,
  onIncidentUpdate,
  onLoading
}: PCPIncident){
  const { user } = useAuth();
  const [
    selectedProvider,
    setSelectedProvider,
  ] = useState<DirectoryProvider | null>(null);
  const [isLoading, setIsLoading] = useState(false);
  const [isRoleSectionLoading, setIsRoleSectionLoading] = useState(false);
  const [openNewProvider, setOpenNewProvider] = useState(false);
  const [
    isFirstTimeOpeningProviderSearch,
    setIsFirstTimeOpeningProviderSearch,
  ] = useState(true);
  const [practitioner, setPractitioner] = useState<WrappedPractitioner | null>(
    null
  );
  const [practitionerRoles, setPractitionerRoles] = useState<
    AssignableGeneralPractitioner[]
  >([]);
  const [
    selectedPractitionerRole,
    setSelectedPractitionerRole,
  ] = useState<AssignableGeneralPractitioner | null>(null);
  const [isIncidentFirstRender, setIsIncidentFirstRender] = useState(true);
  const [
    isCreatePractitionerRoleDialogOpen,
    setIsCreatePractitionerRoleDialogOpen,
  ] = useState(false);
  const [isMultiplePCPLoading, setIsMultiplePCPLoading] = useState(false);

  const isMultiplePCP = useMemo(
    () => incident?.scope_code === pcpAssignmentIncidentScopeCodes.MULTIPLE_PCP,
    [incident]
  );

  useEffect(() => {
    onLoading(isLoading || isRoleSectionLoading);
  }, [isLoading, isRoleSectionLoading, onLoading]);

  useEffect(() => {
    if (!incident || !isIncidentFirstRender) return;
    const {
      linkedProviderId,
      linkedPractitionerRoleId,
      linkedPractitionerId,
    } = (incident?.scope_data ?? {}) as PCPAssignmentScopeData;

    const getLinkedProvider = async () => {
      if (!linkedProviderId) return;
      const providerDetails = await practitionerService.getProviderDetails(linkedProviderId);
      if (!providerDetails) return;
      setSelectedProvider(providerDetails);
    };

    const getLinkedPractitionerRole = async () => {
      if (!linkedPractitionerRoleId) return;
      const practitionerRole = await practitionerService.getPractitionerRole(
        linkedPractitionerRoleId
      );
      if (!practitionerRole) return;
      setSelectedPractitionerRole(practitionerRole);
    };

    const getLinkedPractitioner = async () => {
      if (!linkedPractitionerId) return;
      const practitioner = await (await axiosFhirInstance.get(
        `Practitioner/${linkedPractitionerId}`,
      ))?.data;
      if (!practitioner) return;
      setPractitioner(practitioner);
    };

    const getLinkedResourcesDetails = async () => {
      setIsLoading(true);
      await getLinkedProvider();
      await getLinkedPractitioner();
      await getLinkedPractitionerRole();
      setIsLoading(false);
      setIsIncidentFirstRender(false);
    };

    getLinkedResourcesDetails();
  }, [incident, isIncidentFirstRender]);

  useEffect(() => {
    const getPractitionerFromProvider = async () => {
      if (!selectedProvider) return;
      try {
        setIsRoleSectionLoading(true);
        const linkedPractitioners = await getPractitionersLinkedToProvider(
          selectedProvider.uuid
        );
        const practitioner = PractitionerWrapper(linkedPractitioners[0]);
        setIsRoleSectionLoading(false);
        if (!practitioner) return;
        setPractitioner(practitioner);
      } catch (error) {
        setIsRoleSectionLoading(false);
      }
    };
    getPractitionerFromProvider();
  }, [selectedProvider]);

  const getRoles = useCallback(async () => {
    if (!practitioner) return;
    try {
      setIsRoleSectionLoading(true);
      const practitionerRoles = await practitionerService.getPractitionerRoles(
        practitioner.id as string
      );
      if (practitionerRoles) setPractitionerRoles(practitionerRoles);
      setIsRoleSectionLoading(false);
    } catch (error) {
      setIsRoleSectionLoading(false);
    }
  }, [practitioner]);

  useEffect(() => {
    getRoles();
  }, [getRoles]);

  const healthStoryAnswers = useMemo(() => {
    const sourceData = incident?.source_data as PCPIncidentSourceData;
    const questionnaireResponse = sourceData?.questionnaireResponse;
    if (!questionnaireResponse) return [];
    return getPCPHealthStoryAnswers(questionnaireResponse);
  }, [incident]);

  const defaultProviderSearchFields = useMemo((): ProviderDirectorySearchFields | null => {
    if (!healthStoryAnswers.length) return null;
    const {
      firstName,
      lastName,
      practiceName,
      address,
      city,
      phone,
    } = getPCPHealthStoryResponses(healthStoryAnswers);
    if (!firstName && !lastName && !practiceName && !address && !city && !phone)
      return null;
    return {
      address,
      city,
      first_name: firstName,
      last_name: lastName,
      practice_name: practiceName,
      phone_number: phone,
    };
  }, [healthStoryAnswers]);

  const handleCloseNewProviderModal = useCallback(() => {
    setIsFirstTimeOpeningProviderSearch(false);
    setOpenNewProvider(false);
  }, []);

  const setRolesSectionStateToInitial = useCallback(() => {
    setPractitioner(null);
    setSelectedPractitionerRole(null);
    setPractitionerRoles([]);
  }, []);

  const handleProviderSelection: OnProviderAsyncAction = useCallback(
    async (provider) => {
      if (!incident) return;
      setIsFirstTimeOpeningProviderSearch(false);
      setIsLoading(true);
      setRolesSectionStateToInitial();
      const providerDetails = await practitionerService.getProviderDetails(provider.uuid);
      if (!providerDetails) return;
      setSelectedProvider(providerDetails);
      const updatedIncident = await updateLinkedProvider(
        incident,
        providerDetails,
        user
      );
      if (updatedIncident) onIncidentUpdate(updatedIncident);
      handleCloseNewProviderModal();
      setIsLoading(false);
    },
    [
      handleCloseNewProviderModal,
      incident,
      onIncidentUpdate,
      setRolesSectionStateToInitial,
    ]
  );

  const handleProviderUnselect: OnProviderUnselect = useCallback(async () => {
    if (!incident) return;
    setIsLoading(true);

    const scopeData = {
      ...(incident.scope_data ?? {}),
      linkedProviderId: null,
      linkedPractitionerRoleId: null,
      linkedPractitionerId: null,
    } as PCPAssignmentScopeData;
    const incidentPayload: IncidentResponse = {
      ...incident,
      scope_data: { ...scopeData },
    };
    const updatedIncident = await incidentService.updateIncident(
      incident.id,
      incidentPayload
    );
    if (updatedIncident) onIncidentUpdate(updatedIncident);
    setRolesSectionStateToInitial();
    setSelectedProvider(null);
    setIsLoading(false);
  }, [onIncidentUpdate, incident, setRolesSectionStateToInitial]);

  const updatePCPLinkedPractitionerRole: OnPractitionerRoleSelect = useCallback(
    async (practitionerRole) => {
      if (!incident) return;
      onLoading(true);
      const updatedIncident = await updateLinkedPractitionerRole(
        incident,
        practitionerRole,
        user
      );
      if (updatedIncident) onIncidentUpdate(updatedIncident);
      onLoading(false);
    },
    [incident, onIncidentUpdate, onLoading]
  );

  const isRolesSectionEnabled = useMemo(
    () =>
      !isRoleSectionLoading &&
      (!!selectedProvider || !!practitioner) &&
      !isLoading,
    [isRoleSectionLoading, selectedProvider, isLoading, practitioner]
  );

  const handlePractitionerRoleSelect: OnPractitionerRoleSelect = useCallback(
    (practitionerRole) => {
      setSelectedPractitionerRole(practitionerRole);
      updatePCPLinkedPractitionerRole(practitionerRole);
    },
    [updatePCPLinkedPractitionerRole]
  );

  const handlePractitionerRoleCreation: OnPractitionerRoleCreation = useCallback(
    (practitionerRole) => {
      getRoles();
      handlePractitionerRoleSelect(practitionerRole);
      setIsCreatePractitionerRoleDialogOpen(false);
    },
    [handlePractitionerRoleSelect, getRoles]
  );

  const handlePatientPCPSelection: OnPractitionerRoleSelect = useCallback(
    async (practitionerRole) => {
      const practitionerGeneralData = practitionerRole.practitioner;
      if (!practitionerGeneralData) return;
      const practitioner = PractitionerWrapper(practitionerGeneralData.resource! as Practitioner);
      setIsMultiplePCPLoading(true);
      onLoading(true);
      const provider =
        practitioner.getProviderId() && practitionerGeneralData.isVerified
          ? await practitionerService.getProviderDetails(practitioner.getProviderId() as string)
          : null;
      if (!provider && practitioner.getProviderId()) {
        setIsMultiplePCPLoading(false);
        onLoading(false);
        return;
      }
      setRolesSectionStateToInitial();
      let incidentPayload: IncidentResponse | null = null;
      if (provider) {
        setSelectedProvider(provider);
        incidentPayload = getNewLinkedProviderPayload(incident!, provider, user);
      } else {
        setPractitioner(practitioner);
        incidentPayload = getNewLinkedPractitionerPayload(
          incident!,
          practitioner,
          user
        );
      }
      setSelectedPractitionerRole(practitionerRole);
      incidentPayload = getNewLinkedPractitionerRolePayload(
        incidentPayload,
        practitionerRole,
        user
      );
      const updatedIncident = await incidentService.updateIncident(
        incidentPayload?.id ?? null,
        incidentPayload
      );
      if (updatedIncident) onIncidentUpdate(updatedIncident);
      setIsMultiplePCPLoading(false);
      onLoading(false);
    },
    [incident, setRolesSectionStateToInitial]
  );

  const handlePractitionerCreation = useCallback(
    (practitioner: WrappedPractitioner) => {
      setPractitioner(practitioner);
    },
    []
  );

  const isDispositionDone =
    incident?.status !== IncidentStatus.NEW &&
    incident?.status !== IncidentStatus.IN_PROGRESS;

  const { fhirTaskId } = (incident?.source_data ?? {}) as PCPIncidentSourceData;

  return(
    <>
      <Grid item xs={12} md={3} lg={3} sx={{ mt: 2, display: 'block' }}>
        <Card>
          <Accordion defaultExpanded={true}>
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel2a-content"
              id="panel2a-header"
              sx={{ py: 2 }}
            >
              <Stack direction="row" alignItems="center" spacing={1}>
                <Typography>Health Story PCP Information</Typography>
                <IconButton  onClick={() => {
                  window.open(
                    `${PATH_DASHBOARD.nat.task}/${fhirTaskId}`,"blank"
                  )
                }}>
                  <Iconify 
                    icon={'ic:outline-open-in-new'} 
                    width={18} 
                    height={18} 
                    color='#3dab2b'
                  />
                </IconButton>
              </Stack>
            </AccordionSummary>
            <Stack sx={{ m: 2 }}>
              <HealthStoryInformation
                scopeCode={incident?.scope_code ?? ""}
                healthStoryAnswers={healthStoryAnswers} 
              />
            </Stack>
          </Accordion>
        </Card>
      </Grid>

      {incident?.scope_code === pcpAssignmentIncidentScopeCodes.PCP_MULTI_MATCH && (
        <Grid item xs={12} md={3} lg={3} sx={{ mt: 2, display: 'block' }}>
          <Card>
            <Accordion defaultExpanded={true}>
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel2a-content"
                id="panel2a-header"
                sx={{ py: 2 }}
              >
                Matched Providers
              </AccordionSummary>
              <Stack sx={{ m: 2 }}>
                <MatchedProvidersGrid
                  incident={incident}
                  selectedProvider={selectedProvider}
                  isIncidentDispositionDone={isDispositionDone}
                  onProviderSelect={handleProviderSelection}
                />
              </Stack>
            </Accordion>
          </Card>
        </Grid>
      )}

      {isMultiplePCP && (
        <Grid item xs={12} md={3} lg={3} sx={{ mt: 2, display: 'block' }}>
          <Card>
            <Accordion defaultExpanded={true}>
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel2a-content"
                id="panel2a-header"
                sx={{ py: 2 }}
              >
                Patient's Current PCPs
              </AccordionSummary>
              <Stack sx={{ m: 2 }}>
                <RoleGrid
                  hsAssignedPractitionerRoleId={
                    incident?.source_data?.assignedPractitionerRoleId
                  }
                  isIncidentDispositionDone={isDispositionDone}
                  practitionerRoles={patientPCPPractitionerRoles ?? []}
                  selectedPractitionerRole={selectedPractitionerRole}
                  sectionEnabled={true}
                  onPractitionerRoleSelect={handlePatientPCPSelection}
                  isLoading={isMultiplePCPLoading || !patientPCPPractitionerRoles}
                  showPractitionerColumn={true}
                  showCreateRoleButton={false}
                  isButtonMode={true}
                />
              </Stack>
            </Accordion>
          </Card>
        </Grid>
      )}

      <Grid item xs={12} md={3} lg={3} sx={{ mt: 2, display: 'block' }}>
        <Card>
          <Accordion defaultExpanded={true}>
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel2a-content"
              id="panel2a-header"
              sx={{ py: 2 }}
            >
              Provider
            </AccordionSummary>
            <Stack sx={{ m: 2 }}>
              <ProviderGrid
                isIncidentDispositionDone={isDispositionDone}
                onSearchProvider={() => {
                  setOpenNewProvider(true);
                }}
                practitioner={practitioner}
                selectedProvider={selectedProvider}
                isLoading={isLoading}
                onProviderUnselect={handleProviderUnselect}
              />
            </Stack>
          </Accordion>
        </Card>
      </Grid>

      <Grid item xs={12} md={3} lg={3} sx={{ mt: 2, display: 'block' }}>
        <Card>
          <Accordion defaultExpanded={!isDispositionDone}>
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel2a-content"
              id="panel2a-header"
              sx={{ py: 2 }}
            >
              Roles
            </AccordionSummary>
            <Stack sx={{ m: 2 }}>
              <RoleGrid
                isIncidentDispositionDone={isDispositionDone}
                onCreateRoleButtonClick={() => {
                  setIsCreatePractitionerRoleDialogOpen(true);
                }}
                practitionerRoles={practitionerRoles}
                onPractitionerRoleSelect={handlePractitionerRoleSelect}
                selectedPractitionerRole={selectedPractitionerRole}
                sectionEnabled={isRolesSectionEnabled}
                isLoading={isRoleSectionLoading || isLoading}
              />
            </Stack>
          </Accordion>
        </Card>
      </Grid>

      <NewProvider
        onDrawerClose={handleCloseNewProviderModal}
        isOpen={openNewProvider}
        onProviderAction={handleProviderSelection}
        providerActionButtonIcon={<HowToReg />}
        providerActionButtonTitle="Select"
        defaultSearchFields={
          isFirstTimeOpeningProviderSearch ? defaultProviderSearchFields : null
        }
      />

      <CreatePractitionerRole
        onPractitionerRoleCreation={handlePractitionerRoleCreation}
        practitioner={practitioner}
        provider={selectedProvider}
        isVisible={isCreatePractitionerRoleDialogOpen}
        onPractitionerCreation={handlePractitionerCreation}
        onDialogClose={() => {
          setIsCreatePractitionerRoleDialogOpen(false);
        }}
      />
    </>
  )
}