/* eslint-disable */
// @ts-nocheck
import { useCallback, useEffect, useMemo, useState } from 'react';
import {
  Container,
  Grid,
  Typography,
  Card,
  Autocomplete,
  TextField,
  Box,
  IconButton,
  Tooltip,
} from '@mui/material';
import { FilterList as FilterListIcon } from '@mui/icons-material';
import { DataGrid, GridSortModel } from '@mui/x-data-grid';
import { PersonSearch as PersonSearchIcon } from '@mui/icons-material';
import Page from 'src/components/Page';
import HeaderBreadcrumbs from 'src/components/HeaderBreadcrumbs';
import useSettings from 'src/hooks/useSettings';
import { PATH_DASHBOARD } from 'src/routes/paths';
import { TaskResponse } from 'src/@types/crs/task';
import { getTasks } from 'src/services/api/task';
import getTaskListColumns from './taskColumns';
import useObjectState from 'src/hooks/useObjectState';
import { PaginateQuery } from 'src/api/pagination/dtos';
import SearchTextField from 'src/components/SearchTextField';
import { debounce } from 'src/utils/timers';
import { ValueSetWrapper } from 'src/@nicheaim/fhir-base/wrappers/ValueSet';
import { useValueSets } from 'src/@nicheaim/fhir-react';
import {
  Reference,
  ValueSetComposeIncludeConcept,
} from 'src/nicheaim-infrastructure/application/adapters/out/repositories/fhir/resources';
import SearchMemberModal from '../case/components/SearchMember/SearchMemberModal';
import TaskModal, { getOwnerDisplayLabel } from '../case/components/TasksGrid/TaskModal';
import TaskDetailDrawer from '../case/components/TaskDetailDrawer';
import { WrappedTask } from 'src/@nicheaim/fhir-base/wrappers/Task';
import PeriodFilter, { onDateChange } from 'src/components/PeriodFilter';
import useGridFilterConsolidation from 'src/hooks/useGridFilterConsolidation';
import FilterDrawer from 'src/components/FilterDrawer';
import { initialTaskStatusFilter } from '../case/components/TasksGrid/TasksGrid';
import useLocales from 'src/hooks/useLocales';

interface GridState {
  page: number;
  rowsPerPage: number;
  orderBy: string | null;
  orderDirection: 'asc' | 'desc' | null;
  search: string;
  statusFilter: ValueSetComposeIncludeConcept[];
  ownerFilter: Reference[];
}

interface TaskDetailDrawerState {
  selectedTaskId: string;
  isTaskDetailDrawerOpen: boolean;
}

interface TaskEditModalState {
  taskToEdit: WrappedTask | null;
  isTaskEditModalOpen: boolean;
}

const initialGridState: GridState = {
  page: 1,
  rowsPerPage: 5,
  orderBy: 'periodEnd',
  orderDirection: 'asc',
  search: '',
  statusFilter: [...initialTaskStatusFilter],
  ownerFilter: [],
};
export interface TaskFilters {
  selectedOwners: Reference[];
  startDate: moment.Moment | null;
  endDate: moment.Moment | null;
}
export const initialTaskFilters: TaskFilters = {
  selectedOwners: [],
  startDate: null,
  endDate: null,
};
const TaskList = () => {
  const { themeStretch } = useSettings();
  const [list, setList] = useState<TaskResponse[]>([]);
  const [searchValue, setSearchValue] = useState<string>('');
  const [isSearchMemberModalOpen, setIsSearchMemberModalOpen] = useState<boolean>(false);
  const [isOpenAplyFilters, setIsOpenAplyFilters] = useState<boolean>(false);
  const [totalItems, setTotalItems] = useState<number>(0);
  const [startDate, setStartDate] = useState<any>('');
  const [endDate, setEndDate] = useState<any>('');
  const { i18n } = useLocales();

  const [gridState, updateGridState] = useObjectState<GridState>(initialGridState);
  const [{ selectedTaskId, isTaskDetailDrawerOpen }, updateDrawerState] =
    useObjectState<TaskDetailDrawerState>({
      selectedTaskId: '',
      isTaskDetailDrawerOpen: false,
    });
  const [{ taskToEdit, isTaskEditModalOpen }, updateEditModalState] =
    useObjectState<TaskEditModalState>({
      taskToEdit: null,
      isTaskEditModalOpen: false,
    });

  const [taskStatusRecords] = useValueSets({
    filter: {
      identifier: 'crs-task-status',
    },
    map: ValueSetWrapper,
  });

  const taskStatus = useMemo(() => taskStatusRecords?.[0] ?? null, [taskStatusRecords]);

  const handleSearchChange = useMemo(
    () =>
      debounce((search: string) => {
        updateGridState({ search });
      }, 600),
    []
  );

  const handleTaskDetailsOpen = useCallback((fhirId: string) => {
    updateDrawerState({
      isTaskDetailDrawerOpen: true,
      selectedTaskId: fhirId,
    });
  }, []);

  const fetchTaskList = async () => {
    const { page, rowsPerPage, orderBy, search, orderDirection, statusFilter, ownerFilter } =
      gridState;
    const paginateQuery: PaginateQuery = {
      page: page,
      limit: rowsPerPage,
      sortBy: orderBy && orderDirection ? [[orderBy, orderDirection.toUpperCase()]] : [],
    };

    if (searchValue) {
      paginateQuery.search = searchValue;
    }

    paginateQuery.filter = {};

    if (statusFilter.length) {
      paginateQuery.filter.status = `$in:${statusFilter.map(({ code }) => code).join(',')}`;
    }
    if (startDate) {
      paginateQuery.filter.periodStart = `$gte:${startDate._d.toISOString()}`;
    }

    if (endDate) {
      paginateQuery.filter.periodEnd = `$lte:${endDate._d.toISOString()}`;
    }
    if (ownerFilter.length) {
      paginateQuery.filter.ownerFhirId = `$in:${ownerFilter
        .map(({ reference }) => reference)
        .join(',')}`;
    }

    const response = await getTasks(paginateQuery);
    const data = response?.data;

    setList(data ?? []);
    if (response?.meta?.totalItems !== undefined) setTotalItems(response?.meta?.totalItems);
  };

  useEffect(() => {
    fetchTaskList();
  }, []);
  useEffect(() => {
    fetchTaskList();
  }, [searchValue, gridState]);

  const handleChangeRowsPerPage = (rowsPerPage: number) => {
    updateGridState({ rowsPerPage });
  };

  const handlePageChange = (page: number) => {
    updateGridState({ page: page + 1 });
  };

  const handleSortModelChange = (sortModel: GridSortModel) => {
    const { field, sort } = sortModel?.[0] ?? {};
    updateGridState({
      orderBy: field ?? null,
      orderDirection: sort ?? null,
    });
  };
  const { filters, updateFilters, onClearAllFilters } = useGridFilterConsolidation<any>(
    initialTaskFilters,
    initialTaskFilters
  );
  const handleOnSuccesEdit = (_: any) => {
    fetchTaskList();
  };
  // const handleDateFilterChange =
  //   (dateProp: 'endDate' | 'startDate'): onDateChange =>
  //     (newValue) => {
  //       updateFilters({ [dateProp]: newValue });
  //     };
  // const { startDate, endDate, selectedOwners } = filters;

  return (
    <Box sx={{ width: '100% !important' }}>
      <Page title={i18n('tasks.title', 'crs')}>
        <Container maxWidth={themeStretch ? false : 'xl'}>
          <HeaderBreadcrumbs
            title={i18n('tasks.title', 'crs')}
            heading=""
            links={[
              { name: `${i18n('admin.list.dashboard')}`, href: PATH_DASHBOARD.root },
              { name: 'CRS'},
              { name: `${i18n('tasks.title', 'crs')}`, href: PATH_DASHBOARD.crs.task },
            ]}
          />
          <Grid item xs={12} sx={{ marginTop: 4 }}>
            <Card sx={{ width: '100%', paddingTop: 4, paddingX: 1 }}>
              <Grid
                container
                sx={{
                  display: 'flex',
                  justifyContent: 'space-between',
                  marginBottom: 4,
                  paddingX: 3,
                }}
                spacing={2}
              >
                <Grid item xs={3}>
                  <SearchTextField
                    value={searchValue}
                    onChange={(event) => {
                      const value = event.target.value;
                      setSearchValue(value);
                      handleSearchChange(value);
                    }}
                    fullWidth
                  />
                </Grid>
                <Grid container alignItems="center" justifyContent="flex-end" xs={3}>
                  <Tooltip title="Filter">
                    <IconButton
                      onClick={() => {
                        setIsOpenAplyFilters(true);
                      }}
                      sx={{ height: '40px' }}
                    >
                      <FilterListIcon htmlColor="#919eab" />
                    </IconButton>
                  </Tooltip>
                </Grid>

                {/* <Grid item xs={3}>
                  <Autocomplete
                    value={gridState.statusFilter}
                    multiple
                    fullWidth
                    // onChange={(_: React.SyntheticEvent, taskStatus) => {
                    //   updateGridState({ statusFilter: taskStatus });
                    // }}
                    options={taskStatus?.asList() ?? []}
                    getOptionLabel={({ display }: ValueSetComposeIncludeConcept) => display ?? ''}
                    renderInput={(params) => (
                      <TextField {...params} label={'Status'} variant="outlined" />
                    )}
                  />
                </Grid>
                <Grid item xs={3}>
                  <Autocomplete
                    open={false}
                    value={gridState.ownerFilter}
                    multiple
                    fullWidth
                    sx={{
                      '& .MuiAutocomplete-popupIndicator': {
                        visibility: 'hidden',
                      },
                    }}
                    onChange={(_: React.SyntheticEvent, owners) => {
                      updateGridState({ ownerFilter: owners });
                    }}
                    options={gridState.ownerFilter}
                    getOptionLabel={({ display }: Reference) => display ?? ''}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        label={'Owner'}
                        variant="outlined"
                        InputProps={{
                          ...params.InputProps,
                          startAdornment: (
                            <>
                              <Tooltip title="Click here to search by owner">
                                <IconButton
                                  onClick={() => {
                                    setIsSearchMemberModalOpen(true);
                                  }}
                                >
                                  <PersonSearchIcon />
                                </IconButton>
                              </Tooltip>
                              {params.InputProps.startAdornment}
                            </>
                          ),
                        }}
                      />
                    )}
                  />
                </Grid> */}
              </Grid>
              <DataGrid
                sx={{
                  width: '100% !important',
                  '& .MuiDataGrid-columnHeader': { backgroundColor: '#f4f6f8' },
                  '& .MuiDataGrid-row': {
                    cursor: 'pointer',
                  },
                }}
                rows={list}
                autoHeight={true}
                getCellClassName={() => 'taskGridBodyCell'}
                getRowId={({ fhirId }: TaskResponse) => fhirId}
                getRowSpacing={() => ({ bottom: 5 })}
                getRowHeight={() => 130}
                columns={getTaskListColumns(
                  taskStatus?.asList?.() ?? [],
                  i18n,
                  handleTaskDetailsOpen
                )}
                isRowSelectable={() => false}
                disableColumnFilter
                disableColumnMenu
                rowCount={totalItems}
                pagination
                paginationMode="server"
                sortingMode="server"
                sortModel={
                  gridState.orderBy && gridState.orderDirection
                    ? [{ field: gridState.orderBy, sort: gridState.orderDirection }]
                    : []
                }
                onSortModelChange={handleSortModelChange}
                pageSize={gridState.rowsPerPage}
                onPageSizeChange={handleChangeRowsPerPage}
                onPageChange={handlePageChange}
                rowsPerPageOptions={[5, 10, 25]}
                page={gridState.page - 1}
              />
            </Card>
          </Grid>
        </Container>
      </Page>

      <SearchMemberModal
        open={isSearchMemberModalOpen}
        onCancel={() => {
          setIsSearchMemberModalOpen(false);
        }}
        onSelectResource={(resource) => {
          updateGridState(({ ownerFilter }) => ({
            ownerFilter: [
              ...(ownerFilter ?? []),
              { display: getOwnerDisplayLabel(resource), reference: resource?.id },
            ],
          }));
          setIsSearchMemberModalOpen(false);
        }}
      />
      <TaskDetailDrawer
        onEdit={(task) => {
          updateDrawerState({ isTaskDetailDrawerOpen: false });
          updateEditModalState({
            taskToEdit: task,
            isTaskEditModalOpen: true,
          });
        }}
        open={isTaskDetailDrawerOpen}
        taskId={selectedTaskId}
        onSuccesfulEdit={() => {
          fetchTaskList();
        }}
        onCloseIconButtonClick={() => {
          updateDrawerState({ isTaskDetailDrawerOpen: false });
        }}
      />
      <TaskModal
        taskToEdit={taskToEdit as WrappedTask}
        onSuccessfulEdit={handleOnSuccesEdit}
        onSuccessfulCreation={handleOnSuccesEdit}
        open={isTaskEditModalOpen}
        onClose={() => {
          updateEditModalState({
            isTaskEditModalOpen: false,
          });
        }}
      />
      <FilterDrawer
        onApplyButtonClick={() => {
          updateGridState(initialGridState);
          fetchTaskList();
          setIsOpenAplyFilters(false);
        }}
        title={i18n('patients.details.tasks.filterPopUp.title', 'crs')}
        onCloseIconButtonClick={() => {
          setIsOpenAplyFilters(false);
        }}
        onClearAllButtonClick={() => {
          setStartDate(null), setEndDate(null), updateGridState(initialGridState);
        }}
        anchor={'right'}
        open={isOpenAplyFilters}
        i18n={i18n}
      >
        <TaskFilterList
          startDate={startDate}
          endDate={endDate}
          onStartDateChange={setStartDate}
          onEndDateChange={setEndDate}
          gridState={gridState}
          updateGridState={updateGridState}
          taskStatus={taskStatus}
          setIsSearchMemberModalOpen={setIsSearchMemberModalOpen}
          i18n={i18n}
        />
      </FilterDrawer>
    </Box>
  );
};
export type OnOwnerSelectionChange = (event: React.SyntheticEvent, newValue: Reference[]) => void;

interface TaskFilterListProps {
  startDate: moment.Moment | null;
  endDate: moment.Moment | null;
  onStartDateChange: onDateChange;
  onEndDateChange: onDateChange;
  gridState: any;
  updateGridState: any;
  taskStatus: any;
  setIsSearchMemberModalOpen: any;
  i18n: any;
}

const TaskFilterList = ({
  startDate,
  endDate,
  onStartDateChange,
  onEndDateChange,
  gridState,
  updateGridState,
  taskStatus,
  setIsSearchMemberModalOpen,
  i18n,
}: TaskFilterListProps) => (
  <Box py={3}>
    <Box>
      <Grid item sx={{ marginTop: 1 }} xs={5}>
        <Typography fontSize={'1rem'} fontWeight={'bold'} marginBottom={1.4}>
          Status
        </Typography>
        <Autocomplete
          value={gridState.statusFilter}
          multiple
          fullWidth
          onChange={(_: React.SyntheticEvent, taskStatus) => {
            console.log(taskStatus);
            updateGridState((prevGridState: any) => ({
              ...prevGridState,
              statusFilter: taskStatus,
            }));
          }}
          options={taskStatus?.asList() ?? []}
          getOptionLabel={({ display }: ValueSetComposeIncludeConcept) => display ?? ''}
          renderInput={(params) => (
            <TextField
              {...params}
              label={i18n('patients.details.tasks.filterPopUp.status', 'crs')}
              variant="outlined"
            />
          )}
        />
      </Grid>
    </Box>
    <Box marginTop={3} marginBottom={1}>
      <PeriodFilter
        startDate={startDate}
        endDate={endDate}
        onStartDateChange={onStartDateChange}
        onEndDateChange={onEndDateChange}
      />
    </Box>

    <Box marginTop={3} marginBottom={1}>
      <Grid item xs={3}>
        <Autocomplete
          open={false}
          value={gridState.ownerFilter}
          multiple
          fullWidth
          sx={{
            '& .MuiAutocomplete-popupIndicator': {
              visibility: 'hidden',
            },
          }}
          onChange={(_: React.SyntheticEvent, owners) => {
            updateGridState((prevGridState: any) => ({
              ...prevGridState,
              ownerFilter: owners,
            }));
          }}
          options={gridState.ownerFilter}
          getOptionLabel={({ display }: Reference) => display ?? ''}
          renderInput={(params) => (
            <TextField
              {...params}
              label={i18n('patients.details.tasks.filterPopUp.owner', 'crs')}
              variant="outlined"
              InputProps={{
                ...params.InputProps,
                startAdornment: (
                  <>
                    <Tooltip title="Click here to search by owner">
                      <IconButton
                        onClick={() => {
                          setIsSearchMemberModalOpen(true);
                        }}
                      >
                        <PersonSearchIcon />
                      </IconButton>
                    </Tooltip>
                    {params.InputProps.startAdornment}
                  </>
                ),
              }}
            />
          )}
        />
      </Grid>
    </Box>
  </Box>
);
export default TaskList;
