import {
  Box,
  Button,
  Card,
  Dialog,
  DialogTitle,
  DialogActions,
  Grid,
  IconButton,
  Stack,
  TextField,
  Typography,
} from '@mui/material';
import * as Yup from 'yup';
import moment from 'moment';
import produce from 'immer';
import { useSnackbar } from 'notistack';
import useAuth from 'src/hooks/useAuth';
import { MenuItem } from '@mui/material';
import { MobileDatePicker } from '@mui/lab';
import Iconify from 'src/components/Iconify';
import { Languages } from 'src/@types/crs/patient';
import { useEffect, useMemo, useState } from 'react';
import { useForm, Controller } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import Language from 'src/sections/crs/common/Language';
import { usePatient, useValueSet } from 'src/@nicheaim/fhir-react';
import { ValueSetWrapper, WrappedValueSet } from 'src/@nicheaim/fhir-base/wrappers/ValueSet';
import { convertValueToValueSet, getExtension } from 'src/sections/crs/common/common-utils';
import { PatientWrapper, URLS_ETHNICITY, URLS_GENDER_IDENTITY, URLS_RACE, WrappedPatient } from 'src/@nicheaim/fhir-base/wrappers/Patient';
import { FormProvider, RHFTextField, RHFSelect } from '../../../../../components/hook-form';
import {
  Coding,
  Extension,
  PatientGender,
  ValueSetComposeIncludeConcept,
} from 'src/nicheaim-infrastructure/application/adapters/out/repositories/fhir/resources';
import { checkAclValidation } from 'src/utils/permissions/permission.utils';
import Ethnicity from 'src/sections/crs/common/Ethnicity';
import Race from 'src/sections/crs/common/Race';
import useLocales from 'src/hooks/useLocales';
import GenderIdentityAutoComplete from 'src/components/GenderIdentityAutoComplete';
import { getGenderIdentityExtension } from 'src/utils/fhir';
import crsAcls from 'src/utils/permissions/crs/crsAcls';

type FormValue = {
  lastName: string;
  middleName: string;
  firstName: string;
  birthDate: Date;
  gender: string;
  ethnicity: string[];
  race: string[];
  language: Languages[];
  ethnicityArray: any;
  raceArray: any;
  genderIdentity: ValueSetComposeIncludeConcept | null;
};

type Props = {
  patient: WrappedPatient;
  open: boolean;
  user: any;
  onClose: VoidFunction;
  i18n: any;
  genderIdentityVS: WrappedValueSet | null;
};

const EditMode = ({ i18n, patient, open, onClose, genderIdentityVS }: Props) => {
  const { enqueueSnackbar } = useSnackbar();

  const [, { update }] = usePatient(patient.id!, { map: PatientWrapper });

  const [ethnicityCategories] = useValueSet('omb-ethnicity-category', { map: ValueSetWrapper });
  const [raceCategories] = useValueSet('omb-race-category', { map: ValueSetWrapper });
  const [simpleLanguage] = useValueSet('simple-language', { map: ValueSetWrapper });

  const defaultValues = useMemo(
    () =>
      ({
        lastName: patient?.name?.[0].family || '',
        firstName: patient?.name?.[0].given?.[0] || '',
        middleName: patient?.name?.[0].given?.[1] || '',
        birthDate: patient?.birthDate
          ? moment.utc(new Date(patient?.birthDate)).format('MM/DD/YYYY')
          : new Date(),
        gender: patient?.gender || '',
        ethnicity: patient.getEthnicityArray()?.map((e: Coding) => e.display),
        race: patient.getRaceArray()?.map((e: Coding) => e.display),
        ethnicityArray: patient.getEthnicityArray(),
        raceArray: patient.getRaceArray(),
        language: patient?.getLanguage() || [],
        genderIdentity: patient?.getGenderIdentityCodeableConcept?.() ?? null,
      } as FormValue),
    [patient]
  );

  const EventSchema = Yup.object().shape({
    firstName: Yup.string()
      .required('First Name is required')
      .matches(/^[aA-zZ\s]+$/, 'First Name must be alphabets')
      .max(25, 'First Name must be a maximum of 25 characters'),
    lastName: Yup.string()
      .required('Last Name is required')
      .matches(/^[aA-zZ\s]+$/, 'Last Name must be alphabets')
      .max(25, 'Last Name must be a maximum of 25 characters'),
    middleName: Yup.string()
      .nullable()
      .transform((curr, orig) => (orig === '' ? null : curr))
      .matches(/^[aA-zZ\s]+$/, 'Middle Name must be alphabets')
      .max(25, 'Middle Name must be a maximum of 25 characters'),
    birthDate: Yup.date()
      .nullable()
      .transform((curr, orig) => (orig === '' ? null : curr))
      .required('Date of birth is required')
      .test('date_of_birth_test', 'Date of birth cannot be a date in the future', (value) => {
        const birthDate = moment(value ?? null);
        if (birthDate?.isSameOrAfter(moment())) {
          // @ts-ignore
          setError('birthDate', {
            message: 'Date of birth cannot be a date in the future',
            type: 'manual',
          });
          return false;
        }
        return true;
      }),
    language: Yup.lazy((val) =>
      Array.isArray(val)
        ? Yup.array().of(
            Yup.object().shape({
              value: Yup.string().min(2).required('Language is required'),
            })
          )
        : Yup.object().shape({
            value: Yup.string().min(2).required('Language is required'),
          })
    ),
  });

  const methods = useForm({ resolver: yupResolver(EventSchema), defaultValues });

  const { reset, control, handleSubmit, setError, setValue, getValues } = methods;

  useEffect(() => {
    reset(defaultValues);
  }, [open, defaultValues]);

  const handleClose = () => {
    onClose();
  };

  const onSubmit = async (data: FormValue) => {
    try {
      const dataRace = data?.raceArray;
      const codeValueRace = data?.raceArray?.length > 0 ? 
        getExtension(dataRace, raceCategories, URLS_RACE[1]) : [];

      const dataEthnicity = data?.ethnicityArray;
      
      const codeValueEthnicity = data?.ethnicityArray?.length > 0 ? 
        getExtension(dataEthnicity, ethnicityCategories, URLS_ETHNICITY[1]) : [];

      let codeValueLanguage: any = data?.language?.map((e: Languages) => {
        const coding = convertValueToValueSet(e.value || '', simpleLanguage);
        const communication = {
          language: { coding: [coding], text: coding?.display },
          preferred: e.preferred,
        };
        return communication;
      });

      const genderIdentity = data.genderIdentity;

      const genderIdentityExtension: Extension[] =
        genderIdentity?.code && genderIdentity?.display
          ? [
              {
                ...getGenderIdentityExtension({
                  ...(genderIdentity as Coding),
                  system: genderIdentity?.system?.includes('administrative-gender') ? 
                    genderIdentity?.system : genderIdentityVS?.getConceptSystem?.(),
                }),
              },
            ]
          : [];

      let otherExtension =
        patient?.extension?.filter(
          (e: any) =>
            ![
              ...URLS_ETHNICITY,
              ...URLS_RACE,
              ...URLS_GENDER_IDENTITY
            ]?.some((s: any) => s === e?.url)
        ) || [];

      let extension: any = [
        ...otherExtension,
        ...(codeValueRace && codeValueRace),
        ...(codeValueEthnicity && codeValueEthnicity),
        ...genderIdentityExtension,
      ].filter((e: any) => e !== null);

      await update(
        produce(patient!, (draft) => {
          draft.name ??= [];
          draft.name[0] ??= {};
          draft.name[0].family = data.lastName;
          draft.name[0].given ??= [];
          draft.name[0].given[0] = data.firstName;
          draft.name[0].given[1] = data.middleName;
          draft.birthDate = moment.utc(new Date(data.birthDate)).format('YYYY-MM-DD');
          draft.gender = data.gender as PatientGender;
          draft.extension = [...extension];
          draft.communication = codeValueLanguage;
        })
      );

      handleClose();
      enqueueSnackbar(`${i18n('patients.title', 'crs')} was updated.`);
    } catch {
      handleClose();
      enqueueSnackbar(`${i18n('patients.title', 'crs')} was not updated.`, { variant: 'error' });
    }
  };
  if (!open) return null;

  return (
    <Dialog keepMounted={false} open={open} onClose={handleClose} fullWidth={true} maxWidth="sm">
      <DialogTitle>{i18n('patients.details.personalDetails.title', 'crs')}</DialogTitle>
      <FormProvider methods={methods} onSubmit={handleSubmit(onSubmit)}>
        <Card sx={{ m: 2 }}>
          <Grid container>
            <Grid item xs={12}>
              <Stack spacing={2} sx={{ p: 2 }}>
                <Typography variant="body2">{i18n('patients.lastName', 'crs')}</Typography>
                <RHFTextField name="lastName" label="" />

                <Typography variant="body2">{i18n('patients.middleName', 'crs')}</Typography>
                <RHFTextField name="middleName" label="" />

                <Typography variant="body2">{i18n('patients.firstName', 'crs')}</Typography>
                <RHFTextField name="firstName" label="" />

                <Typography variant="body2">{i18n('patients.dateOfBirth', 'crs')}</Typography>
                <Controller
                  name="birthDate"
                  control={control}
                  render={({ field, fieldState: { error } }) => (
                    <MobileDatePicker
                      {...field}
                      label=""
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          error={!!error?.message}
                          helperText={error?.message}
                          fullWidth
                        />
                      )}
                    />
                  )}
                />

                <Typography variant="body2">{i18n('patients.gender', 'crs')}</Typography>
                <RHFSelect name="gender" label="">
                  <MenuItem disabled></MenuItem>
                  <MenuItem value="female">Female</MenuItem>
                  <MenuItem value="male">Male</MenuItem>
                  <MenuItem value="other">Other</MenuItem>
                  <MenuItem value="unknown">Unknown</MenuItem>
                </RHFSelect>

                <Typography variant="body2">{i18n('patients.genderIdentity', 'crs')}</Typography>
                <GenderIdentityAutoComplete
                  label=""
                  value={getValues('genderIdentity')}
                  onValueChange={(gender) => {
                    setValue('genderIdentity', gender);
                  }}
                  genderIdentityVS={genderIdentityVS}
                  isLoading={!genderIdentityVS}
                />

                <Language simpleLanguage={simpleLanguage} />

                <Ethnicity ethnicityCategories={ethnicityCategories} />

                <Race raceCategories={raceCategories} />
              </Stack>
            </Grid>
          </Grid>
          <Stack spacing={2} alignItems="center">
            <DialogActions>
              <Box sx={{ flexGrow: 1 }} />
              <Button variant="contained" color="info" onClick={handleClose}>
                {i18n('cancel')}
              </Button>
              {checkAclValidation({ acls: [crsAcls.CRS.PATIENT.PERSONAL_DETAILS.EDIT] }) && (
                <Button variant="contained" color="info" type="submit">
                  {i18n('submit')}
                </Button>
              )}
            </DialogActions>
          </Stack>
        </Card>
      </FormProvider>
    </Dialog>
  );
};

type DisplayModeProps = {
  patient: WrappedPatient;
  i18n: any;
  genderIdentityVS: WrappedValueSet | null;
};

const DisplayMode = ({ patient, i18n, genderIdentityVS }: DisplayModeProps) => (
  <Grid container spacing={3} sx={{ p: 1 }}>
    <Grid item xs={3}>
      <Stack direction="column" alignItems="left" justifyContent="space-between">
        <Typography variant="body2" sx={{ color: 'text.secondary', py: 1, fontWeight: 'bold' }}>
          {i18n('patients.lastName', 'crs')}
        </Typography>
        <Typography
          variant="body2"
          sx={{
            textTransform: 'capitalize',
          }}
        >
          {patient?.name?.[0]?.family}
        </Typography>

        <Typography variant="body2" sx={{ color: 'text.secondary', py: 1, fontWeight: 'bold' }}>
          {i18n('patients.gender', 'crs')}
        </Typography>
        <Typography
          variant="body2"
          sx={{
            textTransform: 'capitalize',
          }}
        >
          {patient?.gender}
        </Typography>
        <Box>
          <Typography variant="body2" sx={{ color: 'text.secondary', py: 1, fontWeight: 'bold' }}>
            {i18n('patients.genderIdentity', 'crs')}
          </Typography>
          <Typography
            variant="body2"
            sx={{
              textTransform: 'capitalize',
            }}
          >
            {patient?.getGenderIdentityCodeableConcept?.()?.display ?? ''}
          </Typography>
        </Box>
      </Stack>
    </Grid>

    <Grid item xs={3}>
      <Stack direction="column" alignItems="left" justifyContent="space-between">
        <Typography variant="body2" sx={{ color: 'text.secondary', py: 1, fontWeight: 'bold' }}>
          {i18n('patients.middleName', 'crs')}
        </Typography>
        <Typography
          variant="body2"
          sx={{
            textTransform: 'capitalize',
          }}
        >
          {patient?.name?.[0]?.given?.[1]}
        </Typography>

        <Typography variant="body2" sx={{ color: 'text.secondary', py: 1, fontWeight: 'bold' }}>
          {i18n('patients.details.personalDetails.race', 'crs')}
        </Typography>

        <Typography
          variant="body2"
          sx={{
            textTransform: 'capitalize',
          }}
        >
          {patient?.getRace()?.display}
        </Typography>
      </Stack>
    </Grid>
    <Grid item xs={3}>
      <Stack direction="column" alignItems="left" justifyContent="space-between">
        <Typography variant="body2" sx={{ color: 'text.secondary', py: 1, fontWeight: 'bold' }}>
          {i18n('patients.firstName', 'crs')}
        </Typography>
        <Typography variant="body2"> {patient?.name?.[0]?.given?.[0]}</Typography>

        <Typography variant="body2" sx={{ color: 'text.secondary', py: 1, fontWeight: 'bold' }}>
          {i18n('patients.details.personalDetails.ethnicity', 'crs')}
        </Typography>
        <Typography variant="body2">{patient?.getEthnicity()?.display}</Typography>
      </Stack>
    </Grid>
    <Grid item xs={3}>
      <Stack direction="column" alignItems="left" justifyContent="space-between">
        <Typography variant="body2" sx={{ color: 'text.secondary', py: 1, fontWeight: 'bold' }}>
          {i18n('patients.dateOfBirth', 'crs')}
        </Typography>
        <Typography variant="body2">
          {patient.birthDate ? `${moment(patient.birthDate).format('MMM D Y')}` : 'N/A'}
        </Typography>

        <Typography variant="body2" sx={{ color: 'text.secondary', py: 1, fontWeight: 'bold' }}>
          {i18n('patients.details.personalDetails.ageGroup', 'crs')}
        </Typography>
        <Typography variant="body2" sx={{ textTransform: 'capitalize' }}>
          {patient?.getAgeGroup()}
        </Typography>
      </Stack>
    </Grid>
  </Grid>
);
type PropDetails = {
  patient: WrappedPatient;
  genderIdentityVS: WrappedValueSet | null;
};

export default function PersonalDetails({ patient, genderIdentityVS }: PropDetails) {
  const { i18n } = useLocales();

  const user = useAuth();
  const [openEditPersonalDetails, setEditPersonalDetails] = useState(false);

  const handleCloseEditPersonalDetails = () => {
    setEditPersonalDetails(false);
  };

  return (
    <>
      <Stack direction="row" style={{ display: 'flex' }}>
        <Typography variant="button" sx={{ textTransform: 'uppercase', mt: 1 }}>
          {i18n('patients.details.personalDetails.title', 'crs')}
        </Typography>
        {checkAclValidation({ acls: [crsAcls.CRS.PATIENT.PERSONAL_DETAILS.EDIT] }) && (
          <IconButton onClick={() => setEditPersonalDetails(true)} sx={{ p: 0.5, ml: 1 }}>
            <Iconify icon={openEditPersonalDetails ? 'eva:save-outline' : 'eva:edit-outline'} />
          </IconButton>
        )}
      </Stack>
      <DisplayMode i18n={i18n} patient={patient} genderIdentityVS={genderIdentityVS} />
      <EditMode
        i18n={i18n}
        patient={patient}
        open={openEditPersonalDetails}
        user={user}
        onClose={handleCloseEditPersonalDetails}
        genderIdentityVS={genderIdentityVS}
      />
    </>
  );
}
