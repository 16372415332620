import { useCallback, useState } from 'react';
import { List, ListItem } from '@mui/material';
import { OpenInNew as OpenInNewIcon } from '@mui/icons-material';
import { useTheme } from '@mui/material/styles';
import { WrappedCondition } from 'src/@nicheaim/fhir-base/wrappers/Condition';
import { WrappedGoal } from 'src/@nicheaim/fhir-base/wrappers/Goal';
import GoalConditionDrawer, { OnConditionUnlink } from './GoalConditionDrawer';

export interface GoalConditionListProps {
  conditions: WrappedCondition[];
  goal?: WrappedGoal | null;
  onConditionUnlink?: OnConditionUnlink;
}

export const GoalConditionList = ({
  conditions,
  goal,
  onConditionUnlink,
}: GoalConditionListProps) => {
  const theme = useTheme();
  const [isConditionDrawerOpen, setIsConditionDrawerOpen] = useState(false);
  const [selectedCondition, setSelectedCondition] = useState<WrappedCondition | null>(null);
  const handleSelectCondition = useCallback((condition: WrappedCondition) => {
    setIsConditionDrawerOpen(true);
    setSelectedCondition(condition);
  }, []);
  const handleCloseDrawer = useCallback(() => {
    setIsConditionDrawerOpen(false);
    setSelectedCondition(null);
  }, []);

  return (
    <>
      <List>
        {conditions.map((x) => (
          <ListItem
            key={x.id}
            sx={{ color: theme.palette.primary.main, '&:hover': { cursor: 'pointer' } }}
            onClick={() => handleSelectCondition(x)}
          >
            <OpenInNewIcon sx={{ mr: 1 }} />
            {x?.code?.text ?? ''}
          </ListItem>
        ))}
      </List>
      <GoalConditionDrawer
        onClose={handleCloseDrawer}
        open={isConditionDrawerOpen}
        conditionExternal={selectedCondition}
        goalExternal={goal}
        onUnlink={onConditionUnlink}
        onCloseIconButtonClick={handleCloseDrawer}
      />
    </>
  );
};

export default GoalConditionList;
