import { Box, LinearProgress, Tooltip } from '@mui/material';
import { GridColDef } from '@mui/x-data-grid';
import {
  EventAvailable as EventAvailableIcon,
  Person as PersonIcon,
  Cake as CakeIcon,
  ContactMail as ContactMailIcon,
  ContactPhone as ContactPhoneIcon,
  Category as CategoryIcon,
  Description as DescriptionIcon,
  Update as UpdateIcon,
} from '@mui/icons-material';
import moment from 'moment';
import { TaskResponse, TaskStatusValue } from 'src/@types/crs/task';
import { formatDate, getAge } from 'src/utils/dates';
import { getSeverityDueDateData } from '../helpers/getSeverityDueDateData';
import TaskStatusSeverity from './TaskStatusSeverity';
import { TaskStatus } from '../case/components/TasksGrid/TaskModal';
import CellRow from '../common/CellRow';
import { i18nFunction } from 'src/hooks/useLocales';
import { NOTAPPLICABLE } from '../constants';
import CustomLink from '../common/CustomLink';

const getTaskListColumns = (
  taskStatuses: TaskStatus[],
  i18n: i18nFunction,
  onTaskOpen: (fhirId: string) => void
): GridColDef[] => [
  {
    field: 'fhirId',
    headerName: i18n('tasks.task', 'crs'),
    flex: 1,
    renderCell: (params) => {
      const { taskCodeCodingDisplay, internalNumber, fhirId, taskDescription } =
        params.row as TaskResponse;
      return (
        <Box>
          <CustomLink
            to="#"
            onClick={() => {
              onTaskOpen(fhirId);
            }}
          >
            <CellRow tooltipTitle={i18n('tasks.taskNumber', 'crs')} title={internalNumber} />
          </CustomLink>
          <CellRow
            tooltipTitle={i18n('tasks.taskType', 'crs')}
            title={taskCodeCodingDisplay}
            Icon={<CategoryIcon />}
          />
          <CellRow
            tooltipTitle={i18n('tasks.description', 'crs')}
            title={taskDescription}
            Icon={<DescriptionIcon />}
          />
        </Box>
      );
    },
  },
  {
    field: 'periodEnd',
    headerName: i18n('tasks.period', 'crs'),
    flex: 1,
    renderCell: (params) => {
      const { periodEnd, periodStart } = params.row as TaskResponse;
      const start = moment(periodStart ?? null);
      const end = moment(periodEnd ?? null);
      const { formattedDueDate: formattedStartDate } = getSeverityDueDateData(start);
      const { formattedDueDate: formattedEndDate } = getSeverityDueDateData(end);
      return (
        <Box>
          <CellRow
            tooltipTitle={i18n('tasks.startDate', 'crs')}
            title={formattedStartDate !== NOTAPPLICABLE ? formattedStartDate : null}
            Icon={<EventAvailableIcon />}
          />
          <CellRow
            tooltipTitle={i18n('tasks.endDate', 'crs')}
            title={formattedEndDate !== NOTAPPLICABLE ? formattedEndDate : null}
            Icon={<EventAvailableIcon />}
          />
        </Box>
      );
    },
  },
  {
    field: 'patientFullName',
    headerName: i18n('tasks.beneficiary.beneficiary', 'crs'),
    flex: 1,
    renderCell: (params) => {
      const { patientFullName, patientBirthDate } = params.row as TaskResponse;
      const birthDate = moment(patientBirthDate ?? null);
      let formattedBirthDate = formatDate(birthDate);
      if (!birthDate.isValid()) formattedBirthDate = '';
      return (
        <Box>
          <CellRow
            tooltipTitle={i18n('tasks.beneficiary.fullName', 'crs')}
            title={patientFullName}
            Icon={<PersonIcon />}
          />
          <CellRow
            tooltipTitle={i18n('tasks.beneficiary.birthDate', 'crs')}
            title={formattedBirthDate ? `${formattedBirthDate} (${getAge(birthDate)} yr)` : ''}
            Icon={<CakeIcon />}
          />
        </Box>
      );
    },
  },
  {
    field: 'ownerName',
    headerName: i18n('tasks.owner.owner', 'crs'),
    flex: 1,
    renderCell: (params) => {
      const { ownerName, ownerEmail, ownerPhone } = params.row as TaskResponse;
      return (
        <Box>
          <CellRow
            tooltipTitle={i18n('tasks.owner.fullName', 'crs')}
            title={ownerName}
            Icon={<PersonIcon />}
          />
          <CellRow
            tooltipTitle={i18n('tasks.owner.email', 'crs')}
            title={ownerEmail}
            Icon={<ContactMailIcon />}
          />
          <CellRow
            tooltipTitle={i18n('tasks.owner.phone', 'crs')}
            title={ownerPhone}
            Icon={<ContactPhoneIcon />}
          />
        </Box>
      );
    },
  },
  {
    field: 'requesterName',
    headerName: i18n('tasks.requester.requester', 'crs'),
    flex: 1,
    renderCell: (params) => {
      const { requesterName, requesterEmail } = params.row as TaskResponse;
      return (
        <Box>
          <CellRow
            tooltipTitle={i18n('tasks.requester.fullName', 'crs')}
            title={requesterName}
            Icon={<PersonIcon />}
          />
          <CellRow
            tooltipTitle={i18n('tasks.requester.email', 'crs')}
            title={requesterEmail}
            Icon={<ContactMailIcon />}
          />
        </Box>
      );
    },
  },
  {
    field: 'status',
    headerName: i18n('tasks.status', 'crs'),
    flex: 1,
    align: 'center',
    renderCell: (params) => {
      const { status } = params.row as TaskResponse;
      return (
        <Box sx={{ width: '100%', paddingX: 2 }}>
          <Tooltip title={i18n('tasks.taskStatus', 'crs')}>
            <span>
              <Box
                sx={{
                  display: 'flex',
                  flexDirection: 'row',
                  marginBottom: 0.5,
                  alignItems: 'center',
                }}
              >
                <Box sx={{ marginRight: 1 }}>
                  <UpdateIcon />
                </Box>
                <TaskStatusSeverity
                  sx={{ width: '100%' }}
                  status={status as TaskStatusValue}
                  taskStatuses={taskStatuses}
                />
              </Box>
            </span>
          </Tooltip>
          <LinearProgress
            sx={{ marginTop: 2, width: '100%' }}
            variant="determinate"
            color="info"
            value={status === TaskStatusValue.Completed ? 100 : 0}
          />
        </Box>
      );
    },
  },
  // {
  //   field: 'taskType',
  //   headerName: 'Task',
  //   headerAlign: 'center',
  //   flex: 3,
  //   headerClassName: 'taskGridHeader',
  //   renderCell: (params) => {
  //     const { taskCodeCodingDisplay, taskDescription } = params.row as TaskResponse;
  //     return (
  //       <Box justifyContent={'center'}>
  //         <Box display={'flex'} flexDirection={'row'} marginTop={0.4}>
  //           {/* <PersonIcon sx={{ marginRight: 1 }} fontSize={'small'} /> */}
  //           <Typography>Code: {taskCodeCodingDisplay ?? ''}</Typography>
  //         </Box>
  //         <Box display={'flex'} flexDirection={'row'} marginTop={0.4}>
  //           {/* <ContactMailIcon sx={{ marginRight: 1 }} fontSize={'small'} /> */}
  //           <Typography>Description: {taskDescription ?? ''}</Typography>
  //         </Box>
  //       </Box>
  //     );
  //   },
  // },

  // {
  //   field: 'periodEnd',
  //   headerName: 'End Date',
  //   headerAlign: 'center',
  //   flex: 1,
  //   headerClassName: 'taskGridHeader',
  //   renderCell: (params) => {
  //     const { periodEnd } = params.row as TaskResponse;
  //     const creationDate = moment(periodEnd ?? null);
  //     const { formattedDueDate } = getSeverityDueDateData(creationDate);
  //     if (formattedDueDate)
  //       return (
  //         <Box display={'flex'} flexDirection={'row'} alignItems={'center'}>
  //           <EventAvailableIcon sx={{ marginRight: 1 }} fontSize={'small'} />
  //           <Typography noWrap={true}>
  //             {formattedDueDate === 'NA' ? '' : formattedDueDate}
  //           </Typography>
  //         </Box>
  //       );
  //   },
  // },
  // {
  //   field: 'requesterName',
  //   headerName: 'Requester',
  //   headerAlign: 'center',
  //   flex: 1,
  //   headerClassName: 'taskGridHeader',
  //   renderCell: (params) => {
  //     const { requesterName, requesterEmail } = params.row as TaskResponse;
  //     return (
  //       <Box justifyContent={'center'}>
  //         <Box display={'flex'} flexDirection={'row'} marginTop={0.4}>
  //           <PersonIcon sx={{ marginRight: 1 }} fontSize={'small'} />
  //           <Typography>{requesterName ?? ''}</Typography>
  //         </Box>
  //         <Box display={'flex'} flexDirection={'row'} marginTop={0.4}>
  //           <ContactMailIcon sx={{ marginRight: 1 }} fontSize={'small'} />
  //           <Typography>{requesterEmail ?? ''}</Typography>
  //         </Box>
  //       </Box>
  //     );
  //   },
  // },
  // {
  //   field: 'status',
  //   headerName: 'Status',
  //   headerAlign: 'center',
  //   flex: 0.8,
  //   align: 'center',
  //   headerClassName: 'taskGridHeader',
  //   renderCell: (params) => {
  //     const { status } = params.row as TaskResponse;
  //     return (
  //       <Box
  //         sx={{ width: '100%', display: 'flex', justifyContent: 'center', flexDirection: 'column' }}
  //       >
  //         <TaskStatusSeverity
  //           sx={{ width: '45%', alignSelf: 'center' }}
  //           status={status as TaskStatusValue}
  //           taskStatuses={taskStatuses}
  //         />

  //         <LinearProgress
  //           sx={{ marginTop: 2 }}
  //           variant="determinate"
  //           color="info"
  //           value={status === TaskStatusValue.Completed ? 100 : 0}
  //         />
  //       </Box>
  //     );
  //   },
  // },
];

export default getTaskListColumns;
