import { getReferenceResourceId } from 'src/sections/crs/helpers/common';
import { createWrapper } from '../../fhir-react/base';
import { WrappedPractitioner } from './Practitioner';
import { WrappedOrganization } from './Organization';

export const PractitionerRoleWrapper = createWrapper('PractitionerRole', (practitionerRole) => ({
  getPractitionerName: (practitioners?: WrappedPractitioner[]) => {
    if (practitionerRole?.practitioner?.display) return practitionerRole.practitioner.display;
    const practitioner = practitioners?.find?.(
      ({ id }) => id === practitionerRole?.practitioner?.reference?.split?.('/')?.[0]
    );
    if (!practitioner) return '';
    return practitioner.getFullName() ?? '';
  },

  getOrganizationName: (organizations?: WrappedOrganization[]) => {
    if (practitionerRole?.organization?.display) return practitionerRole.organization.display;
    const organization = organizations?.find?.(
      ({ id }) => id === practitionerRole?.organization?.reference?.split?.('/')?.[0]
    );
    if (!organization) return '';
    return organization?.name ?? '';
  },

  getOrganizationId: () => practitionerRole?.organization?.reference?.split?.('/')?.[1] ?? '',
  getRoleName: () => practitionerRole?.code?.[0]?.coding?.[0]?.display ?? '',
  getRoleId: () => practitionerRole?.code?.[0]?.coding?.[0]?.code ?? '',
  getIdentifier: () => ({
    identifier: practitionerRole?.identifier?.[0]?.value ?? null,
    type: practitionerRole?.identifier?.[0]?.system ?? null,
  }),
}));

export type WrappedPractitionerRole = ReturnType<typeof PractitionerRoleWrapper>;
