import {
  Autocomplete,
  Button,
  Chip,
  DialogActions,
  Grid,
  MenuItem,
  Stack,
  TextField,
  Typography
} from "@mui/material";
import { useState } from "react";
import { DatePicker } from "@mui/lab";
import Iconify from "src/components/Iconify";
import MenuPopover from "src/components/MenuPopover";
import { IconButtonAnimate } from "src/components/animate";
import { ValueSetWrapper } from "src/@nicheaim/fhir-base/wrappers/ValueSet";
import { OrganizationWrapper } from "src/@nicheaim/fhir-base/wrappers/Organization";
import { PractitionerWrapper } from "src/@nicheaim/fhir-base/wrappers/Practitioner";
import { useOrganizations, usePractitioners, useValueSet } from "src/@nicheaim/fhir-react";
import useLocales from "src/hooks/useLocales";

type FiltersProps = {
  filterName: string;
  filterDate: Date | null;
  filterType: string;
  filterCustodian: string;
  onFilterName: (value: string) => void;
  onFilterDate: (value: Date | null) => void;
  onFilterType: (event: React.ChangeEvent<HTMLInputElement>) => void;
  onFilterCustodian: (event: React.ChangeEvent<HTMLInputElement>) => void;
  onFilterCategory: (value: string[]) => void;
  onFilterAuthor: (value: string[]) => void;
  onClearAll: () => void;
};

export default function DocumentsFilters({
  filterName,
  filterType,
  filterDate,
  filterCustodian,
  onFilterName,
  onFilterDate,
  onFilterType,
  onFilterCustodian,
  onFilterCategory,
  onFilterAuthor,
  onClearAll,
}: FiltersProps) {

  const [documentCategory] = useValueSet('crs-document-category-codes', { map: ValueSetWrapper });

  const [documentType] = useValueSet('crs-document-type-codes', { map: ValueSetWrapper });

  const [openFilter, setOpenFilter] = useState<HTMLElement | null>(null);

  const [organizations] = useOrganizations({ map: OrganizationWrapper });

  const [practitioner] = usePractitioners({ map: PractitionerWrapper });

  const { i18n } = useLocales();

  const handleOpenFilter = (event: React.MouseEvent<HTMLElement>) => {
    setOpenFilter(event.currentTarget);
  };

  const handleCloseFilter = () => {
    setOpenFilter(null);
  };

  return (
    <>
      <IconButtonAnimate
        color={openFilter ? 'primary' : 'default'}
        onClick={handleOpenFilter}
        sx={{ width: 40, height: 40 }}
      >
        <Iconify icon="ic:round-filter-list" width={20} height={20} />
      </IconButtonAnimate>

      <MenuPopover
        open={Boolean(openFilter)}
        anchorEl={openFilter}
        onClose={handleCloseFilter}
        sx={{ width: 600 }}
      >
        <Grid container spacing={2} sx={{ p: 1 }}>
          <Grid item xs={6}>
            <Stack spacing={1} sx={{ py: 2 }}>
              <Typography variant="body2">
                {i18n('patients.details.attachments.name', 'crs')}
              </Typography>
              <TextField
                size="small"
                sx={{ pb: 1 }}
                value={filterName}
                onChange={(event) => onFilterName(event.target.value)}
              />

              <Typography variant="body2">
                {i18n('patients.details.attachments.type', 'crs')}
              </Typography>
              <TextField
                size="small"
                select
                value={filterType}
                onChange={onFilterType}
                SelectProps={{
                  MenuProps: {
                    sx: { '& .MuiPaper-root': { maxHeight: 260 } },
                  },
                }}
                sx={{
                  pb: 1,
                  textTransform: 'capitalize',
                }}
              >
                {documentType?.asList().map((option) => (
                  <MenuItem
                    key={option.code}
                    value={option.display}
                    sx={{
                      mx: 1,
                      my: 0.5,
                      borderRadius: 0.75,
                      typography: 'body2',
                      textTransform: 'capitalize',
                    }}
                  >
                    {option.display}
                  </MenuItem>
                ))}
              </TextField>

              <Typography variant="body2">
                {i18n('patients.details.attachments.author', 'crs')}
              </Typography>
              <Autocomplete
                multiple
                freeSolo
                size="small"
                disablePortal
                onChange={(event, newValue: any) => {
                  onFilterAuthor(newValue);
                }}
                options={practitioner?.map((option) => option.getFullName()) || []}
                renderTags={(value, getTagProps) =>
                  value.map((option, index) => (
                    <Chip
                      {...getTagProps({ index })}
                      key={option}
                      size="small"
                      label={option}
                    />
                  ))
                }
                renderInput={(params) => <TextField label="" {...params} />}
              />

            </Stack>
          </Grid>

          <Grid item xs={6}>
            <Stack spacing={1} sx={{ py: 2 }}>

              <Typography variant="body2">
                {i18n('patients.details.attachments.date', 'crs')}
              </Typography>
              <DatePicker
                value={filterDate}
                onChange={onFilterDate}
                renderInput={(params) => (
                  <TextField
                    size="small"
                    {...params}
                    fullWidth
                    sx={{ pb: 1 }}
                  />
                )}
              />

              <Typography variant="body2">
                {i18n('patients.details.attachments.custodian', 'crs')}
              </Typography>
              <TextField
                size="small"
                select
                value={filterCustodian}
                onChange={onFilterCustodian}
                SelectProps={{
                  MenuProps: {
                    sx: { '& .MuiPaper-root': { maxHeight: 260 } },
                  },
                }}
                sx={{
                  pb: 1,
                  textTransform: 'capitalize',
                }}
              >
                {organizations?.map((option) => (
                  <MenuItem
                    key={option.id}
                    value={option.name}
                    sx={{
                      mx: 1,
                      my: 0.5,
                      borderRadius: 0.75,
                      typography: 'body2',
                      textTransform: 'capitalize',
                    }}
                  >
                    {option.name}
                  </MenuItem>
                ))}
              </TextField>

              <Typography variant="body2">
                {i18n('patients.details.attachments.category', 'crs')}
              </Typography>
              <Autocomplete
                multiple
                freeSolo
                size="small"
                disablePortal
                onChange={(event, newValue: any) => {
                  onFilterCategory(newValue);
                }}
                options={documentCategory?.asList().map((option) => option.display) || []}
                renderTags={(value, getTagProps) =>
                  value.map((option, index) => (
                    <Chip
                      {...getTagProps({ index })}
                      key={option}
                      size="small"
                      label={option}
                    />
                  ))
                }
                renderInput={(params) => <TextField label="" {...params} />}
              />

            </Stack>
          </Grid>
        </Grid>

        <Stack spacing={1} alignItems="center">
          <DialogActions>
            <Button variant="contained" color="info" onClick={onClearAll}>
              Clear
            </Button>
          </DialogActions>
        </Stack>
      </MenuPopover>
    </>
  )
}