import { useState } from 'react';
import { Autocomplete, TextField } from "@mui/material";
import usePermissionsContext from 'src/hooks/usePermissionsContext';

const SelectComponent = (props: any) => {
  const items = props?.others?.data && props?.others?.data.length > 0 ? props?.others?.data.map((i: any) => ({ label: `${i?.firstName} ${i?.lastName}`, id: i?.id })) : [{ label: '', id: '' }];
  const [value, setValue] = useState();
  const [inputValue, setInputValue] = useState();
  const {
    record,
    checklistItem,
    refreshChecklistHandler,
    refreshRecordHandler,
  } = props?.others;

  const { workflow } = usePermissionsContext<any>() ?? {};

  return (
    <>
      <Autocomplete
        disablePortal
        id="combo-box-demo"
        value={value}
        inputValue={inputValue}
        onInputChange={(event: any, newInputValue: any) => {
          setInputValue(newInputValue);
        }}
        options={items}
        renderInput={(params) => <TextField {...params} label={props?.name || props?.elementName} />}
        onChange={(event: any, username: any | null) => {
          setValue(username);
          if(workflow?.isAllowedToEdit){
            props?.others?.handler({
              record,
              checklistItem,
              refreshChecklistHandler,
              refreshRecordHandler,
              more: { selectedUser: username?.id },
            })
          }
        }}
      />
    </>
  );
};

export default SelectComponent;
