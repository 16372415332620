import { capitalCase } from 'change-case';

export const trimMultipleWhiteSpaces = (str: string): string => str.replace(/\s+/g, ' ');
export const capitalize = (str: string): string =>
  cleanSearchInput(str)
    .split(' ')
    .map((word) => word[0].toUpperCase() + word.substring(1))
    .join(' ');

export const transformScope = (str: string): string => {
  str = str.replace(/[._]/g, ' ');
  str = capitalCase(str);
  return str;
};

export const convertToQueryParams = (filters: any): string =>
  Object.entries(filters).reduce<string>((filterString, [filterKey, filterValue]) => {
    if ((Array.isArray(filterValue) && !filterValue.length) || !filterValue) {
      return filterString;
    }
    return (filterString += `&${filterKey}=${filterValue}`);
  }, '');

export const cleanSearchInput = (search: string): string => trimMultipleWhiteSpaces(search).trim();
