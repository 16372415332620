import { yupResolver } from '@hookform/resolvers/yup';
import { Add } from '@mui/icons-material';
import { DatePicker } from '@mui/lab';
import {
  Backdrop,
  Button,
  Card,
  CircularProgress,
  Container,
  Dialog,
  DialogTitle,
  Grid,
  MenuItem,
  Stack,
  TextField,
  Typography,
} from '@mui/material';
import { useJsApiLoader } from '@react-google-maps/api';
import { isEmpty } from 'lodash';
import moment from 'moment';
import { useSnackbar } from 'notistack';
import { useMemo, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import InputMask from 'react-input-mask';
import { useNavigate } from 'react-router';
import { IdentifierCode, PatientWrapper } from 'src/@nicheaim/fhir-base/wrappers/Patient';
import { ValueSetWrapper } from 'src/@nicheaim/fhir-base/wrappers/ValueSet';
import { usePatients, useValueSet } from 'src/@nicheaim/fhir-react';
import { CreateFullPatientDto } from 'src/ccm/dto/create-full-patient.dto';
import { fullPatientService } from 'src/ccm/full-patient/services';
import GenderIdentityAutoComplete from 'src/components/GenderIdentityAutoComplete';
import HeaderBreadcrumbs from 'src/components/HeaderBreadcrumbs';
import Page from 'src/components/Page';
import { FormProvider, RHFSelect, RHFTextField } from 'src/components/hook-form';
import { SimpleOrgI } from 'src/contexts/JWTContext';
import useAuth from 'src/hooks/useAuth';
import useLocales from 'src/hooks/useLocales';
import useSettings from 'src/hooks/useSettings';
import {
  ContactPoint,
  ContactPointUse,
  Extension,
  PatientGender,
  ValueSetComposeIncludeConcept,
} from 'src/nicheaim-infrastructure/application/adapters/out/repositories/fhir/resources';
import { PATH_DASHBOARD } from 'src/routes/paths';
import client from 'src/services/api/_client';
import { getGenderIdentityExtension, getIdentifier } from 'src/utils/fhir';
import * as Yup from 'yup';
import AddAddress from '../referral/components/workflow-step/AddressChecklistItem/AddAddress';
import { getUUID } from 'src/utils/url';

type FormValues = {
  firstName: string;
  lastName: string;
  middleName: string;
  dateOfBirth: string;
  gender: string;
  emailType: string;
  email: string;
  phoneType: string;
  street: string;
  street2: string;
  city: string;
  district: string;
  phone: string;
  areaCode: string;
  state: string;
  zipCode: string;
  genderIdentity: ValueSetComposeIncludeConcept | null;
};

const NewPatient = () => {
  const navigate = useNavigate();
  const auth = useAuth();
  const { themeStretch } = useSettings();
  const { enqueueSnackbar } = useSnackbar();
  const [openBackdrop, setOpenBackdrop] = useState(false);
  const [, { create }] = usePatients({ map: PatientWrapper });
  const { i18n } = useLocales();

  const [genderIdentityVS, { isLoading }] = useValueSet('ph-gender-identity', {
    map: ValueSetWrapper,
  });

  const selectedOrg = useMemo<SimpleOrgI>(() => {
    return auth.getSelectedOrg();
  }, [auth]);

  const EventSchema = Yup.object().shape({
    firstName: Yup.string()
      .required('First Name is required')
      .matches(/^[aA-zZ\s]+$/, 'First Name must be alphabets')
      .max(25, 'First Name must be a maximum of 25 characters'),
    lastName: Yup.string()
      .required('Last Name is required')
      .matches(/^[aA-zZ\s]+$/, 'Last Name must be alphabets')
      .max(25, 'Last Name must be a maximum of 25 characters'),
    middleName: Yup.string()
      .nullable()
      .transform((curr, orig) => (orig === '' ? null : curr))
      .matches(/^[aA-zZ\s]+$/, 'Middle Name must be alphabets')
      .max(25, 'Middle Name must be a maximum of 25 characters'),
    email: Yup.string().email('Email must be a valid email address'),
    emailType: Yup.string().when('email', (email, schema) => {
      if (email) return schema.required('Email Type is required');
    }),
    areaCode: Yup.string()
      .nullable()
      .transform((curr, orig) => (orig === '' ? null : curr))
      .matches(/^[(]?[0-9]{3}[)]$/, 'Area Code must be 3 numbers')
      .when('phone', (value, schema) => {
        if (value) return schema.required('Area Code is required');
      }),
    phone: Yup.string()
      .nullable()
      .transform((curr, orig) => (orig === '' ? null : curr))
      .matches(/^[0-9]{3}[-\s\.]?[0-9]{4,6}$/, 'Phone Number must be 7 numbers'),
    phoneType: Yup.string().when('phone', (phone, schema) => {
      if (phone) return schema.required('Phone Type is required');
    }),
    dateOfBirth: Yup.date()
      .nullable()
      .transform((curr, orig) => (orig === '' ? null : curr))
      .required('Date of birth is required')
      .test('date_of_birth_test', 'Date of birth cannot be a date in the future', (value) => {
        const birthDate = moment(value ?? null);
        if (birthDate?.isSameOrAfter(moment())) {
          // @ts-ignore
          setError('dateOfBirth', {
            message: 'Date of birth cannot be a date in the future',
            type: 'manual',
          });
          return false;
        }
        return true;
      }),
  });

  const defaultValues: FormValues = {
    firstName: '',
    lastName: '',
    middleName: '',
    dateOfBirth: '',
    gender: '',
    emailType: '',
    email: '',
    phoneType: '',
    street: '',
    street2: '',
    city: '',
    district: '',
    phone: '',
    areaCode: '',
    state: '',
    zipCode: '',
    genderIdentity: null,
  };

  const methods = useForm({ resolver: yupResolver(EventSchema), defaultValues });
  const [openAddAddress, setOpenAddAddress] = useState(false);
  const [address, setAddress] = useState({});
  const [, setToggleableBoolean] = useState(false);

  const {
    control,
    reset,
    setValue,
    getValues,
    handleSubmit,
    setError,
    formState: { errors },
  } = methods;

  const forceStateUpdate = () => {
    setToggleableBoolean((currValue) => !currValue);
  };

  const handleMPISearch = async (
    dataForm: FormValues,
    create_if_not_exist?: boolean
  ): Promise<any> => {
    const payload = {
      name: [{ family: dataForm.lastName, given: [dataForm.firstName] }],
      birthDate: dataForm.dateOfBirth && new Date(dataForm.dateOfBirth).toISOString().split('T')[0],
      gender: dataForm.gender as PatientGender,
      createIfNotExist: create_if_not_exist,
    };

    const res = await client.post('/mpi/search', payload);
    const data = res?.data[0];

    return data;
  };

  const saveChanges = async (data: any) => {
    setValue('street', data.line[0]);
    setValue('street2', data.line[1]);
    setValue('city', data.city);
    setValue('district', data.district);
    setValue('zipCode', data.postalCode);
    setValue('state', data.state);
    setAddress(data);
    setOpenAddAddress(false);
  };

  const onSubmit = async (data: FormValues) => {
    try {
      setOpenBackdrop(true);

      const mpiSearchResult = await handleMPISearch(data, false);

      if (isEmpty(mpiSearchResult)) {
        const dataResult = await handleMPISearch(data, true);

        if (!isEmpty(dataResult)) {
          dataResult?.identifier?.push({
            use: 'official',
            type: 'master_patient_uuid',
            value: dataResult?.uuid,
            system: `${process.env.REACT_APP_MPI_SEARCH_URL}/patients`,
          });

          const mrn = getIdentifier(dataResult, IdentifierCode.MEDICAL_RECORD_NUMBER);
          const master_person_uuid = getIdentifier(dataResult, IdentifierCode.MASTER_PERSON_UUID);
          const master_patient_uuid = getIdentifier(dataResult, IdentifierCode.MASTER_PATIENT_UUID);

          const telecom: ContactPoint[] = [];

          if (!isEmpty(data.emailType) && !isEmpty(data.email))
            telecom.push({
              system: 'email',
              use: data.emailType as ContactPointUse,
              value: data.email,
            });
          if (!isEmpty(data.phoneType) && !isEmpty(data.phone)) {
            telecom.push({
              system: 'phone',
              use: data.phoneType as ContactPointUse,
              ...(data.areaCode
                ? { value: `${data.areaCode} ${data.phone}` }
                : { value: `${data.phone}` }),
            });
          }

          const genderIdentity = data.genderIdentity;

          const genderIdentityExtension: Extension | null =
            genderIdentity?.code && genderIdentity?.display
              ? getGenderIdentityExtension({
                  ...genderIdentity,
                  system: genderIdentityVS?.getConceptSystem?.(),
                })
              : null;

          if (!isEmpty(mrn) && !isEmpty(master_person_uuid) && !isEmpty(master_patient_uuid)) {
            const [fhirPatient] = await create({
              resourceType: 'Patient',
              active: true,
              identifier: [mrn, master_person_uuid, master_patient_uuid],
              name: [
                {
                  given: [data.firstName, ...(data.middleName ? [data.middleName] : [])],
                  family: data.lastName,
                },
              ],
              birthDate: data.dateOfBirth
                ? new Date(data.dateOfBirth).toISOString().split('T')[0]
                : undefined,
              ...(data.gender && { gender: data.gender as PatientGender }),
              ...(!isEmpty(telecom) && { telecom: telecom }),
              ...(!isEmpty(address) && { address: [address] }),
              managingOrganization: {
                display: selectedOrg?.name,
                reference: `Organization/${getUUID(selectedOrg?.fhir_uri)}`,
              },
              extension: genderIdentityExtension ? [genderIdentityExtension] : undefined,
            });

            const fullPatient = new CreateFullPatientDto();
            fullPatient.fhirRefUri = fhirPatient.id!;
            fullPatient.given = data.firstName;
            fullPatient.family = data.lastName;
            fullPatient.fullName = `${data.firstName} ${data.lastName}`;
            fullPatient.gender = data.gender;
            fullPatient.dateOfBirth = new Date(data.dateOfBirth) || undefined;
            fullPatient.email = data.email;
            fullPatient.phone = data.phone;
            fullPatient.status = 'new';

            await fullPatientService.create(fullPatient);

            navigate(PATH_DASHBOARD.crs.patient.details.forId(fhirPatient.id || ''));
          }
        }
      } else {
        enqueueSnackbar('This patient was already registered', { variant: 'error' });
      }

      reset(defaultValues);
      setOpenBackdrop(false);
    } catch (error) {
      reset(defaultValues);
      setOpenBackdrop(false);
      enqueueSnackbar('An error has ocurred.', { variant: 'error' });
    }
  };

  const JsOptions = useMemo<any>(
    () => ({
      id: 'google-map-script',
      googleMapsApiKey: process.env.REACT_APP_GOOGLE_MAPS_API_KEY || '',
      libraries: ['places'],
    }),
    []
  );
  const { isLoaded } = useJsApiLoader(JsOptions);

  return (
    <Page title={`New ${i18n('patients.title', 'crs')}`}>
      <Container maxWidth={themeStretch ? false : 'xl'}>
        <Backdrop
          sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
          open={openBackdrop}
        >
          <CircularProgress color="inherit" />
        </Backdrop>
        <HeaderBreadcrumbs
          title={`New ${i18n('patients.title', 'crs')}`}
          heading=""
          links={[
            { name: `${i18n('admin.list.dashboard')}`, href: PATH_DASHBOARD.root },
            { name: 'CRS'},
            { name: `${i18n('patients.title', 'crs')} List`, href: PATH_DASHBOARD.crs.patient.list.value },
            { name: `New ${i18n('patients.title', 'crs')}` },
          ]}
        />
        <Grid container spacing={3}>
          <FormProvider methods={methods} onSubmit={handleSubmit(onSubmit)}>
            <Grid container spacing={3} sx={{ p: 2 }}>
              <Grid item xs={12}>
                <Card sx={{ p: 2 }}>
                  <Typography variant="button" sx={{ textTransform: 'uppercase' }}>
                    Personal Details
                  </Typography>
                  <Grid container spacing={2} sx={{ pt: 1 }}>
                    <Grid item xs={4}>
                      <RHFTextField name="firstName" label="First Name" />
                    </Grid>
                    <Grid item xs={4}>
                      <RHFTextField name="middleName" label="Middle Name" />
                    </Grid>
                    <Grid item xs={4}>
                      <RHFTextField name="lastName" label="Last Name" />
                    </Grid>
                    <Grid item xs={4}>
                      <Controller
                        name="dateOfBirth"
                        control={control}
                        render={({ field, fieldState: { error } }) => (
                          <DatePicker
                            value={field.value || null}
                            onChange={(newValue) => {
                              field.onChange(newValue);
                            }}
                            renderInput={(params) => (
                              <TextField
                                {...params}
                                fullWidth
                                error={!!error}
                                helperText={errors?.dateOfBirth?.message}
                              />
                            )}
                          />
                        )}
                      />
                    </Grid>
                    <Grid item xs={4}>
                      <RHFSelect
                        name="gender"
                        label={i18n('patients.gender', 'crs')}
                        fullWidth={true}
                      >
                        <MenuItem value="female">Female</MenuItem>
                        <MenuItem value="male">Male</MenuItem>
                        <MenuItem value="other">Other</MenuItem>
                        <MenuItem value="unknown">Unknown</MenuItem>
                      </RHFSelect>
                    </Grid>
                    <Grid item xs={4}>
                      <GenderIdentityAutoComplete
                        value={getValues('genderIdentity')}
                        onValueChange={(gender) => {
                          setValue('genderIdentity', gender);
                          forceStateUpdate();
                        }}
                        genderIdentityVS={genderIdentityVS}
                        isLoading={isLoading}
                      />
                    </Grid>
                  </Grid>
                </Card>
              </Grid>

              <Grid item xs={12}>
                <Card sx={{ p: 2 }}>
                  <Typography variant="button" sx={{ textTransform: 'uppercase' }}>
                    Contact Details
                  </Typography>
                  <Grid container spacing={2} sx={{ pt: 1 }}>
                    <Grid item xs={4}>
                      <RHFSelect name="emailType" label="Email Type" fullWidth>
                        <MenuItem value="home">Home</MenuItem>
                        <MenuItem value="work">Work</MenuItem>
                      </RHFSelect>
                    </Grid>
                    <Grid item xs={8}>
                      <RHFTextField name="email" label="Email" fullWidth />
                    </Grid>
                    <Grid item xs={4}>
                      <RHFSelect name="phoneType" label="Phone Type" fullWidth>
                        <MenuItem value="home">Home</MenuItem>
                        <MenuItem value="work">Work</MenuItem>
                        <MenuItem value="mobile">Mobile</MenuItem>
                      </RHFSelect>
                    </Grid>
                    <Grid item xs={2}>
                      <Controller
                        name="areaCode"
                        control={control}
                        render={({ field: { onChange, value }, fieldState: { error } }) => (
                          <InputMask mask="(999)" value={value} onChange={onChange}>
                            {(inputProps: any) => (
                              <TextField
                                label="Area Code"
                                {...inputProps}
                                error={!!error?.message}
                                helperText={error?.message}
                              />
                            )}
                          </InputMask>
                        )}
                      />
                    </Grid>
                    <Grid item xs={6}>
                      <Controller
                        name="phone"
                        control={control}
                        render={({ field: { onChange, value }, fieldState: { error } }) => (
                          <InputMask mask="999-9999" value={value} onChange={onChange}>
                            {(inputProps: any) => (
                              <TextField
                                label="Phone"
                                {...inputProps}
                                fullWidth
                                error={!!error}
                                helperText={error?.message}
                              />
                            )}
                          </InputMask>
                        )}
                      />
                    </Grid>
                  </Grid>
                </Card>
              </Grid>

              <Grid item xs={12}>
                <Card sx={{ p: 2 }}>
                  <Typography
                    style={{ display: 'flex', justifyContent: 'space-between' }}
                    variant="button"
                    sx={{ textTransform: 'uppercase' }}
                  >
                    Address Details
                    <Button
                      color="primary"
                      startIcon={<Add />}
                      onClick={() => setOpenAddAddress(true)}
                    >
                      Add
                    </Button>
                  </Typography>
                  <Grid container spacing={2}>
                    <Grid item xs={6}>
                      <RHFTextField name="street" label="Street" fullWidth disabled />
                    </Grid>
                    <Grid item xs={6}>
                      <RHFTextField name="street2" label="Street 2" fullWidth disabled />
                    </Grid>
                    <Grid item xs={12}>
                      <Stack direction="row" spacing={2}>
                        <RHFTextField name="city" label="City" fullWidth disabled />
                        <RHFTextField name="district" label="County" fullWidth disabled />
                        <RHFTextField name="state" label="State" fullWidth disabled />
                        <RHFTextField name="zipCode" label="ZIP Code" fullWidth disabled />
                      </Stack>
                    </Grid>
                  </Grid>
                </Card>
              </Grid>
            </Grid>
            <Stack direction="row" spacing={2} justifyContent="center" sx={{ mt: 2 }}>
              <Button color="primary" variant="outlined" onClick={() => reset()}>
                Clear All
              </Button>
              <Button color="primary" variant="contained" type="submit">
                Save
              </Button>
            </Stack>
          </FormProvider>
          <Dialog open={openAddAddress} fullWidth maxWidth="md">
            <DialogTitle> Add Address</DialogTitle>
            <Card sx={{ p: 2, mt: 2 }}>
              <AddAddress handleClose={() => setOpenAddAddress(false)} handleSave={saveChanges} />
            </Card>
          </Dialog>
        </Grid>
      </Container>
    </Page>
  );
};

export default NewPatient;
