import moment from "moment";
import useAuth from "src/hooks/useAuth";
import { useEffect, useState } from "react";
import { Grid, Typography } from "@mui/material";
import { caseService } from "src/crs/case/service";
import { usePatient } from "src/@nicheaim/fhir-react";
import { referralService } from "src/crs/referral/services";
import ProviderWindow from "src/sections/crs/common/ProviderWindow";
import { CheckCircleOutline as CheckIcon } from "@mui/icons-material";
import { PatientWrapper } from "src/@nicheaim/fhir-base/wrappers/Patient";

const DispositionComponent = (props: any) => {
  const {
    data
  } = props?.others.others;
  const [ patientId, setPatientID] = useState('');
  const [ patient ] = usePatient(patientId, { map: PatientWrapper });
  
  const user = useAuth();

  const [userId, setUserId] = useState<string>('');
  const [fhirUserId, setFhirUserId] = useState<string>('');
  const [patientMrn, setPatientMrn] = useState<string>('');
  const [record, setRecord] = useState<any>({});

  useEffect(() => {
    const currentUser = user.getCurrentUser();
    if (currentUser.id === undefined) return;
    setUserId(currentUser.id);
    if (currentUser.user_fhir_uri === undefined) return;
    setFhirUserId(currentUser.user_fhir_uri);
  }, [user]);

  const fetchReferralRecord = async (id: any) => {
    const result: any = await referralService.referralApiClient.getOne(id);
    setRecord(result);
  };

  const fetchCaseRecord = async (id: any) => {
    const result: any = await caseService.caseApiClient.getOne(id);
    setRecord(result);
  };

  useEffect(() => {
    if (data) {
      if(data?.recordReference === 'REFERRAL'){
        fetchReferralRecord(data?.recordId)
      }else if(data?.recordReference === 'CASE'){
        fetchCaseRecord(data?.recordId);
      }
    }
  }, [data]);

  useEffect(() => {
    if (record) {
      const patientFromReferral = record?.indexedFhirSubjectRefUri?.trim().slice('-36') || 
        record?.indexedSubjectFhirRefUri?.trim().slice('-36') || record?.patientFhirUUID;
      setPatientID(patientFromReferral);
    }
  }, [record])

  useEffect(() => {
    if (patient) {
      const tempMrn = patient?.getMRN()?.value;
      if (tempMrn) {
        setPatientMrn(tempMrn)
      }
    }
  }, [patient])

  return (
    <div>
      {data?.recordReference === "ENGAGEMENT" ? (
        <>
          {data?.workflowInstance?.dispositionBy && (
            <Typography variant="subtitle2" gutterBottom component="div">
              <strong>Disposition By:</strong> <span>{data.workflowInstance.dispositionBy}</span>
            </Typography>
          )}

          {data?.workflowInstance?.dispositionOn && (
            <Typography variant="subtitle2" gutterBottom component="div">
              <strong>Disposition On:</strong> 
              <span>{moment(new Date(data.workflowInstance.dispositionOn)).format('MM/DD/YYYY HH:mm')}</span>
            </Typography>
          )}

          {data?.workflowInstance?.dispositionReason && (
            <Typography variant="subtitle2" gutterBottom component="div">
              <strong>Disposition Reason:</strong>{" "}
              <span>{data.workflowInstance.dispositionReason}</span>
            </Typography>
          )}

          {data?.workflowInstance?.dispositionNote && (
            <Typography variant="subtitle2" gutterBottom component="div">
              <strong>Disposition Note:</strong>{" "}
              <span>{data.workflowInstance.dispositionNote}</span>
            </Typography>
          )}
        </>
      ) : (
        <>
          <Grid container direction="row" alignItems="center" justifyContent="center">
            <Grid item mr={'35%'}>
              <CheckIcon fontSize="large" sx={{ color: "green", marginRight: '10px' }} />
            </Grid>
            <Grid item mr={'35%'}>
              <Typography textAlign="center" style={{ fontSize: 12 }}>Workflow Completed</Typography>
            </Grid>
          </Grid>
          <Grid container direction="row" alignItems="center" justifyContent="flex-start" sx={{ mt: 1}}>
            <ProviderWindow 
              userId={userId} 
              fhirUserId={fhirUserId} 
              patientId={patientMrn} 
              patientMiNTIdentifier={true} 
              patientData={true} 
            />
          </Grid>
        </>
      )}
    </div>
  );
};

export default DispositionComponent;
