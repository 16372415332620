import { 
  Autocomplete,
  Card, 
  Checkbox, 
  Container, 
  FormControlLabel, 
  Grid, 
  IconButton, 
  Stack, 
  Table, 
  TableBody, 
  TableContainer, 
  TablePagination, 
  TextField, 
  Tooltip, 
  Typography 
} from "@mui/material";
import agent from "src/api/agent";
import { debounce } from "lodash";
import Page from "src/components/Page";
import { useSnackbar } from "notistack";
import useAuth from "src/hooks/useAuth";
import useTable from "src/hooks/useTable";
import { GroupAdd } from "@mui/icons-material";
import useSettings from "src/hooks/useSettings";
import { PATH_DASHBOARD } from "src/routes/paths";
import FilterDrawer from "src/components/FilterDrawer";
import { patientService } from "src/engagement/patient";
import { PaginateQuery } from "src/api/pagination/dtos";
import { useCallback, useEffect, useState } from "react";
import TableToolbar from "src/components/table/TableToolbar";
import HeaderBreadcrumbs from "src/components/HeaderBreadcrumbs";
import LoadingTableRow from "src/components/table/LoadingTableRow";
import { TableHeadCustom, TableNoData } from "src/components/table";
import PatientEngagementRow from "./components/PatientEngagementRow";
import { TABLE_HEAD_PATIENT_ENGAGEMENT_LIST } from "../common/table-head";
import useLocales from "src/hooks/useLocales";

export default function PatientEngagementList() {

  const { 
    page, 
    rowsPerPage, 
    selected, 
    setSelected, 
    onSelectRow, 
    onSelectAllRows, 
    onChangePage, 
    onChangeRowsPerPage, 
    setPage 
  } = useTable({
    defaultRowsPerPage: 5,
    defaultOrderBy: 'id',
    initialIndex: 1,
    defaultCurrentPage: 1,
  });
  
  const { themeStretch } = useSettings();
  const { i18n } = useLocales();
  const user = useAuth().getCurrentUser();
  const { enqueueSnackbar } = useSnackbar();
  const [rows, setRows] = useState<any>(null);
  const [search, setSearch] = useState<string>('');
  const [assignedTo, setAssignedTo] = useState(false);
  const [allUsers, setAllUsers] = useState([]);
  const [openAssignTo, setOpenAssignTo] = useState(false);
  const [valueAuto, setValueAuto] = useState<any>(null);
  const [isLoading, setIsLoading] = useState(true);

  const getPaginateQuery = (page: number, rowsPerPage: number, search: string, assignedTo: any) => {
    const paginateQuery: PaginateQuery = { page: page, limit: rowsPerPage };
    if (search) {
      paginateQuery.search = search;
    }
    paginateQuery.filter = {};

    if (assignedTo && user?.userName) {
      paginateQuery.filter.workflowOwnedBy = `$eq:${user?.userName}`;
    }
    return paginateQuery;
  };

  const getPatientEngagements = async (page: number, rowsPerPage: number, search: string, assignedTo: any) => {
    try {
      setIsLoading(true);
      const patientEngagements = await patientService.getAll(
        getPaginateQuery(page, rowsPerPage, search, assignedTo)
      );
      setRows(patientEngagements);
      setIsLoading(false);
    } catch (e) {
      console.log(
        `Something failed while trying to load patient engagements ${e}`
      );
    }
  };

  const getPatientEngagementsList = useCallback(debounce(getPatientEngagements, 600), []);

  const fetchAllUsers = async () => {
    try {
      const getAllUsers = await agent.User.getAllUsers();
      const uniqueUsers :any  = [];
      const userNamesSet = new Set();

      getAllUsers?.forEach((user :any ) => {
        const fullName = `${user.firstName} ${user.lastName}`.toLowerCase();
        if (!userNamesSet.has(fullName)) {
          uniqueUsers.push(user);
          userNamesSet.add(fullName);
        }
      });
      uniqueUsers.sort((a:any, b:any) => {
        const fullNameA = `${a.firstName} ${a.lastName}`.toLowerCase();
        const fullNameB = `${b.firstName} ${b.lastName}`.toLowerCase();
        return fullNameA.localeCompare(fullNameB);
      });

      setAllUsers(uniqueUsers);
    } catch (err) {
      console.log("error on fetching all users", err);
    }
  };

  useEffect(() => {
    fetchAllUsers();
  }, []);

  useEffect(() => {
    getPatientEngagementsList(page, rowsPerPage, search, assignedTo);
  }, [page, rowsPerPage, search, assignedTo]);

  const handlePageChange = (event: unknown, newPage: number) => {
    onChangePage(event, newPage + 1);
  };

  const handleSearch = (query: string) => {
    setSearch(query);
    setPage(1);
  };

  const handlerBulkAssignTo = async(userToAssign: any) => {
    try {
      if(!userToAssign) return;
      await patientService.bulkAssignOwner({ selectedPatients: selected, userToAssign, referenceName: 'ENGAGEMENT' });
      enqueueSnackbar('User was assigned');
    } catch (err) {
      enqueueSnackbar('An error has occurred', { variant: 'error' });
    } finally {
      setOpenAssignTo(false);
      setValueAuto(null);
      setSelected([])
      getPatientEngagementsList(page, rowsPerPage, search, assignedTo);
    }
  };

  return (
    <Page title={i18n('patientEngagement.title', 'engagement')}>
      <Container maxWidth={themeStretch ? false : 'xl'}>
        <HeaderBreadcrumbs
          title={i18n('patientEngagement.title', 'engagement')}
          heading=""
          links={[
            { name: `${i18n('admin.list.dashboard')}`, href: PATH_DASHBOARD.root },
            { name: `${i18n('title', 'engagement')}` },
            { name: `${i18n('patientEngagement.title', 'engagement')}`, href: PATH_DASHBOARD.engagementHub.patientEngagement },
          ]}
        />
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <Card>
              <Stack direction="row" sx={{ py: 2, px: 2 }}>
                <Grid xs={5}>
                  <TableToolbar filterMrn={search} onFilterMrn={handleSearch} />
                </Grid>
                <Grid container xs={4} justifyContent="flex-end">
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={assignedTo ? true : false }
                        name="assignedToMe"
                        onChange={() => setAssignedTo(!assignedTo)}
                      />
                    }
                    label="View assigned to me"
                    sx={{ marginLeft: 1 }}
                  />
                  <Tooltip title="Assign To">
                    <IconButton
                      sx={{ my: 2 }}
                      onClick={() => setOpenAssignTo(true)}
                      disabled={selected.length > 0 ? false : true}
                    >
                      <GroupAdd sx={{ color: selected.length > 0 ? '#00AB55' : '' }} />
                    </IconButton>
                  </Tooltip>
                </Grid>
                <Grid xs={3}>
                  <TablePagination
                    rowsPerPageOptions={[5, 10, 25]}
                    count={rows ? rows.meta.totalItems : 0}
                    rowsPerPage={rowsPerPage}
                    page={page - 1}
                    onPageChange={handlePageChange}
                    onRowsPerPageChange={onChangeRowsPerPage}
                    sx={{ borderTop: 0 }}
                  />
                </Grid>
              </Stack>
              <TableContainer>
                <Table>
                  <TableHeadCustom 
                    headLabel={TABLE_HEAD_PATIENT_ENGAGEMENT_LIST} 
                    rowCount={ rows?.data?.length}
                    numSelected={selected.length}
                    isLoading={isLoading}
                    onSelectAllRows={(checked) => {
                      onSelectAllRows(
                        checked,
                        rows?.data?.map((row: any) => row.patientEngagementId)
                      );
                    }}
                  />
                  <TableBody>
                    {!isLoading ? (
                      rows?.data?.length ? (
                        rows?.data?.map((row: any) => 
                          <PatientEngagementRow 
                            key={row.patientEngagementId} 
                            row={row} 
                            selected={selected.includes(row.patientEngagementId)}
                            onSelectRow={() => onSelectRow(row.patientEngagementId)}
                          />
                        )
                      ) : (
                        <TableNoData isNotFound={true}/>
                      )
                    ) : (
                      <LoadingTableRow colSpan={TABLE_HEAD_PATIENT_ENGAGEMENT_LIST.length} />
                    )}
                  </TableBody>
                </Table>
              </TableContainer>
            </Card>
          </Grid>
          <FilterDrawer 
            title='Assign To' 
            open={openAssignTo}
            anchor={'right'} 
            onApplyButtonClick={() => {
              handlerBulkAssignTo(valueAuto?.id);
            }} 
            onCloseIconButtonClick={() => {
              setOpenAssignTo(false)
            }}
            onClearAllButtonClick={() => { 
              setValueAuto(null) 
            }} 
          >
            <br/>
            <Autocomplete
              id="combo-box-assign-to"
              value={valueAuto}
              onChange={(_, user) => {
                setValueAuto(user);
              }}
              getOptionLabel={(user: any) => user?.label ?? ''}
              options={
                allUsers.map((user: any) => 
                  ({ label: `${user?.firstName} ${user?.lastName}`, id: user?.id}))
              }
              renderInput={(params) => 
                <TextField  
                  label="Users"
                  {...params}
                />
              }
            />
          </FilterDrawer>
        </Grid>
      </Container>
    </Page>
  );
}