import 'moment-timezone';
import { useState } from 'react';
import { withStyles } from '@mui/styles';
import Iconify from 'src/components/Iconify';
import { Theme } from '@mui/material/styles';
import useLocales from 'src/hooks/useLocales';
import Scrollbar from 'src/components/Scrollbar';
import ComplementaryInfoEdit from './ComplementaryInfoEdit';
import { Stack, Grid, Tooltip, IconButton, Box } from '@mui/material';
import ComplementaryInfoRead from 'src/components/complementary-info/ComplementaryInfoRead';
import usePermissionsContext from 'src/hooks/usePermissionsContext';

const ComplementaryInfoComponent = ({
  dataSchema,
  data,
  defaultStructure,
  checklistItemModified,
  handleComplementaryInfo,
}: any) => { 
  const { i18n } = useLocales();
  const { isAllowedToEdit } = usePermissionsContext<any>() ?? {};

  const { scope = {} } = data;
  const { checklistItem = {} } = scope;
  const [editData, setEditData] = useState(false);
  const [editMode, setEditMode] = useState(false);

  const LightTooltip = withStyles((theme: Theme) => ({
    tooltip: {
      backgroundColor: theme?.palette?.common?.white,
      color: 'rgba(0, 0, 0, 0.87)',
      boxShadow: theme?.shadows?.[1],
      fontSize: 11,
      marginTop: '-145px',
    },
  }))(Tooltip); 

  const handlerComplementaryInfo = async (store: any) => {
    await handleComplementaryInfo(store);
    setEditData(false);
    setEditMode(false);
  };

  return (
    <Box sx={{ px: 3 }}>
      <Grid container direction="row" justifyContent="flex-end" alignItems="flex-start">
        <Grid item xs={3} sx={{ textAlign: 'right' }}>
          {isAllowedToEdit && !editMode && Object.keys(checklistItem).length > 0 ? (
            <LightTooltip
              title="Edit Complementary Info"
              placement="bottom"
              style={{ marginRight: 15 }}
            >
              <IconButton
                sx={{ position: 'absolute', top: '25px', right: '25px' }}
                onClick={() => setEditMode(true)}
              >
                <Iconify color="#1890FF" icon="eva:edit-outline" />
              </IconButton>
            </LightTooltip>
          ) : editMode && Object.keys(checklistItem).length > 0 ? (
            <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'flex-end' }}>
              <LightTooltip title="Close Complementary Info" placement="bottom" style={{ marginRight: 15 }}>
                <IconButton
                  sx={{ position: 'absolute', top: '25px', right: '2px' }}
                  onClick={() => setEditMode(false)}
                >
                  <Iconify color="#1890FF" icon="material-symbols:close" />
                </IconButton>
              </LightTooltip>
              <LightTooltip title="Save Complementary Info" placement="bottom" style={{ marginRight: 25 }}>
                <IconButton
                  sx={{ position: 'absolute', top: '25px', right: '25px' }}
                  onClick={() => setEditData(!editData)}
                >
                  <Iconify color="#1890FF" icon="eva:save-outline" />
                </IconButton>
              </LightTooltip>
            </div>
          ) : null}
        </Grid>
      </Grid>

      <Scrollbar sx={{ height: 477}}>
        {editMode ? (
          <Stack sx={{ mt: 2}}>
            <ComplementaryInfoEdit 
              data={data} 
              edit={editData} 
              dataSchema={dataSchema} 
              defaultStructure={defaultStructure}
              handleComplementaryInfo={handlerComplementaryInfo}
            />
          </Stack>
        ) : (
          <ComplementaryInfoRead 
            data={data} 
            checklistItemModified={checklistItemModified}
          />
        )}
      </Scrollbar>
    </Box>
  )
};

export default ComplementaryInfoComponent;