import Cookies from 'js-cookie';
import { ApiConfiguration } from 'src/api/clients/api_client';
import ApiClient from 'src/api/clients/axios_client';
import { getCareflowToken } from 'src/utils/auth-utils';
import DoFormService, { DoFormApiClient } from './DoFormService';

// TODO: refactor to have less similar axios instances
function getAccessToken(): string | undefined {
    return Cookies.get('careflowToken');
}
const baseUrl = process.env.REACT_APP_HOST_API;

const apiConfig = new ApiConfiguration();
apiConfig.getAccessToken = getCareflowToken;
apiConfig.baseUrl = baseUrl ? baseUrl : '';

const doFormApiClient = new DoFormApiClient(new ApiClient(apiConfig));
export const doFormService = new DoFormService(doFormApiClient);