import { ApiConfiguration } from 'src/api/clients/api_client';
import ApiClient from 'src/api/clients/axios_client';
import { getCareflowToken, getTenantCodeFromCareflowToken } from 'src/utils/auth-utils';
import TaskService, { TaskApiClient } from './TaskService';

// TODO: refactor to have less similar axios instances
const baseUrl = process.env.REACT_APP_HOST_API;

const apiConfig = new ApiConfiguration();
apiConfig.getAccessToken = getCareflowToken;
apiConfig.getHeaderTenantIdCode = getTenantCodeFromCareflowToken;
apiConfig.baseUrl = baseUrl ? baseUrl : '';

const taskApiClient = new TaskApiClient(new ApiClient(apiConfig));
export const taskService = new TaskService(taskApiClient);
