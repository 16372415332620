import { Button, SxProps, Typography } from '@mui/material';
import { DataGrid, GridColDef } from '@mui/x-data-grid';
import { Lightbulb as LightbulbIcon } from '@mui/icons-material';
import useClientGrid from 'src/hooks/useClientGrid';
import { WrappedPlanDefinition } from 'src/@nicheaim/fhir-base/wrappers/PlanDefinition';
import { WrappedCondition } from 'src/@nicheaim/fhir-base/wrappers/Condition';
import { spreadSxProp } from 'src/utils/cssStyles';
import GoalConditionList from '../GoalsGrid/GoalConditionList';
import Iconify from 'src/components/Iconify';
import uuidv4 from 'src/utils/uuidv4';

export type OnGoalSuggestionAction = (goalSuggestion: GoalSuggestion) => void;

export interface GoalSuggestion {
  conditions: WrappedCondition[];
  planDefinition: WrappedPlanDefinition;
}

export interface GoalSuggestionsGridProps {
  sx?: SxProps;
  goalSuggestions: GoalSuggestion[];
  onGoalSuggestionAction: OnGoalSuggestionAction;
}

const GoalSuggestionsGrid = ({
  sx,
  goalSuggestions,
  onGoalSuggestionAction,
}: GoalSuggestionsGridProps) => {
  const { rowsPerPage, handlePageSizeChange, sortModel, handleSortModelChange } = useClientGrid({
    initialRowsPerPage: 5,
  });
  return (
    <DataGrid
      autoHeight
      getRowHeight={(params) => {
        const { conditions } =
          goalSuggestions.find(({ planDefinition }) => planDefinition.id === params.id) ?? {};
        if (!!conditions?.length) return 65 * conditions.length;
        return 75;
      }}
      sx={[
        {
          marginTop: 1,
          height: 'auto',
          width: '100%',
          '& .MuiDataGrid-columnHeader': {
            backgroundColor: '#f4f6f8',
          },
          '& .MuiDataGrid-cell': {
            border: 0,
          },
        },
        ...spreadSxProp(sx),
      ]}
      rows={goalSuggestions}
      density="compact"
      getRowId={({ planDefinition }: GoalSuggestion) => planDefinition?.id ?? uuidv4()}
      onRowDoubleClick={(params) => {
        onGoalSuggestionAction(params.row as GoalSuggestion);
      }}
      columns={getGoalSuggestionsGridColumns(onGoalSuggestionAction)}
      isRowSelectable={() => false}
      disableColumnFilter
      disableColumnMenu
      rowsPerPageOptions={[5, 10, 25]}
      pageSize={rowsPerPage}
      onPageSizeChange={handlePageSizeChange}
      pagination
      sortModel={sortModel}
      onSortModelChange={handleSortModelChange}
    />
  );
};

export const getGoalSuggestionsGridColumns = (
  onGoalSuggestionAction: OnGoalSuggestionAction
): GridColDef[] => [
  {
    field: 'icon',
    headerName: '',
    align: 'center',
    flex: 0.1,
    renderCell: () => <LightbulbIcon htmlColor="#ffab00" />,
  },
  {
    field: 'goal',
    headerName: 'Goal',
    flex: 1,
    renderCell: (params) => {
      const { planDefinition } = params.row as GoalSuggestion;
      return (
        <Typography
          sx={{
            whiteSpace: 'normal',
            wordBreak: 'break-word',
          }}
        >
          {planDefinition?.getGoalDescription?.() ?? ''}
        </Typography>
      );
    },
  },
  {
    field: 'condition',
    headerName: 'Condition',
    flex: 1,
    renderCell: (params) => {
      const { conditions } = params.row as GoalSuggestion;
      return <GoalConditionList conditions={conditions} />;
    },
  },
  {
    field: 'action',
    headerName: '',
    flex: 0.3,
    align: 'center',
    renderCell: (params) => {
      const goalSuggestion = params.row as GoalSuggestion;
      return (
        <Button
          sx={{ height: 34 }}
          disabled={false}
          onClick={() => {
            onGoalSuggestionAction(goalSuggestion);
          }}
          variant="contained"
          startIcon={<Iconify icon={'eva:plus-fill'} />}
        >
          Add
        </Button>
      );
    },
  },
];

export default GoalSuggestionsGrid;
