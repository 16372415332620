import { createWrapper } from '../../fhir-react/base';

export const GoalWrapper = createWrapper('Goal', (goal) => ({
  getPathway: () => goal?.category?.[0]?.coding?.[0]?.code,
  getPathwayDisplay: () => goal?.category?.[0]?.text,
  getPlainDescription: () => goal.description.text,
  getAchievementStatus: () => goal?.achievementStatus?.coding?.[0],
  getEndDate: () => goal?.target?.[0]?.dueDate,
  getComments: () => goal?.note?.[0]?.text,
  getDescriptionCode: () => goal?.description?.coding?.[0]?.code,
}));

export type WrappedGoal = ReturnType<typeof GoalWrapper>;
