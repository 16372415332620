import {
  Box,
  Card,
  CircularProgress,
} from '@mui/material';
import CaseTaskGrid from '../CaseTaskGrid';
import { useValueSets } from 'src/@nicheaim/fhir-react';
import React from 'react';
import { WrappedTask } from 'src/@nicheaim/fhir-base/wrappers/Task';
import { WrappedPatient } from 'src/@nicheaim/fhir-base/wrappers/Patient';
import { WrappedCarePlan } from 'src/@nicheaim/fhir-base/wrappers/CarePlan';
import { ValueSetWrapper } from 'src/@nicheaim/fhir-base/wrappers/ValueSet';
import {
  Task,
  ValueSetComposeIncludeConcept,
} from '../../../../../nicheaim-infrastructure/application/adapters/out/repositories/fhir/resources/resources';
import { WrappedHealthcareService } from 'src/@nicheaim/fhir-base/wrappers/HealthcareService';
import { MenuTaskHandler, TaskHandlerByType, TaskPermissions } from 'src/sections/crs/types';
import TaskContextProvider from './TaskProvider';
import { PermissionsProvider } from 'src/contexts/PermissionsContext';
import useCollapsibleContext from 'src/hooks/useCollapsibleContext';

interface TaskByPathwayProps {
  tasks: WrappedTask[];
  patient: WrappedPatient | null;
  healthCareServices: WrappedHealthcareService[];
  carePlan?: WrappedCarePlan | null;
  refreshEntities?: Function;
  refreshTasks: Function;
  openCollapseExternal?: boolean;
  isCollapsible?: boolean;
  rowDblClickHandlers?: TaskHandlerByType[];
  menuTaskHandlers?: MenuTaskHandler[];
  onAssessmentSelect?: (task: Task) => void;
  permissions: TaskPermissions;
}
export interface TaskByPathway {
  id?: string | undefined;
  code: string | undefined;
  display?: string | undefined;
}

const pathwayAll: ValueSetComposeIncludeConcept = {
  code: 'all',
  display: 'All',
};

const TasksByPathway = React.memo(
  ({
    patient,
    tasks,
    carePlan,
    healthCareServices,
    refreshEntities,
    refreshTasks,
    openCollapseExternal,
    isCollapsible = true,
    menuTaskHandlers,
    rowDblClickHandlers,
    onAssessmentSelect,
    permissions,
  }: TaskByPathwayProps) => {
    // Get pathways
    const [, { isLoading: isPathWaysLoading }] = useValueSets({
      filter: {
        identifier: 'crs-path-ways',
      },
      map: ValueSetWrapper,
    });

    const { handleItem } = useCollapsibleContext();

    const programs = healthCareServices?.map((e) => ({
      id: e.id,
      code: e.program?.[0].coding?.[0].code,
      display: e.program?.[0].coding?.[0].display,
    }));

    const pathwaysList: TaskByPathway[] = [pathwayAll, ...(programs ?? [])];

    return (
      <TaskContextProvider
        menuTaskHandlers={menuTaskHandlers}
        rowDblClickHandlers={rowDblClickHandlers}
      >
        <PermissionsProvider permissions={permissions}>
          <Card>
            {!isPathWaysLoading ? (
              <CaseTaskGrid
                tasks={tasks}
                patient={patient as WrappedPatient}
                carePlan={carePlan}
                healthCareServices={healthCareServices}
                onSuccessfulCreation={() => {
                  refreshEntities?.();
                }}
                onSuccessfulEdit={() => {
                  refreshTasks();
                }}
                onAssessmentSelect={onAssessmentSelect}
                pathwaysList={pathwaysList}
                handleItem={handleItem}
              />
            ) : (
              <Box
                sx={{
                  flex: 1,
                  display: 'flex',
                  flexDirection: 'row',
                  justifyContent: 'center',
                  my: 2,
                }}
              >
                <CircularProgress size={50} />
              </Box>
            )}
          </Card>
        </PermissionsProvider>
      </TaskContextProvider>
    );
  }
);

export default TasksByPathway;
