import { Button } from "@mui/material";
import { makeStyles } from "@mui/styles";
import usePermissionsContext from "src/hooks/usePermissionsContext";
import { WorkflowPermissions } from "src/sections/crs/types";

const useStyles = makeStyles({
  closeBtn: {
    color: "#fff",
    backgroundColor: "#FF4842",
    "&:hover": {
      backgroundColor: "#FF4842",
    },
  },
  nextBtn: {
    color: "#fff",
    backgroundColor: "#00AB55",
    "&:hover": {
      backgroundColor: "#00AB55",
    },
  },
});

const Transitions = ({ transitions, handleTransition, isOnCurrentStep}: any) => {
  const classes: any = useStyles({});
  const { workflow } = usePermissionsContext<any>() ?? {};

  const displayButtons = transitions
    ?.sort((a: any, b: any) => {
      return b.code.localeCompare(a.code);
    })
    .map((item: any, index: 0) => {
      const parseMeta: any = JSON.parse(item?.metadata);
      let disabledButton: boolean = false;

      if((item.name === 'Reject' || item.name === 'Close') && !workflow?.isAllowedToClose){
        disabledButton = true
      }else if(item.name === 'Next' && !workflow?.isAllowedToNext){
        disabledButton = true
      }

      return (
        <>
          {!isOnCurrentStep && item.name !== 'Reject' ? null : 
            <Button
              className={classes?.[parseMeta?.ui?.className] || null}
              onClick={() => handleTransition(item)}
              disabled={disabledButton}
            >
              {item.name}
            </Button>
          }
        </>
      );
    });

  return displayButtons;
};

export default Transitions;
