import moment from 'moment';
import { WrappedPatient } from 'src/@nicheaim/fhir-base/wrappers/Patient';
import { WrappedServiceRequest } from 'src/@nicheaim/fhir-base/wrappers/ServiceRequest';
import {
  CodeableConcept,
  Reference,
  ValueSetComposeIncludeConcept,
} from 'src/nicheaim-infrastructure/application/adapters/out/repositories/fhir/resources';
import { getValueSetConceptValue } from 'src/sections/crs/helpers/common';

export enum ReferralStatuses {
  draft = 'Requested',
  active = 'Accepted',
  completed = 'Closed',
  'on-hold' = 'Pending',
  revoked = 'Rejected',
  'entered-in-error' = 'Cancelled',
}

const defaultUsers: any[] = [];

export interface ServiceTypePayload {
  label: string;
  value: { type: string; healthcareService: any; organizationId: string };
}

export interface ReferralPayload {
  id: string | null;
  date?: string;
  start?: string;
  status: string;
  intent: string;
  patientId?: string | null;
  assignedTo?: string;
  referredFrom?: Reference | null;
  referredTo?: string;
  locationOrganization?: string | null;
  category?: CodeableConcept[] | undefined | null;
  serviceType?: CodeableConcept | null;
  note: string | null;
  services: ServiceTypePayload[] | null;
  service: ServiceTypePayload | null;
  serviceId?: string | null;
  serviceName?: string | null;
  locationId?: string;
  locationName?: string;
  parentServiceRequest: WrappedServiceRequest | null | undefined;
}

export type AddReferralPropsType = {
  referral?: any | null | undefined;
  patient?: WrappedPatient | null;
  serviceRequest?: WrappedServiceRequest | null;
  openAdd: boolean;
  onCancel: VoidFunction;
};

export const defaultStateFieldsValues = (
  id: string,
  referral: WrappedServiceRequest | null,
  additionalData?: {
    intentValueSet: ValueSetComposeIncludeConcept[];
    categoryValueSet: ValueSetComposeIncludeConcept[];
  }
) => {
  const { intentValueSet, categoryValueSet } = additionalData ?? {};
  const intent = getValueSetConceptValue(intentValueSet ?? [], referral?.intent) ?? null;
  const category =
    getValueSetConceptValue(categoryValueSet ?? [], referral?.getFirstCategory?.()?.code) ?? null;
  const locationId = referral?.locationReference?.[0]?.reference?.split?.('/')?.[1];
  const locationName = referral?.locationReference?.[0]?.display;
  return {
    intent,
    category,
    date: referral?.occurrenceDateTime
      ? moment(referral?.occurrenceDateTime)?.format('YYYY-MM-DD')
      : moment().format('YYYY-MM-DD'),
    startHour: referral?.occurrenceDateTime
      ? moment(referral?.occurrenceDateTime)?.format('HH:mm')
      : moment().format('HH:mm'),
    referredFromItem: referral?.requester
      ? {
          label: referral?.requester?.display,
          value: referral?.requester?.reference,
        }
      : null,
    referredToItem: referral?.performer
      ? {
          label: referral?.getPerfomerByType('Organization')?.display,
          value: referral?.getPerfomerByType('Organization')?.reference,
        }
      : null,
    orgnization: null,
    organizaionItem: null,
    service: referral?.code?.coding?.[0].code ?? null,
    serviceTypeItem: null,

    status: referral?.status ?? '',
    statusItem:
      referral?.status && ReferralStatuses[referral?.status as keyof typeof ReferralStatuses]
        ? {
            label: ReferralStatuses[referral?.status as keyof typeof ReferralStatuses],
            value: referral?.status,
          }
        : null,
    users: defaultUsers,
    assignedToItem: referral?.getPerfomerByType?.('Practitioner')?.reference
      ? {
          label: referral.getPerfomerByType('Practitioner')?.display,
          value: referral.getPerfomerByType('Practitioner')?.reference,
        }
      : null,
    locationOrganization: locationId
      ? {
          label: locationName,
          value: {
            location: { id: locationId, name: locationName },
          },
        }
      : null,
    file: null,
    selectedFile: null,
    note: null,
    isLoading: false,
    success: null,
    openSearch: false,
    error: null,
  };
};

export const REFERRAL_CREATE_ERROR_MESSAGE = 'Error saving the referral. Please try again';
