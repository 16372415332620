import { ValueSetComposeIncludeConcept } from 'src/nicheaim-infrastructure/application/adapters/out/repositories/fhir/resources';
import { createWrapper } from '../../fhir-react/base';

export const ValueSetWrapper = createWrapper('ValueSet', (valueSet) => ({
  getFirstIncludeConceptSystem: () => valueSet?.compose?.include?.[0]?.system,
  asList(): ValueSetComposeIncludeConcept[] {
    const concept = valueSet?.compose?.include?.[0].concept;
    if (concept && concept.length > 0) {
      const list = valueSet.compose?.include[0].concept;
      if (list) return list;
    }
    return [];
  },

  asListAll(): ValueSetComposeIncludeConcept[] {
    // const includes = valueSet?.compose?.include?.[0].concept;
    const include = valueSet?.compose?.include;
    if (include && include?.length > 0) {
      const concepts = include?.map((i) => i?.concept);
      const list = concepts
        .flat()
        .filter((f) => f !== undefined) as ValueSetComposeIncludeConcept[];
      if (list) return list;
    }

    return [];
  },

  asSortedListAll(): ValueSetComposeIncludeConcept[] {
    const list = this.asListAll();
    return list.sort(function (a, b) {
      // hot fix handle undefined
      if (a?.display === undefined || b?.display === undefined) {
        return 0;
      }

      const displayA = a?.display ? a?.display.toUpperCase() : a?.display;
      const displayB = b?.display ? b?.display.toUpperCase() : b?.display;

      if (displayA < displayB) {
        return -1;
      }
      if (displayA > displayB) {
        return 1;
      }
      return 0;
    });
  },

  asListForInput(
    mapper: {
      [key: string]: string;
    } = {
      display: 'label',
      code: 'value',
    }
  ) {
    const list = this.asList();
    if (!list || list.length <= 0) return [];
    return list.map((el: ValueSetComposeIncludeConcept) => {
      const mapped: {
        [key: string]: string;
      } = {};
      for (const [key, value] of Object.entries(mapper)) {
        if (typeof key === 'string') {
          mapped[value] = key;
        }
      }
      return mapped;
    });
  },

  filterExpansionSystems(systems: string[]) {
    if (!valueSet?.expansion?.contains) return [];

    const matched = valueSet?.expansion?.contains.filter((x) =>
      x?.system ? systems.indexOf(x?.system) > -1 : false
    );

    return {
      ...valueSet,
      expansion: {
        ...valueSet.expansion,
        contains: matched,
      },
    };
  },

  getConceptSystem: (): string | undefined => valueSet?.compose?.include?.[0]?.system,
}));

export type WrappedValueSet = ReturnType<typeof ValueSetWrapper>;
