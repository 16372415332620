import Cookies from 'js-cookie';
import jwtDecode from 'jwt-decode';
import { getRegistries } from 'src/services/api/registry';
import { createContext, ReactNode, useEffect, useState } from 'react';
import { getSettingsTenantByISS, getSettingsThemeByISS } from 'src/utils/getSettings';
import useAuth from 'src/hooks/useAuth';
import { defaultSettings } from 'src/config';
import { Helmet } from 'react-helmet-async';

type TenantThemeProps = {
  children: ReactNode;
};

type TenantTheme = {
  logo: string | undefined;
  pagePrefix: string | undefined;
  tenantName: string | undefined;
  tenantFavicon: string | undefined;
  tenantSpinnerLogo: string | undefined;
};

const initTenantTheme = {
  logo: undefined,
  pagePrefix: undefined,
  tenantFavicon: undefined,
  tenantName: undefined,
  tenantSpinnerLogo: undefined,
};

const defaultTenantTheme = {
  logo: '/logo/my_care_circle_horizontal_logo.png',
  tenantName: 'MyCareCircle',
  pagePrefix: 'MyCareCircle - ',
  tenantFavicon: '/favicon/MyCareCircle-Icon.png',
  tenantSpinnerLogo: '/logo/my_care_circle_horizontal_logo.png',
  settings: defaultSettings,
};

const TenantThemeContext = createContext<TenantTheme>(initTenantTheme);

function TenantThemeProvider({ children }: TenantThemeProps) {
  const [tenantTheme, setTenantTheme] = useState<TenantTheme>(initTenantTheme);
  const { getCurrentUser } = useAuth();
  const { tenant_code: userTenantCode } = getCurrentUser?.() ?? {};

  useEffect(() => {
    const fetchTenantTheme = async () => {
      try {
        const registries = await getRegistries('tenant-theme');
        const JsonObjectRegistries =
          registries?.data?.map(({ keyValue }) => JSON.parse(keyValue)) ?? [];

        const tenantInfo =
          JsonObjectRegistries?.find?.(
            (registry) =>
              registry?.realm?.toLowerCase?.() === userTenantCode?.toLowerCase?.() &&
              !!userTenantCode
          ) ?? defaultTenantTheme;

        const theme = tenantInfo?.settings ?? defaultSettings;

        if (theme) {
          localStorage.setItem('settings', JSON.stringify(theme));
        }

        setTenantTheme(tenantInfo);
      } catch (e) {
        console.error(e);
      }
    };
    fetchTenantTheme();
  }, [userTenantCode]);

  return (
    <TenantThemeContext.Provider value={tenantTheme}>
      {!!tenantTheme?.tenantFavicon && (
        <Helmet>
          <link
            id="favicon"
            rel="shortcut icon"
            type="image/png"
            sizes="16x16"
            href={`${tenantTheme?.tenantFavicon}`}
          />
        </Helmet>
      )}

      {children}
    </TenantThemeContext.Provider>
  );
}

export { TenantThemeContext, TenantThemeProvider };
