import { Box, SxProps, Tooltip, Typography } from '@mui/material';
import { spreadSxProp } from 'src/utils/cssStyles';
import '../task/styles.css';

export interface CellRowProps {
  tooltipTitle?: string | null;
  Icon?: React.ReactNode | null;
  title: string | null | undefined;
  titleSx?: SxProps;
}

const CellRow = ({ tooltipTitle = null, Icon, title, titleSx }: CellRowProps) => (
  <>
    {!!title && (
      <Tooltip title={tooltipTitle}>
        <span>
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'row',
              marginBottom: 0.5,
              alignItems: 'center',
            }}
          >
            <Box sx={{ marginRight: 1 }}>{Icon}</Box>
            <Typography
              variant="body2"
              className="truncate-text"
              sx={[{ whiteSpace: 'normal', wordBreak: 'break-word' }, ...spreadSxProp(titleSx)]}
            >
              {title ?? ''}
            </Typography>
          </Box>
        </span>
      </Tooltip>
    )}
  </>
);

export default CellRow;
