import { MenuItem, TableCell, TableRow, Typography } from '@mui/material';
import { useState } from 'react';
import { format } from 'date-fns';
import { capitalCase } from 'change-case';
import Iconify from 'src/components/Iconify';
import { TableMoreMenu } from 'src/components/table';
import { WrappedObservation } from 'src/@nicheaim/fhir-base/wrappers/Observation';
import { checkAclValidation } from 'src/utils/permissions/permission.utils';
import crsAcls from 'src/utils/permissions/crs/crsAcls';

type Props = {
  row: WrappedObservation | null;
  handleEdit: (data: any) => any;
};

export default function Row({ row, handleEdit }: Props) {
  const [openMenu, setOpenMenuActions] = useState<HTMLElement | null>(null);

  const handleOpenMenu = (event: React.MouseEvent<HTMLElement>) => {
    setOpenMenuActions(event.currentTarget);
  };

  const handleCloseMenu = () => {
    setOpenMenuActions(null);
  };

  return (
    <>
      <TableRow>
        <TableCell>
          <Typography>{row?.code?.coding?.[0].display}</Typography>
        </TableCell>
        <TableCell>
          <Typography>
            {row?.issued && format(new Date(row?.issued), 'MMM dd, yyyy hh:mm')}
          </Typography>
        </TableCell>
        <TableCell>
          <Typography>{row?.getValue()}</Typography>
        </TableCell>
        <TableCell>
          <Typography>{row?.status && capitalCase(row.status.replace('-', ' '))}</Typography>
        </TableCell>
        <TableCell align="center">
          <TableMoreMenu
            open={openMenu}
            onOpen={handleOpenMenu}
            onClose={handleCloseMenu}
            actions={
              checkAclValidation({ acls: [crsAcls.CRS.PATIENT.OBSERVATION.EDIT] })
                ? [
                    <MenuItem key={'menu-i-edit-'} onClick={() => handleEdit(row)}>
                      <Iconify icon={'mdi:pencil'} />
                      Edit
                    </MenuItem>,
                  ]
                : []
            }
          />
        </TableCell>
      </TableRow>
    </>
  );
}
