import CloseIconButton from "src/components/CloseIconButton";
import { Box, Dialog, DialogActions, DialogContent, DialogProps, DialogTitle, List, Typography } from "@mui/material";

export interface SearchCombinationsDialogProps extends DialogProps {
  searchCombinations: string[];
}

const SearchCombinationsDialog = ({
  searchCombinations,
  onClose,
  ...dialogProps
}: SearchCombinationsDialogProps) => {
  return (
    <Dialog disableEscapeKeyDown={false} onClose={onClose} {...dialogProps}>
      <DialogTitle>Possible Search Combinations</DialogTitle>
      <DialogContent>
        <List
          sx={{
            width: "100%",
            paddingX: 3,
            marginTop: 2,
          }}
        >
          <ul>
            {searchCombinations.map((searchCombination, index) => (
              <Box key={`combination-item-${index}`}>
                {searchCombination ? (
                  <li style={{ listStyleType: "disc" }}>
                    <Typography variant="body2" fontSize={"0.9rem"}>
                      {searchCombination}
                    </Typography>
                  </li>
                ) : (
                  <br />
                )}
              </Box>
            ))}
          </ul>
        </List>
      </DialogContent>
      <DialogActions>
        <CloseIconButton
          onClick={() => {
            onClose?.({}, "backdropClick");
          }}
        />
      </DialogActions>
    </Dialog>
  );
};

export default SearchCombinationsDialog;
