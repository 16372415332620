import { useSnackbar, SnackbarProps } from 'notistack';
import { useCallback } from 'react';
import useLocales from './useLocales';

const useNotiMessage = () => {
  const { i18n } = useLocales();
  const { enqueueSnackbar } = useSnackbar();
  const showErrorMessage = useCallback(
    (errorMessage: string, snackBarProps: SnackbarProps = {}) => {
      if (!errorMessage.length) return;
      const separator = errorMessage[errorMessage.length - 1] !== '.' ? '. ' : ' ';
      enqueueSnackbar(`${errorMessage}${separator}${i18n('error.defaultFallback')}`, {
        persist: true,
        variant: 'error',
        ...snackBarProps,
      });
    },
    []
  );
  return { showErrorMessage };
};

export default useNotiMessage;
