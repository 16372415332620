import { WrappedServiceRequest } from 'src/@nicheaim/fhir-base/wrappers/ServiceRequest';
import CollapsibleCard from '../../common/CollapsibleCard';
import { LoadingComponent } from '../../case/components/RelatedReferrals/components';
import useValueSetsByIdentifiers from 'src/hooks/useValueSetsByIdentifier';
import { IdentifierDetails } from '../../patient/components/details/IdentifierDetails';
import { Identifier } from 'src/nicheaim-infrastructure/application/adapters/out/repositories/fhir/resources';
import useLocales from 'src/hooks/useLocales';
import crsAcls from 'src/utils/permissions/crs/crsAcls';
import { checkAclValidation } from 'src/utils/permissions/permission.utils';
export interface ReferralIdentifiersProps {
  isOpen: boolean;
  serviceRequest: WrappedServiceRequest | null;
  onUpdateIdentifiers: (newIdentifiers: Identifier[]) => void;
}

const ReferralIdentifiers = ({
  isOpen,
  serviceRequest,
  onUpdateIdentifiers,
}: ReferralIdentifiersProps) => {
  const {
    valueSets: [serviceRequestIdentifiers],
    isLoading: isLoadingServiceRequestIdentifiers,
  } = useValueSetsByIdentifiers(['crs-serviceRequest-identifier-types']);
  const { i18n } = useLocales();

  const isLoading = isLoadingServiceRequestIdentifiers || !serviceRequest?.id;

  return (
    <CollapsibleCard
      title={i18n('referral.details.identifiers.title', 'crs')}
      titleSx={{ ml: 1 }}
      isOpenExternal={isOpen}
      margin={2}
    >
      {!isLoading ? (
        <IdentifierDetails
          sxContainer={{ height: 260 }}
          identifiers={serviceRequest?.identifier ?? []}
          catalog={serviceRequestIdentifiers?.asList?.() ?? []}
          onUpdateIdentifiers={onUpdateIdentifiers}
          isAllowedToAdd={checkAclValidation({
            acls: [crsAcls.CRS.REFERRAL.IDENTIFIER_DETAILS.ADD],
          })}
          isAllowedToEdit={checkAclValidation({
            acls: [crsAcls.CRS.REFERRAL.IDENTIFIER_DETAILS.EDIT],
          })}
          isAllowedToDelete={checkAclValidation({
            acls: [crsAcls.CRS.REFERRAL.IDENTIFIER_DETAILS.DELETE],
          })}
        />
      ) : (
        <LoadingComponent />
      )}
    </CollapsibleCard>
  );
};

export default ReferralIdentifiers;
