import {
  checkProviderSearchValueDifferences,
  getPractitionersLinkedToProvider,
  mapProviderDirectoryToFhirPractitioner,
  mapProviderSearchFieldsToFhirPractitioner,
} from '../helpers';
import {
  Autocomplete,
  Box,
  Button,
  Checkbox,
  Drawer,
  FormControlLabel,
  FormGroup,
  Grid,
  GridProps,
  IconButton,
  InputAdornment,
  Stack,
  TextField,
  TextFieldProps,
  Tooltip,
  Typography,
} from '@mui/material';
import * as Yup from 'yup';
import {
  Add,
  Business,
  HelpCenter,
  MedicalInformationOutlined,
  Person,
  Phone,
  Place,
  RestartAlt,
  Search,
  Work,
} from '@mui/icons-material';
import { useSnackbar } from 'notistack';
import useAuth from 'src/hooks/useAuth';
import { LoadingButton } from '@mui/lab';
import { useNavigate } from 'react-router';
import { yupResolver } from '@hookform/resolvers/yup';
import { Controller, useForm } from 'react-hook-form';
import { practitionerService } from 'src/crs/practitioner';
import { usePractitioners } from 'src/@nicheaim/fhir-react';
import CloseIconButton from 'src/components/CloseIconButton';
import DirectoryProviderGrid from '../DirectoryProviderGrid';
import SearchCombinationsDialog from './SearchCombinationsDialog';
import { ChangeEvent, useEffect, useMemo, useState } from 'react';
import { FormProvider, RHFTextField } from 'src/components/hook-form';
import ExistingPractitionerDialog from './ExistingPractitionerDialog';
import ManualCreationConfirmDialog from './ManualCreationConfirmDialog';
import ProviderDetailPreviewDialog from './ProviderDetailPreviewDialog';
import { Practitioner } from 'src/@nicheaim/fhir-base/mappings/Practitioner';
import {
  DirectoryProviderLookUp,
  ProviderDirectorySearchFields,
} from 'src/@types/crs/providerDirectory';
import {
  LOOKUP_ERROR_MESSAGE_CODES,
  existingPractitionerVerificationErrorMessage,
} from '../../constants';
import { checkAclValidation } from 'src/utils/permissions/permission.utils';
import crsAcls from 'src/utils/permissions/crs/crsAcls';

export type OnProviderAsyncAction = (
  provider: DirectoryProviderLookUp,
  showExistingPractitionersDialog?: (existingPractitioners: Practitioner[]) => void
) => Promise<void>;

export interface NewProviderProps {
  isOpen: boolean;
  onDrawerClose: () => void;
  defaultSearchFields?: ProviderDirectorySearchFields | null;
  isCreationMode?: boolean;
  onProviderAction?: OnProviderAsyncAction;
  providerActionButtonIcon?: React.ReactNode;
  providerActionButtonTitle?: string;
  title?: string;
}

export interface ProviderSearchFields extends ProviderDirectorySearchFields {}

export default function NewProvider({
  isOpen,
  onDrawerClose,
  defaultSearchFields,
  onProviderAction,
  providerActionButtonIcon,
  providerActionButtonTitle,
  title,
  isCreationMode = false,
}: NewProviderProps) {
  const { user } = useAuth();
  const [isProvidersLoading, setIsProvidersLoading] = useState(false);
  const [isProviderPreviewDialogOpen, setIsProviderPreviewDialogOpen] = useState(false);
  const [isAdding, setIsAdding] = useState(false);
  const [providers, setProviders] = useState<DirectoryProviderLookUp[]>([]);
  const [selectedProviderId, setSelectedProviderId] = useState<string | null>(null);
  const [errorMessage, setErrorMessage] = useState('');
  const [shouldLookForPracticeNPI, setShouldLookForPracticeNPI] = useState(false);
  const [isExistingPractitionersDialogOpen, setIsExistingPractitionersDialogOpen] = useState(false);
  const [existingPractitioners, setExistingPractitioners] = useState<Practitioner[]>([]);
  const [classifications, setClassifications] = useState<string[]>([]);
  const [isFirstSearchDone, setIsFirstSearchDone] = useState(false);
  const [isManualCreationConfirmDialogOpen, setIsManualCreationConfirmDialogOpen] = useState(false);
  const [lastSearchFieldValues, setLastSearchFieldValues] = useState<ProviderSearchFields | null>(
    null
  );

  const [searchFlag, setSearchFlag] = useState(false);
  const [searchCombinations, setSearchCombinations] = useState<string[]>([]);
  const [isInvalidCombinationError, setIsInvalidCombinationError] = useState(false);
  const [isSearchCombinationDialogOpen, setIsSearchCombinationDialogOpen] = useState(false);
  const { enqueueSnackbar } = useSnackbar();
  const navigate = useNavigate();
  const [_, { create: createPractitioner }] = usePractitioners({
    autofetch: false,
  });

  const EventSchema = Yup.object().shape({
    first_name: Yup.string().required('First Name is required'),
    last_name: Yup.string().required('Last Name is required'),
  });

  const defaultValues = useMemo(
    () => ({
      last_name: defaultSearchFields?.last_name ?? '',
      first_name: defaultSearchFields?.first_name ?? '',
      npi: defaultSearchFields?.npi ?? '',
      classification: defaultSearchFields?.classification ?? '',
      address: defaultSearchFields?.address ?? '',
      city: defaultSearchFields?.city ?? '',
      practice_name: defaultSearchFields?.practice_name ?? '',
      phone_number: defaultSearchFields?.phone_number ?? '',
    }),
    [defaultSearchFields]
  );

  const methods = useForm({ resolver: yupResolver(EventSchema), defaultValues });

  const {
    control,
    reset,
    watch,
    handleSubmit,
    formState: { errors },
  } = methods;

  const values = watch();

  useEffect(() => {
    if (!lastSearchFieldValues) return;
    const isANewSearch = checkProviderSearchValueDifferences(lastSearchFieldValues, values);
    setIsFirstSearchDone(!isANewSearch);
  }, [values, lastSearchFieldValues]);

  useEffect(() => {
    if (isOpen) return;
    setProviders([]);
    setIsFirstSearchDone(false);
    setLastSearchFieldValues(null);
    setSearchFlag(false);
  }, [isOpen]);

  useEffect(() => {
    if (!isOpen || !defaultSearchFields) return;
    if (typeof defaultSearchFields !== 'object') return;
    reset();
    setSearchFlag(true);
  }, [isOpen, defaultSearchFields]);

  useEffect(() => {
    if (!searchFlag) return;
    searchProviderMatching();
  }, [searchFlag]);

  useEffect(() => {
    const getClassificationsOptions = async () => {
      const classifications = await practitionerService.getClassifications();
      setClassifications(classifications);
    };
    getClassificationsOptions();
  }, []);

  useEffect(() => {
    const getSearchCombinations = async () => {
      const response =
        (await practitionerService.lookupPractitionersInDirectory({
          npi: '',
          first_name: 'dummy',
          last_name: '',
          address: '',
          city: '',
          classification: '',
          practice_name: '',
          practice_npi: '',
          phone_number: '',
        })) ?? {};
      if (
        response?.error_code === LOOKUP_ERROR_MESSAGE_CODES.INVALID_VALUE_SET &&
        response?.error_message?.message
      )
        setSearchCombinations(
          Array.isArray(response.error_message.message)
            ? response.error_message.message
            : [response.error_message.message]
        );
    };
    getSearchCombinations();
  }, []);

  const handleShowExistingPractitioners = (existPractitioners: Practitioner[]) => {
    setExistingPractitioners(existPractitioners);
    setIsExistingPractitionersDialogOpen(true);
  };

  const handleVerifyExistingPractitioner = async (provider: DirectoryProviderLookUp) => {
    setIsAdding(true);
    try {
      const existPractitioners = await getPractitionersLinkedToProvider(provider.uuid);
      if (existPractitioners.length) {
        handleShowExistingPractitioners(existPractitioners);
        return setIsAdding(false);
      }
    } catch (error) {
      enqueueSnackbar(existingPractitionerVerificationErrorMessage, {
        variant: 'error',
      });
      return;
    }

    const providerDetails = await practitionerService.getProviderDetails(provider?.uuid);
    if (!providerDetails) {
      setIsAdding(false);
      enqueueSnackbar(
        "There was an error while trying to get Provider's details. Please try again",
        { variant: 'error' }
      );
      return;
    }

    const { fhirIdentifierTypes, licenseCodeMapping } =
      await practitionerService.getProviderDirectoryMappingData();

    if (!fhirIdentifierTypes || !licenseCodeMapping) {
      showPractitionerCreationError();
      return setIsAdding(false);
    }
    const practitionerPayload = mapProviderDirectoryToFhirPractitioner({
      provider: providerDetails,
      userName: user?.userName,
      fhirIdentifierTypes,
      licenseCodeMapping,
    });
    handleAddPractitioner(practitionerPayload);
  };

  const showPractitionerCreationError = () => {
    enqueueSnackbar('There was an error while trying to create practitioner. Please try again', {
      variant: 'error',
    });
  };

  const handleAddPractitioner = async (payload: Practitioner) => {
    setIsAdding(true);
    const practitioner = await createPractitioner(payload);
    setIsAdding(false);
    if (!practitioner) {
      showPractitionerCreationError();
      return;
    }
    enqueueSnackbar('Practitioner Succesfully Created', { variant: 'success' });

    navigate(`${window.location.pathname}/${practitioner?.[0]?.id}?type=edit`, {
      replace: false,
    });
  };

  const handleCreatePractitioner = async () => {
    setIsAdding(true);
    const practitioner = mapProviderSearchFieldsToFhirPractitioner(values, user?.userName);
    handleAddPractitioner(practitioner);
  };

  const searchProviderMatching = async () => {
    setIsProvidersLoading(true);
    const providerResponse =
      (await practitionerService.lookupPractitionersInDirectory({
        ...values,
        practice_npi: shouldLookForPracticeNPI ? values?.npi : '',
      })) ?? {};
    setIsProvidersLoading(false);
    if (providerResponse?.error_message?.message) {
      if (providerResponse?.error_code === LOOKUP_ERROR_MESSAGE_CODES.INVALID_VALUE_SET) {
        setErrorMessage(
          'Invalid search combination. Press the [?] icon for more information on valid combinations.'
        );
        setIsInvalidCombinationError(true);

        return;
      }
      setErrorMessage(
        Array.isArray(providerResponse?.error_message?.message)
          ? providerResponse?.error_message?.message?.[0]
          : providerResponse?.error_message?.message
      );
      return;
    }
    if (!providerResponse?.matches) {
      enqueueSnackbar("Couldn't complete search. Please try again.", {
        variant: 'error',
      });
      return;
    }
    setLastSearchFieldValues({ ...values });
    setIsFirstSearchDone(true);
    setProviders(providerResponse.matches);
  };

  return (
    <>
      <Drawer
        anchor="left"
        open={isOpen}
        onClose={onDrawerClose}
        sx={{
          height: 'auto',
        }}
      >
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'row',
            height: 'auto',
            width: !isFirstSearchDone ? 'auto' : '100vw',
            flex: 1,
          }}
        >
          <Box
            sx={{
              boxShadow: '8px -30px 24px rgba(22,28,36,0.16)',
              display: 'flex',
              width: '45vw',
            }}
          >
            <FormProvider
              methods={methods}
              onSubmit={handleSubmit(searchProviderMatching)}
              style={{ width: '100%' }}
            >
              <Box
                sx={{
                  width: '100%',
                  display: 'flex',
                  flexDirection: 'row',
                  justifyContent: 'space-between',
                  borderBottomStyle: 'solid',
                  borderBottomWidth: '1px',
                  borderBottomColor: '#E5E8EB',
                  paddingY: 2,
                }}
              >
                <Grid
                  width={'100%'}
                  height={'100%'}
                  container
                  justifyContent="space-between"
                  sx={{ paddingX: 3 }}
                >
                  <Stack
                    style={{
                      width: '100%',
                    }}
                    direction="row"
                    spacing={2}
                  >
                    <LoadingButton
                      sx={{ height: 38 }}
                      variant="contained"
                      type="submit"
                      loading={isProvidersLoading}
                      disabled={isProvidersLoading || isAdding}
                      startIcon={<Search />}
                    >
                      Search Providers
                    </LoadingButton>
                    <CloseIconButton onClick={onDrawerClose} />
                  </Stack>
                </Grid>
              </Box>
              <Box
                sx={{
                  width: '100%',
                  height: '100%',
                  paddingX: 3,
                }}
              >
                <Grid container sx={{ marginBottom: 3, marginTop: 2 }}>
                  <Grid item xs={12}>
                    <Typography variant="h5" component="h5" color="text.primary">
                      {isCreationMode || !title ? 'Providers Search' : title}
                    </Typography>
                  </Grid>
                </Grid>
                <Box>
                  <Section>
                    <SectionField>
                      <SearchField
                        id="npi"
                        name="npi"
                        label="NPI"
                        placeholder="NPI"
                        InputProps={{
                          startAdornment: (
                            <InputAdornment position="start">
                              <MedicalInformationOutlined />
                            </InputAdornment>
                          ),
                        }}
                      />
                    </SectionField>
                    <SectionField sx={{ display: 'flex', alignItems: 'center' }}>
                      <FormGroup>
                        <FormControlLabel
                          control={
                            <Checkbox
                              checked={shouldLookForPracticeNPI}
                              onChange={(event: ChangeEvent<HTMLInputElement>) => {
                                setShouldLookForPracticeNPI(event.target.checked);
                              }}
                              name="shouldLookPracticeNpi"
                              id="shouldLookPracticeNpi"
                              color="primary"
                            />
                          }
                          label="Look for Practice NPI"
                        />
                      </FormGroup>
                    </SectionField>
                  </Section>
                  <Section>
                    <SectionField>
                      <SearchField
                        id="first_name"
                        name="first_name"
                        label="First Name"
                        placeholder="First Name"
                        InputProps={{
                          startAdornment: (
                            <InputAdornment position="start">
                              <Person />
                            </InputAdornment>
                          ),
                        }}
                      />
                    </SectionField>
                    <SectionField>
                      <SearchField
                        id="last_name"
                        name="last_name"
                        label="Last Name"
                        placeholder="Last Name"
                        InputProps={{
                          startAdornment: (
                            <InputAdornment position="start">
                              <Person />
                            </InputAdornment>
                          ),
                        }}
                      />
                    </SectionField>
                  </Section>
                  <Section>
                    <SectionField>
                      <Controller
                        name="classification"
                        control={control}
                        render={({ field }) => (
                          <Autocomplete
                            {...field}
                            fullWidth
                            onChange={(event, newValue) => field.onChange(newValue)}
                            options={classifications}
                            renderInput={(params) => (
                              <TextField
                                {...params}
                                InputProps={{
                                  ...params.InputProps,
                                  startAdornment: (
                                    <>
                                      <InputAdornment position="start">
                                        <Work />
                                      </InputAdornment>
                                      {params.InputProps.startAdornment}
                                    </>
                                  ),
                                }}
                                label={'Classification'}
                                placeholder="Classification"
                                variant="outlined"
                              />
                            )}
                          />
                        )}
                      />
                    </SectionField>
                    <SectionField>
                      <SearchField
                        id="practice_name"
                        name="practice_name"
                        label="Practice Name"
                        placeholder="Practice Name"
                        InputProps={{
                          startAdornment: (
                            <InputAdornment position="start">
                              <Business />
                            </InputAdornment>
                          ),
                        }}
                      />
                    </SectionField>
                  </Section>
                  <Section>
                    <SectionField xs={12}>
                      <SearchField
                        id="address"
                        name="address"
                        label="Address"
                        placeholder="Address"
                        InputProps={{
                          startAdornment: (
                            <InputAdornment position="start">
                              <Place />
                            </InputAdornment>
                          ),
                        }}
                      />
                    </SectionField>
                  </Section>
                  <Section>
                    <SectionField>
                      <SearchField
                        id="city"
                        name="city"
                        label="City"
                        InputProps={{
                          startAdornment: (
                            <InputAdornment position="start">
                              <Place />
                            </InputAdornment>
                          ),
                        }}
                      />
                    </SectionField>
                    <SectionField>
                      <SearchField
                        id="phone_number"
                        name="phone_number"
                        label="Phone Number"
                        placeholder="Phone Number"
                        InputProps={{
                          startAdornment: (
                            <InputAdornment position="start">
                              <Phone />
                            </InputAdornment>
                          ),
                        }}
                      />
                    </SectionField>
                  </Section>
                  <Section>
                    <SectionField xs={12}>
                      <Box
                        sx={{
                          width: '100%',
                          display: 'flex',
                          flexDirection: 'row',
                          justifyContent: 'space-between',
                          alignItems: 'center',
                        }}
                      >
                        <Button
                          sx={{ height: 38 }}
                          variant="text"
                          type="submit"
                          onClick={() => {
                            reset();
                            setShouldLookForPracticeNPI(false);
                            setIsFirstSearchDone(false);
                            setLastSearchFieldValues(null);
                          }}
                          startIcon={<RestartAlt />}
                        >
                          Clear Search
                        </Button>
                        {searchCombinations.length && (
                          <Box sx={{ position: 'relative', top: -2 }}>
                            <Tooltip title="Possible Search Combinations">
                              <span>
                                <IconButton
                                  className={isInvalidCombinationError ? 'shining-icon-button' : ''}
                                  onClick={() => {
                                    setIsSearchCombinationDialogOpen(true);
                                  }}
                                >
                                  <HelpCenter sx={{ height: 30, width: 30 }} color="primary" />
                                </IconButton>
                              </span>
                            </Tooltip>
                          </Box>
                        )}
                      </Box>
                    </SectionField>
                  </Section>
                  <Section>
                    <SectionField xs={12}>
                      {!!errorMessage && (
                        <Box>
                          <Typography
                            color={'#ff3333'}
                            sx={{
                              fontWeight: 'bold',
                              wordBreak: 'normal',
                              whiteSpace: 'pre-line',
                              fontSize: '0.9rem',
                            }}
                          >
                            {errorMessage}
                          </Typography>
                        </Box>
                      )}
                    </SectionField>
                  </Section>
                </Box>
              </Box>
            </FormProvider>
          </Box>
          {isFirstSearchDone && (
            <Box sx={{ width: '55vw', display: 'flex' }}>
              <Box
                flexDirection={'column'}
                sx={{
                  paddingX: 2,
                  paddingY: 2,
                  height: '100%',
                  width: '100%',
                }}
              >
                <Grid style={{ display: 'flex' }} container>
                  <Grid
                    item
                    xs={12}
                    justifyContent={'space-between'}
                    flexDirection={'row'}
                    display={'flex'}
                    sx={{ paddingX: 2 }}
                  >
                    <Box>
                      <Typography
                        style={{ fontWeight: 'bolder' }}
                        variant="h4"
                        color="text.primary"
                      >
                        Providers from Directory
                      </Typography>
                    </Box>
                    {isCreationMode &&
                      checkAclValidation({
                        acls: [crsAcls.CRS.PRACTITIONER.MANUAL_ADD],
                      }) && (
                        <Box>
                          <LoadingButton
                            sx={{ height: 38 }}
                            variant="contained"
                            loading={isAdding}
                            disabled={isProvidersLoading || isAdding}
                            onClick={() => {
                              if (errors) return setIsAdding(false);
                              setIsManualCreationConfirmDialogOpen(true);
                            }}
                            startIcon={<Add />}
                          >
                            Create Manually
                          </LoadingButton>
                        </Box>
                      )}
                  </Grid>
                </Grid>
                <Box sx={{ width: '100%', height: '100%', marginTop: 2 }}>
                  <DirectoryProviderGrid
                    isCreationMode={isCreationMode}
                    onProviderAction={async (provider) => {
                      setIsAdding(true);
                      await onProviderAction?.(provider, handleShowExistingPractitioners);
                      setIsAdding(false);
                    }}
                    providers={providers}
                    isLoading={isProvidersLoading}
                    isAdding={isAdding}
                    onAdd={handleVerifyExistingPractitioner}
                    onPreview={(provider) => {
                      setIsProviderPreviewDialogOpen(true);
                      setSelectedProviderId(provider?.uuid);
                    }}
                    providerActionButtonIcon={providerActionButtonIcon}
                    providerActionButtonTitle={providerActionButtonTitle}
                  />
                </Box>
              </Box>
            </Box>
          )}
        </Box>
      </Drawer>
      <ProviderDetailPreviewDialog
        isOpen={isProviderPreviewDialogOpen}
        onClose={() => {
          setIsProviderPreviewDialogOpen(false);
        }}
        providerId={selectedProviderId}
      />
      <ExistingPractitionerDialog
        isOpen={isExistingPractitionersDialogOpen}
        practitioners={existingPractitioners}
        onClose={() => {
          setIsExistingPractitionersDialogOpen(false);
        }}
      />
      <ManualCreationConfirmDialog
        open={isManualCreationConfirmDialogOpen}
        onClose={() => {
          setIsManualCreationConfirmDialogOpen(false);
        }}
        provider={values}
        onCreate={handleCreatePractitioner}
        isLoading={isAdding}
      />
      <SearchCombinationsDialog
        open={isSearchCombinationDialogOpen}
        onClose={() => {
          setIsSearchCombinationDialogOpen(false);
        }}
        searchCombinations={searchCombinations}
      />
    </>
  );
}

export interface SectionProps {
  children: React.ReactNode;
}
const Section = ({ children }: SectionProps) => (
  <Grid container spacing={3} sx={[{ marginBottom: 3.4 }]}>
    {children}
  </Grid>
);

const SectionField = ({ children, xs = 6, ...props }: GridProps) => (
  <Grid {...props} item xs={xs}>
    {children}
  </Grid>
);

type IProps = {
  name: string;
};

type Props = IProps & TextFieldProps;

const SearchField = (props: Props) => (
  <RHFTextField {...props} fullWidth variant="outlined" color="info" />
);
