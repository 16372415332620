import axios from 'axios';
import { fhirClient } from 'src/App';
import { PRINTABLE_CARE_PLAN_TEMPLATE_URL, RENDER_TEMPLATE_BACK_URL } from 'src/config';

export default function usePrintableCarePlan() {
  const axiosInstance = axios.create();

  async function getRender(payload): Promise<any> {
    try {
      const response = await axiosInstance.post(RENDER_TEMPLATE_BACK_URL, payload);
      return response.data;
    } catch (error) {
      console.error(error);
    }
  }

  async function getCarePlanData(carePlanId: string): Promise<any[]> {
    let query = `/CarePlan?_id=${carePlanId}`;
    let carePlanQueryRes;

    try {
      carePlanQueryRes = await fhirClient.get<any>(query);
    } catch (error) {
      console.error(error);
      throw new Error('failed to get CarePlan data for printable CarePlan');
    }

    if (carePlanQueryRes.entry.length < 1) {
      throw new Error('failed to get CarePlan data');
    }

    let patientId: string = '';
    const resourcesList: any[] = [];

    carePlanQueryRes.entry.forEach((item: any) => {
      if (item.resource) {
        resourcesList.push(item.resource);
      }

      if (
        item.resource.resourceType === 'CarePlan' &&
        item.resource.subject &&
        item.resource.subject.reference &&
        item.resource.subject.reference.includes('Patient/')
      ) {
        patientId = item.resource.subject.reference.substring(item.resource.subject.reference.indexOf('/') + 1);
      }
    });

    if (patientId === '') {
      throw new Error('failed to get Patient id from CarePlan data');
    }

    let queryPatient = `/Patient?_id=${patientId}`;
    queryPatient += '&_revinclude=CareTeam:subject';
    queryPatient += '&_revinclude=Goal:subject';
    queryPatient += '&_revinclude=MeasureReport:subject';
    queryPatient += '&_revinclude=RelatedPerson:patient';
    queryPatient += '&_revinclude=Observation:subject';
    queryPatient += '&_revinclude=Task:subject';

    let patientQueryRes;

    try {
      patientQueryRes = await fhirClient.get<any>(queryPatient);
    } catch (error) {
      console.error(error);
      throw new Error('failed to get Patient data for printable CarePlan');
    }

    const practitionersIdsList: string[] = [];

    if (!patientQueryRes || !patientQueryRes.entry) {
      return resourcesList;
    }

    patientQueryRes.entry.forEach((item: any) => {
      if (item.resource) {
        resourcesList.push(item.resource);
      }

      if (item.resource.resourceType === 'CareTeam' && item.resource.participant) {
        item.resource.participant.forEach((item2: any) => {
          if (item2.member && item2.member.reference) {
            const pId = item2.member.reference.substring(item2.member.reference.indexOf('/') + 1);
            practitionersIdsList.push(pId);
          }
        });
      }
    });

    if (practitionersIdsList.length > 0) {
      let queryPract = `/Practitioner?_id=${practitionersIdsList.join(',')}`;

      let practitionerQueryRes;

      try {
        practitionerQueryRes = await fhirClient.get<any>(queryPract);
      } catch (error) {
        console.error(error);
        throw new Error('failed to get Practitioners data for printable CarePlan');
      }

      practitionerQueryRes.entry.forEach((item: any) => {
        if (item.resource) {
          resourcesList.push(item.resource);
        }
      });
    }

    return resourcesList;
  }

  async function getPrintableCareplan(carePlanId: string) {
    const resp = await getCarePlanData(carePlanId);

    if (!resp) {
      return;
    }

    // TODO: REMOVE process when template is fixed
    const rList = JSON.stringify(resp);

    const requestData = {
      template_url: PRINTABLE_CARE_PLAN_TEMPLATE_URL,
      // mo_url:
      //   'https://functions.patientholistics.app/survey_templates/messages_ehn_therapy_management.mo',
      data: {
        data: rList,
      },
    };

    const render = await getRender(requestData);

    return render;
  }

  return { getPrintableCareplan };
}
