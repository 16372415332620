import { 
  Backdrop,
  Button, 
  CircularProgress, 
  Dialog, 
  DialogActions, 
  DialogContent, 
  DialogTitle 
} from "@mui/material";
import { useState } from "react";
import { useSnackbar } from "notistack";
import Iconify from 'src/components/Iconify';
import { patientService } from "src/engagement/patient";
import { WrappedPatient } from "src/@nicheaim/fhir-base/wrappers/Patient";
import { CreatePatientEngagementDto } from "src/engagement/patient/dto/create-patient-engagement.dto";
import useLocales from "src/hooks/useLocales";
import { checkAclValidation } from "src/utils/permissions/permission.utils";
import crsAcls from "src/utils/permissions/crs/crsAcls";

type Props = {
  patient: WrappedPatient | null;
  handlerRefresh: Function;
};

export default function CreatePatientEngagement({
  patient,
  handlerRefresh
}: Props) {

  const { enqueueSnackbar } = useSnackbar();
  const { i18n } = useLocales();
  const [openModal, setOpenModal] = useState(false);
  const [openBackdrop, setOpenBackdrop] = useState(false);
  const addPermission = checkAclValidation({ acls: [crsAcls.ENGAGEMENT_HUB.PATIENT_ENGAGEMENT.ADD] })
  
  const handlerCreatePatientEngagement = async () => {
    setOpenBackdrop(true);
    try {
      const payload: CreatePatientEngagementDto = {
        fhirId: patient?.id ?? '',
        language: patient?.getLanguage()?.[0]?.value ?? '',
        scope: 'INITIAL_PATIENT_ENGAGEMENT'
      };
  
      await patientService.createPatientEngagement(payload);
      enqueueSnackbar(`${i18n('patients.title', 'crs')} Engagement was created`);
      handlerRefresh();
    } catch (error) {
      console.log(error);
      enqueueSnackbar('An error has occurred', { variant: 'error' });
    } finally{
      setOpenModal(false);
      setOpenBackdrop(false);
    }
  };

  return(
    <>
      {addPermission && (
        <Button
          disabled={false}
          sx={{ height: '36px' }}
          onClick={() => setOpenModal(true)}
          startIcon={<Iconify icon={'eva:plus-fill'} />}
          style={{ position: 'absolute', top: '25px', right: '25px' }}
        >
          Add {i18n('patients.title', 'crs')} Engagement
        </Button>
      )}

      <Dialog open={openModal}>
        <Backdrop
          sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
          open={openBackdrop}
        >
          <CircularProgress color="inherit" />
        </Backdrop>
        <DialogTitle>Add {i18n('patients.title', 'crs')} Engagement</DialogTitle>
        <DialogContent>
          <br/>
          Are you sure you want to create a new {i18n('patients.title', 'crs')} Engagement?
        </DialogContent>
        <DialogActions>
          <Button variant="contained" color="error" onClick={() => setOpenModal(false)}>
              Disagree
          </Button>

          <Button variant="contained" onClick={handlerCreatePatientEngagement}>
            Agree
          </Button>
        </DialogActions>
      </Dialog>
    </>
  )
}