import { 
  Box, 
  Container, 
  Dialog, 
  DialogContent, 
  DialogTitle, 
  Grid, 
  Tooltip, 
  Typography 
} from "@mui/material";
import _ from 'lodash';
import moment from "moment";
import agent from "src/api/agent";
import Page from "src/components/Page";
import useAuth from "src/hooks/useAuth";
import { useSnackbar } from "notistack";
import { useParams } from "react-router";
import { capitalCase } from "change-case";
import { mapContactAttempts } from "./map";
import { setSILDisplay } from "src/utils/fhir";
import useSettings from "src/hooks/useSettings";
import EditIcon from '@mui/icons-material/Edit';
import NotesList from "../common/notes/NotesLists";
import CreateTask from "../../nat/task/CreateTask";
import { useEffect, useMemo, useState } from "react";
import { patientService } from "src/engagement/patient";
import Workflow from "src/components/workflow/Workflow";
import { TableCustom } from "src/components/TableCustom";
import { TABLE_HEAD_CONTACT } from "../common/table-head";
import KeyValueGrid from "../../../components/KeyValueGrid";
import { dateYearFormatWithAge } from "src/utils/formatTime";
import TasksList from "./components/workflow-step/ListTasks";
import { getParseColumn, UI_SCHEMA } from "src/utils/utilities";
import ExpandableCard from "src/components/common/ExpandableCard";
import Input from "src/components/workflow/common/components/Input";
import { TaskWrapper } from "src/@nicheaim/fhir-base/wrappers/Task";
import ConfirmContact from "./components/workflow-step/ConfirmContact";
import ConfirmNatOptIn from "./components/workflow-step/ConfirmNatOptIn";
import ButtonType from 'src/components/workflow/common/components/Button';
import SelectType from "src/components/workflow/common/components/Select";
import { PatientWrapper } from "src/@nicheaim/fhir-base/wrappers/Patient";
import { ConsentWrapper } from "src/@nicheaim/fhir-base/wrappers/Consent";
import ConfirmMemberInfo from "./components/workflow-step/ConfirmMemberInfo";
import ScheduleNatSession from "./components/workflow-step/ScheduleNatSession";
import ConfirmCMOptInItem from "./components/workflow-step/ConfirmCMOptInItem";
import ReviewNatReportItem from "./components/workflow-step/ReviewNatReportItem";
import { PatientEngagementResponse } from "src/engagement/patient/PatientService";
import ConfirmGuarantorInfo from "./components/workflow-step/ConfirmGuarantorInfo";
import DispositionType from "src/components/workflow/common/components/Disposition";
import ComplementaryInfo from "src/components/complementary-info/ComplementaryInfo";
import { RelatedPersonWrapper } from "src/@nicheaim/fhir-base/wrappers/RelatedPerson";
import { useConsents, usePatient, useRelatedPersons, useTasks } from "src/@nicheaim/fhir-react";
import useLocales from "src/hooks/useLocales";
import HeaderBreadcrumbs from "src/components/HeaderBreadcrumbs";
import { PATH_DASHBOARD } from "src/routes/paths";
import { PermissionsProvider } from "src/contexts/PermissionsContext";
import { checkAclValidation } from "src/utils/permissions/permission.utils";
import crsAcls from "src/utils/permissions/crs/crsAcls";
import { PatientEngagementPermissions, WorkflowPermissions } from "src/sections/crs/types";

export default function PatientEngagementDetails() {

  const { id: patientEngagementId = null } = useParams();
  const user = useAuth();
  const userEmail = user?.getCurrentUser?.()?.email;
  const { themeStretch } = useSettings();
  const { enqueueSnackbar } = useSnackbar();
  const { i18n } = useLocales();

  const [patientId, setPatientId] = useState("");
  const [patientEngagement, setPatientEngagement] = 
    useState<PatientEngagementResponse | undefined>();
  const [contactAttempts, setContactAttempts] = useState<any>([]);
  const [allUsers, setAllUsers] = useState([]);

  const [workflowStage, setWorkflowStage] = useState(undefined);
  const [workflowInstance, setWorkflowInstance] = useState<any>();
  const [checklistData, setChecklistData] = useState<any>([]);
  const [reject, setReject] = useState(false);
  const [disposition, setDisposition] = useState<any>(false);
  const [externalRefreshWorkFlow, setExternalRefreshWorkFlow] = useState(false);

  const [confirmOptInItem, setConfirmOptInItem] = useState<any>(false);
  const [reviewNatReportItem, setReviewNatReportItem] = useState<any>(false);
  const [showTasksList, setShowTasksList] = useState<boolean>(false);
  const [confirmNatOptInModalOpen, setConfirmNatOptInModalOpen] = useState(false);
  const [scheduleNatSessionModalOpen, setScheduleNatSessionModalOpen] = useState(false);
  const [confirmContactModalOpen, setConfirmContactModalOpen] = useState(false);
  const [createTask, setCreateTask] = useState(false);
  const [confirmModalOpen, setConfirmModalOpen] = useState(false);
  const [confirmGuarantorModalOpen, setConfirmGuarantorModalOpen] = useState(false);

  const [isOpenReAssign, setIsOpenReAssign] = useState(false);

  useEffect(() => {
    if (patientEngagement) {
      const patientIdFhir = patientEngagement.patientFhirUUID;
      if (patientIdFhir) {
        setPatientId(patientIdFhir);
      }
    }
  }, [patientEngagement]);

  const [patient, { refresh: refreshPatient, update: updatePatient }] = 
  usePatient(patientId, {
    map: PatientWrapper,
    autofetch: !!patientId,
  });

  const [ tasks, { refresh: refreshTask } ] = useTasks({
    filter: {
      patient: patient?.id,
      code: 'completed-nat-assessment'
    },
    autofetch: !!patient?.id,
    map: TaskWrapper
  });

  const [ consent ] = useConsents({
    filter: {
      patient: patient?.id
    },
    autofetch: !!patient?.id,
    map: ConsentWrapper
  });

  const [
    relatedPersons,
    { create: createRelatedPerson, update: updateRelatedPerson, refresh: refreshRelatedPerson },
  ] = useRelatedPersons({
    filter: {
      patient: patient?.id,
    },
    autofetch: !!patient,
    map: RelatedPersonWrapper,
  });

  const getPatientEngagementDetails = async (id: any) => {
    try {
      const resp = await patientService.patientApiClient.getOne(id);
      setPatientEngagement(resp);
    } catch (e) {
      console.log(`Something failed get patient engagement details ${e}`);
    }
  };

  useEffect(() => {
    getPatientEngagementDetails(patientEngagementId);

    return () => {
      setPatientEngagement(undefined);
    };
  }, [patientEngagementId]);

  const REFERENCE_NAME = useMemo(() => patientEngagement?.workflowScope, [patientEngagement]);

  const fetchStageFromWorkflow = async () => {
    try {
      const result = await agent.Workflow.getInstance(patientEngagement?.patientEngagementId, 'ENGAGEMENT');
      setWorkflowInstance(result);
      setWorkflowStage(result?.stage?.id);
    } catch (error) {
      console.log('error', error);
    }
  };

  const fetchContactAttempt = async (patientEngagementId: string) => {
    const result: any = await patientService.getContactAttempt(patientEngagementId);
    setContactAttempts(result);
  };

  const fetchAllUsers = async () => {
    try {
      const getAllUsers = await agent.User.getAllUsers();
      setAllUsers(
        getAllUsers
          .sort((_a: any, _b: any) => {
            const a = (_a.firstName + " " + _a.lastName).toLowerCase(),
              b = (_b.firstName + " " + _b.lastName).toLowerCase();

            if (a < b) return -1;

            if (a > b) return 1;

            return 0;
          })
          .filter((e: any) => e.status === "Active")
      );
    } catch (err) {
      console.log("error on fetching all users", err);
    }
  };

  useEffect(() => {
    fetchStageFromWorkflow();
    fetchContactAttempt(patientEngagement?.patientEngagementId || '')
  }, [patientEngagement, REFERENCE_NAME]);

  useEffect(() => {
    fetchAllUsers();
  }, []);

  const engagementWorkflowData = useMemo(() => ({
    id: 1,
    recordId: patientEngagement?.patientEngagementId || '',
    recordReference: 'ENGAGEMENT',
    stage: {
      id: workflowStage,
    },
    referralRecord: patientEngagement,
    workflowInstance,
  }), [workflowStage, workflowInstance]);

  const WorkflowComponentMapping: any = {
    Input,
    Select: SelectType,
    Button: ButtonType,
    Disposition: DispositionType,
  };

  const WorkflowData: any = {
    disposition,
    reject,
  };

  const handlerDisposition = ({
    record,
    checklistItem,
    refreshChecklistHandler,
    refreshRecordHandler,
    more,
  }: any) => {
    setDisposition(true);
  };

  const handlerAssignUser = ({
    record,
    checklistItem,
    refreshChecklistHandler,
    refreshRecordHandler,
    more,
  }: any) => {
    setChecklistData(checklistItem);
    assignUser(
      checklistItem,
      refreshChecklistHandler,
      refreshRecordHandler,
      more,
    );
  };

  const handlerEngage = ({
    record,
    checklistItem,
    refreshChecklistHandler,
    refreshRecordHandler,
    more,
  }: any) => {
    setConfirmContactModalOpen(true);
    setChecklistData(checklistItem);
  };

  const handlerConfirmMemberInfo = ({
    record,
    checklistItem,
    refreshChecklistHandler,
    refreshRecordHandler,
    more,
  }: any) => {
    setConfirmModalOpen(true);
    setChecklistData(checklistItem);
  };

  const handlerConfirmationGuarantorInfo = ({
    record,
    checklistItem,
    refreshChecklistHandler,
    refreshRecordHandler,
    more,
  }: any) => {
    setConfirmGuarantorModalOpen(true);
    setChecklistData(checklistItem);
  };

  const handlerConfirmationNatOptIn = ({
    record,
    checklistItem,
    refreshChecklistHandler,
    refreshRecordHandler,
    more,
  }: any) => {
    setConfirmNatOptInModalOpen(true);
    setChecklistData(checklistItem);
  };

  const handlerConfirmationScheduleNatSession = ({
    record,
    checklistItem,
    refreshChecklistHandler,
    refreshRecordHandler,
    more,
  }: any) => {
    setScheduleNatSessionModalOpen(true);
    setChecklistData(checklistItem);
  };

  const handlerConfirmationReviewNat = ({
    record,
    checklistItem,
    refreshChecklistHandler,
    refreshRecordHandler,
    more,
  }: any) => {
    setReviewNatReportItem(true);
    setChecklistData(checklistItem);
  };

  const handlerConfirmationCmOptIn = ({
    record,
    checklistItem,
    refreshChecklistHandler,
    refreshRecordHandler,
    more,
  }: any) => {
    setConfirmOptInItem(true);
    setChecklistData(checklistItem);
  };

  const handlerConfirmationInitiateNatRequest = ({
    record,
    checklistItem,
    refreshChecklistHandler,
    refreshRecordHandler,
    more,
  }: any) => {
    setCreateTask(true);
    setChecklistData(checklistItem);
  };

  const handlerConfirmCompleteNatInterview = ({
    record,
    checklistItem,
    refreshChecklistHandler,
    refreshRecordHandler,
    more,
  }: any) => {
    setShowTasksList(true);
    setChecklistData(checklistItem);
  };

  const handlerReject = async (open: any) => {
    setReject(open);
  };

  const WorkflowHandler: any = {
    ASSIGN_OWNER_ITEM: {
      type: "payload",
      handler: handlerAssignUser,
      data: allUsers,
    },
    CONTACT_ITEM: {
      type: "payload",
      handler: handlerEngage,
      data: true,
    },
    CONFIRM_MEMBER_INFO_ITEM: {
      type: "confirm",
      handler: handlerConfirmMemberInfo,
      data: true,
    },
    CONFIRM_GUARANTOR_INFO_ITEM: {
      type: "confirm",
      handler: handlerConfirmationGuarantorInfo,
      data: true,
    },
    CONFIRM_NAT_OPTIN_ITEM: {
      type: "confirm",
      handler: handlerConfirmationNatOptIn,
      data: true,
    },
    SCHEDULE_NAT_SESSION_ITEM: {
      type: "confirm",
      handler: handlerConfirmationScheduleNatSession,
      data: true,
    },
    REVIEW_NAT_REPORT_ITEM: {
      type: "confirm",
      handler: handlerConfirmationReviewNat,
      data: true,
    },
    CONFIRM_CM_OPTIN_ITEM: {
      type: "confirm",
      handler: handlerConfirmationCmOptIn,
      data: true,
    },
    INITIATE_NAT_REQUEST_ITEM: {
      type: "confirm",
      handler: handlerConfirmationInitiateNatRequest,
      data: true,
    },
    COMPLETE_NAT_INTERVIEW_ITEM: {
      type: "confirm",
      handler: handlerConfirmCompleteNatInterview,
      data: true,
    },
    setDisposition: {
      type: 'confirm',
      handler: handlerDisposition,
      data: false,
    },
    setReject: {
      type: 'confirm',
      handler: (open: boolean = true) => handlerReject(open),
      data: false,
    },
  };

  const assignUser = async (
    checklistItem: any,
    refreshChecklistHandler: any,
    refreshRecordHandler: any,
    more: any
  ) => {
    const payload = {
      item: checklistItem,
      record: {
        ...patientEngagement,
        ...workflowInstance,
        assigned: {
          selectedUser: more.selectedUser,
        },
      }
    };

    try {
      await agent.Workflow.assignOwner(payload);
      enqueueSnackbar('User was assigned');
      refreshChecklistHandler();
    } catch (err) {
      enqueueSnackbar('An error has occurred', { variant: 'error' });
      console.log("error on trying to update patient engagement record");
    } finally {
      setIsOpenReAssign(false);
      setExternalRefreshWorkFlow(true);
      getPatientEngagementDetails(patientEngagementId);
    }
    setExternalRefreshWorkFlow(false);
  };

  const handleReAssingOwned = async (ownedSelected: any) => {
    try {
      const payload = {
        record: {
          ...workflowInstance,
          assigned: {
            selectedUser: ownedSelected?.more?.selectedUser,
          },
        },
      };

      const result = await agent.Workflow.reAssignOwner(payload);
      if(result){
        enqueueSnackbar('User was assigned');
      }
    } catch (err) {
      enqueueSnackbar('An error has occurred', { variant: 'error' });
      console.log('error on handleReAssingOwned');
    } finally {
      setExternalRefreshWorkFlow(true);
      getPatientEngagementDetails(patientEngagementId);
    }
    setIsOpenReAssign(false);
    setExternalRefreshWorkFlow(false);
  };

  const handlerConfirmMember = async () => {
    const workflowInstanceData =
      workflowInstance && workflowInstance.data && typeof workflowInstance.data === 'string'
        ? JSON.parse(workflowInstance.data)
        : workflowInstance && !workflowInstance?.data
        ? {}
        : workflowInstance?.data;

    const workflowInstanceDataSchema =
      workflowInstance &&
      workflowInstance.dataSchema &&
      typeof workflowInstance.dataSchema === 'string'
        ? JSON.parse(workflowInstance.dataSchema)
        : workflowInstance && !workflowInstance?.dataSchema
        ? {}
        : workflowInstance?.dataSchema;

    const payloadData =
      workflowInstanceData && Object.keys(workflowInstanceData).length > 0
        ? _.merge(workflowInstanceData, {
            scope: {
              checklistItem: {
                ...workflowInstanceData.checklistItem,
                CONFIRM_PATIENT_INFO_ITEM:
                  {
                    validatedOn: new Date().toISOString(),
                    validatedBy: userEmail || '',
                    modifiedFields: "",
                  },
              },
            },
          })
        : {
            scope: {
              checklistItem: {
                CONFIRM_PATIENT_INFO_ITEM:
                  {
                    validatedOn: new Date().toISOString(),
                    validatedBy: userEmail || '',
                    modifiedFields: "",
                  },
              },
            },
          };

    const CONFIRM_PATIENT_INFO_ITEM = UI_SCHEMA.CONFIRM_PATIENT_INFO_ITEM;

    const payloadDataSchema =
      workflowInstanceDataSchema && Object.keys(workflowInstanceDataSchema).length > 0
        ? _.merge(workflowInstanceDataSchema, {
            type: 'object',
            properties: {
              CONFIRM_PATIENT_INFO_ITEM,
            },
          })
        : {
            type: 'object',
            properties: {
              CONFIRM_PATIENT_INFO_ITEM,
            },
          };

    const payloadForUpdateWorkflowinstance = {
      referenceId: patientEngagement?.patientEngagementId,
      referenceName: REFERENCE_NAME,
      data: JSON.stringify(payloadData),
      dataSchema: JSON.stringify(payloadDataSchema),
    };

    const setCondition = JSON.parse(checklistData.conditions);
    setCondition.data = { confirm: true, message: "Patient info confirmed" };
    const conditions = JSON.stringify(setCondition);

    const payloadForSetItemCompleted = {
      item: { ...checklistData, conditions },
      record: {
        ...patientEngagement,
        ...workflowInstance,
      },
    };

    try {
      await agent.Checklist.markItemCompleted(payloadForSetItemCompleted);
      await agent.Workflow.updateWorkflowInstance(payloadForUpdateWorkflowinstance);
    } catch (err) {
      console.log('error', err);
    } finally {
      setExternalRefreshWorkFlow(true);
      getPatientEngagementDetails(patientEngagementId);
    }
    setExternalRefreshWorkFlow(false);
  };

  const handlerConfirmNATOptIn = async (data: any) => {

    const workflowInstanceData =
    workflowInstance && workflowInstance.data && typeof workflowInstance.data === 'string'
      ? JSON.parse(workflowInstance.data)
      : workflowInstance && !workflowInstance?.data
      ? {}
      : workflowInstance?.data;

    const workflowInstanceDataSchema =
      workflowInstance &&
      workflowInstance.dataSchema &&
      typeof workflowInstance.dataSchema === 'string'
        ? JSON.parse(workflowInstance.dataSchema)
        : workflowInstance && !workflowInstance?.dataSchema
        ? {}
        : workflowInstance?.dataSchema;

    const payloadData =
      workflowInstanceData && Object.keys(workflowInstanceData).length > 0
        ? _.merge(workflowInstanceData, {
            scope: {
              checklistItem: {
                ...workflowInstanceData.checklistItem,
                CONFIRM_NAT_OPTIN_ITEM:
                  {
                    validatedOn: new Date().toISOString(),
                    validatedBy: userEmail || '',
                    modifiedFields: {
                      opt_out_decision: data.opt_out_decision,
                      method: data.method
                    },
                  },
              },
            },
          })
        : {
            scope: {
              checklistItem: {
                CONFIRM_NAT_OPTIN_ITEM:
                  {
                    validatedOn: new Date().toISOString(),
                    validatedBy: userEmail || '',
                    modifiedFields: {
                      opt_out_decision: data.opt_out_decision,
                      method: data.method
                    },
                  },
              },
            },
          };

    const CONFIRM_NAT_OPTIN_ITEM = UI_SCHEMA.CONFIRM_NAT_OPTIN_ITEM;

    const payloadDataSchema =
      workflowInstanceDataSchema && Object.keys(workflowInstanceDataSchema).length > 0
        ? _.merge(workflowInstanceDataSchema, {
            type: 'object',
            properties: {
              CONFIRM_NAT_OPTIN_ITEM,
            },
          })
        : {
            type: 'object',
            properties: {
              CONFIRM_NAT_OPTIN_ITEM,
            },
          };

    const payloadForUpdateWorkflowinstance = {
      referenceId: patientEngagement?.patientEngagementId,
      referenceName: REFERENCE_NAME,
      data: JSON.stringify(payloadData),
      dataSchema: JSON.stringify(payloadDataSchema),
    };

    const setCondition = JSON.parse(checklistData.conditions);
    setCondition.data = { confirm: true, message: "Confirmed HJ Opt-Out" };
    const conditions = JSON.stringify(setCondition);

    const payloadForSetItemCompleted = {
      item: { ...checklistData, conditions },
      record: {
        ...patientEngagement,
        ...workflowInstance,
      },
    };

    try {
      await agent.Checklist.markItemCompleted(payloadForSetItemCompleted);
      await agent.Workflow.updateWorkflowInstance(payloadForUpdateWorkflowinstance);
    } catch (err) {
      console.log('error', err);
    } finally {
      setExternalRefreshWorkFlow(true);
      getPatientEngagementDetails(patientEngagementId);
    }
    setExternalRefreshWorkFlow(false);
  };

  const handlerConfirmGuarantor = async (data: any) => {
    
    const workflowInstanceData =
    workflowInstance && workflowInstance.data && typeof workflowInstance.data === 'string'
      ? JSON.parse(workflowInstance.data)
      : workflowInstance && !workflowInstance?.data
      ? {}
      : workflowInstance?.data;

    const workflowInstanceDataSchema =
      workflowInstance &&
      workflowInstance.dataSchema &&
      typeof workflowInstance.dataSchema === 'string'
        ? JSON.parse(workflowInstance.dataSchema)
        : workflowInstance && !workflowInstance?.dataSchema
        ? {}
        : workflowInstance?.dataSchema;

    const payloadData =
      workflowInstanceData && Object.keys(workflowInstanceData).length > 0
        ? _.merge(workflowInstanceData, {
            scope: {
              checklistItem: {
                ...workflowInstanceData.checklistItem,
                CONFIRM_GUARANTOR_INFO_ITEM:
                  {
                    validatedOn: new Date().toISOString(),
                    validatedBy: userEmail || '',
                    modifiedFields: "",
                  },
              },
            },
          })
        : {
            scope: {
              checklistItem: {
                CONFIRM_GUARANTOR_INFO_ITEM:
                  {
                    validatedOn: new Date().toISOString(),
                    validatedBy: userEmail || '',
                    modifiedFields: "",
                  },
              },
            },
          };

    const CONFIRM_GUARANTOR_INFO_ITEM = UI_SCHEMA.CONFIRM_GUARANTOR_INFO_ITEM;

    const payloadDataSchema =
      workflowInstanceDataSchema && Object.keys(workflowInstanceDataSchema).length > 0
        ? _.merge(workflowInstanceDataSchema, {
            type: 'object',
            properties: {
              CONFIRM_GUARANTOR_INFO_ITEM,
            },
          })
        : {
            type: 'object',
            properties: {
              CONFIRM_GUARANTOR_INFO_ITEM,
            },
          };

    const payloadForUpdateWorkflowinstance = {
      referenceId: patientEngagement?.patientEngagementId,
      referenceName: REFERENCE_NAME,
      data: JSON.stringify(payloadData),
      dataSchema: JSON.stringify(payloadDataSchema),
    };

    const setCondition = JSON.parse(checklistData.conditions);
    setCondition.data = { confirm: true, message: "Parent/Caregiver info confirmed" };
    const conditions = JSON.stringify(setCondition);

    const payloadForSetItemCompleted = {
      item: { ...checklistData, conditions },
      record: {
        ...patientEngagement,
        ...workflowInstance,
      },
    };

    try {

      if(!data?.skip){
        if (data?.id) {
          await updateRelatedPerson(data);
        } else {
          await createRelatedPerson(data);
        }
      }

      await agent.Checklist.markItemCompleted(payloadForSetItemCompleted);
      await agent.Workflow.updateWorkflowInstance(payloadForUpdateWorkflowinstance);
    } catch (err) {
      console.log('error', err);
    } finally {
      setExternalRefreshWorkFlow(true);
      getPatientEngagementDetails(patientEngagementId);
    }

    setExternalRefreshWorkFlow(false);
    refreshRelatedPerson();
  };

  const handlerConfirmScheduleHJSession = async (data: any) => {
    const workflowInstanceData =
    workflowInstance && workflowInstance.data && typeof workflowInstance.data === 'string'
      ? JSON.parse(workflowInstance.data)
      : workflowInstance && !workflowInstance?.data
      ? {}
      : workflowInstance?.data;

    const workflowInstanceDataSchema =
      workflowInstance &&
      workflowInstance.dataSchema &&
      typeof workflowInstance.dataSchema === 'string'
        ? JSON.parse(workflowInstance.dataSchema)
        : workflowInstance && !workflowInstance?.dataSchema
        ? {}
        : workflowInstance?.dataSchema;

    const payloadData =
      workflowInstanceData && Object.keys(workflowInstanceData).length > 0
        ? _.merge(workflowInstanceData, {
            scope: {
              checklistItem: {
                ...workflowInstanceData.checklistItem,
                SCHEDULE_NAT_SESSION_ITEM:
                  {
                    validatedOn: new Date().toISOString(),
                    validatedBy: userEmail || '',
                    modifiedFields: {
                      date_hour: data.date_hour && new Date(data.date_hour).toISOString(),
                      location: data.location
                    },
                  },
              },
            },
          })
        : {
            scope: {
              checklistItem: {
                SCHEDULE_NAT_SESSION_ITEM:
                  {
                    validatedOn: new Date().toISOString(),
                    validatedBy: userEmail || '',
                    modifiedFields: {
                      date_hour: data.date_hour && new Date(data.date_hour).toISOString(),
                      location: data.location
                    },
                  },
              },
            },
          };

    const SCHEDULE_NAT_SESSION_ITEM = UI_SCHEMA.SCHEDULE_NAT_SESSION_ITEM;

    const payloadDataSchema =
      workflowInstanceDataSchema && Object.keys(workflowInstanceDataSchema).length > 0
        ? _.merge(workflowInstanceDataSchema, {
            type: 'object',
            properties: {
              SCHEDULE_NAT_SESSION_ITEM,
            },
          })
        : {
            type: 'object',
            properties: {
              SCHEDULE_NAT_SESSION_ITEM,
            },
          };

    const payloadForUpdateWorkflowinstance = {
      referenceId: patientEngagement?.patientEngagementId,
      referenceName: REFERENCE_NAME,
      data: JSON.stringify(payloadData),
      dataSchema: JSON.stringify(payloadDataSchema),
    };

    const setCondition = JSON.parse(checklistData.conditions);
    setCondition.data = { confirm: true, message: "HJ session schedule" };
    const conditions = JSON.stringify(setCondition);

    const payloadForSetItemCompleted = {
      item: { ...checklistData, conditions },
      record: {
        ...patientEngagement,
        ...workflowInstance,
      },
    };

    try {
      await agent.Checklist.markItemCompleted(payloadForSetItemCompleted);
      await agent.Workflow.updateWorkflowInstance(payloadForUpdateWorkflowinstance);
    } catch (err) {
      console.log('error', err);
    } finally {
      setExternalRefreshWorkFlow(true);
      getPatientEngagementDetails(patientEngagementId);
    }

    setExternalRefreshWorkFlow(false);
  };

  const handlerConfirmContactAttempt = async (data: any) => {
    let resultContactAttempt: any = {};

    const payloadForSetItemCompleted = {
      item: checklistData,
      record: {
        ...patientEngagement,
        ...workflowInstance,
      },
    };

    const workflowOutcomeDisposition: string | undefined = process.env.REACT_APP_WORKFLOW_OUTCOME_DISPOSITION;
    const outcomeDisposition: string[] = workflowOutcomeDisposition ? workflowOutcomeDisposition.split(',') : [];

    if(outcomeDisposition?.find((item: string) => item === data?.outcome)){
      setReject(true);
      return;
    }

    try {
      resultContactAttempt = await patientService.createContactAttempt(data);
      if (!_.isEmpty(resultContactAttempt)) {
        await agent.Checklist.markItemCompleted(payloadForSetItemCompleted);
        fetchContactAttempt(patientEngagement?.patientEngagementId || '');
      }
    } catch (err) {
      console.log('error', err);
    } finally {
      setExternalRefreshWorkFlow(true);
      getPatientEngagementDetails(patientEngagementId);
    }

    setExternalRefreshWorkFlow(false);

    return resultContactAttempt;
  };

  const handlerInitiateHJRequest = async (data: any) => {
    const workflowInstanceData =
    workflowInstance && workflowInstance.data && typeof workflowInstance.data === 'string'
      ? JSON.parse(workflowInstance.data)
      : workflowInstance && !workflowInstance?.data
      ? {}
      : workflowInstance?.data;

    const workflowInstanceDataSchema =
      workflowInstance &&
      workflowInstance.dataSchema &&
      typeof workflowInstance.dataSchema === 'string'
        ? JSON.parse(workflowInstance.dataSchema)
        : workflowInstance && !workflowInstance?.dataSchema
        ? {}
        : workflowInstance?.dataSchema;

    const payloadData =
      workflowInstanceData && Object.keys(workflowInstanceData).length > 0
        ? _.merge(workflowInstanceData, {
            scope: {
              checklistItem: {
                ...workflowInstanceData.checklistItem,
                INITIATE_NAT_REQUEST_ITEM:
                  {
                    validatedOn: new Date().toISOString(),
                    validatedBy: userEmail || '',
                    patientId: patient?.id,
                    orderId: data,
                    modifiedFields: "",
                  },
              },
            },
          })
        : {
            scope: {
              checklistItem: {
                INITIATE_NAT_REQUEST_ITEM:
                  {
                    validatedOn: new Date().toISOString(),
                    validatedBy: userEmail || '',
                    patientId: patient?.id,
                    orderId: data,
                    modifiedFields: "",
                  },
              },
            },
          };

    const INITIATE_NAT_REQUEST_ITEM = UI_SCHEMA.INITIATE_NAT_REQUEST_ITEM;

    const payloadDataSchema =
      workflowInstanceDataSchema && Object.keys(workflowInstanceDataSchema).length > 0
        ? _.merge(workflowInstanceDataSchema, {
            type: 'object',
            properties: {
              INITIATE_NAT_REQUEST_ITEM,
            },
          })
        : {
            type: 'object',
            properties: {
              INITIATE_NAT_REQUEST_ITEM,
            },
          };

    const payloadForUpdateWorkflowinstance = {
      referenceId: patientEngagement?.patientEngagementId,
      referenceName: REFERENCE_NAME,
      data: JSON.stringify(payloadData),
      dataSchema: JSON.stringify(payloadDataSchema),
    };

    const payloadForSetItemCompleted = {
      item: checklistData,
      record: {
        ...patientEngagement,
        ...workflowInstance,
      },
    };

    try {
      if (!_.isEmpty(data)) {
        await agent.Checklist.markItemCompleted(payloadForSetItemCompleted);
        await agent.Workflow.updateWorkflowInstance(payloadForUpdateWorkflowinstance);
      }
    } catch (err) {
      console.log('error', err);
    } finally {
      setExternalRefreshWorkFlow(true);
      getPatientEngagementDetails(patientEngagementId);
    }

    setExternalRefreshWorkFlow(false);
    refreshTask();
  };

  const handlerCompleteHJRequest = async () => {
    setExternalRefreshWorkFlow(true);
    getPatientEngagementDetails(patientEngagementId);
    setExternalRefreshWorkFlow(false);
  }; 

  const handlerReviewHJRequest = async () => {
    const workflowInstanceData =
      workflowInstance && workflowInstance.data && typeof workflowInstance.data === 'string'
        ? JSON.parse(workflowInstance.data)
        : workflowInstance && !workflowInstance?.data
        ? {}
        : workflowInstance?.data;

    const workflowInstanceDataSchema =
      workflowInstance &&
      workflowInstance.dataSchema &&
      typeof workflowInstance.dataSchema === 'string'
        ? JSON.parse(workflowInstance.dataSchema)
        : workflowInstance && !workflowInstance?.dataSchema
        ? {}
        : workflowInstance?.dataSchema;

    const payloadData =
      workflowInstanceData && Object.keys(workflowInstanceData).length > 0
        ? _.merge(workflowInstanceData, {
            scope: {
              checklistItem: {
                ...workflowInstanceData.checklistItem,
                REVIEW_NAT_REPORT_ITEM:
                  {
                    validatedOn: new Date().toISOString(),
                    validatedBy: userEmail || '',
                    modifiedFields: "",
                  },
              },
            },
          })
        : {
            scope: {
              checklistItem: {
                REVIEW_NAT_REPORT_ITEM:
                  {
                    validatedOn: new Date().toISOString(),
                    validatedBy: userEmail || '',
                    modifiedFields: "",
                  },
              },
            },
          };

    const REVIEW_NAT_REPORT_ITEM = UI_SCHEMA.REVIEW_NAT_REPORT_ITEM;

    const payloadDataSchema =
      workflowInstanceDataSchema && Object.keys(workflowInstanceDataSchema).length > 0
        ? _.merge(workflowInstanceDataSchema, {
            type: 'object',
            properties: {
              REVIEW_NAT_REPORT_ITEM,
            },
          })
        : {
            type: 'object',
            properties: {
              REVIEW_NAT_REPORT_ITEM,
            },
          };

    const payloadForUpdateWorkflowinstance = {
      referenceId: patientEngagement?.patientEngagementId,
      referenceName: REFERENCE_NAME,
      data: JSON.stringify(payloadData),
      dataSchema: JSON.stringify(payloadDataSchema),
    };

    const setCondition = JSON.parse(checklistData.conditions);
    setCondition.data = { confirm: true, message: "HJ report reviewed" };
    const conditions = JSON.stringify(setCondition);

    const payloadForSetItemCompleted = {
      item: { ...checklistData, conditions },
      record: {
        ...patientEngagement,
        ...workflowInstance,
      },
    };

    try {
      await agent.Checklist.markItemCompleted(payloadForSetItemCompleted);
      await agent.Workflow.updateWorkflowInstance(payloadForUpdateWorkflowinstance);
    } catch (err) {
      console.log('error', err);
    } finally {
      setExternalRefreshWorkFlow(true);
      getPatientEngagementDetails(patientEngagementId);
    }

    setExternalRefreshWorkFlow(false);
  };

  const handlerConfirmCMOptIN = async (data: any) => {
    const workflowInstanceData =
    workflowInstance && workflowInstance.data && typeof workflowInstance.data === 'string'
      ? JSON.parse(workflowInstance.data)
      : workflowInstance && !workflowInstance?.data
      ? {}
      : workflowInstance?.data;

    const workflowInstanceDataSchema =
      workflowInstance &&
      workflowInstance.dataSchema &&
      typeof workflowInstance.dataSchema === 'string'
        ? JSON.parse(workflowInstance.dataSchema)
        : workflowInstance && !workflowInstance?.dataSchema
        ? {}
        : workflowInstance?.dataSchema;

    const payloadData =
      workflowInstanceData && Object.keys(workflowInstanceData).length > 0
        ? _.merge(workflowInstanceData, {
            scope: {
              checklistItem: {
                ...workflowInstanceData.checklistItem,
                CONFIRM_CM_OPTIN_ITEM:
                  {
                    validatedOn: new Date().toISOString(),
                    validatedBy: userEmail || '',
                    modifiedFields: {
                      opt_out_decision: data.opt_out_decision,
                      type: data.type,
                      notes: data.notes
                    },
                  },
              },
            },
          })
        : {
            scope: {
              checklistItem: {
                CONFIRM_CM_OPTIN_ITEM:
                  {
                    validatedOn: new Date().toISOString(),
                    validatedBy: userEmail || '',
                    modifiedFields: {
                      opt_out_decision: data.opt_out_decision,
                      type: data.type,
                      notes: data.notes
                    },
                  },
              },
            },
          };

    const CONFIRM_CM_OPTIN_ITEM = UI_SCHEMA.CONFIRM_CM_OPTIN_ITEM;

    const payloadDataSchema =
      workflowInstanceDataSchema && Object.keys(workflowInstanceDataSchema).length > 0
        ? _.merge(workflowInstanceDataSchema, {
            type: 'object',
            properties: {
              CONFIRM_CM_OPTIN_ITEM,
            },
          })
        : {
            type: 'object',
            properties: {
              CONFIRM_CM_OPTIN_ITEM,
            },
          };

    const payloadForUpdateWorkflowinstance = {
      referenceId: patientEngagement?.patientEngagementId,
      referenceName: REFERENCE_NAME,
      data: JSON.stringify(payloadData),
      dataSchema: JSON.stringify(payloadDataSchema),
    };

    const setCondition = JSON.parse(checklistData.conditions);
    setCondition.data = { confirm: true, message: "CM Opt-Out Confirmed" };
    const conditions = JSON.stringify(setCondition);

    const payloadForSetItemCompleted = {
      item: { ...checklistData, conditions },
      record: {
        ...patientEngagement,
        ...workflowInstance,
      },
    };

    try {
      await agent.Checklist.markItemCompleted(payloadForSetItemCompleted);
      await agent.Workflow.updateWorkflowInstance(payloadForUpdateWorkflowinstance);
    } catch (err) {
      console.log('error', err);
    } finally {
      setExternalRefreshWorkFlow(true);
      getPatientEngagementDetails(patientEngagementId);
    }

    setExternalRefreshWorkFlow(false);
  };

  const handlerComplementaryInfo = async (store: any) => {

    const payloadForUpdateWorkflowinstance = {
      referenceId: workflowInstance?.referenceId,
      referenceName: workflowInstance?.referenceName,
      data: JSON.stringify({
        scope: {
          checklistItem: {
            ...store,
          },
        },
      }),
    };

    try {
      await agent.Workflow.updateWorkflowInstance(payloadForUpdateWorkflowinstance);
    } catch (err) {
      console.log('error on handleComplementaryInfo', err);
    } finally {
      setExternalRefreshWorkFlow(true);
    }

    setExternalRefreshWorkFlow(false);
  };

  const defaultStructure = useMemo(() => {

    const checklistItem = getParseColumn(workflowInstance, 'data')?.scope?.checklistItem;

    return {
      ...(checklistItem?.CONFIRM_PATIENT_INFO_ITEM && {
        CONFIRM_PATIENT_INFO_ITEM: {
            ...(checklistItem?.CONFIRM_PATIENT_INFO_ITEM?.validatedOn && 
              { validatedOn: 
                moment.utc(checklistItem?.CONFIRM_PATIENT_INFO_ITEM?.validatedOn).
                  format('MM/DD/YYYY HH:mm:ss') }),
          }
        }),
      ...(checklistItem?.CONFIRM_NAT_OPTIN_ITEM && {
        CONFIRM_NAT_OPTIN_ITEM: {
            ...(checklistItem?.CONFIRM_NAT_OPTIN_ITEM?.validatedOn && 
              { validatedOn: 
                moment.utc(checklistItem?.CONFIRM_NAT_OPTIN_ITEM?.validatedOn).
                  format('MM/DD/YYYY HH:mm:ss') }),
          }
        }),
      ...(checklistItem?.CONFIRM_GUARANTOR_INFO_ITEM && {
        CONFIRM_GUARANTOR_INFO_ITEM: {
            ...(checklistItem?.CONFIRM_GUARANTOR_INFO_ITEM?.validatedOn && 
              { validatedOn: 
                moment.utc(checklistItem?.CONFIRM_GUARANTOR_INFO_ITEM?.validatedOn).
                  format('MM/DD/YYYY HH:mm:ss') }),
          }
        }),
      ...(checklistItem?.SCHEDULE_NAT_SESSION_ITEM && {
        SCHEDULE_NAT_SESSION_ITEM: {
            ...(checklistItem?.SCHEDULE_NAT_SESSION_ITEM?.validatedOn && 
              { validatedOn: 
                moment.utc(checklistItem?.SCHEDULE_NAT_SESSION_ITEM?.validatedOn).
                  format('MM/DD/YYYY HH:mm:ss') }),
            modifiedFields:{
              ...(checklistItem?.SCHEDULE_NAT_SESSION_ITEM?.modifiedFields?.date_hour && 
                { date_hour: 
                  moment.utc(checklistItem?.SCHEDULE_NAT_SESSION_ITEM?.modifiedFields?.date_hour).
                    format('MM/DD/YYYY HH:mm:ss') }),
            }
          }
        }),
      ...(checklistItem?.INITIATE_NAT_REQUEST_ITEM && {
        INITIATE_NAT_REQUEST_ITEM: {
            ...(checklistItem?.INITIATE_NAT_REQUEST_ITEM?.validatedOn && 
              { validatedOn: 
                moment.utc(checklistItem?.INITIATE_NAT_REQUEST_ITEM?.validatedOn).
                  format('MM/DD/YYYY HH:mm:ss') }),
          }
        }),
      ...(checklistItem?.REVIEW_NAT_REPORT_ITEM && {
        REVIEW_NAT_REPORT_ITEM: {
            ...(checklistItem?.REVIEW_NAT_REPORT_ITEM?.validatedOn && 
              { validatedOn: 
                moment.utc(checklistItem?.REVIEW_NAT_REPORT_ITEM?.validatedOn).
                  format('MM/DD/YYYY HH:mm:ss') }),
          }
        }),
      ...(checklistItem?.CONFIRM_CM_OPTIN_ITEM && {
        CONFIRM_CM_OPTIN_ITEM: {
            ...(checklistItem?.CONFIRM_CM_OPTIN_ITEM?.validatedOn && 
              { validatedOn: 
                moment.utc(checklistItem?.CONFIRM_CM_OPTIN_ITEM?.validatedOn).
                  format('MM/DD/YYYY HH:mm:ss') }),
          }
        }),
    }
  },[workflowInstance]);

  const patientEngagementPermissions: PatientEngagementPermissions = useMemo(
    () => ({
      isAllowedToEdit: checkAclValidation({ acls: [crsAcls.ENGAGEMENT_HUB.PATIENT_ENGAGEMENT.EDIT] }),
      notes: {
        isAllowedToAdd: checkAclValidation({ acls: [crsAcls.ENGAGEMENT_HUB.PATIENT_ENGAGEMENT.NOTES.ADD] }),
        isAllowedToView: checkAclValidation({ acls: [crsAcls.ENGAGEMENT_HUB.PATIENT_ENGAGEMENT.VIEW] }),
      },
    }),
    []
  );

  const workflowPermissions: WorkflowPermissions = useMemo(
    () => ({
      isAllowedToAdd: checkAclValidation({ acls: [crsAcls.WORKFLOW.ADD] }),
      isAllowedToEdit: checkAclValidation({ acls: [crsAcls.WORKFLOW.EDIT] }),
      isAllowedToNext: checkAclValidation({ acls: [crsAcls.WORKFLOW.NEXT] }),
      isAllowedToClose:checkAclValidation({ acls: [crsAcls.WORKFLOW.CLOSE] }) ,
    }),
    []
  );

  const permissions: any = { ...patientEngagementPermissions, workflow: {...workflowPermissions}};

  return (
    <Page title={i18n('patientEngagement.details.title', 'engagement')}>
      <Container maxWidth={themeStretch ? false : 'xl'}>
        <PermissionsProvider permissions={permissions}>
          <HeaderBreadcrumbs
            title={i18n('patientEngagement.details.title', 'engagement')}
            heading=""
            links={[
              { name: `${i18n('admin.list.dashboard')}`, href: PATH_DASHBOARD.root },
              { name: `${i18n('title', 'engagement')}` },
              { name: `${i18n('patientEngagement.title', 'engagement')}`, href: PATH_DASHBOARD.engagementHub.patientEngagement },
              { name: `${i18n('patientEngagement.details.title', 'engagement')}` },
            ]}
          />
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <Workflow
                data={engagementWorkflowData}
                refreshRecord={fetchStageFromWorkflow}
                initialWorkflow={'INITIAL_PATIENT_ENGAGEMENT'}
                workflowHandler={WorkflowHandler}
                workflowData={WorkflowData}
                componentMapping={WorkflowComponentMapping}
                refreshWorkFlowExternal={externalRefreshWorkFlow}
                i18Path='patientEngagement.details.workflow'
                namespace='engagement'
              />
            </Grid>

            <Grid item xs={12} md={6} lg={6}>
              <ExpandableCard 
                title={`${i18n('patients.title', 'crs')} Engagement`}
              >
                <Box sx={{ px: 3, pb: 2 }}>
                  <KeyValueGrid label="Status" value={patientEngagement?.workflowStatus || ''} />
                  <KeyValueGrid
                    label="Sub Status"
                    value={patientEngagement?.workflowStageName || ''}
                  />
                  <KeyValueGrid
                    label="Language"
                    value={patientEngagement?.patientLanguage || ''}
                  />

                  <Grid container item spacing={1} wrap="nowrap">
                    <Grid
                      container
                      item
                      xs={4}
                      sm={4}
                      md={4}
                      lg={4}
                      xl={4}
                      justifyContent="flex-end"
                    >
                      <Typography variant="body2" align="right">
                        Assigned To
                      </Typography>
                    </Grid>
                    <Grid
                      container
                      item
                      xs={8}
                      sm={8}
                      md={8}
                      lg={8}
                      xl={8}
                      zeroMinWidth
                      sx={{ alignItems: "center" }}
                    >
                      <Tooltip
                        title={
                          patientEngagement?.workflowOwnedBy
                            ? capitalCase(patientEngagement?.workflowOwnedBy?.split('@')[0].replace('.', ' '))
                            : ""
                        }
                      >
                        <Typography variant="body2" noWrap sx={{ color: 'text.secondary' }}>
                          {patientEngagement?.workflowOwnedBy
                            ? capitalCase(patientEngagement?.workflowOwnedBy?.split('@')[0].replace('.', ' '))
                            : ""}
                        </Typography>
                      </Tooltip>
                      {patientEngagementPermissions.isAllowedToEdit && (
                        <Tooltip title="Edit Assigned To" placement="bottom">
                          <EditIcon
                            className="font-small margin-xs-right-btn text-primary div-hand"
                            sx={{
                              color: "#1890FF",
                              cursor: "pointer",
                              marginLeft: "8px",
                              fontSize: "1.2rem !important",
                            }}
                            onClick={() => setIsOpenReAssign(true)}
                          />
                        </Tooltip>
                      )}
                    </Grid>
                  </Grid>
                  
                  <KeyValueGrid 
                    label="Opt In Status" 
                    value={consent?.[0]?.getOptInStatus() ? consent?.[0]?.getOptInStatus() : 'Not available'}
                  />
                  <Grid
                    container
                    item
                    justifyContent="center"
                    alignItems="center"
                  >
                    <KeyValueGrid
                      label="Interview Status"
                      value={capitalCase(tasks?.slice(-1)?.map((item) => item?.status)?.toString())}
                    />
                  </Grid>
                </Box>
              </ExpandableCard>
            </Grid>

            <Grid item xs={12} md={6} lg={6}>
              <ExpandableCard 
                title={i18n('patients.title', 'crs')}
              >
                <Box sx={{ px: 3, pb: 2 }}>
                  <KeyValueGrid
                    label="Given Name"
                    value={patientEngagement?.patientGiven || ''}
                  />
                  <KeyValueGrid
                    label="Family Name"
                    value={patientEngagement?.patientFamily || ''}
                  />
                  <KeyValueGrid
                    label="DOB"
                    value={patientEngagement?.patientDOB && dateYearFormatWithAge(patientEngagement?.patientDOB) || ''}
                  />
                  {!_.isEmpty(patientEngagement?.patientLastSIL) && (
                    <KeyValueGrid
                      label="SIL"
                      value={patientEngagement?.patientLastSIL ? setSILDisplay(patientEngagement?.patientLastSIL) : ""}
                    />
                  )}
                </Box>
              </ExpandableCard>
            </Grid>
            
            <Grid item xs={12} md={6} lg={6}>
              <ExpandableCard 
                title="Complementary Info"
              >
                <ComplementaryInfo 
                  dataSchema={getParseColumn(workflowInstance, 'dataSchema')}
                  data={getParseColumn(workflowInstance, 'data')}
                  defaultStructure={defaultStructure}
                  handleComplementaryInfo={handlerComplementaryInfo}
                />
              </ExpandableCard>
            </Grid>

            <Grid item xs={12}>
              <ExpandableCard title="Contact Attempts">
                <TableCustom
                  data={Array.isArray(contactAttempts) && mapContactAttempts(contactAttempts)}
                  tableHead={TABLE_HEAD_CONTACT}
                />
              </ExpandableCard>
            </Grid>

            <Grid item xs={12} >
              <ExpandableCard  title="Notes">
                {patientEngagementId && (
                  <NotesList patientEngagementId={patientEngagementId} />
                )}
              </ExpandableCard>
            </Grid>
            
            <ConfirmMemberInfo
              isOpen={confirmModalOpen}
              handlerIsOpen={setConfirmModalOpen}
              patient={patient}
              handlePatient={updatePatient}
              handleConfirmMember={handlerConfirmMember}
              refreshPatient={refreshPatient}
            />

            <ConfirmGuarantorInfo
              patient={patient}
              isOpen={confirmGuarantorModalOpen}
              handlerIsOpen={setConfirmGuarantorModalOpen}
              relatedPerson={relatedPersons?.length ? relatedPersons[0] : null}
              handleRelatedPerson={handlerConfirmGuarantor}
            />

            <ConfirmNatOptIn
              isOpen={confirmNatOptInModalOpen}
              handlerIsOpen={setConfirmNatOptInModalOpen}
              handlerConfirmNATOptIn={handlerConfirmNATOptIn}
            />

            <ScheduleNatSession
              isOpen={scheduleNatSessionModalOpen}
              handlerIsOpen={setScheduleNatSessionModalOpen}
              handlerSchedulHJSession={handlerConfirmScheduleHJSession}
            />

            <ConfirmContact
              patient={patient}
              patientEngagementId={patientEngagementId || ''}
              isOpen={confirmContactModalOpen}
              handlerIsOpen={setConfirmContactModalOpen}
              handlePatient={updatePatient}
              refreshPatient={refreshPatient}
              handleConfirmContactAttempt={handlerConfirmContactAttempt}
            />
              
            <CreateTask
              isOpen={createTask}
              patient={patient}
              relatedPersons={relatedPersons}
              handlerIsOpen={setCreateTask}
              handleInitiateHJRequest={handlerInitiateHJRequest}
            />

            <TasksList
              patient={patient}
              tasks={tasks}
              refreshTasks={refreshTask}
              isOpen={showTasksList}
              handlerIsOpen={setShowTasksList}
              handleCompleteHJRequest={handlerCompleteHJRequest}
            />

            <ReviewNatReportItem
              patients={patient}
              isOpen={reviewNatReportItem}
              handlerIsOpen={setReviewNatReportItem}
              handleReviewHJRequest={handlerReviewHJRequest}
            />

            <ConfirmCMOptInItem
              isOpen={confirmOptInItem}
              handlerIsOpen={setConfirmOptInItem}
              handlerConfirmCMOptIN={handlerConfirmCMOptIN}
            />
          </Grid>

          <Dialog
            open={isOpenReAssign}
            onClose={() => setIsOpenReAssign(false)}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
            fullWidth
            maxWidth="md"
            PaperProps={{
              sx: {
                minHeight: "400px",
              },
            }}
          >
            <DialogTitle id="alert-dialog-title">Re-Assign To</DialogTitle>
            <DialogContent>
              <SelectType
                others={{
                  data: allUsers,
                  patient: patientEngagement,
                  handler: handleReAssingOwned,
                  checklistItem: {},
                }}
              />
            </DialogContent>
          </Dialog>
        </PermissionsProvider>
      </Container>
    </Page>
  );
}
