import { 
  PH_META_TAG_CODE_SYSTEM_URL, 
  PH_META_TAG_CREATED_BY_CODE, 
  PH_META_TAG_CREATED_ON_CODE, 
  PH_META_TAG_PROVIDER_SOURCE_CODE, 
  PRACTITIONER_PROVIDER_DIR_LINK_IDENTIFIER_SYSTEM, 
  PRACTITIONER_PROVIDER_DIR_LINK_IDENTIFIER_TYPE_SYSTEM, 
  PROVIDER_CLASSIFICATION_CODING_SYSTEM, 
  PROVIDER_CLASSIFICATION_IDENTIFIER_CODING_CODE, 
  PROVIDER_DIRECTORY_BASE_URL, 
  PROVIDER_LICENSE_IDENTIFIER_CODING_CODE, 
  PROVIDER_LICENSE_VERIFICATION_IDENTIFIER_CODING_SYSTEM, 
  PROVIDER_LICENSE_VERIFICATION_IDENTIFIER_SYSTEM 
} from "src/config";
import moment from "moment";
import { cloneDeep } from "lodash";
import { 
  Address, 
  AssignableGeneralPractitioner, 
  DirectoryProvider, 
  Gender, 
  License, 
  LinkedPractitioner, 
  LinkedPractitionerRole, 
  ProviderCodeMapping, 
  Specialty, 
  Telecom, 
  pcpHealthStoryQuestionIds
} from "src/@types/crs/providerDirectory";
import { incidentService } from "src/crs/incident";
import { ProviderSearchFields } from "./new/NewProvider";
import { IncidentResponse } from "src/crs/incident/IncidentService";
import { Practitioner } from "src/@nicheaim/fhir-base/mappings/Practitioner";
import { WrappedPractitioner } from "src/@nicheaim/fhir-base/wrappers/Practitioner";
import { 
  Address as AddressFHIR, 
  Bundle, 
  Coding, 
  ContactPoint, 
  Identifier, 
  PractitionerQualification, 
  QuestionnaireResponse, 
  QuestionnaireResponseItemAnswer 
} from "src/nicheaim-infrastructure/application/adapters/out/repositories/fhir/resources";
import { formatUSAddress, getValidProviderDirectoryLinkIdentifiers } from "src/utils/fhir";
import axiosFhirInstance from "src/application/adapters/out/repositories/axiosFhirInstance";
import { QuestionnaireResponseAnswer, QuestionnaireResponseAnswerMapping } from "src/@types/nat/report";
import { LinkedProvider, LinkedResource, PCPAssignmentScopeData, PCPHealthStoryResponse } from "src/@types/crs/incident";
import { IDENTIFIER_CODE_SYSTEM_MAPPING, NPI_IDENTIFIER_SYSTEM, PROVIDER_LINK_IDENTIFIER, validTelecomSystems } from "../constants";

export interface MapProviderLicenseToFhirIdentifierParams {
  license: License;
  providerUUID: string;
  licenseCodeMapping: ProviderCodeMapping;
  fhirIdentifierTypes: Coding[];
}

export const mapProviderLicenseToFhirIdentifier = ({
  providerUUID,
  license: { uuid, period_end, period_start, value, name, code },
  licenseCodeMapping,
  fhirIdentifierTypes,
}: MapProviderLicenseToFhirIdentifierParams): Identifier[] => {
  let licenseCode = licenseCodeMapping.concepts.find(
    ({ provider }) => provider === code
  )?.fhir;

  if (!licenseCode) licenseCode = licenseCodeMapping.default;

  const licenseDisplay =
    fhirIdentifierTypes.find(({ code }) => code === licenseCode)?.display ?? "";

  return [
    {
      system: `${PRACTITIONER_PROVIDER_DIR_LINK_IDENTIFIER_SYSTEM}/${providerUUID}/license`,
      type: {
        coding: [
          {
            code: PROVIDER_LICENSE_IDENTIFIER_CODING_CODE,
            display: "PatientHolistics Providers Directory License",
            system: PRACTITIONER_PROVIDER_DIR_LINK_IDENTIFIER_TYPE_SYSTEM,
          },
        ],
        text: "PatientHolistics Providers Directory License",
      },
      value: uuid,
    },
    {
      system: PROVIDER_LICENSE_VERIFICATION_IDENTIFIER_SYSTEM,
      period: getPeriodFromValues(period_start, period_end),
      type: {
        coding: [
          {
            code: licenseCode,
            display: licenseDisplay,
            system: PROVIDER_LICENSE_VERIFICATION_IDENTIFIER_CODING_SYSTEM,
          },
        ],
        text: name,
      },
      value: value,
    },
  ];
};

export interface MapProviderLicenseToFhirQualificationParams {
  license: License;
  providerUUID: string;
  relatedClassification: Specialty;
  licenseCodeMapping: ProviderCodeMapping;
  fhirIdentifierTypes: Coding[];
}

export const mapProviderLicenseToFhirQualification = ({
  license,
  providerUUID,
  relatedClassification: { code, name },
  licenseCodeMapping,
  fhirIdentifierTypes,
}: MapProviderLicenseToFhirQualificationParams): PractitionerQualification => ({
  code: {
    coding: [
      {
        code,
        display: name,
        system: PROVIDER_CLASSIFICATION_CODING_SYSTEM,
      },
    ],
  },
  identifier: mapProviderLicenseToFhirIdentifier({
    providerUUID,
    license,
    licenseCodeMapping,
    fhirIdentifierTypes,
  }),
});

export interface MapProviderLicenseToFhirParams {
  license: License;
  providerUUID: string;
  classifications: Specialty[];
  licenseCodeMapping: ProviderCodeMapping;
  fhirIdentifierTypes: Coding[];
}

export const mapProviderLicenseToFhir = ({
  providerUUID,
  license,
  classifications,
  licenseCodeMapping,
  fhirIdentifierTypes,
}: MapProviderLicenseToFhirParams): {
  identifier?: Identifier[];
  qualification?: PractitionerQualification;
} => {
  const { taxonomy_ref } = license;
  const taxonomyId = taxonomy_ref?.split?.("/")?.[1];
  const relatedClassification = classifications.find(
    ({ uuid }) => uuid === taxonomyId
  );
  if (!relatedClassification)
    return {
      identifier: mapProviderLicenseToFhirIdentifier({
        providerUUID,
        license,
        licenseCodeMapping,
        fhirIdentifierTypes,
      }),
    };

  return {
    qualification: mapProviderLicenseToFhirQualification({
      providerUUID,
      license,
      licenseCodeMapping,
      fhirIdentifierTypes,
      relatedClassification,
    }),
  };
};

export const mapProviderClassificationToFhir = (
  providerUUID: string,
  { code, name, uuid }: Specialty
): PractitionerQualification => ({
  code: {
    coding: [
      {
        code,
        display: name,
        system: PROVIDER_CLASSIFICATION_CODING_SYSTEM,
      },
    ],
  },
  identifier: [
    {
      system: `${PRACTITIONER_PROVIDER_DIR_LINK_IDENTIFIER_SYSTEM}/${providerUUID}/taxonomy`,
      type: {
        coding: [
          {
            code: PROVIDER_CLASSIFICATION_IDENTIFIER_CODING_CODE,
            display: "PatientHolistics Providers Directory Taxonomy",
            system: PRACTITIONER_PROVIDER_DIR_LINK_IDENTIFIER_TYPE_SYSTEM,
          },
        ],
        text: "PatientHolistics Providers Directory Taxonomy",
      },
      value: uuid,
    },
  ],
});

export interface MapProviderDirectoryToFhirPractitionerParams {
  provider: DirectoryProvider;
  licenseCodeMapping: ProviderCodeMapping;
  fhirIdentifierTypes: Coding[];
  userName?: string | null;
}

export const mapProviderDirectoryToFhirPractitioner = ({
  provider,
  licenseCodeMapping,
  fhirIdentifierTypes,
  userName,
}: MapProviderDirectoryToFhirPractitionerParams): Practitioner => {
  const birthDate = moment(provider?.dob ?? null);
  const primaryTelecom = provider?.telecom?.find?.(
    ({ is_preferred, value }) => !!is_preferred && !!value
  );
  const primaryAddress = provider?.address?.find?.(
    ({ is_preferred }) => !!is_preferred
  );

  let licensesAsIdentifiers: Identifier[] = [];
  let licensesAsQualifications: PractitionerQualification[] = [];

  const classifications = [
    ...(provider?.classification ? provider?.classification : []),
    ...(provider?.specialization ? provider?.specialization : []),
  ];

  provider?.license?.map?.((license) => {
    const { identifier, qualification } = mapProviderLicenseToFhir({
      classifications,
      fhirIdentifierTypes,
      licenseCodeMapping,
      license,
      providerUUID: provider.uuid,
    });
    if (identifier?.length)
      licensesAsIdentifiers = [...licensesAsIdentifiers, ...identifier];

    if (qualification)
      licensesAsQualifications = [...licensesAsQualifications, qualification];
  });

  const classificationsAsQualifications = classifications.map(
    (classification) =>
      mapProviderClassificationToFhir(provider.uuid, classification)
  );

  const practitioner: Practitioner = {
    resourceType: "Practitioner",
    birthDate: birthDate.isValid() ? birthDate.format("YYYY-MM-DD") : undefined,
    active: true,
    gender: getGenderFromProvider(provider?.gender) as any,
    name: [
      {
        given: provider?.first_name?.trim?.()
          ? [
              provider?.first_name,
              ...(provider?.middle_name ? [provider?.middle_name] : []),
            ]
          : undefined,
        family: provider?.last_name,
        prefix: provider?.name_prefix?.trim()
          ? [provider.name_prefix]
          : undefined,
        suffix: provider?.name_suffix?.trim()
          ? [provider.name_suffix]
          : undefined,
      },
    ],
    identifier: [
      {
        period: {
          start: moment().toISOString(),
        },
        value: provider?.uuid,
        ...PROVIDER_LINK_IDENTIFIER,
      },
      ...(provider?.identifier
        ?.map?.(({ code, value, period_end, period_start }) => ({
          system:
            IDENTIFIER_CODE_SYSTEM_MAPPING.find(
              ({ code: codeMapping }) => codeMapping === code
            )?.system ?? undefined,
          value,
          period: getPeriodFromValues(period_start, period_end),
        }))
        ?.filter?.(({ system }) => !!system) ?? []),
      ...licensesAsIdentifiers,
    ],
    telecom: provider?.telecom?.length
      ? [
          ...(primaryTelecom ? [mapProviderTelecomToFhir(primaryTelecom)] : []),
          ...[
            ...provider.telecom
              .filter(
                (telecom) => telecom !== primaryTelecom && !!telecom.value
              )
              .map((telecom) => mapProviderTelecomToFhir(telecom)),
          ],
        ]
      : undefined,
    address: provider?.address?.length
      ? [
          ...(primaryAddress ? [mapProviderAddressToFhir(primaryAddress)] : []),
          ...[
            ...provider.address
              .filter((address) => address !== primaryAddress)
              .map((address) => mapProviderAddressToFhir(address)),
          ],
        ]
      : undefined,
    qualification:
      licensesAsQualifications.length || classificationsAsQualifications.length
        ? [...licensesAsQualifications, ...classificationsAsQualifications]
        : undefined,
    meta: {
      tag: [
        ...getCreatedMetaTagCodings(userName),
        getProviderSourceMetaTagCoding(provider?.uuid as string),
      ],
    },
  };
  return practitioner;
};

export const mapProviderSearchFieldsToFhirPractitioner = (
  {
    first_name,
    last_name,
    npi,
    address,
    city,
    phone_number,
  }: ProviderSearchFields,
  userName: string | undefined | null
): Practitioner => ({
  active: true,
  resourceType: "Practitioner",
  name: [
    {
      given: [first_name ?? ""],
      family: last_name ?? "",
    },
  ],
  identifier: npi?.trim()
    ? [
        {
          system: NPI_IDENTIFIER_SYSTEM,
          value: npi,
        },
      ]
    : undefined,
  address:
    address?.trim() || city?.trim()
      ? [
          {
            line: address?.trim?.() ? [address?.trim()] : undefined,
            city: city?.trim() ?? undefined,
            period: {
              start: moment().startOf("day").format("YYYY-MM-DD"),
            },
          },
        ]
      : undefined,
  telecom: phone_number?.trim()
    ? [
        {
          system: "phone",
          value: phone_number?.trim(),
          period: {
            start: moment().startOf("day").format("YYYY-MM-DD"),
          },
        },
      ]
    : undefined,
  meta: {
    tag: [...getCreatedMetaTagCodings(userName)],
  },
});

export const verifyPractitionerProviderLink = (
  practitioner: Practitioner,
  providerDirectoryId: string
): boolean => {
  const providerLinkIdentifiers = getValidProviderDirectoryLinkIdentifiers(
    practitioner?.identifier ?? []
  );

  return !!providerLinkIdentifiers.find(
      ({ value }) => value === providerDirectoryId
  );
};

export const getPractitionersLinkedToProvider = async (
  providerId: string
): Promise<Practitioner[] | never> => {
  const practitionersResponse =  ((await axiosFhirInstance.get(
    `Practitioner?identifier=${providerId}&active=true`,
  ))?.data as Bundle<Practitioner>)?.
  entry?.map?.(({ resource }) => resource!) ?? []; 

  if (!practitionersResponse) throw new Error("Unable to get Practitioner");
  const practitioners = practitionersResponse?.filter?.((practitioner) =>
    verifyPractitionerProviderLink(practitioner, providerId)
  );

  return practitioners;
};

export const pcpHealthStoryQuestionsMapping: QuestionnaireResponseAnswerMapping[] = [
  {
    questionId: pcpHealthStoryQuestionIds.HAS_PCP,
    label: "Has PCP",
    valueFieldName: "valueBoolean",
  },
  {
    questionId: pcpHealthStoryQuestionIds.LAST_NAME,
    label: "Last Name",
    valueFieldName: "valueString",
  },
  {
    questionId: pcpHealthStoryQuestionIds.FIRST_NAME,
    label: "First Name",
    valueFieldName: "valueString",
  },
  {
    questionId: pcpHealthStoryQuestionIds.PRACTICE.NAME,
    label: "Practice Name",
    valueFieldName: "valueString",
  },
  {
    questionId: pcpHealthStoryQuestionIds.CITY,
    label: "City",
    valueFieldName: "valueString",
  },
  {
    questionId: pcpHealthStoryQuestionIds.ADDRESS,
    label: "Address",
    valueFieldName: "valueString",
  },
  {
    questionId: pcpHealthStoryQuestionIds.PHONE,
    label: "Phone",
    valueFieldName: "valueString",
  },
];

export const getPCPHealthStoryAnswers = (
  questionnaireResponse: QuestionnaireResponse | null | undefined
): QuestionnaireResponseAnswer[] => {
  const answers: QuestionnaireResponseAnswer[] = [];
  pcpHealthStoryQuestionsMapping.map(
    ({ questionId, label, valueFieldName }) => {
      let value: any = null;
      for (const { linkId, item, answer } of questionnaireResponse?.item ??
        []) {
        if (linkId === questionId) {
          value =
            answer?.[0]?.[
              valueFieldName as keyof QuestionnaireResponseItemAnswer
            ] ?? null;
          break;
        }
        if (!item) continue;
        value =
          item?.find?.(({ linkId }) => linkId === questionId)?.answer?.[0]?.[
            valueFieldName as keyof QuestionnaireResponseItemAnswer
          ] ?? null;
      }

      if (value === null) return;
      answers.push({ questionId, label, value, valueFieldName });
    }
  );
  return answers;
};

export const getQuestionnaireResponseAnswer = (
  questionId: string,
  answers: QuestionnaireResponseAnswer[]
): QuestionnaireResponseAnswer | null =>
  answers.find((answer) => answer.questionId === questionId) ?? null;

export const getPCPHealthStoryResponses = (
  healthStoryAnswers: QuestionnaireResponseAnswer[]
): PCPHealthStoryResponse => {
  const lastName = getQuestionnaireResponseAnswer(
    pcpHealthStoryQuestionIds.LAST_NAME,
    healthStoryAnswers
  )?.value;
  const firstName = getQuestionnaireResponseAnswer(
    pcpHealthStoryQuestionIds.FIRST_NAME,
    healthStoryAnswers
  )?.value;
  const practiceName = getQuestionnaireResponseAnswer(
    pcpHealthStoryQuestionIds.PRACTICE.NAME,
    healthStoryAnswers
  )?.value;
  const address = getQuestionnaireResponseAnswer(
    pcpHealthStoryQuestionIds.ADDRESS,
    healthStoryAnswers
  )?.value;
  const city = getQuestionnaireResponseAnswer(
    pcpHealthStoryQuestionIds.CITY,
    healthStoryAnswers
  )?.value;
  const phone = getQuestionnaireResponseAnswer(
    pcpHealthStoryQuestionIds.PHONE,
    healthStoryAnswers
  )?.value;

  return {
    lastName,
    firstName,
    practiceName,
    address,
    city,
    phone,
  };
};

export const setPCPIncidentScopeData = <T extends LinkedResource>(
  linkResourceFieldName:
    | "linkedProviderId"
    | "linkedPractitionerRoleId"
    | "linkedPractitionerId",
  linkedResourcesFieldName:
    | "linkedProviders"
    | "linkedPractitionerRoles"
    | "linkedPractitioners",
  data: T,
  incidentScopeData: IncidentResponse["scope_data"],
  user: any
) => {
  try {
    const scopeData = cloneDeep({
      ...((incidentScopeData ?? {}) as PCPAssignmentScopeData),
    });

    if (!scopeData?.[linkedResourcesFieldName])
      scopeData[linkedResourcesFieldName] = [];
    let isNew = false;

    const linkedResources = scopeData[linkedResourcesFieldName] as T[];
    let linkedResource = linkedResources.find(({ id }) => id === data.id);

    const now = moment().toISOString();
    if (!linkedResource) {
      isNew = true;
      linkedResource = {
        meta: {
          createdOn: now,
          createdBy: user?.userName,
        },
      } as T;
    }
    linkedResource.meta = {
      ...(linkedResource.meta ?? {}),
      linkedBy: user?.userName,
      linkedOn: now,
    };

    Object.entries(data).map(([key, value]) => {
      linkedResource![key as keyof T] = value;
    });
    if (isNew) linkedResources.push(linkedResource);
    scopeData[linkResourceFieldName] = data.id;
    return scopeData;
  } catch (error) {
    return null;
  }
};

export const getNewLinkedPractitionerRolePayload = (
  incident: IncidentResponse | null,
  practitionerRole: AssignableGeneralPractitioner,
  user: any
): IncidentResponse | null => {
  if (!incident) return null;
  const practitioner = practitionerRole.practitioner;
  const practitionerFhir = practitioner?.resource as Practitioner;
  const organization = practitionerRole.organization;
  const linkedPractitionerRoleNewData: LinkedPractitionerRole = {
    id: practitionerRole.resourceId,
    role: {
      code: practitionerRole.roleCode,
      display: practitionerRole.role,
    },
    practitioner: {
      id: practitioner?.id,
      npi: practitioner?.npi,
      firstName: practitionerFhir?.name?.[0]?.given?.[0],
      lastName: practitionerFhir?.name?.[0]?.family,
      email: practitioner?.email,
      phone: practitioner?.phone,
      address: practitioner?.address
        ? { ...(practitioner?.address as AddressFHIR) }
        : null,
    },
    organization: organization
      ? {
          id: organization?.id,
          npi: organization?.npi,
          name: organization?.fullName,
          email: organization?.email,
          phone: organization?.phone,
          address: organization?.address
            ? { ...(organization?.address as Address) }
            : null,
        }
      : null,
  };
  const newScopeData = setPCPIncidentScopeData(
    "linkedPractitionerRoleId",
    "linkedPractitionerRoles",
    linkedPractitionerRoleNewData,
    incident?.scope_data,
    user
  );
  if (!newScopeData) return null;
  return { ...incident, scope_data: newScopeData };
};

export const getNewLinkedProviderPayload = (
  incident: IncidentResponse | null,
  provider: DirectoryProvider,
  user: any
): IncidentResponse | null => {
  if (!incident) return null;
  const address = getProviderPrimaryAddress(provider?.address ?? []);
  const linkedProviderNewData: LinkedProvider = {
    id: provider?.uuid,
    firstName: provider.first_name ?? null,
    lastName: provider.last_name ?? null,
    email:
      getProviderPrimaryTelecom(provider?.telecom ?? [], "email")?.value ??
      null,
    phone:
      getProviderPrimaryTelecom(provider?.telecom ?? [], "phone")?.value ??
      null,
    npi: provider?.npi,
    address: address ? mapProviderAddress(address) : null,
  };

  let newScopeData = setPCPIncidentScopeData(
    "linkedProviderId",
    "linkedProviders",
    linkedProviderNewData,
    incident?.scope_data,
    user
  );
  if (!newScopeData) return null;
  newScopeData = {
    ...newScopeData,
    linkedPractitionerId: null,
  } as PCPAssignmentScopeData;
  return { ...incident, scope_data: newScopeData };
};

export const getNewLinkedPractitionerPayload = (
  incident: IncidentResponse | null,
  practitioner: WrappedPractitioner,
  user: any
): IncidentResponse | null => {
  if (!incident) return null;
  const linkedPractitionerNewData: LinkedPractitioner = {
    id: practitioner.id,
    firstName: practitioner.getFirstName() ?? null,
    lastName: practitioner.getLastName() ?? null,
    email: practitioner.getEmails()?.[0]?.value ?? null,
    phone: practitioner.getPhones()?.[0]?.value ?? null,
    npi: practitioner.getNPI() ?? null,
    address: (practitioner?.address?.[0] as AddressFHIR) ?? null,
  };

  let newScopeData = setPCPIncidentScopeData(
    "linkedPractitionerId",
    "linkedPractitioners",
    linkedPractitionerNewData,
    incident?.scope_data,
    user
  );
  if (!newScopeData) return null;
  newScopeData = {
    ...newScopeData,
    linkedProviderId: null,
  } as PCPAssignmentScopeData;
  return { ...incident, scope_data: newScopeData };
};

export const getProviderPrimaryTelecom = (
  telecoms: Telecom[],
  telecomType: string
): Telecom | undefined =>
  telecoms.find(
    ({ is_preferred, type }) => !!(is_preferred && type === telecomType)
  ) ?? telecoms.find(({ type, value }) => type === telecomType && !!value);

export const getProviderPrimaryAddress = (
  addresses: Address[]
): Address | undefined =>
  addresses.find(({ is_preferred }) => !!is_preferred) ?? addresses?.[0];

export const formatProviderAddress = (address: Address | undefined | null) =>
  formatUSAddress(mapProviderAddress(address));

export const mapProviderAddress = (
  address: Address | undefined | null
): AddressFHIR => ({
  city: address?.city ?? "",
  line: [
    ...(address?.line_1 ? [address?.line_1] : []),
    ...(address?.line_2 ? [address?.line_2] : []),
  ],
  state: address?.state ?? "",
  postalCode: address?.postal_code ?? "",
  country: address?.state ? "US" : "",
});

/* eslint-disable */
export const updateLinkedProvider = async (
  incident: IncidentResponse,
  provider: DirectoryProvider,
  user: any
): Promise<IncidentResponse | null> => {
  const incidentPayload = getNewLinkedProviderPayload(incident, provider, user);
  return await incidentService.updateIncident(incidentPayload?.id ?? null, incidentPayload);
};

export const updateLinkedPractitionerRole = async (
  incident: IncidentResponse,
  practitionerRole: AssignableGeneralPractitioner,
  user: any
): Promise<IncidentResponse | null> => {
  const incidentPayload = getNewLinkedPractitionerRolePayload(
    incident,
    practitionerRole,
    user
  );
  return await incidentService.updateIncident(incidentPayload?.id ?? null, incidentPayload);
};

export const getGenderFromProvider = (
  gender: DirectoryProvider["gender"] | undefined
): Gender => {
  switch (gender?.toUpperCase?.()) {
    case "F":
      return Gender.FEMALE;
    case "M":
      return Gender.MALE;
    case "U":
      return Gender.UNKOWN;
    default:
      return Gender.OTHER;
  }
};

export const mapProviderAddressToFhir = (address: Address): AddressFHIR => ({
  type: "physical",
  use: address?.use === "home" ? address?.use : "work",
  line: address?.line_1?.trim?.()
    ? [address?.line_1, ...(address?.line_2 ? [address?.line_2] : [])]
    : undefined,
  city: address?.city ?? undefined,
  postalCode: address?.postal_code ?? undefined,
  state: address?.state ?? undefined,
  country: address?.country ?? undefined,
  period: getPeriodFromValues(address?.period_start, address?.period_end),
});

export const getPeriodFromValues = (
  periodStart: string | null | undefined,
  periodEnd: string | null | undefined
): { start: string | undefined; end: string | undefined } | undefined =>
  periodStart || periodEnd
    ? {
        start: periodStart ?? undefined,
        end: periodEnd ?? undefined,
      }
    : undefined;

export const checkProviderSearchValueDifferences = (
  lastSearchValues: ProviderSearchFields | null,
  newSearchValues: ProviderSearchFields
): boolean => {
  if (!lastSearchValues) return true;
  for (const key in lastSearchValues) {
    if (!Object.prototype.hasOwnProperty.call(newSearchValues, key)) continue;
    if (
      lastSearchValues[key as keyof ProviderSearchFields]?.toLowerCase() !==
      newSearchValues[key as keyof ProviderSearchFields]?.toLowerCase()
    )
      return true;
  }

  return false;
};

export const mapProviderTelecomToFhir = (telecom: Telecom): ContactPoint => ({
  system: (validTelecomSystems.includes(telecom?.type?.toLowerCase?.())
    ? telecom?.type?.toLowerCase?.()
    : "other") as ContactPoint["system"],
  value: telecom?.value ?? "",
  use: telecom?.use as ContactPoint["use"],
  period: getPeriodFromValues(telecom?.period_start, telecom?.period_end),
});

export const getCreatedMetaTagCodings = (
  createdBy: string | undefined | null,
  createdOn?: string
): Coding[] => [
  ...(createdBy
    ? [
        {
          code: PH_META_TAG_CREATED_BY_CODE,
          display: createdBy,
          system: PH_META_TAG_CODE_SYSTEM_URL,
        },
      ]
    : []),
  {
    code: PH_META_TAG_CREATED_ON_CODE,
    display: createdOn ?? new Date().toISOString(),
    system: PH_META_TAG_CODE_SYSTEM_URL,
  },
];

export const getProviderSourceMetaTagCoding = (
  providerUUID: string
): Coding => ({
  code: PH_META_TAG_PROVIDER_SOURCE_CODE,
  display: `${PROVIDER_DIRECTORY_BASE_URL}/provider/${providerUUID}`,
  system: PH_META_TAG_CODE_SYSTEM_URL,
});