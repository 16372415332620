import { useEffect, useState } from 'react';
import { Box, Button, Card, CircularProgress, Collapse, Grid } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import Iconify from '../../../../components/Iconify';
import { useQuery } from '@tanstack/react-query';
import { getReferralStatistics } from 'src/services/api/crsReferral';
import DonutChart from '../../case/components/ChartDonut';
import ChartBar, { ChartBarProps } from '../../case/components/ChartBar';
import useLocales from 'src/hooks/useLocales';

type Props = {
  isOpen: boolean;
  onOpen: VoidFunction;
};

export default function ReferralStatics({ isOpen, onOpen }: Props) {
  const theme = useTheme();
  const { i18n } = useLocales();

  const { data, isFetching } = useQuery({
    queryKey: ['referral/statistics'],
    queryFn: async () => {
      const statistics = await getReferralStatistics();
      return statistics;
    },
    cacheTime: 0,
  });

  const [dataByYear, setDataByYear] = useState<ChartBarProps['chartData']>([]);
  const [selectedYear, setSelectedYear] = useState<number | null>(null);

  useEffect(() => {
    if (!data) return;
    const years = [...new Set(data.creationDate.map(({ year }) => year))];
    const dataByYear: ChartBarProps['chartData'] = years.map((year) => ({
      label: String(year),
      value: year,
      data: [
        {
          name: 'Created Referrals',
          data: Array(12)
            .fill(null)
            .map((_, index) => {
              const { numOfRecords } =
                data.creationDate.find(
                  ({ month, year: yearAux }) => month === index + 1 && year === yearAux
                ) ?? {};
              if (!numOfRecords) return 0;
              return numOfRecords;
            }),
        },
      ],
    }));
    setDataByYear(dataByYear);
    setSelectedYear(Math.max(...years));
  }, [data]);

  return (
    <Card sx={isOpen ? { p: 1 } : { p: 1, bgcolor: 'background.neutral' }}>
      <Box sx={{ mt: 1, mb: 1 }}>
        <Button
          size="small"
          endIcon={
            <Iconify icon={isOpen ? 'eva:arrow-ios-upward-fill' : 'eva:arrow-ios-downward-fill'} />
          }
          onClick={onOpen}
        >
          {i18n('referral.list.statistics.statisticsTitle', 'crs')}
        </Button>
      </Box>
      <Collapse in={isOpen}>
        {!isFetching && data && selectedYear ? (
          <Grid spacing={4} container>
            <Grid item xs={12} md={6}>
              <DonutChart
                title={i18n('referral.list.statistics.overallReferralStatus', 'crs')}
                total={
                  data.status.closed +
                  data.status.open +
                  data.status.rejected +
                  data.status.unspecified
                }
                chartData={[
                  { label: 'Open', value: Number(data.status.open) },
                  { label: 'Closed', value: Number(data.status.closed) },
                  { label: 'Rejected', value: Number(data.status.rejected) },
                  { label: 'Unspecified', value: Number(data.status.unspecified) },
                ]}
                chartColors={[
                  [theme.palette.warning.main, theme.palette.warning.main],
                  [theme.palette.primary.main, theme.palette.primary.main],
                  [theme.palette.error.main, theme.palette.error.main],
                  [theme.palette.grey['400'], theme.palette.grey['400']],
                ]}
                labelColors={[
                  theme.palette.warning.main,
                  theme.palette.primary.main,
                  theme.palette.error.main,
                  theme.palette.grey['400'],
                ]}
              />
            </Grid>

            <Grid item xs={12} md={6}>
              <ChartBar
                title={i18n('referral.list.statistics.referralStatusByCreationDate', 'crs')}
                chartLabels={[
                  'Jan',
                  'Feb',
                  'Mar',
                  'Apr',
                  'May',
                  'Jun',
                  'Jul',
                  'Aug',
                  'Sep',
                  'Oct',
                  'Nov',
                  'Dec',
                ]}
                chartData={dataByYear}
                selectedSeries={selectedYear}
                onSeriesChange={(value) => {
                  setSelectedYear(Number(value));
                }}
              />
            </Grid>
          </Grid>
        ) : (
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              paddingBottom: 3,
            }}
          >
            <CircularProgress size={80} />
          </Box>
        )}
      </Collapse>
    </Card>
  );
}
