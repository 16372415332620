import {
  Autocomplete,
  Box,
  Button,
  CircularProgress,
  FormControl,
  InputLabel,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
  TextField,
  Typography,
} from '@mui/material';
import { isEmpty } from 'lodash';
import { useSnackbar } from 'notistack';
import { useCallback, useEffect, useMemo, useState } from 'react';
import useObjectState from 'src/hooks/useObjectState';
import { TableHeadCustom } from 'src/components/table';
import { useCodeSystem, useOrganizations } from 'src/@nicheaim/fhir-react';
import { referralService } from 'src/crs/referral/services';
import { GridItem, GridSection } from 'src/components/CustomModal';
import { getFullAddress } from 'src/sections/crs/common/common-utils';
import { WrappedPatient } from 'src/@nicheaim/fhir-base/wrappers/Patient';
import { ServiceByFiltersResponse } from 'src/crs/referral/services/ReferralService';
import { TABLE_HEAD_CHILD_REFERRAL_SEARCH_SERVICE } from 'src/sections/crs/common/table-head';
import { Address } from 'src/nicheaim-infrastructure/application/adapters/out/repositories/fhir/resources';
import useLocales from 'src/hooks/useLocales';
import {
  OrganizationWrapper,
  WrappedOrganization,
} from 'src/@nicheaim/fhir-base/wrappers/Organization';
import { capitalize, cleanSearchInput } from 'src/utils/string';
import { debounce } from 'src/utils/timers';

type Props = {
  patient?: WrappedPatient | null;
  updateState: any;
};

const SearchServices = ({ patient, updateState }: Props) => {
  const { enqueueSnackbar } = useSnackbar();
  const [state, updateStateFilters] = useObjectState<any>({});
  const [services, setServices] = useState<any>([]);
  const [serviceType] = useCodeSystem('crs-referral-services');
  const { i18n } = useLocales();
  const [organizationInput, setOrganizationInput] = useState<string>('');
  const [selectedOrganization, setSelectedOrganization] = useState<WrappedOrganization | null>(
    null
  );
  const [organizationNameSearch, setOrganizationNameSearch] = useState<string>('');

  const [organizations, { isFetching: isOrganizationsLoading }] = useOrganizations({
    filter: {
      ...(organizationNameSearch ? { name: organizationNameSearch } : {}),
    },
    map: OrganizationWrapper,
    pagination: {
      pageSize: 50,
    },
  });

  const [serviceName, setServiceName] = useState('');

  const fetchOrganizations = useCallback(
    debounce((organizationName: string) => {
      setOrganizationNameSearch(cleanSearchInput(organizationName ?? ''));
    }),
    []
  );

  useEffect(() => {
    fetchOrganizations(organizationInput);
  }, [organizationInput, fetchOrganizations]);

  const { serviceTypeItem, postalCode, states, isLoading } = state;

  const referralServiceTypes = useMemo(
    () =>
      serviceType?.concept
        ?.map((type) => ({
          label: type.display,
          value: type.code,
        }))
        .sort((x1, x2) =>
          x1.label && x2.label && x1.label > x2.label
            ? 1
            : x1.label && x2.label && x1.label < x2.label
            ? -1
            : 0
        ) ?? [],
    [serviceType]
  );

  const postalCodes = useMemo(
    () =>
      patient?.address
        ?.map((address: Address) => ({
          label: address?.postalCode,
          value: address?.postalCode,
        }))
        .sort((x1, x2) =>
          x1.label && x2.label && x1.label > x2.label
            ? 1
            : x1.label && x2.label && x1.label < x2.label
            ? -1
            : 0
        ) ?? [],
    [patient]
  );

  const statesPatient = useMemo(
    () =>
      patient?.address
        ?.map((address: Address) => ({
          label: address?.state,
          value: address?.state,
        }))
        .sort((x1, x2) =>
          x1.label && x2.label && x1.label > x2.label
            ? 1
            : x1.label && x2.label && x1.label < x2.label
            ? -1
            : 0
        ) ?? [],
    [patient]
  );

  const validateRequiredFields = () => {
    if (!serviceTypeItem) {
      return false;
    }
    return true;
  };

  const handleSearchServices = async () => {
    setServices([]);

    const searchParams = {
      serviceType: serviceTypeItem?.label || serviceTypeItem,
      postalCode: postalCode?.value || postalCode,
      state: states?.value || states,
      organizationId: selectedOrganization?.id,
      serviceName,
    };

    updateStateFilters({ isLoading: true });

    try {
      const servicesResponse = await referralService.getServicesByFilters(searchParams);
      if (servicesResponse) {
        setServices(servicesResponse);
      }
    } catch (error) {
      enqueueSnackbar(`An error has occurred.`, { variant: 'error' });
    }
    updateStateFilters({ isLoading: false });
  };

  const handleSelectedService = (data: ServiceByFiltersResponse) => {
    updateState({
      serviceTypeItem: {
        label: `${data?.healthcareService?.name}${
          data?.organization?.name ? ` | ${data?.organization?.name}` : ''
        } `,
        value: {
          healthcareService: data?.healthcareService,
          organization: data?.organization,
        },
      },
      locationOrganization: {
        label: data?.location?.name,
        value: {
          location: data?.location,
        },
      },
      openSearch: false,
    });
  };

  return (
    <>
      <GridSection mt={0}>
        <GridItem xs={12} sx={{ ml: 1 }}>
          <form>
            <InputLabel shrink sx={{ mt: 2 }}>
              {i18n('patients.details.serviceRequests.searchService.serviceName', 'crs')}
            </InputLabel>
            <FormControl fullWidth>
              <TextField
                fullWidth
                value={serviceName}
                onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                  const text = event.target.value;
                  setServiceName(text);
                }}
                variant="standard"
              />
            </FormControl>
            <InputLabel shrink sx={{ mt: 2 }}>
              {i18n('patients.details.serviceRequests.searchService.organization', 'crs')}
            </InputLabel>
            <FormControl fullWidth>
              <Autocomplete
                value={selectedOrganization}
                fullWidth
                onChange={(_, organization) => {
                  setSelectedOrganization(organization);
                }}
                onBlur={() => {
                  setOrganizationInput('');
                }}
                options={organizations ?? []}
                getOptionLabel={(organization: WrappedOrganization) =>
                  capitalize(organization?.name ?? '')
                }
                renderInput={(params) => (
                  <TextField
                    {...params}
                    value={organizationInput}
                    onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                      const text = event.target.value;
                      setOrganizationInput(text);
                    }}
                    variant="standard"
                    InputProps={{
                      ...params.InputProps,
                      endAdornment: (
                        <>
                          {isOrganizationsLoading ? (
                            <CircularProgress color="inherit" size={20} />
                          ) : null}
                          {params.InputProps.endAdornment}
                        </>
                      ),
                    }}
                  />
                )}
              />
            </FormControl>
            <InputLabel shrink sx={{ mt: 2 }}>
              {i18n('patients.details.serviceRequests.searchService.serviceType', 'crs')}
            </InputLabel>
            <FormControl fullWidth>
              <Autocomplete
                freeSolo
                disableClearable
                options={referralServiceTypes}
                value={serviceTypeItem}
                onChange={(_: any, value: any | null) => {
                  if (value && value?.value) {
                    updateStateFilters({ serviceTypeItem: value });
                  }
                }}
                onInputChange={(_: any, value: any | null) => {
                  updateStateFilters({ serviceTypeItem: value });
                }}
                renderInput={(params) => <TextField required variant="standard" {...params} />}
              />
            </FormControl>
            <InputLabel shrink sx={{ mt: 2 }}>
              {i18n('patients.details.serviceRequests.searchService.postalCode', 'crs')}
            </InputLabel>
            <FormControl fullWidth>
              <Autocomplete
                freeSolo
                disableClearable
                options={postalCodes}
                value={postalCode}
                onChange={(_: any, value: any | null) => {
                  updateStateFilters({ postalCode: value });
                }}
                onInputChange={(_: any, value: any | null) => {
                  updateStateFilters({ postalCode: value });
                }}
                renderInput={(params) => <TextField required variant="standard" {...params} />}
              />
            </FormControl>
            <InputLabel shrink sx={{ mt: 2 }}>
              {i18n('patients.details.serviceRequests.searchService.state', 'crs')}
            </InputLabel>
            <FormControl fullWidth>
              <Autocomplete
                freeSolo
                disableClearable
                options={statesPatient}
                value={states}
                onChange={(_: any, value: any | null) => {
                  updateStateFilters({ states: value });
                }}
                onInputChange={(_: any, value: any | null) => {
                  updateStateFilters({ states: value });
                }}
                renderInput={(params) => <TextField required variant="standard" {...params} />}
              />
            </FormControl>
            <Box mt={2} display={'flex'} justifyContent={'flex-end'}>
              <Button variant="contained" onClick={handleSearchServices}>
                {i18n('patients.details.serviceRequests.searchService.search', 'crs')}
              </Button>
            </Box>
          </form>
        </GridItem>
      </GridSection>
      <GridSection>
        <GridItem xs={12} sx={{ ml: 1 }}>
          <TableContainer component={Paper}>
            <Table size="small" aria-label="Services table" stickyHeader>
              <TableHeadCustom headLabel={TABLE_HEAD_CHILD_REFERRAL_SEARCH_SERVICE} />
              <TableBody>
                {!isEmpty(services) ? (
                  services?.map((row: ServiceByFiltersResponse, index: number) => {
                    return (
                      <TableRow key={'t-row-' + index}>
                        <TableCell style={{ cursor: 'pointer' }} sx={{ fontSize: `0.75rem` }}>
                          {setRow(row?.healthcareService)}
                        </TableCell>
                        <TableCell style={{ cursor: 'pointer' }} sx={{ fontSize: `0.75rem` }}>
                          {setRow(row?.organization)}
                        </TableCell>
                        <TableCell style={{ cursor: 'pointer' }} sx={{ fontSize: `0.75rem` }}>
                          {setRow(row?.location)}
                        </TableCell>
                        <TableCell>
                          {!isEmpty(row?.healthcareService) && (
                            <Button
                              sx={{
                                height: 36,
                                width: 63,
                              }}
                              onClick={() => {
                                handleSelectedService(row);
                              }}
                              variant="contained"
                            >
                              Select
                            </Button>
                          )}
                        </TableCell>
                      </TableRow>
                    );
                  })
                ) : (
                  <>
                    {isLoading ? (
                      <TableCell
                        colSpan={TABLE_HEAD_CHILD_REFERRAL_SEARCH_SERVICE?.length}
                        rowSpan={6}
                      >
                        <Box mt={2} display={'flex'} justifyContent={'center'}>
                          <CircularProgress />
                        </Box>
                      </TableCell>
                    ) : (
                      <TableCell colSpan={TABLE_HEAD_CHILD_REFERRAL_SEARCH_SERVICE?.length}>
                        <Typography variant="body2" align="center">
                          No rows
                        </Typography>
                      </TableCell>
                    )}
                  </>
                )}
              </TableBody>
            </Table>
          </TableContainer>
        </GridItem>
      </GridSection>
    </>
  );
};

const setRow = (row: any) => {
  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
      }}
    >
      <Box>
        <Typography variant="subtitle1">{row?.name}</Typography>
      </Box>

      <Box>{row?.comment}</Box>

      {row?.address ? <Box>{getFullAddress(row?.address)}</Box> : null}

      {row?.telecom &&
        row?.telecom?.map((t: any, index: number) => (
          <Box key={'box-telecom-' + index}>{t?.value}</Box>
        ))}
    </Box>
  );
};

export default SearchServices;
