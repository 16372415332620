import { useState } from 'react';
import { Avatar, Box, Button } from '@mui/material';

import ImageUploadDialog from './ImageUploadDialog';
import { FileWithPath } from 'react-dropzone';

export interface EditableAvatarProps {
  imageSourceUrl?: string | null;
  isLoading?: boolean;
  onUploadImage: (image: FileWithPath) => Promise<boolean> | boolean;
}

const EditableAvatar = ({ imageSourceUrl, isLoading, onUploadImage }: EditableAvatarProps) => {
  const [isImageUploadDialogOpen, setIsImageUploadDialogOpen] = useState(false);

  return (
    <>
      <Box
        sx={{
          display: 'flex',
          width: 'auto',
          flexDirection: 'column',
          alignItems: 'center',
        }}
      >
        <Avatar
          src={imageSourceUrl ?? ''}
          sx={{
            width: 90,
            height: 90,
            '&:hover': {
              cursor: 'pointer',
            },
          }}
        />
        <Box sx={{ marginTop: 1 }}>
          <Button
            sx={{
              border: 0,
              '&:hover': {
                border: 0,
              },
            }}
            onClick={() => {
              setIsImageUploadDialogOpen(true);
            }}
            variant="outlined"
          >
            Change Picture
          </Button>
        </Box>
      </Box>
      <ImageUploadDialog
        open={isImageUploadDialogOpen}
        isLoading={isLoading}
        onClose={() => {
          setIsImageUploadDialogOpen(false);
        }}
        onImageUpload={async (file) => {
          const isUploaded = await onUploadImage(file);
          if (isUploaded) setIsImageUploadDialogOpen(false);
        }}
      />
    </>
  );
};

export default EditableAvatar;
