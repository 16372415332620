import {
  AddReferralPropsType,
  defaultStateFieldsValues,
  ReferralPayload,
  ReferralStatuses,
  REFERRAL_CREATE_ERROR_MESSAGE,
} from './constants';
import {
  Autocomplete,
  Button,
  CircularProgress,
  FormControl,
  Grid,
  IconButton,
  InputLabel,
  Stack,
  TextField,
} from '@mui/material';
import moment from 'moment';
import { useDocumentReferences, useLocations, useValueSet } from 'src/@nicheaim/fhir-react';
import agent from 'src/api/agent';
import { useSnackbar } from 'notistack';
import useAuth from 'src/hooks/useAuth';
import { isArray, isEmpty } from 'lodash';
import { useCallback, useEffect, useMemo, useState } from 'react';
import Iconify from 'src/components/Iconify';
import SearchServices from './SearchServices';
import CustomModal, { GridItem, GridSection } from 'src/components/CustomModal';
import useObjectState from 'src/hooks/useObjectState';
import { referralService } from 'src/crs/referral/services';
import useLocales from 'src/hooks/useLocales';
import { ValueSetWrapper } from 'src/@nicheaim/fhir-base/wrappers/ValueSet';
import {
  FhirResource,
  ServiceRequest,
  ValueSetComposeIncludeConcept,
} from 'src/nicheaim-infrastructure/application/adapters/out/repositories/fhir/resources';
import SearchMember, {
  MemberResourceTypes,
} from 'src/sections/crs/case/components/SearchMember/SearchMember';
import { WrappedPatient } from 'src/@nicheaim/fhir-base/wrappers/Patient';
import { ReferenceResources } from 'src/sections/crs/types';
import { WrappedPractitioner } from 'src/@nicheaim/fhir-base/wrappers/Practitioner';
import {
  OrganizationWrapper,
  WrappedOrganization,
} from 'src/@nicheaim/fhir-base/wrappers/Organization';
import { WrappedPractitionerRole } from 'src/@nicheaim/fhir-base/wrappers/PractitionerRole';
import { WrappedRelatedPerson } from 'src/@nicheaim/fhir-base/wrappers/RelatedPerson';
import { cleanSearchInput } from 'src/utils/string';
import {
  HealthcareServiceWrapper,
  WrappedHealthcareService,
} from 'src/@nicheaim/fhir-base/wrappers/HealthcareService';
import { ServiceByFiltersResponse } from 'src/crs/referral/services/ReferralService';

export enum RequesterResourceTypes {
  PRACTITIONER = 'Practitioner',
  PRACTITIONER_ROLE = 'PractitionerRole',
  ORGANIZATION = 'Organization',
  PATIENT = 'Patient',
  RELATED_PERSON = 'RelatedPerson',
}

const AddReferral = (props: AddReferralPropsType) => {
  const { i18n } = useLocales();

  const [requestIntentVS] = useValueSet('request-intent', { map: ValueSetWrapper });
  const [requestCategoryVS] = useValueSet('request-category', { map: ValueSetWrapper });
  const { referral: propReferral, patient, serviceRequest, openAdd, onCancel } = props;

  const { enqueueSnackbar } = useSnackbar();

  const authUser = useAuth().getCurrentUser();

  const [state, updateState] = useObjectState<any>({});

  const [requesterType, setRequesterType] = useState<
    RequesterResourceTypes | ReferenceResources | null
  >(null);
  const [isRequesterSelectorOpen, setIsRequesterSelectorOpen] = useState(false);
  const [isHealthcareLoading, setIsHealthcareLoading] = useState(true);
  const [healthCareServices, setHealthCareServices] = useState<
    {
      label: string;
      value: {
        healthcareService: WrappedHealthcareService | undefined;
        organization: WrappedOrganization | undefined;
      };
    }[]
  >([]);
  const [locationIds, setLocationIds] = useState<string[]>([]);

  const {
    date,
    startHour,
    serviceTypeItem,
    referredToItem,
    status,
    statusItem,
    users,
    assignedToItem,
    locationOrganization,
    file,
    selectedFile,
    note,
    isLoading,
    openSearch,
    intent,
    category,
    error,
  } = state;

  const [_, { create: createDocumentReference }] = useDocumentReferences({
    autofetch: false,
  });

  const [documentReferences] = useDocumentReferences({
    filter: {
      related: propReferral?.fhirId,
      autofetch: propReferral?.fhirId ? true : false,
    },
    pagination: {
      pageSize: 10,
    },
  });

  const getHealthCareServices = useCallback(async (searchParams: any = {}) => {
    try {
      const services = await referralService.getServicesByFilters(searchParams);
      if (!services?.length) return [];
      const distinctServices = services.reduce<ServiceByFiltersResponse[]>(
        (accumServices, service) => {
          if (
            !!accumServices.find(
              (serv) => serv?.healthcareService?.id === service?.healthcareService?.id
            )
          )
            return accumServices;
          return [...accumServices, service];
        },
        []
      );
      return distinctServices.map((service) => ({
        label: `${service?.healthcareService?.name}${
          service?.organization?.name ? ` | ${service?.organization?.name}` : ''
        } `,
        value: {
          healthcareService: service?.healthcareService
            ? HealthcareServiceWrapper(service?.healthcareService)
            : undefined,
          organization: service?.organization
            ? OrganizationWrapper(service?.organization)
            : undefined,
        },
      }));
    } catch (error) {
      return [];
    }
  }, []);

  useEffect(() => {
    updateState(
      defaultStateFieldsValues(authUser.user_fhir_uri, propReferral, {
        intentValueSet: requestIntentVS?.asList?.() ?? [],
        categoryValueSet: requestCategoryVS?.asList?.() ?? [],
      })
    );
    setRequesterType(propReferral?.requester ?? null);
    closeSelectors();

    const getAsyncStateValues = async () => {
      if (!propReferral) return;
      const serviceRequest = propReferral as ServiceRequest;

      const healthCareServiceId = serviceRequest?.performer
        ?.find?.((performer) => performer?.reference?.split?.('/')?.[0] === 'HealthcareService')
        ?.reference?.split?.('/')?.[1];

      if (!healthCareServiceId) return;
      setIsHealthcareLoading(true);
      const healthCareService = await getHealthCareServices({ serviceId: healthCareServiceId });
      setIsHealthcareLoading(false);
      updateState({ serviceTypeItem: healthCareService?.[0] ?? null });
    };
    getAsyncStateValues();
  }, [openAdd, propReferral, requestCategoryVS, requestIntentVS]);

  useEffect(() => {
    const getHealthCareServicesAsync = async () => {
      setIsHealthcareLoading(true);

      const services = await getHealthCareServices();

      setHealthCareServices(services);

      setIsHealthcareLoading(false);
    };
    getHealthCareServicesAsync();
  }, []);

  useEffect(() => {
    const healthcareService = serviceTypeItem?.value?.healthcareService as WrappedHealthcareService;
    const newLocationIds = [
      ...(healthcareService?.location?.reduce?.<Set<string>>((locationIds, location) => {
        const locationId = location?.reference?.split?.('/')?.[1];
        if (locationId) locationIds.add(locationId);
        return locationIds;
      }, new Set()) ?? []),
    ];
    setLocationIds(newLocationIds);
  }, [serviceTypeItem]);

  const [locationRecords, { isFetching: isLocationsLoading }] = useLocations({
    filter: {
      ...(locationIds.length ? { _id: locationIds.join() } : {}),
    },
    autofetch: !!locationIds.length,
    pagination: {
      pageSize: 1000,
    },
  });

  const locations = useMemo(() => {
    if (!locationRecords?.length) return [];
    return locationRecords.map((location) => ({
      label: location?.name,
      value: {
        location,
      },
    }));
  }, [locationRecords]);

  const lastAttachment = useMemo(() => {
    const lastDocumentReference = documentReferences?.[0];
    if (lastDocumentReference?.content?.[0]?.attachment) {
      return lastDocumentReference?.content?.[0]?.attachment;
    }
    return null;
  }, [documentReferences]);

  const userListWithNames = useMemo(
    () =>
      users
        ?.filter((f: any) => !isEmpty(f.fhirUri.trim()))
        ?.map((user: any) => ({
          label: `${user.firstName} ${user.lastName}`,
          value: user.fhirUri,
        }))
        .sort((x1, x2) => (x1.label > x2.label ? 1 : x1.label < x2.label ? -1 : 0)) ?? [],
    [users]
  );

  const getAllUsers = async () => {
    const result = await agent.User.getAllUsers();
    updateState({
      users: isArray(result) ? [...result] : [],
    });
  };

  useEffect(() => {
    getAllUsers();
  }, [openAdd]);

  const validateRequiredFields = () => {
    const errors: { status?: boolean; intent?: boolean; service?: boolean } = {};
    if (!status) errors.status = true;
    if (!intent) errors.intent = true;
    if (!serviceTypeItem) errors.service = true;

    if (Object.keys(errors).length) {
      updateState({ error: errors });
      return false;
    }
    return true;
  };

  const onClearAllPress = () => {
    updateState(defaultStateFieldsValues(authUser.user_fhir_uri, null));
  };

  const onSavePress = async () => {
    updateState({
      isLoading: true,
    });
    if (!validateRequiredFields()) {
      updateState({
        isLoading: false,
        success: null,
      });
      enqueueSnackbar('Please make sure to  fill required fields.', { variant: 'error' });
      return;
    }

    const time = `${date} ${startHour}`;

    const momentDate = moment(time);

    const payload: ReferralPayload = {
      id: propReferral?.id ?? null,
      date: momentDate.isValid() ? momentDate.toISOString() : undefined,
      start: startHour,
      status: statusItem?.value,
      intent: intent.code,
      patientId: patient?.id ?? null,
      assignedTo: assignedToItem?.value,
      referredFrom: (requesterType as ReferenceResources)?.reference
        ? {
            reference: (requesterType as ReferenceResources)?.reference,
            display: (requesterType as ReferenceResources)?.display,
          }
        : null,
      referredTo: referredToItem?.value,
      category: category
        ? [
            {
              text: category.display,
              coding: [
                {
                  code: category.code,
                  display: category.display,
                  system: requestCategoryVS?.getConceptSystem?.(),
                },
              ],
            },
          ]
        : undefined,
      locationOrganization: null,
      locationId: locationOrganization?.value?.location?.id,
      locationName: locationOrganization?.value?.location?.name,
      note,
      services: null,
      service: null,
      serviceId: serviceTypeItem?.value?.healthcareService?.id,
      serviceType: serviceTypeItem?.value?.healthcareService?.type?.[0],
      serviceName: serviceTypeItem?.value?.healthcareService?.name,

      parentServiceRequest: serviceRequest,
    };

    handleSaveReferral(payload);
  };

  const handleSaveReferral = async (payload: ReferralPayload) => {
    if (propReferral?.id) {
      const updatedReferral = await createReferral(payload);

      if (!isEmpty(updatedReferral) && selectedFile) {
        const reader = new FileReader();
        reader.onloadend = async () => {
          const base64 = reader.result?.toString()?.replace(/^data:(.*,)?/, '');
          const newAttachment = await createAttachment(selectedFile, base64, updatedReferral);
          if (!newAttachment) {
            errorReferralCreation();
            enqueueSnackbar('Error uploading file.', { variant: 'error' });
          } else {
            completeReferralCreation();
            enqueueSnackbar('Referral saved successfully');
          }
        };
        reader.onerror = () => {
          errorReferralCreation();
          enqueueSnackbar('Error reading the selected file', { variant: 'error' });
        };
        reader.readAsDataURL(selectedFile);
      } else if (!isEmpty(updatedReferral)) {
        completeReferralCreation();
        enqueueSnackbar('Referral saved successfully');
      } else {
        errorReferralCreation();
        enqueueSnackbar(`${REFERRAL_CREATE_ERROR_MESSAGE}`, { variant: 'error' });
      }
    } else {
      try {
        let newReferrals = await createReferral(payload);

        if (!isEmpty(newReferrals) && selectedFile) {
          const reader = new FileReader();
          reader.onloadend = async () => {
            const base64 = reader.result?.toString()?.replace(/^data:(.*,)?/, '');
            const newAttachment = await createAttachment(selectedFile, base64, newReferrals);
            if (!newAttachment) {
              errorReferralCreation();
              enqueueSnackbar('Error uploading file.', { variant: 'error' });
            } else {
              completeReferralCreation();
              enqueueSnackbar('Referral saved successfully');
            }
          };
          reader.onerror = () => {
            errorReferralCreation();
            enqueueSnackbar('Error reading the selected file', { variant: 'error' });
          };
          reader.readAsDataURL(selectedFile);
        } else if (!isEmpty(newReferrals)) {
          completeReferralCreation();
          enqueueSnackbar('Referral saved successfully');
        } else {
          errorReferralCreation();
          enqueueSnackbar(`${REFERRAL_CREATE_ERROR_MESSAGE}`, { variant: 'error' });
        }
      } catch (e) {
        errorReferralCreation();
        enqueueSnackbar(`${REFERRAL_CREATE_ERROR_MESSAGE}`, { variant: 'error' });
      }
    }
    onCancel();
  };

  const updateReferral = async (payload: any) => {
    const response = await referralService.updateChildReferral(propReferral?.id, payload);
    return response;
  };

  const createReferral = async (payload: any) => {
    const response = await referralService.createChildReferral(payload);
    return response;
  };

  const createAttachment = async (selectedFile: any, base64: any, relatedReferrals: any) => {
    const referralReferences = {
      reference: `ServiceRequest/${relatedReferrals?.id}`,
      type: 'ServiceRequest',
    };

    const attachmentPayload: any = {
      resourceType: 'DocumentReference',
      status: 'current',
      context: {
        related: [referralReferences],
      },
      subject: {
        reference: `Patient/${patient?.id}`,
        display: patient?.getFullName(),
        type: 'Patient',
      },
      author: [
        {
          reference: authUser.user_fhir_uri,
          display: authUser.name,
          type: 'Practitioner',
        },
      ],
      date: moment().format('YYYY-MM-DDTHH:MM:ssZ'),
      content: [
        {
          attachment: {
            contentType: selectedFile?.type,
            size: selectedFile?.size,
            data: base64,
          },
        },
      ],
    };

    return createDocumentReference(attachmentPayload);
  };

  const completeReferralCreation = () => {
    updateState({
      success: 'Referral saved successfully' as any,
      isLoading: false,
    });
  };

  const errorReferralCreation = () => {
    updateState({
      isLoading: false,
      success: null,
    });
  };

  const handleDownloadAttachment = async () => {
    if (lastAttachment) {
      const element = document.createElement('a');
      element.setAttribute(
        'href',
        `data:${lastAttachment?.contentType};base64,${lastAttachment?.data}`
      );
      element.setAttribute('download', `filename.${lastAttachment.contentType?.split('/')[1]}`);
      element.style.display = 'none';
      document.body.appendChild(element);
      element.click();
      document.body.removeChild(element);
    }
  };

  const requesterTypes = Object.entries(RequesterResourceTypes).map(([_, value]) => value);

  const isTwoColumnLayout = openSearch || isRequesterSelectorOpen;

  const closeSelectors = () => {
    updateState({ openSearch: false });
    setIsRequesterSelectorOpen(false);
  };

  return (
    <CustomModal
      open={openAdd}
      onCancel={onCancel}
      onSave={onSavePress}
      breadcrumbs={[' ']}
      title={
        propReferral
          ? `${i18n('referral.details.secondaryReferrals.editReferralTitle', 'crs')}`
          : `${i18n('referral.details.secondaryReferrals.addReferralTitle', 'crs')}`
      }
      containerSx={[isTwoColumnLayout ? { width: '90vw' } : { width: '55vw' }]}
      childrenWithoutPadding={<></>}
      isLoading={isLoading || isHealthcareLoading}
    >
      <Grid container spacing={1}>
        <Grid item xs={isTwoColumnLayout ? 5 : 12}>
          <GridSection>
            <GridItem>
              <TextField
                fullWidth
                value={date}
                label={i18n('referral.details.ocurrenceDate', 'crs')}
                defaultValue={date}
                onChange={(e) => updateState({ date: e.target.value })}
                type="date"
                variant="outlined"
              />
            </GridItem>
            <GridItem>
              <TextField
                fullWidth
                label={i18n('referral.details.ocurrenceHour', 'crs')}
                value={startHour}
                defaultValue={startHour}
                onChange={(e) => updateState({ startHour: e.target.value })}
                type="time"
                variant="outlined"
              />
            </GridItem>
          </GridSection>
          <GridSection>
            <GridItem>
              <Autocomplete
                disablePortal
                options={Object.entries(ReferralStatuses).map((current) => ({
                  label: current[1],
                  value: current[0],
                }))}
                value={statusItem}
                getOptionLabel={(item) => item.label}
                onChange={(_: any, value: any | null) => {
                  if (value)
                    updateState((state) => ({
                      error: {
                        ...(state?.error ?? {}),
                        status: null,
                      },
                    }));
                  updateState({ statusItem: value, status: value.value });
                }}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    error={!!error?.status}
                    helperText={!!error?.status && i18n('errorMessages.fieldIsRequired', 'crs')}
                    variant="outlined"
                    label={i18n('referral.details.status', 'crs')}
                  />
                )}
              />
            </GridItem>
            <GridItem>
              <Autocomplete
                fullWidth
                disabled={!requestIntentVS}
                value={intent}
                onChange={(_: React.SyntheticEvent, intent) => {
                  if (intent)
                    updateState((state) => ({
                      error: {
                        ...(state?.error ?? {}),
                        intent: null,
                      },
                    }));
                  updateState({ intent });
                }}
                options={requestIntentVS?.asList?.() ?? []}
                getOptionLabel={({ display }: ValueSetComposeIncludeConcept) => display ?? ''}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    error={!!error?.intent}
                    helperText={!!error?.intent && i18n('errorMessages.fieldIsRequired', 'crs')}
                    label={i18n('referral.details.intent', 'crs')}
                    variant="outlined"
                    InputProps={{
                      ...params.InputProps,
                      endAdornment: (
                        <>
                          {!requestIntentVS?.asList?.()?.length ? (
                            <CircularProgress color="inherit" size={20} />
                          ) : null}
                          {params.InputProps.endAdornment}
                        </>
                      ),
                    }}
                  />
                )}
              />
            </GridItem>
          </GridSection>

          <GridSection>
            <GridItem>
              <Autocomplete
                fullWidth
                disabled={!requestCategoryVS}
                value={category}
                onChange={(_: React.SyntheticEvent, category) => {
                  updateState({ category });
                }}
                options={requestCategoryVS?.asList?.() ?? []}
                getOptionLabel={({ display }: ValueSetComposeIncludeConcept) => display ?? ''}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label={i18n('referral.details.category', 'crs')}
                    variant="outlined"
                    InputProps={{
                      ...params.InputProps,
                      endAdornment: (
                        <>
                          {!requestCategoryVS?.asList?.()?.length ? (
                            <CircularProgress color="inherit" size={20} />
                          ) : null}
                          {params.InputProps.endAdornment}
                        </>
                      ),
                    }}
                  />
                )}
              />
            </GridItem>
            <GridItem>
              <Autocomplete
                options={requesterTypes}
                value={(requesterType ?? null) as RequesterResourceTypes}
                getOptionLabel={(requesterType) => {
                  if ((requesterType as unknown as ReferenceResources)?.reference)
                    return (requesterType as unknown as ReferenceResources)?.display ?? '';

                  if (typeof requesterType === 'string' && requesterType)
                    return i18n(
                      `fhirResource.${requesterType[0].toLowerCase()}${requesterType.substring(1)}`,
                      'crs'
                    );
                  return '';
                }}
                onChange={(_, value: RequesterResourceTypes | ReferenceResources | null) => {
                  if (value && value !== RequesterResourceTypes.PATIENT) {
                    setRequesterType(value);
                    closeSelectors();
                    setIsRequesterSelectorOpen(true);
                    return;
                  }

                  if (value && value === RequesterResourceTypes.PATIENT) {
                    setRequesterType({
                      reference: `Patient/${patient?.id}`,
                      display: patient?.getFullName?.() ?? '',
                      resource: patient as FhirResource,
                    });
                    return;
                  }

                  setRequesterType(null);
                  closeSelectors();
                }}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label={i18n('referral.details.secondaryReferrals.referredFrom', 'crs')}
                    variant="outlined"
                  />
                )}
              />
            </GridItem>
          </GridSection>

          <GridSection>
            <GridItem xs={12}>
              <Autocomplete
                fullWidth
                disabled={isHealthcareLoading}
                options={healthCareServices}
                value={serviceTypeItem}
                onChange={(_: any, value: any | null) => {
                  if (value && value?.value) {
                    updateState((state) => ({
                      error: {
                        ...(state?.error ?? {}),
                        service: null,
                      },
                    }));
                  }
                  updateState({ serviceTypeItem: value, locationOrganization: null });
                }}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    error={!!error?.service}
                    helperText={!!error?.service && i18n('errorMessages.fieldIsRequired', 'crs')}
                    label={i18n('referral.details.secondaryReferrals.service', 'crs')}
                    variant="outlined"
                    InputProps={{
                      ...params.InputProps,
                      startAdornment: (
                        <>
                          {isHealthcareLoading ? (
                            <CircularProgress color="inherit" size={20} />
                          ) : null}
                          {params.InputProps.startAdornment}
                        </>
                      ),
                      endAdornment: (
                        <>
                          <IconButton
                            onClick={() => {
                              closeSelectors();

                              updateState({
                                openSearch: true,
                              });
                            }}
                          >
                            <Iconify icon="ic:baseline-search" />
                          </IconButton>
                          {params.InputProps.endAdornment}
                        </>
                      ),
                    }}
                  />
                )}
              />
            </GridItem>
          </GridSection>

          <GridSection>
            <GridItem>
              <Autocomplete
                fullWidth
                disabled={isLocationsLoading || locations.length < 1}
                options={locations}
                value={locationOrganization}
                onChange={(_: any, value: any | null) => {
                  updateState({
                    locationOrganization: value,
                  });
                }}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label={i18n('referral.details.secondaryReferrals.location', 'crs')}
                    variant="outlined"
                    InputProps={{
                      ...params.InputProps,
                      endAdornment: (
                        <>
                          {isLocationsLoading ? (
                            <CircularProgress color="inherit" size={20} />
                          ) : null}
                          {params.InputProps.endAdornment}
                        </>
                      ),
                    }}
                  />
                )}
              />
            </GridItem>
            <GridItem>
              <Autocomplete
                fullWidth
                disablePortal
                options={userListWithNames}
                value={assignedToItem}
                onChange={(_: any, value: any | null) => updateState({ assignedToItem: value })}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label={i18n('referral.details.assignedTo', 'crs')}
                    variant="outlined"
                  />
                )}
              />
            </GridItem>
          </GridSection>

          <GridSection>
            <GridItem xs={12}>
              <InputLabel shrink>
                {i18n('referral.details.secondaryReferrals.attachment', 'crs')}
              </InputLabel>
              <FormControl fullWidth>
                <TextField
                  fullWidth
                  value={file}
                  defaultValue={file}
                  onChange={(e) =>
                    updateState({
                      file: e.target.value as any,
                      selectedFile: (e.target as any)?.files[0],
                    })
                  }
                  variant="outlined"
                  type="file"
                />
              </FormControl>
            </GridItem>

            {propReferral?.id && lastAttachment ? (
              <GridItem>
                <InputLabel shrink>
                  {i18n('referral.details.secondaryReferrals.dowloadAttachment', 'crs')}{' '}
                  <IconButton onClick={handleDownloadAttachment}>
                    <Iconify icon="bi:download" />
                  </IconButton>
                </InputLabel>
              </GridItem>
            ) : null}
          </GridSection>

          <GridSection>
            <GridItem xs={12}>
              <TextField
                fullWidth
                value={note}
                label={i18n('referral.details.secondaryReferrals.note', 'crs')}
                onChange={(e) => updateState({ note: e.target.value as any })}
                variant="outlined"
                multiline
                maxRows={6}
                rows={6}
              />
            </GridItem>
          </GridSection>
        </Grid>
        {openSearch && (
          <Grid item xs={6.9}>
            <SearchServices patient={patient} updateState={updateState} />
          </Grid>
        )}

        {isRequesterSelectorOpen && (
          <Grid item xs={6.9} sx={{ marginTop: 3 }}>
            <SearchMember
              patient={patient as WrappedPatient}
              onSelectResource={(resource) => {
                let display = '';
                switch (resource.resourceType) {
                  case 'Practitioner':
                    display = (resource as WrappedPractitioner)?.getFullName?.() ?? '';
                    break;
                  case 'Organization':
                    display = (resource as WrappedOrganization)?.name ?? '';
                    break;
                  case 'PractitionerRole':
                    const practitionerRole = resource as WrappedPractitionerRole;
                    const practitionerName = practitionerRole?.getPractitionerName?.();
                    const roleName = practitionerRole?.getRoleName?.();
                    const organizationName = practitionerRole?.getOrganizationName?.();
                    display = [practitionerName, roleName, organizationName]
                      .filter((data) => !!cleanSearchInput(data))
                      .join(' | ');
                    break;
                  case 'RelatedPerson':
                    display = (resource as WrappedRelatedPerson)?.getFullName?.() ?? '';
                    break;
                }
                setRequesterType({
                  reference: `${resource.resourceType}/${resource.id}`,
                  display,
                  resource,
                });
                setIsRequesterSelectorOpen(false);
              }}
              onClear={() => {
                setIsRequesterSelectorOpen(false);
                setRequesterType(null);
              }}
              externalResourceType={requesterType as MemberResourceTypes}
            />
          </Grid>
        )}

        <Stack direction="row" justifyItems="center" spacing={2} sx={{ mt: 2 }}>
          <Button
            disabled={isLoading}
            onClick={onClearAllPress}
            variant="outlined"
            color="secondary"
            size="small"
          >
            {i18n('clear')}
          </Button>
        </Stack>
      </Grid>
    </CustomModal>
  );
};

export default AddReferral;
