import { Address as AddressFHIR, Coding, FhirResource, Practitioner } from "src/nicheaim-infrastructure/application/adapters/out/repositories/fhir/resources/resources";
import { LinkedResource } from "./incident";

export type GeneralPractitionerResourceType =
  | "Organization"
  | "PractitionerRole"
  | "Practitioner";

export enum Use {
  Home = "home",
  Work = "work",
}

export enum Gender {
  MALE = "male",
  FEMALE = "female",
  OTHER = "other",
  UNKOWN = "unknow",
}

export const pcpHealthStoryQuestionIds = {
  HAS_PCP: "/practitioner/have_pcp",
  LAST_NAME: "/practitioner/pcp_last_name",
  FIRST_NAME: "/practitioner/details/pcp_first_name",
  CITY: "/practitioner/details/pcp_city",
  ADDRESS: "/practitioner/details/pcp_address",
  PHONE: "/practitioner/details/pcp_phone_number",
  PRACTICE: {
    NAME: "/practitioner/details/practice_name",
  },
};

export interface Address {
  uuid?: string;
  is_preferred: boolean;
  use: Use;
  type: string;
  line_1: null | string;
  line_2: null;
  city: null | string;
  postal_code: null | string;
  state: null | string;
  country: string;
  period_start?: null;
  period_end?: null;
} 
  
export interface GeneralPractitionerData {
  id: string;
  fullName: string;
  email?: string;
  phone?: string;
  npi?: string;
  address: AddressFHIR;
  isVerified: boolean;
  resource?: FhirResource;
}

export interface AssignableGeneralPractitioner {
  resourceId: string;
  resourceType: GeneralPractitionerResourceType;
  practitioner?: GeneralPractitionerData | null;
  organization?: GeneralPractitionerData | null;
  role?: string | null;
  roleCode?: string | null;
  specialty?: string | null;
}

export interface ProviderStatus {
  code: string;
  display: string;
}

export interface Specialty {
  uuid: string;
  state: null;
  certified: boolean;
  is_primary: boolean;
  code: string;
  classification: string;
  name: string;
}

export interface Certification {
  period_start: null;
  period_end: null;
  uuid: string;
  name: string;
  code: null;
}

export interface Identifier {
  uuid: string;
  system: null;
  assigner: string;
  code: string;
  value: string;
  period_start: string | null;
  period_end: string | null;
  state: string;
}

export interface Telecom {
  is_preferred: boolean;
  use: string;
  type: string;
  value: string;
  period_start: null;
  period_end: null;
  uuid?: string;
}

export interface Practice {
  uuid: string;
  is_primary: boolean;
  status: string;
  period_start: null;
  period_end: null;
  name: string;
  address: Address[];
  telecom: Telecom[];
  indentifier: Identifier[];
  npi?: string;
}

export interface License {
  uuid: string;
  value: string;
  status: string;
  is_primary: boolean;
  period_start: string;
  period_end: string;
  name: string;
  code: string;
  scope_area: string;
  scope_value: string;
  taxonomy_ref: null | string;
  taxonomy_type?: null | string;
}
  
export interface DirectoryProvider {
  uuid: string;
  first_name: string;
  last_name: string;
  middle_name: string;
  name_prefix: string;
  name_suffix: string;
  dob: string;
  gender: "M" | "F" | "O" | "U";
  provider_status: ProviderStatus;
  telecom: Telecom[];
  classification: Specialty[];
  specialization: Specialty[];
  practice: Practice[];
  address: Address[];
  license: License[];
  certification: Certification[];
  identifier: Identifier[];
  npi: string;
  ssn: string;
  score: number;
}

export interface ProviderDirectorySearchFields {
  npi?: string;
  first_name?: string;
  last_name?: string;
  address?: string;
  city?: string;
  classification?: string | null;
  practice_name?: string;
  practice_npi?: string;
  phone_number?: string;
}

export interface DirectoryProviderLookUp {
  uuid: string;
  first_name: string;
  last_name: string;
  middle_name: string;
  name_prefix: string;
  name_suffix: string;
  dob: string;
  gender: "M" | "F" | "O" | "U";
  provider_status: ProviderStatus;
  telecom: Telecom[];
  address: Address[];
  classification: Specialty;
  practice: Practice;
  npi: string;
  ssn: string;
  score: number;
}

export interface PersonResource {
  url?: string | null;
  id?: string | null;
  firstName?: string | null;
  lastName?: string | null;
  npi?: string | null;
  email?: string | null;
  phone?: string | null;
  address?: AddressFHIR | null;
}

export type OnProviderAsyncAction = (
  provider: DirectoryProviderLookUp,
  showExistingPractitionersDialog?: (
    existingPractitioners: Practitioner[]
  ) => void
) => Promise<void>;

export type OnProviderUnselect = () => Promise<void>;

export type OnPractitionerRoleSelect = (
  practitionerRole: AssignableGeneralPractitioner
) => void;

export type OnPractitionerRoleCreation = (
  practitionerRole: AssignableGeneralPractitioner
) => void;

export interface LinkedPractitioner extends LinkedResource, PersonResource {}

export interface LinkedPractitionerRole extends LinkedResource {
  role?: {
    code?: string | null;
    display?: string | null;
  } | null;
  practitioner?: PersonResource | null;
  organization?: {
    url?: string | null;
    id?: string | null;
    name?: string | null;
    npi?: string | null;
    email?: string | null;
    phone?: string | null;
    address?: Address | null;
  } | null;
}

export interface DirectoryProviderLookUpResponse {
  matches?: DirectoryProviderLookUp[] | null;
  error_code?: string | null;
  error_message?: {
    message?: string[] | string | null;
  };
}

export interface ProviderCodeMapping {
  concepts: {
    provider: string;
    fhir: string;
  }[];
  default: string;
}

export interface PractitionerRoleReference {
  id: string;
  role: Coding;
  practitioner: Coding | null;
  organization: Coding | null;
}

export interface Resource {
  id: string;
  name: string;
  resourceType: string;
}

export interface ResourceQueryParams {
  search?: string;
  resourceType: GeneralPractitionerResourceType;
}