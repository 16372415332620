import React, { useEffect } from "react";
import { CheckCircleOutline as CheckIcon, ErrorOutlineRounded } from "@mui/icons-material";
import { Grid, Button, ButtonProps } from "@mui/material";
import { styled } from "@mui/material/styles";

export default (item: any, componentMapping: any, workflowHandler: any, i18n: any, path: string, namespace: string) => {

  const parseMetadata = (item?.metadata && JSON.parse(item?.metadata)) || "";


  const props = parseMetadata?.ui?.component;

  const elementName = i18n(
    `${path ? `${path}.` : ''}${item.code}`,
    { ns: namespace, defaultValue: props?.name || item?.name });
  const itemCode = item?.code;
  const metas = item?.isValid && JSON.parse(item?.isValid);
  const itemIsValid = metas ? metas?.response?.isValid : false;
  const metasResponse = metas?.response;
  const metasResponseType = metas?.response?.typeResponse?.type;

  const {
    record = {},
    checklistItem = {},
    refreshWorkFlow: refreshChecklistHandler = () => { },
    refreshRecordHandler = () => { },
    more = {},
    data = {}
  } = workflowHandler?.[itemCode]?.others;

  const { workflowInstance } = data;
  const workflowInstanceData = workflowInstance?.data && JSON.parse(workflowInstance?.data);
  const workflowDataChecklistItem = workflowInstanceData && workflowInstanceData?.scope?.checklistItem?.hasOwnProperty(`${checklistItem?.code}`);

  if (props?.type !== undefined) {
    return !itemIsValid ? (
      <>
        {(metas?.noMoreAttempts && metas?.noMoreAttempts) ? (
          <Grid container direction="row" alignItems="center">
            <Grid item>
              <ErrorOutlineRounded fontSize="small" sx={{ color: 'red', marginRight: '10px' }} />
            </Grid>
            <Grid item>
              <span style={{ fontSize: 12 }}>{metas?.response?.message}</span>
            </Grid>
          </Grid>
        ) : (
          React.createElement(componentMapping[props?.type], {
            key: props?.id,
            elementName,
            ...props,
            others: {
              ...workflowHandler?.[itemCode],
              record,
              checklistItem,
              refreshChecklistHandler,
              refreshRecordHandler,
            },
          })
        )}
      </>
    ) : (
      // <>else hi...</>
      <Grid container direction="row" alignItems="center">
        <Grid item>
          <CheckIcon fontSize="small" sx={{ color: "green", marginRight: '10px' }} />
        </Grid>
        <Grid item>
          <span style={{ fontSize: 12 }}>{metas?.response?.message || elementName}</span>
        </Grid>
      </Grid>
    );
  }

  const ItemButton = styled(Button)<ButtonProps>(() => ({
    color: "#fff",
    minWidth: "190px",
    backgroundColor: "#1890FF",
    "&:hover": {
      backgroundColor: "#1890FF",
    },
  }));

  return !itemIsValid ? (
    <>
      {metas?.response?.message && workflowDataChecklistItem ? (
        <Grid container direction="row" alignItems="center">
          <Grid item>
            <ErrorOutlineRounded fontSize="small" sx={{ color: 'red', marginRight: '10px' }} />
          </Grid>
          {metasResponseType !== undefined ? (
            <Grid item>
              {React.createElement(componentMapping[metasResponseType], {
                key: props?.id,
                ...props,
                others: {
                  ...workflowHandler?.[itemCode],
                  record,
                  checklistItem,
                  refreshChecklistHandler,
                  refreshRecordHandler,
                  metasResponse,
                },
              })}
            </Grid>
          ) : (
            <Grid item>
              <span style={{ fontSize: 12 }}>{metas?.response?.message}</span>
            </Grid>
          )}
        </Grid>
      ) : (
        <div className={`btn-${elementName.toLowerCase()}`} style={{ marginBottom: 10 }}>
          {/* <>button...</> */}
          <ItemButton
            onClick={
              workflowHandler?.[itemCode]
                ? () =>
                  workflowHandler?.[itemCode].handler({
                    record,
                    checklistItem,
                    refreshChecklistHandler,
                    refreshRecordHandler,
                    more,
                  })
                : () => {
                  console.log('missing handler to this item...');
                }
            }
          >
            {elementName}
          </ItemButton>
        </div>
      )}
    </>
  ) : (
    <Grid container direction="row" alignItems="center">
      <Grid item>
        <CheckIcon fontSize="small" sx={{ color: 'green', marginRight: '10px' }} />
      </Grid>
      {metasResponseType !== undefined ? (
        <Grid item>
          {React.createElement(componentMapping[metasResponseType], {
            key: props?.id,
            ...props,
            others: {
              ...workflowHandler?.[itemCode],
              record,
              checklistItem,
              refreshChecklistHandler,
              refreshRecordHandler,
              metasResponse,
            },
          })}
        </Grid>
      ) : (
        <Grid item>
          <span style={{ fontSize: 12 }}>{metas?.response?.message}</span>
        </Grid>
      )}
    </Grid>
  );
};
