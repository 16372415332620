import produce from 'immer';
import moment from 'moment';
import { isArray, isEmpty } from 'lodash';
import agent from '../../../../api/agent';
import { capitalCase } from 'change-case';
import Program from '../../common/Program';
import Iconify from 'src/components/Iconify';
import useLocales from 'src/hooks/useLocales';
import AssignUser from '../../common/AssignUser';
import { useState, useEffect, useMemo } from 'react';
import { parseShortFormat } from 'src/utils/formatTime';
import InsuranceInfo from './workflow-step/InsuranceInfo';
import { WrappedPatient } from 'src/@nicheaim/fhir-base/wrappers/Patient';
import { Box, Card, Collapse, IconButton, Stack, Typography } from '@mui/material';
import { WrappedServiceRequest } from 'src/@nicheaim/fhir-base/wrappers/ServiceRequest';
import { WrappedHealthcareService } from 'src/@nicheaim/fhir-base/wrappers/HealthcareService';
import axiosFhirInstance from '../../../../application/adapters/out/repositories/axiosFhirInstance';
import { Reference } from 'src/nicheaim-infrastructure/application/adapters/out/repositories/fhir/resources';

interface OwnedFromUserI {
  id: any;
  fullName: string;
}

type Props = {
  title?: string;
  workflowInstance: any;
  referralRecord: any;
  users: any[];
  fhirPatient: WrappedPatient | null;
  onOwnedAssign: (owned: string) => Promise<any>;
  handlers: any;
  referralWorkflowData: any;
  openCollapseExternal: boolean;
  children: any;
  serviceRequest?: WrappedServiceRequest | null;
  healthCareServices?: WrappedHealthcareService[];
  updateServiceRequest?: (data: WrappedServiceRequest) => Promise<any>;
};

const VALID_PROGRAM_INFO_CODE = 'VALID_PROGRAM_INFO_CHECKLIST';

export default function ReferralSummary({
  title,
  workflowInstance,
  users,
  onOwnedAssign,
  fhirPatient,
  handlers,
  referralWorkflowData,
  openCollapseExternal,
  referralRecord,
  children,
  serviceRequest,
  healthCareServices,
  updateServiceRequest
}: Props) {
  const {
    status,
    dispositionReason,
    dispositionBy,
    dispositionNote,
    dispositionOn,
    ownedBy,
    description,
    createdOn,
    scope,
  } = workflowInstance;

  const { i18n } = useLocales();

  const [openModal, setOpenModal] = useState(false);
  const [coverage, setCoverage] = useState<any>();
  const [checklist, setChecklist] = useState<any>([]);
  const [checklistValid, setChecklistValid] = useState<any>([]);
  const [openInsuranceInfo, setOpenInsuranceInfo] = useState(false);
  const [open, setOpen] = useState(false);
  const [openPrograms, setOpenPrograms] = useState(false);

  useEffect(() => {
    fetchCoverage();
    fetchChecklist();
  }, [fhirPatient, workflowInstance]);

  useEffect(() => {
    checkItemsValid();
  }, [checklist]);

  useEffect(() => {
    setOpen(openCollapseExternal);
  }, [openCollapseExternal]);

  const handleCloseModal = () => {
    setOpenModal(false);
  };

  const fetchCoverage = async () => {
    const getCoverage = await axiosFhirInstance.get(
      `Coverage?beneficiary:Patient._id=${fhirPatient}`
    );
    setCoverage(getCoverage?.data?.entry?.pop().resource || false);
  };

  const fetchChecklist = async () => {
    const getChecklist = await agent.Checklist.getChecklistByCode(VALID_PROGRAM_INFO_CODE);
    setChecklist(getChecklist?.items);
  };

  const checkItemsValid = async () => {
    const checkIsItemsAreValid = await agent.Checklist.checkItemsValid({
      items: checklist,
      workflowInstanceId: referralWorkflowData?.workflowInstance?.id,
      workflowStageId: 3,
      referenceName: referralWorkflowData?.recordReference,
      referenceId: referralWorkflowData?.recordId,
    });
    setChecklistValid(checkIsItemsAreValid);
  };

  const isValidItem = (items: any = [], code: any = null) => {
    const getItem = Array.isArray(items) && items?.filter((i: any) => i?.code === code)[0];
    const parse =
      getItem?.isValid && typeof getItem?.isValid === 'string' ? JSON.parse(getItem?.isValid) : {};

    return parse?.response?.isValid || false;
  };

  const ownedFromUsers: OwnedFromUserI | undefined = isArray(users) ? users
    ?.filter((e) => e.userName == ownedBy || e.fhirUri == ownedBy)
    ?.map((t) => ({
      id: t.id,
      fullName: `${t.firstName} ${t.lastName}`,
  }))[0] : undefined;

  const handleCloseInsuranceInfo = () => {
    setOpenInsuranceInfo(false);
    fetchCoverage();
  };

  const handleMissingLabNotes = () => {
    handlers.labNotesHandler(true);
  };

  const handleMissingProgressNotes = () => {
    handlers.progressNotesHandler(true);
  };

  const handleProgramAssignment = async (data: Reference[]) => {
    const nonHealthcareServicePerformers =
      serviceRequest?.performer?.filter?.(
        ({ reference }) => !reference?.includes?.('HealthcareService')
      ) ?? [];
      
      if (updateServiceRequest) {
        await updateServiceRequest( 
          produce(serviceRequest!, (draft) => {
            draft.performer =  [...nonHealthcareServicePerformers, ...data];
          })
        );
      }
  };

  return (
    <Card sx={{ p: 2, display: 'block' }}>
      <Box sx={{ display: 'flex', flexDirection: 'row' }}>
        <Typography sx={{ mt: 1, ml: 1 }}>{title}</Typography>
        <IconButton onClick={() => setOpen(!open)}>
          <Iconify icon={open ? 'eva:arrow-ios-upward-fill' : 'eva:arrow-ios-downward-fill'} />
        </IconButton>
      </Box>

      <Collapse in={open}>
        <Stack spacing={2} sx={{ m: 2 }}>
          {!!referralRecord?.internalNumber && (
            <Stack direction="row" alignItems="center" justifyContent="space-between">
              <Typography variant="body2">{i18n('referral.details.referralSummary.referralNo', 'crs')}</Typography>
              <Typography
                variant="body2"
                sx={{ color: 'text.secondary', textTransform: 'capitalize' }}
              >
                {referralRecord?.internalNumber}
              </Typography>
            </Stack>
          )}
          <Stack direction="row" alignItems="center" justifyContent="space-between">
            <Typography variant="body2">{i18n('referral.details.status', 'crs')}</Typography>
            <Typography
              variant="body2"
              sx={{ color: 'text.secondary', textTransform: 'capitalize' }}
            >
              {referralRecord?.indexedStatus}
            </Typography>
          </Stack>
          {!isEmpty(status) && (
            <Stack direction="row" alignItems="center" justifyContent="space-between">
              <Typography variant="body2">{i18n('referral.details.referralSummary.workflowStatus', 'crs')}</Typography>
              <Typography variant="body2" sx={{ color: 'text.secondary' }}>
                {status}
              </Typography>
            </Stack>
          )}
          <Stack direction="row" alignItems="center" justifyContent="space-between">
            <Typography variant="body2">{i18n('referral.details.referralSummary.urgent', 'crs')}</Typography>
            <Typography variant="body2" sx={{ color: 'text.secondary' }}></Typography>
          </Stack>
          {!isEmpty(scope) && (
            <Stack direction="row" alignItems="center" justifyContent="space-between">
              <Typography variant="body2">{i18n('referral.details.referralSummary.scope', 'crs')}</Typography>
              <Typography variant="body2" sx={{ color: 'text.secondary' }}>
                {capitalCase(scope || '')}
              </Typography>
            </Stack>
          )}
          {!isEmpty(dispositionReason) && (
            <>
              <Stack direction="row" alignItems="center" justifyContent="space-between">
                <Typography variant="body2">{i18n('referral.details.referralSummary.dispositionReason', 'crs')}</Typography>
                <Typography variant="body2" sx={{ color: 'text.secondary' }}>
                  {dispositionReason}
                </Typography>
              </Stack>
              <Stack direction="row" alignItems="center" justifyContent="space-between">
                <Typography variant="body2">{i18n('referral.details.referralSummary.dispositionBy', 'crs')}</Typography>
                <Typography variant="body2" sx={{ color: 'text.secondary' }}>
                  {dispositionBy}
                </Typography>
              </Stack>
              <Stack direction="row" alignItems="center" justifyContent="space-between">
                <Typography variant="body2">{i18n('referral.details.referralSummary.dispositionOn', 'crs')}</Typography>
                <Typography variant="body2" sx={{ color: 'text.secondary' }}>
                  {dispositionOn && moment.utc(new Date(dispositionOn)).format('MM/DD/YYYY HH:mm:ss')}
                </Typography>
              </Stack>
              <Stack direction="row" alignItems="center" justifyContent="space-between">
                <Typography variant="body2">{i18n('referral.details.referralSummary.dispositionNote', 'crs')}</Typography>
                <Typography variant="body2" sx={{ color: 'text.secondary' }}>
                  {dispositionNote}
                </Typography>
              </Stack>
            </>
          )}
          <Stack direction="row" alignItems="center" justifyContent="space-between">
            <Typography variant="body2">{i18n('referral.details.assignedTo', 'crs')}</Typography>
            <Typography variant="body2" sx={{ color: 'text.secondary' }}>
              {ownedFromUsers?.fullName}
              <IconButton sx={{ p: 0.5, ml: 0.5 }} onClick={() => setOpenModal(true)}>
                <Iconify
                  color="#1890FF"
                  icon={openModal ? 'eva:save-outline' : 'eva:edit-outline'}
                />
              </IconButton>
            </Typography>
          </Stack>
          {!isEmpty(description) && (
            <Stack direction="row" alignItems="center" justifyContent="space-between">
              <Typography variant="body2">{i18n('referral.details.referralSummary.description', 'crs')}</Typography>
              <Typography variant="body2" sx={{ color: 'text.secondary' }}>
                {description}
              </Typography>
            </Stack>
          )}
          {children}
          <Stack direction="row" alignItems="center" justifyContent="space-between">
            <Typography variant="body2">{i18n('referral.details.date', 'crs')}</Typography>
            <Typography variant="body2" sx={{ color: 'text.secondary' }}>
              {parseShortFormat(createdOn)}
            </Typography>
          </Stack>
          <Stack direction="row" alignItems="center" justifyContent="space-between">
            <Typography variant="body2">{i18n('referral.details.referralSummary.requesterOrganization', 'crs')}</Typography>
            <Typography variant="body2" sx={{ color: 'text.secondary' }}>
              {referralRecord?.indexedRequesterName}
            </Typography>
          </Stack>
          {!isEmpty(serviceRequest?.performer) &&
            <Stack direction="row" alignItems="center" justifyContent="space-between">
              <Typography variant="body2">{i18n('referral.details.referralSummary.programs', 'crs')}</Typography>
              <Typography variant="body2" sx={{ color: 'text.secondary' }}>
                <IconButton sx={{ p: 0.5, ml: 0.5 }} onClick={() => setOpenPrograms(true)}>
                  <Iconify
                    color="#1890FF"
                    icon={openPrograms ? 'eva:save-outline' : 'eva:edit-outline'}
                  />
                </IconButton>
              </Typography>
            </Stack>
          }
          {isValidItem(checklistValid, 'MISSING_INSURANCE_INFO_ITEM') && (
            <Stack direction="row" alignItems="center" justifyContent="space-between">
              <Typography variant="body2">{i18n('referral.details.referralSummary.insurance', 'crs')}</Typography>
              <Typography variant="body2" sx={{ color: 'text.secondary' }}>
                {!coverage ? 'No' : 'Yes'}
                <IconButton sx={{ p: 0.5, ml: 0.5 }} onClick={() => setOpenInsuranceInfo(true)}>
                  <Iconify
                    color="#1890FF"
                    icon={openModal ? 'eva:save-outline' : 'eva:edit-outline'}
                  />
                </IconButton>
              </Typography>
            </Stack>
          )}
          {isValidItem(checklistValid, 'MISSING_PROGRESS_NOTES_ITEM') && (
            <Stack direction="row" alignItems="center" justifyContent="space-between">
              <Typography variant="body2">{i18n('referral.details.referralSummary.progressNotes', 'crs')}</Typography>
              <Typography variant="body2" sx={{ color: 'text.secondary' }}>
                {!isValidItem(checklistValid, 'MISSING_PROGRESS_NOTES_ITEM') ? 'No' : 'Yes'}
                <IconButton sx={{ p: 0.5, ml: 0.5 }} onClick={() => handleMissingProgressNotes()}>
                  <Iconify
                    color="#1890FF"
                    icon={openModal ? 'eva:save-outline' : 'eva:edit-outline'}
                  />
                </IconButton>
              </Typography>
            </Stack>
          )}
          {isValidItem(checklistValid, 'MISSING_LAB_NOTES_ITEM') && (
            <Stack direction="row" alignItems="center" justifyContent="space-between">
              <Typography variant="body2">{i18n('referral.details.referralSummary.labNotes', 'crs')}</Typography>
              <Typography variant="body2" sx={{ color: 'text.secondary' }}>
                {!isValidItem(checklistValid, 'MISSING_LAB_NOTES_ITEM') ? 'No' : 'Yes'}
                <IconButton sx={{ p: 0.5, ml: 0.5 }} onClick={() => handleMissingLabNotes()}>
                  <Iconify
                    color="#1890FF"
                    icon={openModal ? 'eva:save-outline' : 'eva:edit-outline'}
                  />
                </IconButton>
              </Typography>
            </Stack>
          )}
        </Stack>
      </Collapse>

      <AssignUser
        open={openModal}
        onClose={handleCloseModal}
        users={users}
        ownedId={ownedFromUsers?.id}
        onOwnedAssign={onOwnedAssign}
      />

      <InsuranceInfo
        patient={fhirPatient}
        coverage={coverage}
        serviceRequest={serviceRequest}
        open={openInsuranceInfo}
        workflowReferral={false}
        onCancel={handleCloseInsuranceInfo}
      />

      <Program
        healthCareServices={healthCareServices ?? []}
        open={openPrograms}
        healthCareServiceRelated={serviceRequest?.performer ?? []}
        onCancel={() => setOpenPrograms(false)}
        handleProgramAssigment={handleProgramAssignment}
        edit={true}
      />

    </Card>
  );
}
