import { Box, Typography, Autocomplete, TextField } from '@mui/material';
import { GridColDef } from '@mui/x-data-grid';
import { Edit as EditIcon, NoteAddOutlined as NoteIcon } from '@mui/icons-material';
import ActionButton, { MenuOption } from '../../../../../components/ActionButton';
import {
  GoalGridRowData,
  SeverityStatusDataWithDate,
  onSuccess,
} from '../../../../../@types/crs/case';
import { GridFilterDrawerProps } from '../DataGridWithFilters/DataGridWithFilters';
import SeverityStatus from 'src/components/SeverityStatus';
import moment from 'moment';
import { ValueSetComposeIncludeConcept } from '../../../../../nicheaim-infrastructure/application/adapters/out/repositories/fhir/resources/resources';
import PeriodFilter, { onDateChange } from 'src/components/PeriodFilter';
import CheckboxList from 'src/components/CheckboxList';
import useGridFilterConsolidation from 'src/hooks/useGridFilterConsolidation';
import CaseTaskGrid from '../CaseTaskGrid';
import CollapsibleDataGrid from '../CollapsibleDataGrid';
import GoalModal, { GoalLifeCycleStatusOption } from './GoalModal';
import { WrappedPatient } from 'src/@nicheaim/fhir-base/wrappers/Patient';
import { WrappedCarePlan } from 'src/@nicheaim/fhir-base/wrappers/CarePlan';
import useObjectState from 'src/hooks/useObjectState';
import { WrappedGoal } from 'src/@nicheaim/fhir-base/wrappers/Goal';
import { WrappedPlanDefinition } from 'src/@nicheaim/fhir-base/wrappers/PlanDefinition';
import React, { useEffect } from 'react';
import { momentDateComparator } from 'src/utils/dates';
import useValueSetsByIdentifiers from 'src/hooks/useValueSetsByIdentifier';
import ModalCommunication from 'src/sections/crs/common/ModalCommunication';
import { WrappedCondition } from '../../../../../@nicheaim/fhir-base/wrappers/Condition';
import { useTheme } from '@mui/material/styles';
import useLocales from 'src/hooks/useLocales';
import GoalConditionList from './GoalConditionList';
import { OnConditionUnlink } from './GoalConditionDrawer';
import useClientGrid from 'src/hooks/useClientGrid';
import usePermissionsContext from 'src/hooks/usePermissionsContext';
import { GoalsPermissions } from 'src/sections/crs/types';
import { PermissionsProvider } from 'src/contexts/PermissionsContext';
import useCollapsibleContext from 'src/hooks/useCollapsibleContext';

export interface GoalFilters {
  selectedPathways: ValueSetComposeIncludeConcept[];
  startDate: moment.Moment | null;
  endDate: moment.Moment | null;
  status: GoalLifeCycleStatusOption[];
}

export interface GoalsGridProps extends GridFilterDrawerProps<GoalFilters> {
  pathways: ValueSetComposeIncludeConcept[];
  defaultPathway?: ValueSetComposeIncludeConcept;
  goals: GoalGridRowData[];
  showPathwayFilter?: boolean;
  patient: WrappedPatient | null;
  carePlan?: WrappedCarePlan | null;
  onSuccessfulCreation: onSuccess;
  onSuccessfulEdit: onSuccess;
  onTaskSuccessfulCreation?: onSuccess;
  onTaskSuccessfulEdit?: onSuccess;
  planDefinitions: WrappedPlanDefinition[];
  conditions: WrappedCondition[];
  showCategory?: boolean;
  title?: string;
  onConditionUnlink: OnConditionUnlink;
  statusesList: any;
}

export const initialGoalFilters: GoalFilters = {
  selectedPathways: [],
  startDate: null,
  endDate: null,
  status: [],
};

const GoalsGrid = ({
  goals,
  pathways,
  filterValues,
  showPathwayFilter = true,
  onSearchTextFieldChange,
  searchTextFieldValue,
  onFilterDrawerOpen,
  onFilterDrawerClose,
  isFilterDrawerOpen,
  onApplyFilters,
  patient,
  defaultPathway,
  carePlan,
  onSuccessfulCreation,
  onSuccessfulEdit,
  onTaskSuccessfulCreation,
  planDefinitions,
  conditions,
  onTaskSuccessfulEdit,
  showCategory = false,
  title,
  onConditionUnlink,
  statusesList
}: GoalsGridProps) => {
  const { filters, updateFilters, onClearAllFilters } = useGridFilterConsolidation<GoalFilters>(
    filterValues,
    { ...initialGoalFilters }
  );
  const { i18n } = useLocales();

  const theme = useTheme();

  const { handleItem } = useCollapsibleContext();

  useEffect(() => handleItem?.(statusesList), [statusesList]);

  const {
    tasks: tasksPermissions,
    notes: notesPermissions,
    isAllowedToEdit,
  } = usePermissionsContext<GoalsPermissions>() ?? {};

  const {
    valueSets: [goalStatuses],
  } = useValueSetsByIdentifiers(['crs-goal-lifecycle-status']);

  const { sortModel, handleSortModelChange } = useClientGrid({
    defaultSort: [{ field: 'endDate', sort: 'asc' }],
  });

  const [{ isGoalModalOpen, goalToEdit, addNoteToTask }, updateState] = useObjectState<{
    isGoalModalOpen: boolean;
    goalToEdit: WrappedGoal | null;
    addNoteToTask: boolean;
  }>({
    goalToEdit: null,
    isGoalModalOpen: false,
    addNoteToTask: false,
  });

  const getMenuOptions = (goal: WrappedGoal): MenuOption[] => [
    ...(isAllowedToEdit
      ? [
          {
            IconComponent: EditIcon,
            title: 'Edit',
            callback: () => {
              updateState({
                isGoalModalOpen: true,
                goalToEdit: goal,
              });
            },
            iconColor: theme.palette.primary.main,
          },
        ]
      : []),
    ...(notesPermissions?.isAllowedToView
      ? [
          {
            IconComponent: NoteIcon,
            title: 'Notes',
            callback: () => {
              updateState({
                addNoteToTask: true,
                goalToEdit: goal,
              });
            },
            iconColor: theme.palette.primary.main,
          },
        ]
      : []),
  ];

  const columns: GridColDef[] = [
    { field: 'goal', headerName: 'Goal', flex: 2 },
    {
      field: 'status',
      headerName: `${i18n('case.details.listofGoals.status', 'crs')}`,
      flex: 1,
    },
    {
      field: 'conditions',
      headerName: `${i18n('case.details.listofGoals.condition', 'crs')}`,
      flex: 1,
      renderCell: (params) => (
        <GoalConditionList
          conditions={params.row.conditions}
          goal={params.row.wrappedGoal}
          onConditionUnlink={onConditionUnlink}
        />
      ),
    },
    ...(showCategory
      ? [
          {
            field: 'pathwayDisplay',
            headerName: `${i18n('case.details.listofGoals.category', 'crs')}`,
            flex: 1,
          },
        ]
      : []),
    {
      field: 'endDate',
      headerName: `${i18n('case.details.listofGoals.endDate', 'crs')}`,
      flex: 1,
      sortComparator: (_, __, cellParams1, cellParams2) => {
        const { date: date1 } = (cellParams1?.value as SeverityStatusDataWithDate) ?? {};
        const { date: date2 } = (cellParams2?.value as SeverityStatusDataWithDate) ?? {};
        return momentDateComparator(date1, date2);
      },
      renderCell: (params) => {
        const { endDate } = params.row as GoalGridRowData;
        return <SeverityStatus status={endDate} />;
      },
    },
    {
      field: 'edit',
      headerName: '',
      flex: 0.3,
      sortable: false,
      renderCell: (params) => {
        const { wrappedGoal } = params.row as GoalGridRowData;
        return <ActionButton menuOptions={getMenuOptions(wrappedGoal)} />; //TODO need to add functionality to open edit modal
      },
    },
  ];

  const handleDateFilterChange =
    (dateProp: 'endDate' | 'startDate'): onDateChange =>
    (newValue) => {
      updateFilters({ [dateProp]: newValue });
    };
  const handlePathwaySelection: OnPathwaySelectionChange = (_, newValue) => {
    updateFilters({ selectedPathways: newValue });
  };

  const { startDate, endDate, selectedPathways, status } = filters;

  return (
    <>
      <CollapsibleDataGrid
        rowHeight={80}
        getRowHeight={() => 'auto'}
        addButtonTitle={i18n('case.details.listofGoals.button', 'crs')}
        title={''}
        sortModel={sortModel}
        onSortModelChange={handleSortModelChange}
        onAddButtonClick={() => {
          updateState({
            isGoalModalOpen: true,
            goalToEdit: null,
          });
        }}
        onFilterDrawerOpen={onFilterDrawerOpen}
        rows={goals}
        columns={columns}
        getRowId={(row) => row.id}
        onSearchTextFieldChange={onSearchTextFieldChange}
        searchTextFieldValue={searchTextFieldValue}
        renderCollapsibleContent={({ tasks, id, wrappedGoal }: GoalGridRowData) => (
          <PermissionsProvider permissions={tasksPermissions ?? null}>
            <CaseTaskGrid
              patient={patient}
              carePlan={carePlan}
              tasks={tasks}
              isNestedGrid={true}
              showPagination={false}
              goal={wrappedGoal}
              onSuccessfulCreation={(task) => {
                onTaskSuccessfulCreation?.(task);
              }}
              onSuccessfulEdit={(task) => {
                onTaskSuccessfulEdit?.(task);
              }}
              title={title}
            />
          </PermissionsProvider>
        )}
        subGridRowsExtractor={({ tasks }: GoalGridRowData) => tasks ?? []}
        Filters={
          <GoalFilter
            selectedStatuses={status}
            selectedPathways={selectedPathways}
            showPathwayFilter={showPathwayFilter}
            pathways={pathways}
            endDate={endDate}
            startDate={startDate}
            goalStatuses={goalStatuses?.asList?.() ?? []}
            onEndDateChange={handleDateFilterChange('endDate')}
            onStartDateChange={handleDateFilterChange('startDate')}
            onPathwaySelectionChange={handlePathwaySelection}
            onStatusSelectionChange={(checkedItems) => {
              updateFilters({
                status: checkedItems,
              });
            }}
          />
        }
        FilterDrawerProps={{
          title: 'Goals Filters',
          open: isFilterDrawerOpen,
          onApplyButtonClick: () => {
            onApplyFilters(filters);
          },
          onCloseIconButtonClick: onFilterDrawerClose,
          onClearAllButtonClick: onClearAllFilters,
        }}
      />
      <GoalModal
        planDefinitions={planDefinitions}
        conditions={conditions}
        goalToEdit={goalToEdit}
        onSuccessfulEdit={onSuccessfulEdit}
        onSuccessfulCreation={onSuccessfulCreation}
        defaultPathway={defaultPathway}
        pathways={pathways}
        patient={patient}
        open={isGoalModalOpen}
        carePlan={carePlan}
        onClose={() => {
          updateState({
            isGoalModalOpen: false,
          });
        }}
      />
      <ModalCommunication
        isAllowedToAdd={!!notesPermissions?.isAllowedToAdd}
        open={addNoteToTask}
        patient={patient}
        onCancel={() => {
          updateState({ addNoteToTask: false });
        }}
        carePlan={carePlan}
        resource={goalToEdit}
        typeNote="notes_goal"
      />
    </>
  );
};

export type OnPathwaySelectionChange = (
  event: React.SyntheticEvent,
  newValue: ValueSetComposeIncludeConcept[]
) => void;
interface GoalFilterProps {
  showPathwayFilter?: boolean;
  pathways: ValueSetComposeIncludeConcept[];
  startDate: moment.Moment | null;
  endDate: moment.Moment | null;
  selectedPathways: ValueSetComposeIncludeConcept[];
  onStartDateChange: onDateChange;
  onEndDateChange: onDateChange;
  onPathwaySelectionChange: OnPathwaySelectionChange;
  onStatusSelectionChange: (checkedStatus: GoalLifeCycleStatusOption[]) => void;
  selectedStatuses: GoalLifeCycleStatusOption[];
  goalStatuses: GoalLifeCycleStatusOption[];
}

const GoalFilter = ({
  showPathwayFilter = true,
  pathways,
  startDate,
  endDate,
  onStartDateChange,
  onEndDateChange,
  onPathwaySelectionChange,
  selectedPathways,
  onStatusSelectionChange,
  selectedStatuses,
  goalStatuses,
}: GoalFilterProps) => (
  <>
    <Box py={3}>
      {showPathwayFilter && (
        <Box>
          <Typography fontSize={'1rem'} color={'#919eab'} mb={1}>
            Pathway
          </Typography>

          <Autocomplete
            value={selectedPathways}
            popupIcon={null}
            fullWidth
            multiple
            onChange={onPathwaySelectionChange}
            options={pathways}
            getOptionLabel={(option) => option?.display || ''}
            defaultValue={[]}
            renderInput={(params) => <TextField {...params} variant="outlined" />}
          />
        </Box>
      )}
      <Box marginTop={showPathwayFilter ? 3 : 0} marginBottom={1}>
        <PeriodFilter
          startDate={startDate}
          endDate={endDate}
          onStartDateChange={onStartDateChange}
          onEndDateChange={onEndDateChange}
        />
      </Box>
    </Box>
    <CheckboxList
      containerSx={{ marginBottom: 4 }}
      title="Status"
      keyExtractor={(item) => item.code as string}
      labelExtractor={(item) => item.display}
      onChange={onStatusSelectionChange}
      options={goalStatuses}
      externalCheckedItems={selectedStatuses}
    />
  </>
);

export default GoalsGrid;
