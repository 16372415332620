import { useState, useEffect } from 'react';
import TasksGrid, { TaskFilters, initialTaskFilters } from './TasksGrid/TasksGrid';
import { WrappedTask } from '../../../../@nicheaim/fhir-base/wrappers/Task';
import { TaskGridRowData, onSuccess } from '../../../../@types/crs/case';
import { getTaskGridRows, searchIfContainedInObj } from 'src/sections/crs/helpers/common';
import { Reference, Task, ValueSetComposeIncludeConcept } from 'src/nicheaim-infrastructure/application/adapters/out/repositories/fhir/resources';
import useGridFilters, { GridFilters } from 'src/hooks/useGridFilters';
import { WrappedPatient } from 'src/@nicheaim/fhir-base/wrappers/Patient';
import { WrappedCarePlan } from 'src/@nicheaim/fhir-base/wrappers/CarePlan';
import moment from 'moment';
import { isBetweenDates } from 'src/utils/dates';
import { WrappedGoal } from 'src/@nicheaim/fhir-base/wrappers/Goal';
import useValueSetsByIdentifiers from 'src/hooks/useValueSetsByIdentifier';
import { WrappedHealthcareService } from 'src/@nicheaim/fhir-base/wrappers/HealthcareService';
import { Box, CardContent, Tab, Tabs, Typography } from '@mui/material';
import SeverityStatus, { AlertSeverity } from 'src/components/SeverityStatus';
import useObjectState from 'src/hooks/useObjectState';
import { TaskByPathway } from './TasksByPathway/TasksByPathway';

const pathwayAll: ValueSetComposeIncludeConcept = {
  code: 'all',
  display: 'All',
};

interface TaskByPathwayState {
  selectedPathway: string;
}

export interface CarePlanTaskGridProps {
  tasks: WrappedTask[];
  patient: WrappedPatient | null;
  carePlan?: WrappedCarePlan | null;
  goal?: WrappedGoal | null;
  healthCareServices?: WrappedHealthcareService[] | null;
  isNestedGrid?: boolean;
  showPagination?: boolean;
  onSuccessfulCreation: onSuccess;
  onSuccessfulEdit: onSuccess;
  title?: string;
  onAssessmentSelect?: (task: Task) => void;
  pathwaysList?: TaskByPathway[];
  handleItem?: (items: any) => void;
}

const CaseTaskGrid = ({
  tasks,
  isNestedGrid,
  showPagination,
  patient,
  carePlan,
  goal,
  healthCareServices,
  onSuccessfulCreation,
  onSuccessfulEdit,
  title,
  onAssessmentSelect,
  pathwaysList,
  handleItem
}: CarePlanTaskGridProps) => {

  const [{ selectedPathway }, updateState] = useObjectState<TaskByPathwayState>({
    selectedPathway: 'all',
  });
  
  const handlePathwayTabChange = (_: React.SyntheticEvent, pathway: string) => {
    updateState({
      selectedPathway: pathway,
    });
  };

  const {
    isFilterDrawerOpen,
    onFilterDrawerOpen,
    onFilterDrawerClose,
    onSearchTextFieldChange,
    searchTextFieldValue,
    filters,
    onApplyFilters,
  } = useGridFilters<TaskFilters>(initialTaskFilters);
  const [taskGridRows, setTasksGridRows] = useState<TaskGridRowData[]>([]);
  const [owners, setOwners] = useState<Reference[]>([]);

  const {
    valueSets: [taskStatuses],
  } = useValueSetsByIdentifiers(['crs-task-status']);

  useEffect(() => {
    setOwners(
      tasks.reduce<Reference[]>((owners, { owner }) => {
        if (!owner) return owners;
        if (owners.find(({ reference }) => reference === owner?.reference)) return owners;
        return [...owners, owner];
      }, [])
    );
  }, [tasks]);

  useEffect(() => {
    const taskGridRows = getTaskGridRows(tasks, taskStatuses?.asList?.() ?? []);
    if(handleItem) handleItem(getStatusCountByPathway(taskGridRows));
    setTasksGridRows(filterTaskGridRows(taskGridRows, { filters, searchTextFieldValue }));
  }, [tasks, filters, searchTextFieldValue, taskStatuses]);
  
  return (
    <>
      {pathwaysList && (
        <Tabs
          scrollButtons={'auto'}
          variant={'scrollable'}
          value={selectedPathway}
          onChange={handlePathwayTabChange}
          aria-label="PathWay Tab"
          sx={{ ml: 2 }}
        >
          {pathwaysList?.map((pathway) => {
            const { code, id } = pathway;
            const getTasks = getTaskGridRows(
              getGoalsByPathWay(code, taskGridRows, id),
              taskStatuses?.asList?.() ?? []
            );
            const statuses = getStatusCountByPathway(getTasks);
            return (
              <Tab
                key={code}
                label={
                  <Box display={'flex'} flexDirection={'row'}>
                    <Typography sx={{ fontSize: '1em' }} variant="caption">
                      {code?.toUpperCase()}{' '}
                    </Typography>
                    {Object.entries(statuses).map(([severity, count]) => (
                      <SeverityStatusCount
                        key={severity}
                        count={count}
                        severity={severity as AlertSeverity}
                      />
                    ))}
                  </Box>
                }
                value={code}
              />
            );
          })}
        </Tabs>
      )}
      <CardContent className={'cardContent'} sx={{ padding: 0 }}>
        <Box sx={{ marginTop: 2, paddingBottom: 0 }}>
          {pathwaysList ? pathwaysList?.map((pathway) => {
            const { code, id } = pathway;
            const taskGridRowsByPathway =  getTaskGridRows(
              getGoalsByPathWay(code, taskGridRows, id),
              taskStatuses?.asList?.() ?? []);
            return (
              <TabPanel key={code} pathway={code} selectedPathway={selectedPathway}>
                <TasksGrid
                  patient={patient}
                  tasks={taskGridRowsByPathway}
                  owners={owners}
                  goal={goal}
                  searchTextFieldValue={searchTextFieldValue}
                  onSearchTextFieldChange={onSearchTextFieldChange}
                  isFilterDrawerOpen={isFilterDrawerOpen}
                  onFilterDrawerOpen={onFilterDrawerOpen}
                  onFilterDrawerClose={onFilterDrawerClose}
                  onApplyFilters={onApplyFilters}
                  filterValues={filters}
                  isNestedGrid={isNestedGrid}
                  showPagination={showPagination}
                  onSuccessfulCreation={onSuccessfulCreation}
                  onSuccessfulEdit={onSuccessfulEdit}
                  carePlan={carePlan}
                  healthCareServices={healthCareServices}
                  title={title}
                  onAssessmentSelect={onAssessmentSelect}
                />
              </TabPanel>
            );
          }) : (
            <TasksGrid
              patient={patient}
              tasks={taskGridRows}
              owners={owners}
              goal={goal}
              searchTextFieldValue={searchTextFieldValue}
              onSearchTextFieldChange={onSearchTextFieldChange}
              isFilterDrawerOpen={isFilterDrawerOpen}
              onFilterDrawerOpen={onFilterDrawerOpen}
              onFilterDrawerClose={onFilterDrawerClose}
              onApplyFilters={onApplyFilters}
              filterValues={filters}
              isNestedGrid={isNestedGrid}
              showPagination={showPagination}
              onSuccessfulCreation={onSuccessfulCreation}
              onSuccessfulEdit={onSuccessfulEdit}
              carePlan={carePlan}
              healthCareServices={healthCareServices}
              title={title}
              onAssessmentSelect={onAssessmentSelect}
            />
          )}
        </Box>
      </CardContent>
    </>
  );
};

export const filterTaskGridRows = (
  taskGridRows: TaskGridRowData[],
  { filters, searchTextFieldValue }: GridFilters<TaskFilters>
): TaskGridRowData[] => {
  const { status, startDate, endDate, selectedOwners } = filters;
  let taskGridRowsFiltered = [...taskGridRows];
  const searchByString = searchTextFieldValue?.toLowerCase().trim() ?? '';
  if (searchByString.length >= 3) {
    taskGridRowsFiltered = taskGridRowsFiltered.filter((taskGridRow) =>
      searchIfContainedInObj(
        taskGridRow,
        ['task', 'owner', 'endDate.message', 'status'],
        searchByString
      )
    );
  }

  return taskGridRowsFiltered.filter((taskRow) => {
    const task = taskRow.wrappedTask;
    const taskStartDate = moment(task?.getStartDate() ?? null);
    const taskEndDate = moment(task?.getEndDate() ?? null);
    if (startDate?.isValid?.() && endDate?.isValid?.()) {
      if (!taskStartDate.isValid() || !taskEndDate.isValid()) return false;
      if (!isBetweenDates(taskStartDate, taskEndDate, startDate, endDate)) return false;
    }

    if (startDate?.isValid?.()) {
      if (!taskStartDate.isValid()) return false;
      if (!taskStartDate.isSameOrAfter(startDate)) return false;
    }

    if (endDate?.isValid?.()) {
      if (!taskEndDate.isValid()) return false;
      if (!taskEndDate.isSameOrBefore(endDate)) return false;
    }

    if (selectedOwners.length) {
      if (!selectedOwners.find((owner) => task?.owner?.reference === owner.reference)) return false;
    }

    if (status.length) {
      if (!status.find((status) => status.code === task?.status)) return false;
    }
    return true;
  });
};

interface TabPanelProps {
  children: React.ReactNode;
  selectedPathway: string;
  pathway: string | undefined;
}

const TabPanel = ({ children, selectedPathway, pathway }: TabPanelProps): JSX.Element => (
  <>{selectedPathway === pathway ? <>{children}</> : null}</>
);

type DueDateSeverities = AlertSeverity.SUCCESS | AlertSeverity.ERROR | AlertSeverity.WARNING;

export type StatusSeverityCount = Record<DueDateSeverities, number>;

const getStatusCountByPathway = (tasks: TaskGridRowData[]): StatusSeverityCount => {
  const statuses: StatusSeverityCount = {
    [AlertSeverity.ERROR]: 0,
    [AlertSeverity.WARNING]: 0,
    [AlertSeverity.SUCCESS]: 0,
  };
  for (const {
    endDate: { severity },
  } of tasks) {
    if (statuses.hasOwnProperty(severity as AlertSeverity))
      statuses[severity as DueDateSeverities]++;
  }
  return statuses;
};

const getGoalsByPathWay = (
  pathwayCode: string | undefined,
  tasks: TaskGridRowData[],
  healthCareServiceId: string | undefined
) => {
  if (!pathwayCode || pathwayCode === pathwayAll.code) return tasks.map((e) => e.wrappedTask);
  return tasks
    .filter((e) =>
      e?.basedOn?.some((r) => r.reference === `HealthcareService/${healthCareServiceId}`)
    )
    .map((e) => e.wrappedTask);
};

interface SeverityStatusCountProps {
  count: number;
  severity: AlertSeverity;
}

const SeverityStatusCount = ({ count, severity }: SeverityStatusCountProps) => (
  <SeverityStatus status={{ message: String(count), severity }} sx={{ height: '20px', ml: 0.5 }} />
);

export default CaseTaskGrid;
