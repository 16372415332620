import { createContext } from 'react';
import { MenuTaskHandler, TaskHandlerByType } from 'src/sections/crs/types';

export interface TaskContextData {
  rowDblClickHandlers?: TaskHandlerByType[];
  menuTaskHandlers?: MenuTaskHandler[];
}

const initialTaskContextData: TaskContextData = {
  rowDblClickHandlers: [],
  menuTaskHandlers: [],
};

const TaskContext = createContext<TaskContextData>(initialTaskContextData);

export default TaskContext;
